import styled from "@emotion/styled";
import { Box, Grid, Paper } from "@mui/material";

export const MarketplacePaper = styled(Paper)`
    height: 260px;
`

export const ToolbarPaper = styled(Paper)`
    width: 100%; 
    display: flex; 
    align-items: center;
    padding: 1rem 2rem; 
    border-radius: 0;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    box-shadow: none;
    height: 70px;

    @media (max-width: 1280px) {
        height: 100%;
    }

    @media (max-width: 728px) {
        width: 100%;
        flex-direction: column;

        h6 {
            margin-bottom: 0.5rem;
        }
    }
`

export const ToolbarBox = styled(Box)`
    display: flex;
    align-items: center;

    @media (max-width: 728px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const MarketplacePaperTitulo = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const MarketplacePaperDescricao = styled(Paper)`
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
`

export const NenhumMarketplaceGrid = styled(Grid)`
    display: flex; 
    align-items: center;
    width: 100%; 
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: 4rem;
`