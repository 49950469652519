export const validarTelefone = (value: string | undefined, required?: boolean) => {
    if (!value) return required == true ? false : true;

    const padroesTelefone = [
        /^\+55 \(\d{2}\) \d{5}-\d{4}$/, // +55 (41) 99999-9999
        /^\+55 \(\d{2}\) \d{4}-\d{4}$/, // +55 (41) 9999-9999
        /^\(\d{2}\) \d{5}-\d{4}$/,      // (41) 99999-9999
        /^\(\d{2}\) \d{4}-\d{4}$/,      // (41) 9999-9999
        /^\d{5}-\d{4}$/,                // 99999-9999
        /^\d{4}-\d{4}$/                 // 9999-9999
    ];

    return padroesTelefone.some(padrao => padrao.test(value));
};