import { useMemo } from "react";
import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch";
import { IPedido } from "../interface/IPedido";

export const useFetchPedidos = (filtros?: string) => {
    const apiPedidos = useMemo(() => ApiFactory.getApi("MS_PEDIDOS"), []);
    const { data: pedidos, isLoading, handleRefresh, error, mutate } = useFetch<IPedido[]>(
        apiPedidos, 
        `/${filtros ?? ""}`
    );

    return {
        pedidos,
        isLoading,
        handleRefresh,
        error,
        mutate
    }
}