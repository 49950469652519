import { Box, Typography } from "@mui/material";

export const PaginaNaoEncontrada = () => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", flexDirection: "column" }}>
            <img 
                src={`${process.env.PUBLIC_URL}/images/astronaut.png`} 
                alt="Astronaut" 
                style={{ width: "300px" }}
            />
            <Typography variant="h5" fontWeight={"bold"} mt={"2rem"}>Página não encontrada</Typography>
        </Box>
    );
}