import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLetras } from "../../../core/hooks/useLetras";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { ICategoria } from "../../interfaces/ICategoria";
import { IProduto } from "../../interfaces/IProduto";
import { useState } from 'react';
import { CopiarProdutoModal } from '../CopiarProdutoModal';
import { ConfirmacaoAcaoModal } from '../../../core/components/ConfirmacaoAcaoModal';
import { useProdutos } from '../../hooks/useProdutos';

interface CardIndustrializadoCardapioProps {
    produto: IProduto;
    categoriaUuid: string;
    excluir: (produto: IProduto) => void;
    categorias: ICategoria[];
    handleRefresh: () => void;
}

export const CardIndustrializadoCardapio = ({ 
    produto, 
    excluir, 
    categoriaUuid, 
    categorias, 
    handleRefresh
}: CardIndustrializadoCardapioProps) => {
    const navigate = useNavigate();
    const { real } = useMoeda();
    const { titulo } = useLetras();
    const { salvar, loading } = useProdutos();

    const ativar = async () => {
        await salvar(categoriaUuid, {
            ...produto,
            inativadoAs: undefined
        });
        handleRefresh();
    }

    const inativar = async () => {
        await salvar(categoriaUuid, {
            ...produto,
            inativadoAs: new Date()
        });
        handleRefresh();
    }

    const [copiarProduto, setCopiarProduto] = useState<boolean>(false);
    const handleAbrirCopiarProduto = () => setCopiarProduto(true);
    const handleFecharCopiarProduto = () => setCopiarProduto(false);


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openAtivar, setOpenAtivar] = useState(false);
    const handleOpenAtivar = () => setOpenAtivar(true);
    const handleCloseAtivar = () => setOpenAtivar(false);

    return (
        <>
            <CopiarProdutoModal handleRefresh={handleRefresh} categorias={categorias} open={copiarProduto} produto={produto} handleCancelar={handleFecharCopiarProduto} />
            <ConfirmacaoAcaoModal 
                titulo={produto?.inativadoAs == undefined ? "Inativar produto" : "Ativar produto"}
                descricao={produto?.inativadoAs == undefined 
                    ? "Nenhum marketplace pode visualizar um produto quando ele está inativo." 
                    : "O produto estará disponível em nos marketplaces configurados."
                }
                open={openAtivar}
                onCancelar={handleCloseAtivar}
                onConfirmar={produto?.inativadoAs == undefined ? inativar : ativar}
                loading={loading}
            />
            <Grid item xs={12} md={4} xl={3}>
                <Card variant="outlined">
                    <CardHeader
                        action={
                            <>
                                <IconButton
                                    aria-label="settings"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => navigate(`/cardapio/categorias/${categoriaUuid}/produtos/${produto.uuid}`)}>
                                        <EditIcon fontSize="small" sx={{ marginRight: "10px" }} /> Editar
                                    </MenuItem>
                                    <MenuItem onClick={() => excluir(produto)} >
                                        <DeleteIcon fontSize="small" sx={{ marginRight: "10px" }} /> Excluir
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleAbrirCopiarProduto();
                                    }}>
                                        <CopyAllIcon fontSize="small" sx={{ marginRight: "10px" }} /> Copiar
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                        title={
                            <Tooltip title={titulo(produto.descricao)}>
                                <Typography variant="h6">{titulo(produto.descricao, 20)}</Typography>
                            </Tooltip>
                        }
                        sx={{ height: "90px" }}
                        subheader={
                            <FormControlLabel
                                control={<Switch checked={produto?.inativadoAs == undefined ? true : false} size='small' />}
                                label={produto?.inativadoAs == undefined ? "Ativo" : "Inativo"}
                                onClick={handleOpenAtivar}
                            />
                        }
                    />
                    {produto.imagens?.length ? (
                        <CardMedia
                            component="img"
                            height="140"
                            image={`${produto.imagens[0].url}`}
                            sx={{ objectFit: "contain" }}
                            alt="produto"
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            height="140"
                            image={`${process.env.PUBLIC_URL}/images/produtos/industrializado.png`}
                            sx={{ objectFit: "contain" }}
                            alt="produto"
                        />
                    )}
                    <CardContent sx={{ height: "180px" }}>
                        <Chip sx={{ margin: "0.3rem" }} label={real(produto.preco)} />
                        {produto.marca && <Chip sx={{ margin: "0.3rem" }} label={produto.marca} />}
                        {produto.codigoBarras && <Chip sx={{ margin: "0.3rem" }} label={produto.codigoBarras} />}
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}