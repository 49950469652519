import { Add, AddCircleOutlined, ArrowDropDown, Delete, Edit, RemoveCircleOutline, Search } from "@mui/icons-material";
import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, ListItemIcon, Menu, MenuItem, OutlinedInput, Paper, Toolbar, Tooltip, Typography } from "@mui/material";
import { IInsumo } from "../../../../interfaces/IInsumo";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToolbarPaper, Tools } from "./styles";

interface IToolbarProps {
    selected: IInsumo[];
    handleSearchChange: (e: any) => void;
    handleOpenExclusao: () => void;
    search: string;
}

export const ToolbarInsumos = ({ selected, search, handleSearchChange, handleOpenExclusao }: IToolbarProps) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ToolbarPaper>
            {selected?.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {selected?.length} {selected?.length == 1 ? "insumo" : "insumos"} {selected?.length == 1 ? "selecionado" : "selecionados"}
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Insumos e ingredientes
                </Typography>
            )}
            <Tools>
                <Button
                    onClick={handleClick}
                    endIcon={<ArrowDropDown />}
                    variant={"contained"}
                    disabled={selected?.length <= 0}
                >
                    Ações
                </Button>
                <Menu
                    id={`insumos-menu`}
                    MenuListProps={{
                        'aria-labelledby': `insumos-menu`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem disabled>
                        <Typography variant="inherit" noWrap>
                            {selected?.length} {selected?.length == 1 ? "insumo" : "insumos"} {selected?.length == 1 ? "selecionado" : "selecionados"}
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <Tooltip title='Edite o insumo'>
                        <MenuItem disabled={selected?.length != 1} onClick={() => navigate(`editar/${selected[0]?.uuid}`)}>
                            <ListItemIcon>
                                <Edit fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Editar
                            </Typography>
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title='Exclua o(s) insumo(s)'>
                        <MenuItem disabled={selected?.length < 1} onClick={handleOpenExclusao}>
                            <ListItemIcon>
                                <Delete fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Excluir
                            </Typography>
                        </MenuItem>
                    </Tooltip>
                    <Divider />
                    <Tooltip title='Reabasteca o estoque do(s) insumo(s)'>
                        <MenuItem disabled={selected?.length < 1} onClick={() => navigate("reabastecer")}>
                            <ListItemIcon>
                                <AddCircleOutlined fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Reabastecer
                            </Typography>
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title='Consuma o estoque do(s) insumo(s)'>
                        <MenuItem disabled={selected?.length < 1} onClick={() => navigate("consumir")}>
                            <ListItemIcon>
                                <RemoveCircleOutline fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Consumir
                            </Typography>
                        </MenuItem>
                    </Tooltip>
                </Menu>
                <Tooltip sx={{ ml: "1rem" }} title='Cadastre um novo insumo'>
                    <Button
                        startIcon={<Add />}
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("cadastrar")}
                        disabled={selected.length >= 1}
                    >
                        Cadastrar
                    </Button>
                </Tooltip>
                <FormControl sx={{ width: "300px", ml: "1rem" }}>
                    <InputLabel htmlFor="filtro-nome-insumo">Pesquisa</InputLabel>
                    <OutlinedInput
                        size="small"
                        id="filtro-nome-insumo"
                        startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
                        label="Pesquisa"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </FormControl>
            </Tools>
        </ToolbarPaper>
    );
}