import { Avatar, Box, Button, Drawer, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { RealInput } from "../../../core/components/RealInput";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { IItemProduto } from "../../../pedidos/interface/IItemProduto";
import { IProdutoPedido } from "../../../pedidos/interface/IProdutoPedido";
import { IComplemento } from "../../interfaces/IComplemento";
import { IProduto } from "../../interfaces/IProduto";
import { BodyDetalhesContainer, DetalhesContainer, HeaderDetalhesContainer } from "../CardPreparadoOperacao/styles";
import { ComplementoPaperInput } from "./container/ComplementoPaperInput";
import { IITemComplemento } from "../../interfaces/IITemComplemento";
import { v4 } from "uuid";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";

interface DrawerPreparadoOperacaoProps {
    open: boolean;
    toggleDrawer: () => void;
    produto: IProduto | IProdutoPedido;
    onSubmit: (produto: IProdutoPedido) => void;

    complementos?: IComplemento[];
    observacao?: string;
    valorAdicional?: any;
    uuid?: string;
}

export const DrawerPreparadoOperacao = ({
    toggleDrawer,
    onSubmit,
    produto,
    open,
    uuid,
    observacao: observacaoEdicao,
    valorAdicional: valorAdicionalEdicao,
    complementos: complementosEdicao
}: DrawerPreparadoOperacaoProps) => {
    const [complementos, setComplementos] = useState<IComplemento[]>(complementosEdicao ?? (produto.complementos ?? []));
    const [observacao, setObservacao] = useState<string | undefined>(observacaoEdicao);
    const [valorAdicional, setValorAdicional] = useState<any>(valorAdicionalEdicao);
    const { modeloTela } = useTamanhoTela();

    const snackbar = useSnackbar();
    const { real } = useMoeda();


    const buttonRef = useRef<HTMLButtonElement>(null);


    useEffect(() => {
        const timeout = setTimeout(() => {
            if (open && buttonRef.current) {
                buttonRef.current.focus();
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [open]);

    const handleValorAdicional = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValorAdicional(Number(event.target.value))
    }

    const handleObservacao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObservacao(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const erros: string[] = [];

        complementos.map(complemento => {
            if (complemento.obrigatorio && (!complemento.itensSelecionados || complemento.itensSelecionados?.length == 0)) {
                erros.push(`O complemento ${complemento.descricao} não foi preenchido`)
            }

            if (complemento.obrigatorio && ((complemento.itensSelecionados?.length ?? 1) < complemento.numeroMinimoItens)) {
                erros.push(`O complemento ${complemento.descricao} precisa de ${complemento.numeroMinimoItens} itens selecionados`);
            }

            if (complemento.obrigatorio && complemento.numeroMaximoItens && ((complemento.itensSelecionados?.length ?? 1) > complemento.numeroMaximoItens)) {
                erros.push(`O complemento ${complemento.descricao} precisa de ${complemento.numeroMaximoItens} itens selecionados`);
            }
        })

        if (erros.length) {
            return snackbar(erros[0], { severity: "error" })
        }

        const dadosProduto: IProdutoPedido = {
            uuidProdutoReferencia: produto.uuid,
            valorAdicional,
            ...produto,
            observacao,
            complementos,
            uuid: uuid ?? v4()
        }

        onSubmit(dadosProduto);
        handleToggleDrawer();
    }

    const definirItensComplemento = (complementoUuid: string, itens: IITemComplemento[]) => {
        const novosComplementos = complementos;
        const indexComplemento = complementos.findIndex(complemento => complemento.uuid === complementoUuid);

        if (indexComplemento == -1) {
            return snackbar("Complemento não encontrado");
        }

        novosComplementos[indexComplemento].itensSelecionados = itens;
        setComplementos([...novosComplementos])
    }

    const handleToggleDrawer = () => {
        if (!uuid) {
            const complementos = produto.complementos?.map(complemento => {
                complemento.itensSelecionados = []
                return complemento;
            }) ?? [];
            setComplementos(complementos);
            setObservacao(undefined);
            setValorAdicional(undefined);
        }

        toggleDrawer();
    }

    const valorTotal = useMemo(() => {
        let total = produto.preco ?? 0;

        complementos?.map(complemento => {
            complemento.itensSelecionados?.map(item => {
                total = total + item.preco;
            })
        })

        total = total + (valorAdicional ?? 0)

        return total ?? produto.preco;
    }, [complementos, valorAdicional, uuid, produto.preco])

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={handleToggleDrawer}
            sx={{ borderRadius: 0 }}
        >
            <form onSubmit={handleSubmit}>
                <DetalhesContainer>
                    <HeaderDetalhesContainer>
                        {produto.imagens?.length ? (
                            <Avatar
                                alt="Preparado"
                                src={`${produto.imagens[0].url}`}
                                sx={{ width: 90, height: 90 }}
                            />
                        ) : (
                            <Avatar
                                alt="Preparado"
                                src={`${process.env.PUBLIC_URL}/images/produtos/generico.png`}
                                sx={{ width: 90, height: 90 }}
                            />
                        )}
                        <div>
                            <Typography gutterBottom variant="h5" fontWeight={"bold"} component="div">
                                {produto.descricao}
                            </Typography>
                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                {real(valorTotal)}
                            </Typography>
                        </div>
                    </HeaderDetalhesContainer>
                    <BodyDetalhesContainer>
                        <Grid
                            spacing={1}
                            container
                            height={"100%"}
                        >
                            <Grid item xs={12} sm={6}>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        padding: "1rem",
                                        height: "100%",
                                        overflowY: "auto",
                                        gap: "1rem",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                        Detalhes
                                    </Typography>
                                    {complementos?.map(complemento => {
                                        return (
                                            <ComplementoPaperInput definirItensComplemento={definirItensComplemento} complemento={complemento} />
                                        );
                                    })}
                                    {produto.configuracao?.habilitarObservacao && (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                padding: "1rem",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Stack width={"100%"} flexDirection={"row"} justifyContent={"space-between"}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Observação
                                                </Typography>
                                                <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                    {valorAdicional ? real(valorAdicional) : "R$ 0,00"}
                                                </Typography>
                                            </Stack>
                                            <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
                                                <TextField
                                                    sx={{ width: '65%' }}
                                                    label="Observação"
                                                    placeholder="Observação"
                                                    onChange={handleObservacao}
                                                    value={observacao}
                                                />
                                                <TextField
                                                    sx={{ width: '35%', marginLeft: "0.5rem" }}
                                                    label="Valor adicional"
                                                    placeholder="Valor adicional"
                                                    onChange={handleValorAdicional}
                                                    value={valorAdicional}
                                                    InputProps={{
                                                        inputComponent: RealInput as any,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Paper>
                            </Grid>
                            {modeloTela == "desktop" ? (
                                <Grid item xs={12} sm={6}>
                                    <Paper variant="outlined" sx={{ padding: "1rem", height: "100%" }}>
                                        <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                            Resumo
                                        </Typography>
                                        <Paper variant="outlined" sx={{ padding: "1rem", height: "calc(100% - 8rem)", overflowY: "auto" }}>
                                            <Stack
                                                width={"100%"}
                                                flexDirection={"column"}
                                                justifyContent={"flex-start"}
                                                alignItems={"center"}
                                                height={"100%"}
                                            >
                                                {complementos.map(complemento => {
                                                    return (
                                                        <Paper variant='outlined' sx={{ padding: "1rem", width: "100%", marginTop: "1rem" }}>
                                                            <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                                {complemento.descricao}
                                                            </Typography>
                                                            <Stack sx={{ height: "100%" }} flexDirection={"column"}>
                                                                {complemento.itensSelecionados?.map((item, index) => {
                                                                    return <div key={index}>{item.descricao}</div>
                                                                })}
                                                            </Stack>
                                                        </Paper>
                                                    );
                                                })}
                                                {produto.configuracao?.habilitarObservacao && (
                                                    <Paper variant='outlined' sx={{ padding: "1rem", width: "100%", marginTop: "1rem" }}>
                                                        <Typography gutterBottom variant="h6" fontWeight={"bold"} component="div">
                                                            Observação
                                                        </Typography>
                                                        <Stack sx={{ height: "100%" }} flexDirection={"column"}>
                                                            {observacao && <div>{observacao}</div>}
                                                        </Stack>
                                                    </Paper>
                                                )}
                                            </Stack>
                                        </Paper>
                                        <Button
                                            sx={{ marginTop: "1rem", width: "100%", height: "5rem" }}
                                            type='submit'
                                            variant='contained'
                                            ref={buttonRef}
                                        >
                                            CONCLUIR
                                        </Button>
                                    </Paper>
                                </Grid>
                            ) : (
                                <Button
                                    sx={{ marginTop: "1rem", width: "100%", height: "5rem" }}
                                    type='submit'
                                    variant='contained'
                                    ref={buttonRef}
                                >
                                    CONCLUIR
                                </Button>
                            )}
                        </Grid>
                    </BodyDetalhesContainer>
                </DetalhesContainer>
            </form>
        </Drawer>
    );
}