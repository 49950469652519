import { Typography, TextField, Button, CircularProgress, Stack } from "@mui/material";
import { CadastrarClienteForm } from "./styles";
import { useMascara } from "../../../core/hooks/useMascara";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { clienteSchema } from "../../schemas/clienteSchema";
import { FieldValues } from "react-hook-form/dist/types";
import { useEffect } from "react";
import { useClientes } from "../../hooks/useClientes";
import { ICliente } from "../../interface/ICliente";

interface CadastrarClienteProps {
  dadosCliente?: ICliente;
  cadastrar: (cliente: FieldValues) => void;
  cancelar: () => void;
  loading: boolean;
  editar?: (cliente: FieldValues) => void;
}

export const CadastrarEditarCliente = ({ cadastrar, editar, dadosCliente, cancelar, loading }: CadastrarClienteProps) => {
  const { mascaraCPF, mascaraTelefone } = useMascara();
  const { setValue, register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(clienteSchema),
    mode: "onChange",
  });

  const nomeValue = watch("nome");
  const cpfValue = watch("cpf")
  const telefoneValue = watch("telefone")
  
  useEffect(() => {
    setValue("cpf", mascaraCPF(cpfValue))
  },[cpfValue])
  
  useEffect(() => {
    setValue("telefone", mascaraTelefone(telefoneValue))
  },[telefoneValue])

  useEffect(() => {
    if(dadosCliente) {
      const { telefone, nome, observacao, cpf } = dadosCliente;
      if(nome) {
        setValue("nome", nome);
      }

      if(telefone) {
        setValue("telefone", mascaraTelefone(telefone))
      }

      if(observacao) {
        setValue("observacao", observacao);
      }

      if(cpf) {
        setValue("cpf", mascaraCPF(cpf));
      }
    }
  }, [dadosCliente])

  const handleCancelar = () => {
    setValue("nome", "");
    setValue("telefone", "");
    setValue("cpf", "");
    setValue("observacao", "");
    cancelar();
  }

  const handleFormulario = async (cliente: FieldValues) => {
    if(dadosCliente?.uuid && editar) {
      return editar(cliente);
    }

    return cadastrar(cliente);
  }

  return (
    <CadastrarClienteForm onSubmit={handleSubmit(handleFormulario)}>
      <Typography variant="h6">Cadastro do cliente</Typography>
      <TextField
        label="Nome*"
        style={{ width: "100%", margin: "1rem 0" }}
        {...register("nome")}
        error={!!errors.nome}
        helperText={<>{errors.nome?.message}</>}
        autoFocus={!nomeValue}
      />
      <TextField
        label="Observação"
        style={{ width: "100%", margin: "1rem 0" }}
        {...register("observacao")}
        error={!!errors.observacao}
        helperText={<>{errors.observacao?.message}</>}
      />
      <TextField
        label="Telefone"
        style={{ width: "100%", margin: "1rem 0" }}
        {...register("telefone")}
        error={!!errors.telefone}
        helperText={<>{errors.telefone?.message}</>}
      />
      <TextField
        label="CPF"
        style={{ width: "100%", margin: "1rem 0" }}
        {...register("cpf")}
        error={!!errors.cpf}
        helperText={<>{errors.cpf?.message}</>}
      />
      <Stack flexDirection={"row"} sx={{ width: "100%" }} justifyContent={"space-between"}>
        <Button
          variant="outlined"
          style={{ width: "47%" }}
          type="button"
          onClick={handleCancelar}
        >
          CANCELAR
        </Button>
        <Button
          variant="contained"
          style={{ width: "47%" }}
          type="submit"
        >
          {loading == true ? <CircularProgress color="inherit" /> : (
            dadosCliente?.uuid ? "EDITAR" : "CADASTRAR"
          )}
        </Button>
      </Stack>
    </CadastrarClienteForm>
  );
};
