// components/EnderecoMarketplacePaper.tsx
import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { useMarketplaces } from "../../../../hooks/useMarketplaces";
import { useUFs } from "../../../../../core/hooks/useUFs";
import { useEffect, useState } from "react";
import { Clear, Edit, Save } from "@mui/icons-material";
import { useCEP } from "../../../../../core/hooks/useCEP";
import { useMascara } from "../../../../../core/hooks/useMascara";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";

interface EnderecoMarketplacePaperProps {
    marketplace: IMarketplace;
}

export const EnderecoMarketplacePaper = ({ marketplace }: EnderecoMarketplacePaperProps) => {
    const { salvar, loading } = useMarketplaces();
    const ufs = useUFs();

    const [edicaoHabilitada, setEdicaoHabilitada] = useState(false);

    const [bairro, setBairro] = useState<string | undefined>(marketplace.configuracao?.endereco?.bairro);
    const [cidade, setCidade] = useState<string | undefined>(marketplace.configuracao?.endereco?.cidade);
    const [uf, setUf] = useState<string | undefined>(marketplace.configuracao?.endereco?.uf);
    const [cep, setCep] = useState<string | undefined>(marketplace.configuracao?.endereco?.cep);
    const [rua, setRua] = useState<string | undefined>(marketplace.configuracao?.endereco?.rua);
    const [numero, setNumero] = useState<string | undefined>(marketplace.configuracao?.endereco?.numero);
    const [complemento, setComplemento] = useState<string | undefined>(marketplace.configuracao?.endereco?.complemento);
    const [observacao, setObservacao] = useState<string | undefined>(marketplace.configuracao?.endereco?.observacao);

    const { data: cepData, loading: cepLoading } = useCEP(cep || '');
    const snackbar = useSnackbar();

    const handleLimpar = async () => {
        if (!edicaoHabilitada) {
            return setEdicaoHabilitada(true);
        }

        setBairro(undefined)
        setCidade(undefined)
        setUf(undefined)
        setCep(undefined)
        setRua(undefined)
        setNumero(undefined)
        setComplemento(undefined)
        setObservacao(undefined)

        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                endereco: undefined
            }
        });
        setEdicaoHabilitada(false);
    }

    const handleEdicaoHabilitada = async () => {
        if (!edicaoHabilitada) {
            return setEdicaoHabilitada(true);
        }

        if (!uf) {
            return snackbar("UF inválida", { severity: "error" });
        }

        if (!cidade) {
            return snackbar("Cidade inválida", { severity: "error" });
        }

        if (!rua) {
            return snackbar("Rua inválida", { severity: "error" });
        }

        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                endereco: {
                    bairro,
                    cep,
                    cidade,
                    complemento,
                    numero,
                    observacao,
                    rua,
                    uf
                }
            }
        });
        setEdicaoHabilitada(false);
    };

    useEffect(() => {
        if (cepData) {
            setUf(cepData.uf)
            setCidade(cepData.cidade);
            setRua(cepData.rua);
            setBairro(cepData.bairro);
        }
    }, [cepData]);

    const { mascaraCEP } = useMascara();

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography variant="h6">Endereço</Typography>
                {edicaoHabilitada ? (
                    <Box>
                        <Button onClick={handleLimpar} sx={{ mr: 1 }} variant="outlined" endIcon={<Clear />}>
                            Limpar
                        </Button>
                        <Button onClick={handleEdicaoHabilitada} variant="contained" endIcon={<Save />}>
                            Salvar
                        </Button>
                    </Box>
                ) : (
                    <Button onClick={handleEdicaoHabilitada} endIcon={<Edit />}>
                        Editar
                    </Button>
                )}
            </Box>
            <form>
                <Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
                    <TextField
                        label="CEP"
                        value={cep}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setCep(mascaraCEP(e.target.value))}
                        disabled={!edicaoHabilitada}
                        InputLabelProps={{
                            shrink: !cep ? false : true
                        }}
                    />
                    <FormControl sx={{ marginTop: "1rem" }}>
                        <InputLabel>UF</InputLabel>
                        <Select
                            label="UF*"
                            value={uf}
                            onChange={(e) => setUf(e.target.value as string)}
                            disabled={!edicaoHabilitada}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                    },
                                },
                            }}
                        >
                            {ufs.map((uf, index) => (
                                <MenuItem key={index} value={uf}>{uf}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Cidade*"
                        value={cidade}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setCidade(e.target.value)}
                        disabled={!edicaoHabilitada}
                        InputLabelProps={{
                            shrink: !cidade ? false : true
                        }}
                    />
                    <TextField
                        label="Rua*"
                        value={rua}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setRua(e.target.value)}
                        disabled={!edicaoHabilitada}
                        InputLabelProps={{
                            shrink: !rua ? false : true
                        }}
                    />
                    <TextField
                        label="Número"
                        value={numero}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setNumero(e.target.value)}
                        disabled={!edicaoHabilitada}
                        InputLabelProps={{
                            shrink: !numero ? false : true
                        }}
                    />
                    <TextField
                        label="Complemento"
                        value={complemento}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setComplemento(e.target.value)}
                        disabled={!edicaoHabilitada}
                    />
                    <TextField
                        label="Observação"
                        value={observacao}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setObservacao(e.target.value)}
                        disabled={!edicaoHabilitada}
                    />
                </Box>
            </form>
        </Paper >
    );
};
