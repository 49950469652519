export enum TipoAnexoPedido {
    "nota_nao_fiscal" = "nota_nao_fiscal",
    "comanda_acrescimo_produto_cozinha" = "comanda_acrescimo_produto_cozinha",
    "nota_fiscal" = "nota_fiscal",
    "comanda_numero_pedido" =  "comanda_numero_pedido"
}

export enum TipoAnexoDescricao {
    "nota_fiscal" = "Nota fiscal",
    "nota_nao_fiscal" = "Nota não fiscal",
    "comanda_acrescimo_produto_cozinha" = "Comanda de cozinha",
    "comanda_numero_pedido" = "Comanda com o número do pedido"
}