import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { useConfiguracoesPedidoContext } from '../../contexts/ConfiguracoesPedidoContext';
import { NovoMetodoPagamento } from '../../containers/NovoMetodoPagamento';
import { MetodosPagamentoTransferList } from '../../../core/components/MetodosPagamentoTransferList';
import { Tools } from './styles';


export const MetodosPagamento = () => {
    const [formularioAberto, setFormularioAberto] = React.useState(false);
    const { configuracao, salvarMetodosPagamento } = useConfiguracoesPedidoContext();

    const abrirFormulario = () => setFormularioAberto(true);
    const fecharFormulario = () => setFormularioAberto(false);

    const adicionarMetodoPagamento = (metodo: string) => {
        salvarMetodosPagamento([...(configuracao?.metodosPagamento ?? []), metodo]);
    }


    return (
        <>
            <Tools variant='outlined'>
                <Typography variant="h6">Métodos de pagamento</Typography>
                <Box>
                    <NovoMetodoPagamento
                        adicionarMetodoPagamento={adicionarMetodoPagamento}
                        fecharFormulario={fecharFormulario}
                        formularioAberto={formularioAberto}
                        metodosExistentes={configuracao?.metodosPagamento ?? []}
                    />
                    <Button variant='contained' startIcon={<Add />} onClick={abrirFormulario}>
                        Customizado
                    </Button>
                </Box>
            </Tools>
            <MetodosPagamentoTransferList metodosPagamento={configuracao?.metodosPagamento ?? []} salvar={salvarMetodosPagamento} />
        </>
    );
}