import { useThemeContext } from '../../contexts/ThemeContext';
import { Box, Typography } from "@mui/material";
import { CardContainer } from "./styles";

interface CardProps {
    titulo: string;
    conteudo: React.ReactNode;
    icone: React.ReactNode
}

export const CardIconTop = ({ conteudo, titulo, icone }: CardProps) => {
    const { theme } = useThemeContext();

    return (
        <CardContainer>
            <Box className="conteudo">
                <Typography variant="subtitle1" fontWeight={"500"}>
                    {titulo}
                </Typography>
                <Typography variant="h4" fontWeight={"bold"}>
                    {conteudo}
                </Typography>
            </Box>
            <Box className="icone">
                {icone}
            </Box>
        </CardContainer>
    )
}