import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, CircularProgress, Divider, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useDataHora } from "../../../core/hooks/useDataHora";
import { useConfiguracoesPedidoContext } from "../../../pedidos/contexts/ConfiguracoesPedidoContext";
import { useClientes } from "../../hooks/useClientes";
import { useFetchClientes } from "../../hooks/useFetchClientes";
import { ICliente } from "../../interface/ICliente";
import { CadastrarEditarClienteModal } from "./containers/CadastrarEditarClienteModal";
import { CadastrarEditarEnderecoModal } from "./containers/CadastrarEditarEnderecoModal";
import { ExcluirClienteModal } from "./containers/ExcluirClienteModal";
import { ButtonsContainer, FiltrosContainer, HeaderContainer, Loading, NenhumCliente, PageContainer, TableContainer } from "./styles";
import { useAutenticacaoContext } from '../../../core/contexts/AutenticacaoContext';
import { useNavigate } from 'react-router-dom';

export const ListarClientes = () => {
    const [clienteEdicao, setClienteEdicao] = useState<ICliente | undefined>(undefined);
    const [clienteExclusao, setClienteExclusao] = useState<ICliente | undefined>(undefined);
    const [clienteEndereco, setClienteEndereco] = useState<ICliente | undefined>(undefined);

    const snackbar = useSnackbar();

    const [openCadastrarEditar, setOpenCadastrarEditar] = useState(false);
    const handleOpenCadastrarEditar = () => setOpenCadastrarEditar(true);
    const handleCloseCadastrarEditar = () => setOpenCadastrarEditar(false);

    const [openExcluir, setOpenExcluir] = useState(false);
    const handleOpenExcluir = () => setOpenExcluir(true);
    const handleCloseExcluir = () => setOpenExcluir(false);

    const [openEndereco, setOpenEndereco] = useState(false);
    const handleOpenEndereco = () => setOpenEndereco(true);
    const handleCloseEndereco = () => setOpenEndereco(false);

    const { salvar, loading, excluir } = useClientes();
    const {
        dados,
        handlePaginaChange,
        handleRefresh,
        handleLimpar,
        handleNomeChange,
        handleObservacaoChange,
        handleTelefoneChange,
        isLoading,
        nome,
        observacao,
        telefone,
        handleFiltrar
    } = useFetchClientes();
    const { dataHoraBr } = useDataHora();

    const handleCancelarEditarCadastrar = () => {
        handleCloseCadastrarEditar();
        setClienteEdicao(undefined);
    }

    const handleCadastrarCliente = async ({ nome, telefone, cpf, observacao }: FieldValues) => {
        if (loading) {
            return;
        }

        const clienteCadastrado = await salvar({ nome, telefone, cpf, observacao });

        if (!clienteCadastrado) {
            return snackbar("Ocorreu um erro ao cadastrar o cliente", { severity: "error" })
        }

        handleRefresh();
        handleCancelarEditarCadastrar();
        return snackbar("Cliente cadastrado com sucesso", { severity: "success" })
    }

    const handleEditarCliente = async ({ nome, telefone, cpf, observacao }: FieldValues) => {
        if (loading) {
            return;
        }

        const dados: ICliente = { nome, telefone, cpf, observacao };

        if (clienteEdicao?.uuid) {
            dados.uuid = clienteEdicao.uuid
        }

        const clienteEditado = await salvar(dados);
        if (!clienteEditado) {
            return snackbar("Ocorreu um erro ao editar o cliente", { severity: "error" })
        }

        handleRefresh();
        handleCancelarEditarCadastrar();
        return snackbar("Cliente editado com sucesso", { severity: "success" })
    }

    const handleExcluirCliente = async (clienteUuid: string) => {
        if (loading) {
            return;
        }

        const clienteExcluido = await excluir(clienteUuid);
        if (!clienteExcluido) {
            return snackbar("Ocorreu um erro ao excluir o cliente", { severity: "error" })
        }

        handleRefresh();
        handleCancelarExcluir();
        return snackbar("Cliente excluído com sucesso", { severity: "success" })
    }

    const handleAbrirEdicaoCliente = (cliente: ICliente) => {
        setClienteEdicao(cliente);
        setOpenCadastrarEditar(true);
    }

    const handleAbrirExclusaoCliente = (cliente: ICliente) => {
        setClienteExclusao(cliente);
        handleOpenExcluir();
    }

    const handleCancelarExcluir = () => {
        setClienteExclusao(undefined);
        handleCloseExcluir();
    }

    const handleAbrirEndereco = (cliente: ICliente) => {
        setClienteEndereco(cliente);
        handleOpenEndereco();
    }

    const handleCancelarEndereco = () => {
        setClienteEndereco(undefined);
        handleCloseEndereco();
    }

    const handleSalvar = () => {
        handleCloseEndereco();
        handleRefresh();
    }

    const navigate = useNavigate();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Gestão de clientes")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])
    
    return (
        <PageContainer>
            <CadastrarEditarClienteModal
                open={openCadastrarEditar}
                handleCadastrarCliente={handleCadastrarCliente}
                handleEditarCliente={handleEditarCliente}
                handleCancelar={handleCancelarEditarCadastrar}
                loading={loading}
                dadosCliente={clienteEdicao}
            />
            <ExcluirClienteModal
                cliente={clienteExclusao}
                open={openExcluir}
                excluir={handleExcluirCliente}
                cancelar={handleCancelarExcluir}
            />
            <CadastrarEditarEnderecoModal
                cliente={clienteEndereco}
                open={openEndereco}
                salvar={handleSalvar}
                cancelar={handleCancelarEndereco}
            />
            <FiltrosContainer>
                <Typography variant='h6'>Clientes</Typography>
                <Box className="filtros-clientes">
                    <TextField
                        placeholder="Nome"
                        type="text"
                        value={nome}
                        size='small'
                        onChange={handleNomeChange}
                        className='input-cliente'
                    />
                    <TextField
                        placeholder="Observação"
                        type="text"
                        value={observacao}
                        size='small'
                        onChange={handleObservacaoChange}
                        className='input-cliente'
                    />
                    <TextField
                        placeholder="Telefone"
                        type="text"
                        value={telefone}
                        size='small'
                        sx={{ width: "200px" }}
                        onChange={handleTelefoneChange}
                        className='input-cliente'
                    />
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={handleLimpar}
                        >
                            Limpar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleFiltrar}
                        >
                            Filtrar
                        </Button>
                        <Divider orientation="vertical" flexItem sx={{ mr: "0.5rem", ml: "0.5rem" }} />
                        <Tooltip title="Cadastre um novo cliente">
                            <Button
                                onClick={handleOpenCadastrarEditar}
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Novo
                            </Button>
                        </Tooltip>
                    </ButtonsContainer>
                </Box>
            </FiltrosContainer>
            <Paper sx={{
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                margin: "0 auto",
                width: "85%"
            }}>

                <TableContainer>
                    {isLoading
                        ? (
                            <Loading>
                                <CircularProgress />
                            </Loading>
                        )
                        : dados && dados.itens.length ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" width="20%">Nome</TableCell>
                                        <TableCell align="left" width="20%">Telefone</TableCell>
                                        <TableCell align="left" width="20%">Observação</TableCell>
                                        <TableCell align="left" width="20%">Cadastrado às</TableCell>
                                        <TableCell align="left" width="6.66%">Endereço</TableCell>
                                        <TableCell align="left" width="6.66%">Editar</TableCell>
                                        <TableCell align="left" width="6.66%">Excluir</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dados.itens.map((cliente: ICliente) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="left" width="20%">{cliente.nome}</TableCell>
                                                <TableCell align="left" width="20%">{cliente.telefone?.length ? cliente.telefone : "-"}</TableCell>
                                                <TableCell align="left" width="20%">{cliente.observacao?.length ? cliente.observacao : "-"}</TableCell>
                                                <TableCell align="left" width="20%">{dataHoraBr(cliente.cadastradoAs)}</TableCell>
                                                <TableCell align="center" width="6.66%">
                                                    <IconButton onClick={() => handleAbrirEndereco(cliente)} aria-label="delete" size="small">
                                                        <LocationOnIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center" width="6.66%">
                                                    <IconButton onClick={() => handleAbrirEdicaoCliente(cliente)} aria-label="delete" size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center" width="6.66%">
                                                    <IconButton onClick={() => handleAbrirExclusaoCliente(cliente)} aria-label="delete" size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <NenhumCliente>
                                <Typography>
                                    Nenhum cliente cadastrado
                                </Typography>
                            </NenhumCliente>
                        )}
                </TableContainer>
                {dados && (
                    <Pagination
                        count={dados.quantidadeTotalPaginas}
                        page={dados.paginaAtual}
                        onChange={handlePaginaChange}
                        color="primary"
                        showFirstButton
                        showLastButton
                        siblingCount={2}
                        boundaryCount={2}
                        sx={{ marginTop: "1rem" }}
                    />
                )}
            </Paper>
        </PageContainer>
    );
}