import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';

interface ConfirmacaoAcaoProps {
  open: boolean;
  titulo: string;
  descricao: string;
  onConfirmar: () => void;
  onCancelar: () => void;
  loading?: boolean;
}

export const ConfirmacaoAcaoModal: React.FC<ConfirmacaoAcaoProps> = ({ open, titulo, descricao, onConfirmar, onCancelar, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancelar}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
      <DialogContent sx={{ minWidth: "450px" }}>
        <DialogContentText id="alert-dialog-description">
          {descricao}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancelar} color="primary">
          Cancelar
        </Button>
        {loading ? (
          <CircularProgress sx={{
            width: 15,
            height: 15
          }} />
        ) : (
          <Button variant='contained' onClick={onConfirmar} color="primary" autoFocus>
            Confirmar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
