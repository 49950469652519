import { Close } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useColunasStatusEsteiraPedidos } from "../../hooks/useColunasStatusEsteiraPedidos";
import { useLetras } from "../../../../../core/hooks/useLetras";
import { StatusPedido } from "../../../../enum/StatusPedido";

interface ConfiguracoesEsteiraPedidosProps {
    open: boolean;
    onClose: () => void;
    colunas?: string[];
    handleColunasChange: (event: SelectChangeEvent<string[]>) => void;
    tempoAtualizacao: number;
    handleTempoAtualizacaoChange: (tempo: SelectChangeEvent<number>) => void;
}

function propsTab(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const ConfiguracoesEsteiraPedidos = ({ open, onClose, colunas, handleColunasChange, tempoAtualizacao, handleTempoAtualizacaoChange }: ConfiguracoesEsteiraPedidosProps) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { configuracoes: configuracoesColunas } = useColunasStatusEsteiraPedidos();
    const { titulo } = useLetras();

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"md"}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h6">
                    Configurações
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            <DialogContent sx={{ display: "flex", justifyContent: "space-between" }} dividers>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Colunas" {...propsTab(0)} />
                    <Tab label="Tempo de atualização" {...propsTab(0)} />
                </Tabs>
                <Box sx={{ padding: "1rem", display: "flex", alignItems: "center", width: "650px", justifyContent: "center" }}>
                    {
                        value === 0 && (
                            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <Alert severity="info" sx={{ mb: 4, width: 600 }}>
                                    <AlertTitle>Status do pedido</AlertTitle>
                                    A esteira de pedidos organiza os pedidos em colunas
                                    representando diferentes estágios do processo,
                                    facilitando o acompanhamento do status de cada pedido.
                                </Alert>
                                <FormControl>
                                    <InputLabel id="colunas-label">Colunas</InputLabel>
                                    <Select
                                        label="Colunas"
                                        labelId="colunas-label"
                                        id="colunas"
                                        multiple
                                        value={colunas}
                                        onChange={handleColunasChange}
                                        renderValue={(selected) => titulo(selected.join(', '))}
                                        sx={{ minWidth: "300px", maxWidth: "600px" }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 224,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {configuracoesColunas.map((config) => (
                                            <MenuItem key={config.id} value={StatusPedido[config.id as unknown as number]}>
                                                <Checkbox checked={colunas?.includes(StatusPedido[config.id as unknown as number])} />
                                                {titulo(StatusPedido[config.id as unknown as number])}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )
                    }
                    {
                        value === 1 && (
                            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <Alert severity="info" sx={{ mb: 4, width: 600 }}>
                                    <AlertTitle>Tempo de atualização</AlertTitle>
                                    Essa configuração define a cada tempo o sistema
                                    irá buscar os novos pedidos efetuados.
                                </Alert>
                                <FormControl>
                                    <InputLabel id="tempo-atualizacao-label">Tempo de atualização</InputLabel>
                                    <Select
                                        label="Tempo de atualização"
                                        labelId="tempo-atualizacao-label"
                                        id="tempo-atualizacao"
                                        value={tempoAtualizacao}
                                        onChange={handleTempoAtualizacaoChange}
                                        sx={{ minWidth: "300px", maxWidth: "600px" }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 224,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={"5s"} value={5000}>
                                            5 Segundos
                                        </MenuItem>
                                        <MenuItem key={"15s"} value={15000}>
                                            15 Segundos
                                        </MenuItem>
                                        <MenuItem key={"30s"} value={30000}>
                                            30 Segundos
                                        </MenuItem>
                                        <MenuItem key={"60s"} value={60000}>
                                            1 minuto
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
}