import { Box, Button, CardActionArea, Chip, CircularProgress, Container, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { useEstabelecimentos } from "../../hooks/useEstabelecimentos";
import { useEffect, useState } from "react";
import { IAcessoUsuario } from "../../../usuarios/interfaces/IAcessoUsuario";
import StorefrontIcon from '@mui/icons-material/Storefront';
import { PaperEstabelecimento, PaperEstabelecimentoDados, PaperEstabelecimentoTitulo, StoreIcon, TituloPagina } from "./styles";
import { Add } from "@mui/icons-material";

export const ListarEstabelecimentos = () => {
    const [acessos, setAcessos] = useState<IAcessoUsuario[]>();
    const { alterarAcesso } = useAutenticacaoContext();
    const { buscarEstabelecimentosUsuario, loading } = useEstabelecimentos();

    const handleListarAcessosEstabelecimentos = async () => {
        const acessosEstabelecimentos = await buscarEstabelecimentosUsuario();
        setAcessos(acessosEstabelecimentos);
    }

    useEffect(() => {
        handleListarAcessosEstabelecimentos();
        alterarAcesso();
    }, [])

    return (
        <Container>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <TituloPagina item xs={12}>
                    <Typography variant="h6" fontWeight={"bold"}>Seus estabelecimentos</Typography>
                    <Button variant="contained" disabled startIcon={<Add />}>NOVO ESTABELECIMENTO</Button>
                </TituloPagina>
                <Grid item xs={12} sx={{ mt: 4 }}>
                    {loading ? (
                        <Grid container spacing={4}>
                            {Array.from({ length: 6 }, (_, index) => (
                                <Grid item xs={12} md={4} sm={6} key={index}>
                                    <Skeleton variant="rounded" height={175} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        acessos?.length ? (
                            <Grid container spacing={4}>
                                {acessos.map((acesso) => {
                                    return (
                                        <Grid key={acesso.uuid} item md={4} xs={12} sm={6}>
                                            <PaperEstabelecimento onClick={() => alterarAcesso(acesso.uuid)}>
                                                <CardActionArea sx={{ 
                                                    width: "100%", 
                                                    height: "100%",
                                                    padding: "1.5rem",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <PaperEstabelecimentoTitulo>
                                                        <StoreIcon className="store-icon">
                                                            <StorefrontIcon />
                                                        </StoreIcon>
                                                        <Typography variant="subtitle1" sx={{ ml: 2, fontWeight: "bold" }}>
                                                            {acesso.estabelecimento?.nomeFantasia}
                                                        </Typography>
                                                    </PaperEstabelecimentoTitulo>
                                                </CardActionArea>
                                            </PaperEstabelecimento>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        ) : (
                            <Box>Nenhum estabelecimento encontrado</Box>
                        )
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}