import { Dialog, DialogContent } from "@mui/material"
import { CadastrarEditarCliente } from "../../../../containers/CadastrarEditarCliente";
import { ICliente } from "../../../../interface/ICliente";
import { FieldValues } from "react-hook-form";

interface CadastrarEditarClienteModalProps {
    handleCancelar: () => void;
    handleCadastrarCliente: (cliente: FieldValues) => void;
    handleEditarCliente: (cliente: FieldValues) => void;
    open: boolean;
    loading: boolean;
    dadosCliente?: ICliente
}

export const CadastrarEditarClienteModal = ({ open, handleCancelar, handleEditarCliente, handleCadastrarCliente, dadosCliente, loading }: CadastrarEditarClienteModalProps) => {
    return (
        <Dialog open={open} onClose={handleCancelar} maxWidth="md">
            <DialogContent>
                <CadastrarEditarCliente
                    cadastrar={handleCadastrarCliente}
                    editar={handleEditarCliente}
                    cancelar={handleCancelar}
                    loading={loading}
                    dadosCliente={dadosCliente}
                />
            </DialogContent>
        </Dialog>
    )
}