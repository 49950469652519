import { useEffect } from "react";
import { Environment } from "../config/environment";

export const useUrlToken = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem(Environment.TOKEN_RECUPERAR_SENHA_KEY, token);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);
}