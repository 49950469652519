import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const QuantidadePessoasBox = styled(Box)`
    display: flex;
    align-items: flex-start; /* Adjust alignment to top */
    justify-content: center;
    width: 100%;
    height: 100%;

    @media(max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`

export const QuantidadePessoasPaper = styled(Paper)`
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 450px;
`

export const PagadoresPaper = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-left: 2rem;
    overflow-y: auto;
    height: 100%;

    @media(max-width: 768px) {
        margin-top: 1rem;
    }
`
