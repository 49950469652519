import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const PedidosNaoConcluidos = styled(Paper)`
  padding: 1rem;
  width: 300px;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
`

interface PedidoCardProps {
  selecionado?: boolean;
}

export const PedidoCard = styled(Paper) <PedidoCardProps>`
  align-self: flex-start;
  width: 100%;
  height: 145px;
  border-radius: 2rem;
  margin-top: 0.7rem;

  ${({ selecionado, theme }) =>
    selecionado &&
    `
        background-color: ${theme.palette.primary.main}09;
        border: 1px solid ${theme.palette.primary.main};
        color: ${theme.palette.primary.main};
    `
  }
`;

export const NenhumProdutoEmAndamento = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
`