import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Slider, TextField, Typography } from "@mui/material"
import { ButtonsContainer, Formulario, ImagemContainer, PrecificacaoPizza } from "./styles"
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { CardUploadImagem } from "../../../core/components/CardUploadImagem";
import { IImagemProduto } from "../../interfaces/IImagemProduto";
import { useEffect, useState } from "react";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { ModeloPrecificacaoPizza } from "../../enum/ModeloPrecificacaoPizza";
import { configuracaoPizzaSchema } from "../../schema/configuracaoPizzaSchema";

interface ConfiguracaoPizzaContainerProps {}

export const ConfiguracaoPizzaContainer = ({ }: ConfiguracaoPizzaContainerProps) => {
    const { 
        handleExcluirImagem, 
        handleUploadImagem, 
        imagens, 
        registrarConfiguracoesPizza, 
        handleVoltar, 
        handleProximo,
        descricao,
        modeloPrecificacao,
        quantidadeFatias,
        quantidadeSabores,
        setDescricao,
        setQuantidadeFatias,
        setModeloPrecificacao,
        setQuantidadeSabores
    } = useCadastrarEditarProdutoContext();

    const { setValue, register, watch, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(configuracaoPizzaSchema),
        mode: "onChange"
    });
    const [imagem, setImagem] = useState<undefined | File | IImagemProduto>(undefined);


    useEffect(() => {
        setValue("quantidadeSabores", quantidadeSabores ?? 0);
        setValue("quantidadeFatias", quantidadeFatias ?? 0);
        setValue("descricao", descricao);
        setValue("modeloPrecificacao", modeloPrecificacao);
    }, [quantidadeFatias, quantidadeSabores, descricao, modeloPrecificacao])

    useEffect(() => {
        if (imagens?.length) {
            setImagem(imagens[0]);
        } else {
            setImagem(undefined);
        }
    }, [imagens])

    const handleConfiguracoes = (values: FieldValues) => {
        registrarConfiguracoesPizza(values);
    }

    return (
        <PrecificacaoPizza>
            <form onSubmit={handleSubmit(handleConfiguracoes)} style={{ width: "100%" }}>
                <Formulario>
                    <Typography variant="h6" sx={{ marginBottom: "2rem" }}>Configuração</Typography>
                    <TextField
                        label="Tamanho*"
                        sx={{ width: "100%", marginBottom: "1rem" }}
                        {...register("descricao")}
                        error={!!errors.descricao}
                        helperText={<>{errors.descricao?.message}</>}
                        InputLabelProps={{ shrink: watch("descricao") ? true : false }}
                        placeholder="Ex: Pequena, Média, etc..."
                        onChange={(e) => setDescricao(e.target.value)}
                        value={descricao}
                    />
                    <FormControl fullWidth error={!!errors.modeloPrecificacao}>
                        <InputLabel
                            id="precificacao-pizza-label"
                            error={!!errors.modeloPrecificacao}
                        >
                            Precificação*
                        </InputLabel>
                        <Select
                            labelId="precificacao-pizza-label"
                            id="precificacao-pizza"
                            label="Precificação"
                            {...register("modeloPrecificacao")}
                            error={!!errors.modeloPrecificacao}
                            defaultValue={undefined}
                            onChange={(e: any) => setModeloPrecificacao(e.target.value)}
                            value={modeloPrecificacao}
                        >
                            <MenuItem value={ModeloPrecificacaoPizza["Maior preço"]}>Maior preço</MenuItem>
                            <MenuItem value={ModeloPrecificacaoPizza["Preço dividido"]}>Preço dividido</MenuItem>
                            <MenuItem value={ModeloPrecificacaoPizza["Preço misto"]}>Preço misto</MenuItem>
                        </Select>
                        {!!errors.modeloPrecificacao && (
                            <FormHelperText error={true}>
                                <>{errors.modeloPrecificacao?.message}</>
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth sx={{ margin: "1rem auto", padding: "0 1rem" }}>
                        <Typography variant="subtitle1"><b>{watch("quantidadeSabores")}</b> sabores</Typography>
                        <Slider
                            aria-label="Sabores"
                            value={quantidadeSabores}
                            onChange={(e, newValue) => {
                                setValue("quantidadeSabores", newValue);
                                setQuantidadeSabores(Number(newValue));
                            }}
                            valueLabelDisplay="auto"
                            marks
                            min={0}
                            max={20}
                            name="quantidadeSabores"
                        />
                        {!!errors.quantidadeSabores && (Number(watch("quantidadeSabores")) <= 0) && (
                            <FormHelperText error={true}>
                                <>{errors.quantidadeSabores?.message}</>
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth sx={{ margin: "1rem auto", padding: "0 1rem" }}>
                        <Typography variant="subtitle1"><b>{watch("quantidadeFatias")}</b> fatias</Typography>
                        <Slider
                            aria-label="Fatias"
                            value={quantidadeFatias}
                            onChange={(e, newValue) => {
                                setValue("quantidadeFatias", newValue);
                                setQuantidadeFatias(Number(newValue));
                            }}
                            valueLabelDisplay="auto"
                            marks
                            min={0}
                            max={20}
                            name="quantidadeFatias"
                        />
                        {!!errors.quantidadeFatias && (Number(watch("quantidadeFatias")) <= 0) && (
                            <FormHelperText error={true}>
                                <>{errors.quantidadeFatias?.message}</>
                            </FormHelperText>
                        )}
                    </FormControl>
                    <ImagemContainer>
                        <CardUploadImagem
                            titulo="Imagem"
                            descricao={
                                imagem
                                    ? (imagem instanceof File ? imagem?.name : (imagem?.nomeOriginal ?? imagem?.nome))
                                    : "Faça o upload"
                            }
                            url={imagem instanceof File ? URL.createObjectURL(imagem) : imagem?.url}
                            excluir={handleExcluirImagem}
                            upload={handleUploadImagem}
                        />
                    </ImagemContainer>
                    <ButtonsContainer>
                        <Box></Box>
                        <Button size="medium" variant="contained" type="submit">
                            Próximo
                        </Button>
                    </ButtonsContainer>
                </Formulario>
            </form>
        </PrecificacaoPizza>
    )
}