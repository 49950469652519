import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { IUsuario } from "../../usuarios/interfaces/IUsuario";
import { IEstabelecimento } from "../../estabelecimento/interfaces/IEstabelecimento";
import { AxiosError } from "axios";
import { Environment } from "../config/environment";
import { IErro } from "../interfaces/IError";
import { ApiFactory } from "../config/ApiFactory";
import { Plataforma } from "../enum/Plataforma";
import { useSnackbar } from "./SnackbarContext";
import { useUsuarios } from "../../usuarios/hooks/useUsuarios";
import { useLocation, useNavigate } from "react-router-dom";
import { IPerfil } from "../../perfis/interfaces/IPerfil";

interface IAutenticacaoContextProps {
    usuario?: IUsuario;
    perfil?: IPerfil;
    estabelecimento?: IEstabelecimento;
    verificarAcessoFuncionalidade: (nome: string) => boolean
    verificarAcessoModulo: (nome: string) => boolean
    autenticar: (email: string, senha: string) => Promise<IErro | string>;
    alterarAcesso: (uuid?: string) => Promise<void>;
    autenticado: boolean;
    logout: () => void;
    buscarProprioUsuario: () => Promise<IUsuario | null>
    atualizarEstadoProprioUsuario: (usuario: IUsuario) => void
    getToken: (key: "TOKEN_KEY" | "TOKEN_ESTABELECIMENTO_KEY") => string | null;
    loading: boolean;
}

export const useAutenticacaoContext = () => {
    return useContext(AutenticacaoContext)
}
export const AutenticacaoContext = createContext({} as IAutenticacaoContextProps)

export const getToken = (key: "TOKEN_RECUPERAR_SENHA_KEY" | "TOKEN_KEY" | "TOKEN_ESTABELECIMENTO_KEY") => localStorage.getItem(Environment[key]);
export const removeToken = (key: "TOKEN_RECUPERAR_SENHA_KEY" | "TOKEN_KEY" | "TOKEN_ESTABELECIMENTO_KEY") => localStorage.removeItem(Environment[key]);
export const setToken = (token: string, key: "TOKEN_KEY" | "TOKEN_ESTABELECIMENTO_KEY") => localStorage.setItem(Environment[key], token);

export const AutenticacaoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const snackbar = useSnackbar();
    const apiUsuarios = ApiFactory.getApi("MS_USUARIOS_ESTABELECIMENTO");
    const apiEstabelecimentos = ApiFactory.getApi("MS_ESTABELECIMENTOS");
    const [estabelecimento, setEstabelecimento] = useState<IEstabelecimento>();
    const [usuario, setUsuario] = useState<IUsuario>();
    const [perfil, setPerfil] = useState<IPerfil>();
    const navigate = useNavigate();
    const location = useLocation();

    const { autenticar, alterarAcesso, loading } = useUsuarios();

    const autenticado = useMemo(() => {
        if (!getToken("TOKEN_ESTABELECIMENTO_KEY") && !getToken("TOKEN_KEY")) {
            return false;
        }

        return true;
    }, []);

    useEffect(() => {
        if (!usuario && getToken("TOKEN_KEY")) {
            buscarProprioUsuario();
            if (getToken("TOKEN_ESTABELECIMENTO_KEY")) {
                buscarEstabelecimento(true);
            }
        }
    }, [])

    useEffect(() => {
        handleValidarRotasEstabelecimento();
    }, [location.pathname, navigate]);

    const verificarAcessoModulo = (nome: string) => {
        if(!perfil) {
            return false;
        }

        const modulo = perfil.modulos?.find(modulo => modulo.nome == nome);
        if(modulo) {
            return true;
        }

        return false;
    }

    const verificarAcessoFuncionalidade = (nome: string) => {
        if(!perfil) {
            return false;
        }

        let acesso = false;
        perfil?.modulos?.map(modulo => {
            modulo?.funcionalidades.map(funcionalidade => {
                if(funcionalidade.nome == nome) {
                    acesso = true;
                }
            })
        })
        return acesso;
    }

    const handleValidarRotasEstabelecimento = async () => {
        const urlsPermitidasSemEstabelecimento = [
            '/estabelecimentos',
            '/usuarios/meu-perfil'
        ];

        let dadosEstabelecimento = estabelecimento;

        if(autenticado && !dadosEstabelecimento && !urlsPermitidasSemEstabelecimento.includes(location.pathname)) {
            dadosEstabelecimento = await buscarEstabelecimento(false);
            setEstabelecimento(dadosEstabelecimento)
            if(!dadosEstabelecimento && !urlsPermitidasSemEstabelecimento.includes(location.pathname)) {
                snackbar("Estabelecimento não definido", { severity: "error" })
                navigate('/estabelecimentos');
            }
        }
    }

    const atualizarEstadoProprioUsuario = (usuario: IUsuario) => setUsuario(usuario);

    const handleAlterarAcesso = async (uuid?: string) => {
        if (!uuid) {
            removeToken("TOKEN_ESTABELECIMENTO_KEY")
            return setEstabelecimento(undefined);
        }

        const resultado = await alterarAcesso(uuid);
        if (resultado) {
            setToken(resultado.token, "TOKEN_ESTABELECIMENTO_KEY");
            await buscarEstabelecimento(true);
            navigate('/dashboard');
        }
    }

    const handleAutenticar = async (email: string, senha: string) => {
        const resultado = await autenticar(email, senha);
        if (typeof resultado === "string") {
            setToken(resultado, "TOKEN_KEY");
            return window.location.href = "/estabelecimentos";
        }

        return resultado
    }

    const logout = () => {
        localStorage.removeItem(Environment.TOKEN_KEY);
        localStorage.removeItem(Environment.TOKEN_ESTABELECIMENTO_KEY);
        setUsuario(undefined);
        setEstabelecimento(undefined);
        window.location.href = '/login'
    }

    const buscarProprioUsuario = async () => {
        try {
            const { data } = await apiUsuarios.get('/meu-usuario');
            setUsuario(data);
            return data;
        } catch (error) {
            logout();
            snackbar("Usuário não encontrado", { severity: "error" })
            return null;
        }
    }

    const buscarEstabelecimento = async (atualizarEstado: boolean) => {
        try {
            const { data } = await apiEstabelecimentos.get('/');
            if(atualizarEstado) {
                setEstabelecimento(data);
            }
            buscarPerfil(true);
            return data;
        } catch (error) {
            window.location.href = "/estabelecimentos"
            snackbar("Estabelecimento não encontrado", { severity: "error" })
            return null;
        }
    }

    const buscarPerfil = async (atualizarEstado: boolean) => {
        try {
            const { data } = await apiEstabelecimentos.get('/perfis/ativo');
            if(atualizarEstado) {
                setPerfil(data);
            }
            return data;
        } catch (error) {
            snackbar("Perfil não encontrado", { severity: "error" })
            return null;
        }
    }

    return (
        <AutenticacaoContext.Provider value={{
            loading,
            logout,
            autenticado,
            usuario,
            estabelecimento,
            buscarProprioUsuario,
            atualizarEstadoProprioUsuario,
            getToken,
            autenticar: handleAutenticar,
            alterarAcesso: handleAlterarAcesso,
            verificarAcessoFuncionalidade,
            verificarAcessoModulo,
            perfil
        }}>
            {children}
        </AutenticacaoContext.Provider>
    );
} 