export enum TipoMarketplace {
    "PDV" = "PDV",
    "IFOOD" = "IFOOD",
    "RAPPI" = "RAPPI",
    "CARDAPIO_DIGITAL_SIMPLES" = "CARDAPIO_DIGITAL_SIMPLES",
    "CARDAPIO_DIGITAL_MESA" = "CARDAPIO_DIGITAL_MESA",
    "CARDAPIO_DIGITAL_DELIVERY_RETIRADA" = "CARDAPIO_DIGITAL_DELIVERY_RETIRADA"
}

export enum TipoMarketplaceTitulo {
    "PDV" = "Ponto de venda (PDV)",
    "IFOOD" = "IFood",
    "RAPPI" = "Rappi",
    "CARDAPIO_DIGITAL_SIMPLES" = "Simples",
    "CARDAPIO_DIGITAL_MESA" = "QR Code",
    "CARDAPIO_DIGITAL_DELIVERY_RETIRADA" = "Online"
}

export enum TipoMarketplaceDescricao {
    "PDV" = "Sistema utilizado internamente pelos colaboradores para emitir pedidos",
    "IFOOD" = "Integração com a plataforma iFood.",
    "RAPPI" = "Integração com a plataforma Rappi.",
    "CARDAPIO_DIGITAL_SIMPLES" = "Cardápio digital sem funcionalidade de pedidos.",
    "CARDAPIO_DIGITAL_MESA" = "Cardápio digital com sessões por mesa para pedidos sem garçom.",
    "CARDAPIO_DIGITAL_DELIVERY_RETIRADA" = "Cardápio digital para pedidos online com opção de entrega e retirada."
}

export enum TipoMarketplaceCor {
    "PDV" = "#000080",
    "IFOOD" = "#EA1D2C",
    "RAPPI" = "#FF4B55",
    "CARDAPIO_DIGITAL_SIMPLES" = "#4CAF50",
    "CARDAPIO_DIGITAL_MESA" = "#6A0DAD",
    "CARDAPIO_DIGITAL_DELIVERY_RETIRADA" = "#1976D2"
}

export enum TipoMarketplaceSigla {
    "PDV" = "PDV",
    "IFOOD" = "iFood",
    "RAPPI" = "Rappi",
    "CARDAPIO_DIGITAL_SIMPLES" = "CDS",
    "CARDAPIO_DIGITAL_MESA" = "CDM",
    "CARDAPIO_DIGITAL_DELIVERY_RETIRADA" = "CDO"
}

export const tiposMarketplaceEmBreve = [
    TipoMarketplace.RAPPI,
    TipoMarketplace.IFOOD,
    TipoMarketplace.CARDAPIO_DIGITAL_MESA,
    TipoMarketplace.CARDAPIO_DIGITAL_SIMPLES,
];

export const tiposMarketplaceIndisponivel = [
    TipoMarketplace.PDV
];