import { Autocomplete, Box, Button, Checkbox, Container, Divider, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { PedidosBoard } from "../../containers/PedidosBoard";
import { useDataHora } from "../../../core/hooks/useDataHora";
import { usePedidos } from "../../hooks/usePedidos";
import { DatePicker } from "@mui/x-date-pickers";
import { PageContainer, Toolbar } from "./styles";
import { useEffect, useMemo, useState } from "react";
import { IPedido } from "../../interface/IPedido";
import { IColunaBoardPedidos } from "../../interface/IColunaBoardPedidos";
import { StatusPedido, StatusPedidoDescricao, StatusPedidosCores } from "../../enum/StatusPedido";
import { useSearchParams } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { filtrosEsteiraPedidosSchema } from "../../schemas/filtrosEsteiraPedidoSchema";
import moment from "moment";
import { TipoPedido } from "../../enum/TipoPedido";
import { useLetras } from "../../../core/hooks/useLetras";
import { CheckBox, CheckBoxOutlineBlank, Settings } from "@mui/icons-material";
import { ConfiguracoesEsteiraPedidos } from "./containers/ConfiguracoesEsteiraPedidos";
import { useColunasStatusEsteiraPedidos } from "./hooks/useColunasStatusEsteiraPedidos";
export const EsteiraPedidos = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pedidos, setPedidos] = useState<IPedido[]>([]);
    const { atualizarStatus, listarPedidos, loading } = usePedidos();
    const { diaAnteriorEua, diaAtualEua, dataEua } = useDataHora();
    const { titulo } = useLetras();

    const [tempoAtualizacao, setTempoAtualizacao] = useState(15000); // 15s
    const handleTempoAtualizacaoChange = (e: SelectChangeEvent<number>) => {
        setTempoAtualizacao(Number(e.target.value) ?? 15000);
        handleListarPedidos();
    };

    const [configuracoesOpen, setConfiguracoesOpen] = useState(false);
    const handleConfiguracoes = () => setConfiguracoesOpen(!configuracoesOpen);

    const [colunasStatus, setColunasStatus] = useState<string[]>([
        "Aguardando confirmação",
        "Em preparo",
        "Em transporte",
        "Concluído",
        "Cancelado",
        "Em andamento"
    ]);
    const { colunas } = useColunasStatusEsteiraPedidos(colunasStatus);
    const handleColunasChange = (event: SelectChangeEvent<string[]>) => {
        if(Array.isArray(event.target.value)) {
            setColunasStatus(event.target.value);
        }
    }

    const { setValue, control, reset, handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(filtrosEsteiraPedidosSchema)
    });

    const handleListarPedidos = async () => {
        if (!searchParams.get('dataInicio') || !searchParams.get('dataFim') || !searchParams.get('tipos')) {
            return;
        }

        const pedidosListados = await listarPedidos({
            dataInicio: searchParams.get('dataInicio') as string,
            dataFim: searchParams.get('dataFim') as string,
            tipos: searchParams.get('tipos')
        });
        setPedidos(pedidosListados);
    }

    useEffect(() => {
        handleListarPedidos();
    }, [searchParams])

    useEffect(() => {
        let dataInicio = searchParams.get('dataInicio');
        let dataFim = searchParams.get('dataFim');
        let tipos = searchParams.get('tipos');

        if (!dataInicio || !dataFim) {
            dataInicio = diaAnteriorEua();
            dataFim = diaAtualEua();
        }

        if (!tipos) {
            tipos = Object.values(TipoPedido).join(',');
        }

        setValue("dataInicio", dataInicio);
        setValue("dataFim", dataFim);
        setValue("tipos", tipos.split(','));

        if (!searchParams.get('dataInicio') || !searchParams.get('dataFim') || !searchParams.get('tipos')) {
            setSearchParams({ dataInicio, dataFim, tipos });
        } else {
            handleListarPedidos();
        }
    }, []);


    useEffect(() => {
        const intervalId = setInterval(() => {
            handleListarPedidos();
        }, tempoAtualizacao);

        return () => clearInterval(intervalId);
    }, [handleListarPedidos]);

    const aplicarFiltros = (dados: FieldValues) => {
        if (dados.dataInicio && dados.dataFim) {
            setSearchParams(state => {
                state.set('dataInicio', dataEua(dados.dataInicio));
                state.set('dataFim', dataEua(dados.dataFim));

                return state;
            });
        }
        if (dados.tipos) {
            setSearchParams(state => {
                state.set('tipos', dados.tipos.join(','))
                return state;
            })
        }
        handleListarPedidos();
    }

    const limparFiltros = () => {
        reset({
            dataInicio: null,
            dataFim: null,
            tipos: null
        });
        handleListarPedidos();
    }

    const pedidosFiltrados = useMemo(() => {
        return pedidos.filter(pedido => colunasStatus.includes(StatusPedido[pedido.status] as string)) ?? []
    }, [colunasStatus, pedidos])

    return (
        <PageContainer>
            <Toolbar>
                <Typography variant="h6" fontWeight={"bold"}>
                    {loading ? "Carregando pedidos..." : `${pedidosFiltrados.length} pedidos`}
                </Typography>
                <form onSubmit={handleSubmit(aplicarFiltros)}>
                    <Box sx={{ display: "flex", alignItems: "center" }} className="form">
                        <FormControl sx={{ marginRight: "1rem" }} error={!!errors.dataInicio}>
                            <Controller
                                defaultValue={moment()}
                                name="dataInicio"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="Data início"
                                            slotProps={{ textField: { size: 'small', error: !!errors.dataInicio } }}
                                            value={moment(field.value)}
                                            defaultValue={moment()}
                                            onChange={(date) => {
                                                field.onChange(moment(date));
                                            }}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                        <FormControl error={!!errors.dataFim} sx={{ marginRight: "1rem" }} >
                            <Controller
                                defaultValue={moment()}
                                name="dataFim"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="Data final"
                                            slotProps={{ textField: { size: 'small', error: !!errors.dataFim } }}
                                            defaultValue={moment()}
                                            value={moment(field.value)}
                                            onChange={(date) => {
                                                field.onChange(moment(date));
                                            }}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                        <Controller
                            name="tipos"
                            control={control}
                            defaultValue={Object.values(TipoPedido)}
                            render={({ field }) => (
                                <FormControl sx={{ minWidth: "180px" }} error={!!errors.tipos}>
                                    <InputLabel error={!!errors.tipos} size="small" id="tipos-label">Contextos</InputLabel>
                                    <Select
                                        label="Contextos"
                                        labelId="tipos-label"
                                        id="tipos"
                                        multiple
                                        value={field.value ?? []}
                                        size="small"
                                        onChange={field.onChange}
                                        defaultValue={Object.values(TipoPedido)}
                                        renderValue={(selected) => titulo(selected.join(', '))}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 224,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {Object.values(TipoPedido).map((tipo) => (
                                            <MenuItem key={tipo} value={tipo}>
                                                <Checkbox checked={field?.value?.indexOf(tipo) > -1} />
                                                {titulo(tipo)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Box sx={{ ml: "1rem", mr: "1rem" }}>
                            <Button
                                variant="outlined"
                                sx={{ marginRight: "10px" }}
                                onClick={limparFiltros}
                            >
                                Limpar
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Filtrar
                            </Button>
                        </Box>
                        <IconButton onClick={handleConfiguracoes}>
                            <Settings />
                        </IconButton>
                    </Box>
                </form>
            </Toolbar>
            <Box sx={{
                height: "100%",
                pt: 1,
                pb: 4,
                pr: 4,
                pl: 4,
            }}>
                <PedidosBoard
                    atualizarStatus={atualizarStatus}
                    colunasStatus={colunas}
                    pedidos={pedidosFiltrados}
                    setColunasStatus={setColunasStatus}
                    setPedidos={setPedidos}
                />
            </Box>
            <ConfiguracoesEsteiraPedidos 
                colunas={colunasStatus} 
                handleColunasChange={handleColunasChange} 
                tempoAtualizacao={tempoAtualizacao}
                handleTempoAtualizacaoChange={handleTempoAtualizacaoChange}
                open={configuracoesOpen} 
                onClose={handleConfiguracoes} 
            />
        </PageContainer>
    );
}