import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { getToken, useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { useFetch } from "../../core/hooks/useFetch";
import { IUsuario } from "../interfaces/IUsuario";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IErro } from "../../core/interfaces/IError";
import { IAcessoUsuario } from "../interfaces/IAcessoUsuario";

export function useUsuarios () {
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const apiRecuperarSenha = useMemo(() => ApiFactory.getApi("MS_USUARIOS_RECUPERAR_SENHA"), []);
    const apiUsuarios = useMemo(() => ApiFactory.getApi("MS_USUARIOS_ESTABELECIMENTO"), []);
    const { atualizarEstadoProprioUsuario, estabelecimento } = useAutenticacaoContext();
    
    const inativarUsuarios = async (usuarios: IUsuario[]) => {
        setLoading(true);

        const resultados: { uuid: string, sucesso: boolean }[] = [];
        await Promise.all(usuarios.map(async usuario => {
            if(!usuario.uuid) return;
            const acesso = usuario.acessos?.find(acesso => acesso.estabelecimentoUuid == estabelecimento?.uuid);
            if(!acesso) return;

            try { 
                const { data } = await apiUsuarios.delete(`/${usuario.uuid}/acessos/${acesso.uuid}`);
                if(data) [
                    resultados.push({ uuid: usuario.uuid, sucesso: true })
                ]
            } catch (error) {
                resultados.push({ uuid: usuario.uuid, sucesso: false })
            }
        }))

        setLoading(false);
        if(resultados.filter(resultado => !resultado.sucesso).length) {
            showSnackbar("Ocorreu um erro ao inativar os usuários", { severity: "error" })
            return
        }

        showSnackbar("Usuários inativados com sucesso", { severity: "success" })
    }

    const reativarUsuarios = async (usuarios: IUsuario[]) => {
        setLoading(true);

        const resultados: { uuid: string, sucesso: boolean }[] = [];
        await Promise.all(usuarios.map(async usuario => {
            if(!usuario.uuid) return;
            const acesso = usuario.acessos?.find(acesso => acesso.estabelecimentoUuid == estabelecimento?.uuid);
            if(!acesso) return;

            try { 
                const { data } = await apiUsuarios.post(`/${usuario.uuid}/acessos/${acesso.uuid}`);
                if(data) [
                    resultados.push({ uuid: usuario.uuid, sucesso: true })
                ]
            } catch (error) {
                resultados.push({ uuid: usuario.uuid, sucesso: false })
            }
        }))

        setLoading(false);
        if(resultados.filter(resultado => !resultado.sucesso).length) {
            showSnackbar("Ocorreu um erro ao reativar os usuários", { severity: "error" })
            return
        }

        showSnackbar("Usuários reativados com sucesso", { severity: "success" })
    }

    const autenticar = async (email: string, senha: string): Promise<IErro | string> => {
        try {
            setLoading(true);
            const { data } = await apiUsuarios.post('/autenticar', {
                email,
                senha
            })

            if(data && data.token) {
                return data.token
            }

            return { status: true, mensagem: "Ocorreu um erro ao gerar o token de autenticação" }
        } catch (error) {
            if(error instanceof AxiosError) {
                return {
                    status: true,
                    mensagem: error.response?.data.message
                }
            }

            return {
                status: true,
                mensagem: "Ocorreu um erro, tente novamente mais tarde"
            }
        } finally {
            setLoading(false); 
        }
    }

    const alterarAcesso = async (uuid: string) => {
        try {
            const { data } = await apiUsuarios.patch(`/acessos/${uuid}`, undefined, {
                headers: {
                    'Authorization': `Bearer ${getToken("TOKEN_KEY")}`
                }
            });
            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: "error" })
                return null;
            }

            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: "error" })
            return null;
        }
    }

    const cadastrarUsuario = useCallback(async (usuario: IUsuario) => {
        if(loading) {
            return false;
        }

        try {
            setLoading(true);
            const { data } = await apiUsuarios.post('/', {
                ...usuario
            });
            if(data) {
                return true;
            }
            return false;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return false;
        } finally {
            setLoading(false);
        }
    }, [])  

    const liberarAcessos = useCallback(async (email: string, acessos: IAcessoUsuario[]) => {
        if(loading) {
            return false;
        }

        try {
            setLoading(true);
            const { data } = await apiUsuarios.post(`${email}/acessos`, {
                acessos
            });
            if(data) {
                return true;
            }
            return false;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return false;
        } finally {
            setLoading(false);
        }
    }, [])  

    const alterarSenha = useCallback(async (confirmacaoSenha: string) => {
        let apiConfig = (!getToken("TOKEN_KEY") || !getToken("TOKEN_ESTABELECIMENTO_KEY")) ? apiRecuperarSenha : apiUsuarios;

        try {
            await apiConfig.put('/', {senha: confirmacaoSenha});
            showSnackbar('A sua senha foi alterada', {severity: 'success'});
            return true;
        } catch (e) {
            if(e instanceof AxiosError){
                showSnackbar(e.response?.data.message, {severity: 'error'});
                return false;
            }
            showSnackbar('Ocorreu um erro ao tentar alterar a senha', {severity: 'error'});
            return false;
        }
    }, [])

    const atualizarUsuario = useCallback(async (usuario: IUsuario, proprioUsuario?: boolean) => {
        try {
            const { data } = await apiUsuarios.put(`/`, usuario);
            showSnackbar('Usuário atualizado', { severity: 'success' })
            if(proprioUsuario) {
                atualizarEstadoProprioUsuario(data.usuario);
            }
            return true;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return false;
        }
    }, [])

    const excluirUsuario = useCallback(async (usuario: IUsuario) => {
        try {
            await apiUsuarios.delete(`/${usuario.uuid}`);
            showSnackbar('Usuário excluído', { severity: 'success' })
            return true;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return false;
        }
    }, [])

    const alterarPerfil = useCallback(async (usuarioUuid: string, perfilUuid: string) => {
        try {
            await apiUsuarios.patch(`/${usuarioUuid}/perfil/${perfilUuid}`);
            showSnackbar('Perfil alterado com sucesso', { severity: 'success' })
            return true;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return false;
            }
            showSnackbar("Ocorreu um erro, tente novamente mais tarde", { severity: 'error' })
            return false;
        }
    }, [])

    return { alterarPerfil, inativarUsuarios, excluirUsuario, atualizarUsuario, cadastrarUsuario, alterarSenha, loading, reativarUsuarios, autenticar, alterarAcesso, liberarAcessos }
}