import { Alert, Box, Button, CardActionArea, CardContent, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { OperacaoCategoriasProdutosContainer } from "../../../../../produtos/containers/OperacaoCategoriasProdutosContainer";
import { CheckoutPaper } from "../../../../components/CheckoutPaper";
import { ImprimirAnexoButton } from "../../../../components/ImprimirAnexoButton";
import { MesaPaper } from "../../../../components/MesaPaper";
import { MetodoPagamentoSelect } from "../../../../components/MetodoPagamentoSelect";
import { useOperacaoContext } from "../../../../contexts/OperacaoContext";
import { TipoPedido } from "../../../../enum/TipoPedido";
import { IProdutoPedido } from "../../../../interface/IProdutoPedido";
import { CategoriasContainer, Layer, ProdutosPaper } from "./styles";
import { useFetchCategorias } from "../../../../../produtos/hooks/useFetchCategorias";
import { CarrinhoMobilePaper } from "../../styles";
import { ProdutosCarrinhoContainer } from "../../../../../produtos/containers/ProdutosCarrinhoContainer";
import { useTamanhoTela } from "../../../../../core/hooks/useTamanhoTela";
import { TecladoNumeros } from "../../../../../core/components/TecladoNumeros";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { MesasContainer } from "../MesasContainer";
import { TipoAnexoPedido } from "../../../../enum/TipoAnexoPedido";
import { DividirPagamento } from "../DividirPagamento";
import { EmitirNotaFiscalModal } from "../EmitirNotaFiscalModal";
import { SwitchEmitirNotaFiscalPaper } from "../../../../components/SwitchEmitirNotaFiscalPaper";
import { TipoGatilhoComanda } from "../../../../enum/TipoGatilhoComanda";
import { IPagamentoPedido } from "../../../../interface/IPagamentoPedido";

export const OperacaoMesa = () => {
    const [mesaId, setMesaId] = useState<number>(0);
    const [numero, setNumero] = useState<string>('');
    const { modeloTela } = useTamanhoTela();
    const snackbar = useSnackbar();
    const { categorias, isLoading } = useFetchCategorias();
    const [drawerPagamentoAberto, setDrawerPagamento] = useState(false);

    const abrirDrawerPagamento = () => {
        setDrawerPagamento(true);
    }

    const fecharDrawerPagamento = () => {
        setDrawerPagamento(false);
    }

    const {
        adicionarProduto,
        valorTotalPedido,
        pagamentos,
        alterarPagamentos,
        produtos,
        aumentarQuantidade,
        diminuirQuantidade,
        removerProduto,
        fecharPedido,
        loading,
        configuracao,
        alterarTipo,
        valorTroco,
        pedidos,
        selecionarPedido,
        abrirPedido,
        pedidoSelecionado,
        valorRecebido,
        cancelarPedido,
        editarProduto,
        numeroTotalItens,
        numeroPedidoDiario,
        switchNotaFiscal,
        modalNotaFiscal,
        handleModalNotaFiscal,
        handleSwitchNotaFiscal,
        produtosSelecionados,
        configuracaoNFCe,
        fluxoContexto,
        gerarAnexosBotaoImprimirComandas
    } = useOperacaoContext();

    const removerMesa = () => {
        setMesaId(0);
        selecionarPedido(undefined);
    }

    const selecionarMesa = (id: number) => {
        if (!id || (id < 0) || (id > (configuracao?.numeroMesas ?? 0))) {
            return snackbar("Mesa não encontrada", { severity: "error" })
        }

        setMesaId(id);
        const pedidoMesa = pedidos.find(pedido => pedido.mesaId == id);
        selecionarPedido(pedidoMesa?.uuid ?? undefined);
    }

    const handleAlterarPagamento = (pagamento?: IPagamentoPedido) => {
        if (!pagamento?.metodoPagamento) {
            alterarPagamentos(undefined);
        } else {
            alterarPagamentos([pagamento]);
        }
    }

    const handleNumeroChange = (novoNumero: string) => {
        setNumero(novoNumero)
    }

    const handleAdicionarProduto = async (produto: IProdutoPedido) => {
        if (!pedidoSelecionado) {
            await abrirPedido({
                mesaId,
                produtos: [produto]
            });
        }

        adicionarProduto(produto);
    }

    useEffect(() => {
        alterarTipo(TipoPedido.mesa);
    }, [])

    const configuracoesComandaCozinha = fluxoContexto
        ?.configuracoesComandas
        ?.filter(config => config.gatilhos.includes(TipoGatilhoComanda.BOTAO_IMPRIMIR));

    return (
        <Grid
            container
            spacing={0}
            sx={{ width: "100%", height: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <DividirPagamento
                aberto={drawerPagamentoAberto}
                fechar={fecharDrawerPagamento}
            />
            {!mesaId ? (
                <>
                    <Grid item xs={12} sm={12} md={4} sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <TecladoNumeros
                            handleNumeroChange={handleNumeroChange}
                            concluir={() => selecionarMesa(Number(numero))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <MesasContainer
                            selecionarMesa={selecionarMesa}
                            configuracoes={configuracao}
                            pedidos={pedidos}
                            mesaId={mesaId}
                            sm={2}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    {modeloTela == "mobile" && (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                sx={{ width: "100%", height: "3rem", borderRadius: 0 }}
                                onClick={removerMesa}
                            >
                                VOLTAR
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={8}>
                        <ProdutosPaper variant="outlined">
                            <CategoriasContainer>
                                <OperacaoCategoriasProdutosContainer
                                    categorias={categorias}
                                    produtosCarrinho={produtos}
                                    adicionarProduto={handleAdicionarProduto}
                                    aumentarQuantidade={aumentarQuantidade}
                                    diminuirQuantidade={diminuirQuantidade}
                                    removerProduto={removerProduto}
                                    contexto={TipoPedido.mesa}
                                    editarProduto={editarProduto}
                                />
                                {!mesaId && (
                                    <Layer>
                                        <Paper sx={{ padding: "1rem" }}>
                                            <Typography>
                                                Nenhuma mesa selecionada
                                            </Typography>
                                        </Paper>
                                    </Layer>
                                )}
                            </CategoriasContainer>
                        </ProdutosPaper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {modalNotaFiscal && (
                            <EmitirNotaFiscalModal
                                cancelar={handleModalNotaFiscal}
                                concluir={fecharPedido}
                                open={modalNotaFiscal}
                                loading={loading}
                            />
                        )}
                        <CheckoutPaper
                            loading={loading}
                            valorTotalPedido={valorTotalPedido}
                            pedidoSelecionado={pedidoSelecionado}
                            numeroTotalItens={numeroTotalItens}
                            numeroPedidoDiario={numeroPedidoDiario}
                            opcoes={[
                                {
                                    titulo: "Concluir",
                                    funcao: fecharPedido
                                },
                                {
                                    titulo: "Dividir pagamento",
                                    funcao: abrirDrawerPagamento
                                },
                                {
                                    titulo: "Cancelar",
                                    funcao: cancelarPedido,
                                    desabilitado: !pedidoSelecionado
                                }
                            ]}
                        >
                            <MesaPaper mesaId={mesaId} />
                            {configuracoesComandaCozinha?.length && (
                                <ImprimirAnexoButton
                                    gerarAnexo={() => gerarAnexosBotaoImprimirComandas()}
                                    loading={loading}
                                    color={
                                        !produtosSelecionados.length
                                            ? "primary"
                                            : "secondary"
                                    }
                                    titulo={
                                        !produtosSelecionados.length
                                            ? "EMITIR COMANDA(S)"
                                            : `${produtosSelecionados.length} PRODUTO(S) SELECIONADO(S)`
                                    }
                                />
                            )}
                            {modeloTela == "mobile" && (produtos?.length > 0) && (
                                <CarrinhoMobilePaper variant="outlined" sx={{ marginTop: "1rem" }}>
                                    <ProdutosCarrinhoContainer
                                        categorias={categorias}
                                        produtosCarrinho={produtos}
                                        aumentarQuantidade={aumentarQuantidade}
                                        diminuirQuantidade={diminuirQuantidade}
                                        removerProduto={removerProduto}
                                        editarProduto={editarProduto}
                                    />
                                </CarrinhoMobilePaper>
                            )}
                            <Box sx={{ mt: "1rem", width: "100%" }}>
                                <MetodoPagamentoSelect
                                    pagamento={pagamentos[0]}
                                    onChange={handleAlterarPagamento}
                                    valorTotal={valorTotalPedido}
                                />
                            </Box>
                            {configuracaoNFCe && !configuracaoNFCe.obrigatorio && (
                                <SwitchEmitirNotaFiscalPaper
                                    checked={switchNotaFiscal}
                                    change={handleSwitchNotaFiscal}
                                    disabled={configuracaoNFCe?.obrigatorio}
                                />
                            )}
                        </CheckoutPaper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                        <MesasContainer
                            tools={modeloTela == "desktop"
                                ? <Button
                                    variant="contained"
                                    sx={{ width: "100%", height: "3rem" }}
                                    onClick={removerMesa}
                                >VOLTAR</Button>
                                : <></>
                            }
                            selecionarMesa={selecionarMesa}
                            configuracoes={configuracao}
                            pedidos={pedidos}
                            mesaId={mesaId}
                            sm={12}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}