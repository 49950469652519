import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SelecionarValoresBox = styled(Box)`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`

export const ValoresContainer = styled(Box)`
    width: 50%;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;

    @media(max-width: 768px) {
        width: 100%;
    }
`

export const PagadoresContainer = styled(Box)`
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media(max-width: 768px) {
        width: 100%;
    }
`
