import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const PrecificacaoPizza = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const Formulario = styled(Paper)`
    padding: 1rem;
    width: 100%;
`

export const ImagemContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
`

export const ButtonsContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
`