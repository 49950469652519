
import { Navigate, useParams } from "react-router-dom";
import { PedidoDetalhado } from "../../containers/PedidoDetalhado";


export const Pedido = () => {
    const { uuid } = useParams();

    if(!uuid) {
        return <Navigate to={"/dashboard"}/>;
    }

    return (
        <PedidoDetalhado uuid={uuid} />
    );
}