import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { MeuPerfil } from "../pages/MeuPerfil";
import { ListarUsuarios } from "../pages/ListarUsuarios";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { CadastrarUsuario } from "../pages/CadastrarUsuario";
import { Layout } from "../../core/components/Layout";
import { IMenu } from "../../core/interfaces/IMenu";
import { useMemo } from "react";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";

interface IUsuarioRoutesProps {
    autenticado: boolean;
}

export const UsuarioRoutes = ({ autenticado }: IUsuarioRoutesProps) => {
    const { modeloTela } = useTamanhoTela();
    const { perfil, verificarAcessoModulo } = useAutenticacaoContext();

    const itensMenu: IMenu[] = useMemo(() => {
        const itens = [
            {
                titulo: "Meu perfil",
                url: "/usuarios/meu-perfil",
                subitens: []
            }
        ];

        if (verificarAcessoModulo("Gestão de usuários")) {
            itens.push(
                {
                    titulo: "Base de usuários",
                    url: "/usuarios",
                    subitens: []
                },
                {
                    titulo: "Cadastrar",
                    url: "/usuarios/cadastrar",
                    subitens: []
                },
            )
        }

        return itens
    }, [perfil])

    return (
        <Routes>
            <Route element={
                <Layout
                    drawer={true}
                    titulo="Usuários"
                    itensMenu={itensMenu}
                    modeloTela={modeloTela}
                >
                    <PrivateRoutes
                        autenticado={autenticado}
                    />
                </Layout>
            }>
                <Route path="/" element={<ListarUsuarios />} />
                <Route path="/cadastrar" element={<CadastrarUsuario />} />
                <Route path="/meu-perfil" element={<MeuPerfil />} />
                <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
            </Route>
        </Routes>
    );
}