import { Alert, Box, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { MesaCard, MesasComponentContainer } from "./styles";
import { IConfiguracaoPedidos } from "../../../../interface/IConfiguracaoPedidos";
import { IPedido } from "../../../../interface/IPedido";

interface MesasContainerProps {
    configuracoes: IConfiguracaoPedidos | null | undefined;
    selecionarMesa: (id: number) => void;
    mesaId: number;
    pedidos: IPedido[];
    sm: number;
    tools?: React.ReactNode;
}

export const MesasContainer = ({ configuracoes, selecionarMesa, mesaId, pedidos, sm, tools }: MesasContainerProps) => {
    return (
        <MesasComponentContainer >
            {tools && (
                <Grid container justifyContent="center">
                    <Grid item xs={12} sx={{ position: "sticky", top: 0, zIndex: 1, background: "#fff" }}>
                        {tools}
                    </Grid>
                </Grid>
            )}
            <Box sx={{ maxHeight: "calc(100% - 48px)", overflowY: "auto" }}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        width: "100%",
                        padding: "1rem 0",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {configuracoes && configuracoes.numeroMesas ? (
                        [...Array(configuracoes.numeroMesas)].map((_, index) => {
                            let ocupado = pedidos?.find(pedido => pedido.mesaId == (index + 1)) ? true : false;

                            return (
                                (
                                    <Grid item xs={4} sm={sm} key={index}>
                                        <MesaCard
                                            variant="outlined"
                                            onClick={() => selecionarMesa(index + 1)}
                                            selecionado={(index + 1) === mesaId}
                                            ocupado={ocupado}
                                        >
                                            <CardActionArea sx={{ height: "100%" }}>
                                                <CardContent sx={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography variant="h6" fontWeight="bold" component="div">
                                                        {index + 1}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </MesaCard>
                                    </Grid>
                                )
                            )
                        })
                    ) : (
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%"
                        }}>
                            Nenhuma mesa configurada
                        </Box>
                    )}
                </Grid>
            </Box>
        </MesasComponentContainer>
    );
}