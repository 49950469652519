import { yupResolver } from "@hookform/resolvers/yup";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Breadcrumbs, Button, CircularProgress, FormControl, FormHelperText, FormLabel, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useAcoesInsumosContext } from "../../contexts/AcoesInsumosContext";
import { UnidadeInsumo, UnidadeInsumoDescricao } from "../../enum/UnidadeInsumo";
import { useInsumos } from "../../hooks/useInsumos";
import { IInsumo } from "../../interfaces/IInsumo";
import { insumoSchema } from "../../schema/insumoSchema";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper } from "./styles";

export const CadastrarEditarInsumo = () => {
    const { uuid } = useParams();

    const { setValue, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(insumoSchema),
        mode: "onChange",
    });

    const { salvar, loading, buscar } = useInsumos();

    const [insumo, setInsumo] = useState<undefined | IInsumo>(undefined);
    const [quantidadeAberto, setQuantidadeAberto] = useState(false);
    const navigate = useNavigate();

    const buscarInsumo = async (uuid: string) => {
        const dadosInsumo = await buscar(uuid);

        if(!dadosInsumo) {
            navigate("/cardapio/estoque/insumos");
            return;
        }

        setInsumo(dadosInsumo);
    }

    useEffect(() => {
        if(uuid) {
            buscarInsumo(uuid);
        }
    }, [uuid])

    useEffect(() => {
        if(insumo) {
            setValue("nome", insumo.nome);
            setValue("quantidade", String(insumo.quantidade));
            setValue("unidade", insumo.unidade);
        }
    }, [insumo])

    const handleSubmitForm = async ({
        nome,
        unidade,
        quantidade
    }: FieldValues) => {
        const insumoSalvo = await salvar({
            nome,
            quantidade,
            unidade,
            uuid: insumo ? insumo.uuid : v4()
        });

        if (insumoSalvo) {
            navigate("/cardapio/estoque/insumos")
        }
    }

    const unidadeInsumoOptions = Object.entries(UnidadeInsumoDescricao).map(([key, value]) => {
        if (key in UnidadeInsumo) {
            return (
                <MenuItem key={key} value={key}>
                    {value}
                </MenuItem>
            );
        }
        return null;
    }).filter(Boolean);

    const getUnidadeDescricao = (unidade: UnidadeInsumo): string => {
        const descricao = UnidadeInsumoDescricao[unidade] ?? "-";
        return descricao || "-";
    };

    useEffect(() => {
        setValue("quantidade", 0);
    }, [])

    return (
        <PageContainer>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" href="/cardapio/estoque/insumos">
                        Insumos e ingredientes
                    </Link>
                    <Typography color="text.primary">{insumo ? "Editar" : "Cadastrar"} insumo</Typography>
                </Breadcrumbs>
                <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                    {insumo ? "Editar" : "Cadastrar"} insumo
                </Typography>
                <ContentContainer>
                    <FormContainer>
                        <Accordion
                            expanded={true}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary>
                                <Typography variant="h6">Nome e unidade</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Nome"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("nome")}
                                    error={!!errors.nome}
                                    helperText={<>{errors.nome?.message}</>}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormControl sx={{ width: "100%", margin: "0.7rem 0" }}>
                                    <InputLabel
                                        id="unidade-insumo-label"
                                        error={!!errors.unidade}
                                    >
                                        Unidade
                                    </InputLabel>
                                    <Select
                                        labelId="unidade-insumo-label"
                                        id="unidade-insumo"
                                        placeholder="Selecione uma unidade"
                                        {...register("unidade")}
                                        value={watch("unidade") || ''}
                                        error={!!errors.unidade}
                                        label="Unidade"
                                        defaultValue={undefined}
                                        disabled={insumo ? true : false}
                                    >
                                        {unidadeInsumoOptions}
                                    </Select>
                                    {errors.unidade && <FormHelperText error><>{errors.unidade.message}</></FormHelperText>}
                                </FormControl>
                                <Alert severity={"info"} sx={{ marginTop: "1rem" }}>
                                    A unidade não pode ser alterada após o insumo ser cadastrado
                                </Alert>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={quantidadeAberto}
                            sx={{
                                "&.Mui-expanded": {
                                    margin: 0,
                                },
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                onClick={() => setQuantidadeAberto(!quantidadeAberto)}
                            >
                                <Typography variant="h6">
                                    Quantidade
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Quantidade"
                                    style={{ width: "100%" }}
                                    {...register("quantidade")}
                                    error={!!errors.quantidade}
                                    helperText={<>{errors.quantidade?.message}</>}
                                    InputLabelProps={{ shrink: true }}
                                    value={watch("quantidade")}
                                    disabled={insumo ? true : false}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </FormContainer>
                    <ResumoPaper>
                        <Typography variant="h6">Resumo</Typography>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Nome</b>: {watch("nome")?.length ? watch("nome") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Unidade</b>: {getUnidadeDescricao(watch("unidade"))}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Quantidade</b>: {watch("quantidade")?.length ? watch("quantidade") : 0}
                            </Typography>
                        </Paper>
                        <ButtonsContainer>
                            <Button size="large" variant="outlined" onClick={() => navigate("/cardapio/estoque/insumos")}>
                                Cancelar
                            </Button>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button size="large" variant="contained" type="submit">
                                    {insumo ? "Atualizar" : "Cadastrar"} 
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
        </PageContainer>
    );
}