import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { IProduto } from "../../interfaces/IProduto";
import { IProdutoPedido } from "../../../pedidos/interface/IProdutoPedido";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { v4 } from "uuid";
import { useLetras } from "../../../core/hooks/useLetras";

interface CardIndustrializadoOperacaoProps {
    produto: IProduto;
    adicionarProduto: (produto: IProdutoPedido) => void;
}

export const CardIndustrializadoOperacao = ({ produto, adicionarProduto }: CardIndustrializadoOperacaoProps) => {
    const { real } = useMoeda();
    const { maiusculas } = useLetras();
    return (
        <Card sx={{ maxWidth: 300, height: 250 }} variant='outlined' onClick={() => adicionarProduto({ ...produto, uuidProdutoReferencia: produto.uuid })}>
            <CardActionArea sx={{ height: "100%", width: "100%" }}>
            {produto.imagens?.length ? (
                <CardMedia
                    component="img"
                    height="140px"
                    image={`${produto.imagens[0].url}`}
                    sx={{ objectFit: "contain" }}
                    alt={produto.descricao}
                />
            ) : (
                <CardMedia
                    component="img"
                    height="140px"
                    image={`${process.env.PUBLIC_URL}/images/produtos/industrializado.png`}
                    sx={{ objectFit: "contain" }}
                    alt={produto.descricao}
                />
            )}
            <CardContent sx={{ height: "100%" }}>
                <Typography gutterBottom variant="subtitle2" fontWeight={"bold"} component="div">
                {maiusculas(produto.descricao, 20)}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    {real(produto.preco)}
                </Typography>
            </CardContent>
            </CardActionArea>
        </Card>
    );
}