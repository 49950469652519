import { Dialog, DialogContent } from "@mui/material";
import { IConfiguracaoPedidos } from "../../../../../pedidos/interface/IConfiguracaoPedidos";
import { SalvarEndereco } from "../../../../containers/SalvarEndereco";
import { ICliente } from "../../../../interface/ICliente";
import { IRegiao } from "../../../../../core/interfaces/IRegiao";
import { GestaoEnderecosCliente } from "../../../../containers/GestaoEnderecosCliente";

interface CadastrarEditarEnderecoModalProps {
    cliente?: ICliente;
    salvar: () => void;
    open: boolean;
    cancelar: () => void;
}

export const CadastrarEditarEnderecoModal = ({ cliente, salvar, open, cancelar }: CadastrarEditarEnderecoModalProps) => {
    return (
        <Dialog open={open}>
            {cliente && (
                <GestaoEnderecosCliente cancelar={cancelar} cliente={cliente} opcoes={["EXCLUIR_ENDERECO", "EDITAR_ENDERECO"]} salvar={salvar}/>
            )}
        </Dialog>
    )
}