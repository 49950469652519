import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { consultarIndustrializadoSchema } from "../../schema/consultarIndustrializadoSchema";
import { ButtonsContainer, ConsultaContainer, Formulario } from "./styles";
import { useEffect } from "react";

interface ConsultarIndustrializadoContainerProps {
    handleCodigoBarrasChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    codigoBarras: string | undefined;
}

export const ConsultarIndustrializadoContainer = () => {
    const { consultarIndustrializado, loading, handleProximo, produto } = useCadastrarEditarProdutoContext();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(consultarIndustrializadoSchema),
        mode: "onChange",
    });

    useEffect(() => {
        if(produto) {
            handleProximo();
        }
    }, [produto])

    return (
        <ConsultaContainer>
            <Formulario>
                <form onSubmit={handleSubmit(consultarIndustrializado)}>
                    <Typography
                        variant="h6"
                        sx={{ width: "100%", marginBottom: "1rem" }}
                    >
                        Consulta
                    </Typography>
                    <TextField
                        label="Código de barras*"
                        sx={{ width: "100%", marginBottom: "1rem" }}
                        {...register("codigoBarras")}
                        error={!!errors.codigoBarras}
                        helperText={<>{errors.codigoBarras?.message}</>}
                    />
                    <ButtonsContainer>
                        <Box></Box>
                        {loading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Button variant="contained" type="submit">
                                Próximo
                            </Button>
                        )}
                    </ButtonsContainer>
                </form>
            </Formulario>
        </ConsultaContainer>
    );
}