import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { ModulosDrawer } from "../../../core/components/ModulosDrawer";
import { Header } from "../../../core/components/Header";

export const NaoAutorizado = () => {
    return (
        <>
            <ModulosDrawer />
            <Header tituloModulo={"Não autorizado"} />
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", flexDirection: "column" }}>
                <Alert severity="error">
                    <AlertTitle>Não autorizado</AlertTitle>
                    Você não possui permissão para acessar essa tela.
                </Alert>
            </Box>
        </>
    );
}