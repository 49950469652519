import { useEffect, useState } from "react";

interface ITamanhoTela {
    width: number | undefined;
    height: number | undefined;
}

export function useTamanhoTela() {
    const [tamanhoTela, setTamanhoTela] = useState<ITamanhoTela>({
        width: undefined,
        height: undefined
    });

    const [modeloTela, setModeloTela] = useState<'desktop' | 'mobile' | undefined>();

    function handleResize() {
        setTamanhoTela({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {
        if(window && typeof window !== "undefined") {
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [])

    useEffect(() => {
        if(tamanhoTela.width && tamanhoTela.width <= 768) {
            setModeloTela("mobile");
            return;
        }

        setModeloTela("desktop")
    }, [tamanhoTela.width])

    return { modeloTela, tamanhoTela }
}