import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const TecladoContainer = styled(Paper)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 3rem 1.5rem;
    height: 87vh;
    border: 0;
`

export const TecladoRow = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    margin-top: 0.5rem;

    button {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        margin: 0 0.5rem;
        font-size: 2.2rem;
        font-weight: bold;

        svg {
            font-size: 2rem;
        }
    }
`