import { Autocomplete, Button, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useRegex } from "../../../core/hooks/useRegex";
import { useClientes } from "../../hooks/useClientes";
import { ICliente } from "../../interface/ICliente";
import { VincularClienteForm } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { AlertPermissaoFuncionalidade } from "../../../core/components/AlertPermissaoFuncionalidade";

interface VincularClienteProps {
  alterarModo: (modo: 'cadastrar' | 'vincular') => void;
  vincularCliente: (cliente: ICliente) => void;
  novoCliente: ICliente;
  handleNovoCliente: (cliente: ICliente) => void;
}

export const VincularCliente = ({ alterarModo, vincularCliente, novoCliente, handleNovoCliente }: VincularClienteProps) => {
  const { testeNumericoTelefone } = useRegex();
  const { buscarClientesPorNomeOuTelefone } = useClientes();
  const [clientes, setClientes] = useState<ICliente[]>([]);
  const [filtroTipo, setFiltroTipo] = useState<"nome" | "telefone">('nome');
  const [clienteSelecionado, setClienteSelecionado] = useState<ICliente | undefined>(undefined);
  const [filtro, setFiltro] = useState('');

  const handleFiltroChange = (event: React.SyntheticEvent<Element, Event>, valor: string) => {
    setFiltro(valor.toLocaleLowerCase());
  }

  const handleBuscarClientes = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const valorInput = event.target.value;

    if (!valorInput.length) {
      return;
    }

    let novoFiltroTipo: 'nome' | 'telefone' = 'nome';

    if (testeNumericoTelefone(valorInput)) {
      novoCliente['telefone'] = valorInput;
      novoCliente['nome'] = '';
      novoFiltroTipo = 'telefone'
      setFiltroTipo(novoFiltroTipo);
    } else {
      novoCliente['nome'] = valorInput;
      novoCliente['telefone'] = '';
      novoFiltroTipo = 'nome'
      setFiltroTipo(novoFiltroTipo);
    }

    handleNovoCliente(novoCliente);

    const clientes = await buscarClientesPorNomeOuTelefone(
      valorInput,
      novoFiltroTipo
    );

    setClientes([...clientes, novoCliente]);
  };

  const handleClienteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ICliente | null
  ) => {
    if (value && value.uuid == 'novo') {
      return alterarModo('cadastrar');
    }

    if (!value) {
      return setClienteSelecionado(undefined);
    }

    return setClienteSelecionado(value);
  };

  const handleVincularCliente = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!clienteSelecionado) {
      return;
    }

    vincularCliente(clienteSelecionado)
  }
  
  const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();

  return (
    <VincularClienteForm onSubmit={handleVincularCliente}>
      <Typography variant="h6">Cliente</Typography>
      {!perfil || !verificarAcessoFuncionalidade("Gestão de clientes") ? (
        <AlertPermissaoFuncionalidade funcionalidade="Gestão de clientes"  />
      ) : (
        <>
          <Autocomplete
            options={clientes}
            onInputChange={handleFiltroChange}
            getOptionLabel={(cliente) => {
              if (filtroTipo == "telefone" && cliente["telefone"]) {
                return cliente["telefone"];
              }

              if (cliente['nome'].toLowerCase().includes(filtro)) {
                return cliente['nome'];
              }

              if (cliente['observacao'] && cliente['observacao'].toLowerCase().includes(filtro)) {
                return cliente['observacao'];
              }

              return cliente['nome'];
            }}
            onChange={handleClienteChange}
            style={{ width: "100%", margin: "1rem 0" }}
            noOptionsText={"Nenhum cliente encontrado"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nome ou telefone"
                onChange={handleBuscarClientes}
              />
            )}
            renderOption={(props, option) => {
              if (option.uuid == 'novo') {
                return (
                  <MenuItem {...props} key={option.uuid} value={option.uuid}>
                    <Button>Cadastrar {option.nome.length ? option.nome : option.telefone}</Button>
                  </MenuItem>
                );
              }

              if (option) {
                return (
                  <MenuItem {...props} key={option.uuid} value={option.uuid}>
                    {option.nome} {option.observacao ? ` ${option.observacao}` : ""} {option.telefone ? `- ${option.telefone}` : ''}
                  </MenuItem>
                );
              }

              return null;
            }}
          />
          <Button
            variant="contained"
            style={{ width: "100%" }}
            type="submit"
            disabled={!clienteSelecionado}
          >
            VINCULAR
          </Button>
        </>
      )}
    </VincularClienteForm>
  );
}