import { useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext"
import { ApiFactory } from "../../core/config/ApiFactory";
import { ReferenciaItem } from "../enum/ReferenciaItem";
import { IVendaMes } from "../interfaces/IVendaMes";

export const useContas = () => {
    const apiFinanceiro = useMemo(() => ApiFactory.getApi("MS_FINANCEIRO"), []);
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();

    const buscarContasPorIntervalo = async (intervaloUuid: string) => {
        if(!intervaloUuid) {
            return snackbar("Intervalo inválido", { severity: "error" });
        }
        setLoading(true);
        try {
            const { data } = await apiFinanceiro.get(`/contas/intervalo/${intervaloUuid}`);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar as contas do intervalo", { severity: "error" })
        } finally {
            setLoading(false);
        }
    }

    const consultarTicketMedio = async () => {
        setLoading(true);
        try {
            const { data } = await apiFinanceiro.get(`/contas/ticket-medio`);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar o ticket médio", { severity: "error" })
        } finally {
            setLoading(false);
        }
    }

    const consultarValorTotal = async (referenciaTipo: ReferenciaItem) => {
        setLoading(true);
        try {
            const { data } = await apiFinanceiro.get(`/contas/valor-total/${referenciaTipo}`);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar o valor total", { severity: "error" })
        } finally {
            setLoading(false);
        }
    }

    const consultarVendasMes = async (dataInicio?: string, dataFim?: string): Promise<IVendaMes[] | undefined> => {
        setLoading(true);
        try {
            let url =`/contas/valor-mes`;
            if(dataInicio && dataFim) {
                url = `${url}?dataInicio=${dataInicio}&dataFim=${dataFim}`
            } 
            const { data } = await apiFinanceiro.get(url);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar o valor total", { severity: "error" })
        } finally {
            setLoading(false);
        }
    }

    return { buscarContasPorIntervalo, loading, consultarValorTotal, consultarTicketMedio, consultarVendasMes }
}