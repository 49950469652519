import { Environment } from "./environment";
import axios from "axios";

type Aplicacoes = "MS_PEDIDOS" 
| "MS_USUARIOS_ESTABELECIMENTO" 
| "MS_ESTABELECIMENTOS" 
| "MS_FINANCEIRO" 
| "MS_USUARIOS_RECUPERAR_SENHA" 
| "MS_CLIENTES" 
| "MS_PRODUTOS"
| "MS_MARKETPLACES"

export class ApiFactory {
    static getApi(apiNome: Aplicacoes) {
        const api = axios.create({
            baseURL: Environment[apiNome]
        });

        api.interceptors.request.use(async config => {
            let token = apiNome == "MS_USUARIOS_RECUPERAR_SENHA" 
            ? localStorage.getItem(Environment.TOKEN_RECUPERAR_SENHA_KEY)
            : localStorage.getItem(Environment.TOKEN_ESTABELECIMENTO_KEY);

            if(!token) {
                token = localStorage.getItem(Environment.TOKEN_KEY);
            }

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        return api;
    }
}