import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const BoardContainer = styled(Box)`
    z-index: 999;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    gap: 1rem;
    height: 100%;
`