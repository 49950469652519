export const useRegex = () => {
    const testeNumerico = (input: string) => {
        const regex = /^\d+$/;
        return regex.test(input);
    }

    const testeNumericoTelefone = (input: string) => {
        const regex = /^[\d()\- ]+$/;
        return regex.test(input);
    }
    
    const testeAlfanumerico = (input: string) => {
        const regex = /^\d+$/;
        return regex.test(input);
    }

    return { testeNumerico, testeAlfanumerico, testeNumericoTelefone }
}