import { UnidadeInsumo } from "../../produtos/enum/UnidadeInsumo";

export const useUnidade = () => {
    const formatarQuilo = (quantidade: number): string => {
        const valorAbsoluto = Math.abs(quantidade);
        const quilos = Math.floor(valorAbsoluto);
        const gramas = Math.round((valorAbsoluto - quilos) * 1000);
    
        let resultado = '';
        if (quilos > 0) {
            resultado += `${quilos} kg`;
        }
        if (gramas > 0) {
            if (resultado.length > 0) resultado += ' e ';
            resultado += `${gramas} g`;
        }
    
        if (resultado.length === 0) {
            resultado = '0 g';
        }
    
        if (quantidade < 0) {
            resultado = '-' + resultado;
        }
    
        return resultado;
    };

    const formatarLitro = (quantidade: number): string => {
        const valorAbsoluto = Math.abs(quantidade);
        const litros = Math.floor(valorAbsoluto);
        const ml = Math.round((valorAbsoluto - litros) * 1000);
    
        let resultado = '';
        if (litros > 0) {
            resultado += `${litros} L`;
        }
        if (ml > 0) {
            if (resultado.length > 0) resultado += ' e ';
            resultado += `${ml} ml`;
        }
    
        if (resultado.length === 0) {
            resultado = '0 ml';
        }
    
        if (quantidade < 0) {
            resultado = '-' + resultado;
        }
    
        return resultado;
    };
    
    const formatar = (quantidade: number, unidade: UnidadeInsumo) => {
        if(unidade == UnidadeInsumo.kg) {
            return formatarQuilo(quantidade);
        }
        if(unidade == UnidadeInsumo.L) {
            return formatarLitro(quantidade);
        }
        
        if(quantidade <= 1) {
            return `${quantidade} item`
        }

        return `${quantidade} itens`
    }

    return { formatarQuilo, formatarLitro, formatar }
}