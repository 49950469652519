import { Alert, AlertTitle, Box, Card, Grid, Paper, Typography } from "@mui/material";
import { DashboardCard, DashboardGrid } from "./styles";
import { useEffect, useMemo, useState } from "react";
import { useContas } from "../../../financeiro/hooks/useContas";
import { ReferenciaItem } from "../../../financeiro/enum/ReferenciaItem";
import { useMoeda } from "../../hooks/useMoeda";
import { useClientes } from "../../../clientes/hooks/useClientes";
import { IVendaMes } from "../../../financeiro/interfaces/IVendaMes";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheme } from "@emotion/react";
import { useThemeContext } from "../../contexts/ThemeContext";
import { useTamanhoTela } from "../../hooks/useTamanhoTela";
import { useAutenticacaoContext } from "../../contexts/AutenticacaoContext";
import { AlertPermissaoFuncionalidade } from "../../components/AlertPermissaoFuncionalidade";


export const Dashboard = () => {
    const { theme } = useThemeContext();
    const { modeloTela } = useTamanhoTela();
    const [ticketMedio, setTicketMedio] = useState<undefined | number>();
    const [valorTotalProdutos, setValorTotalProdutos] = useState<undefined | number>();
    const [valorTotalEntregas, setValorTotalEntregas] = useState<undefined | number>();
    const [totalClientes, setTotalClientes] = useState<undefined | number>();
    const [vendasMes, setVendasMes] = useState<undefined | IVendaMes[]>();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();

    const { consultarTotal } = useClientes();
    const { consultarTicketMedio, consultarValorTotal, consultarVendasMes } = useContas();

    const efetuarConsultas = async () => {
        const resultadoTicketMedio = await consultarTicketMedio();
        setTicketMedio(resultadoTicketMedio ?? 0);

        const resultadoValorTotalProdutos = await consultarValorTotal(ReferenciaItem.produto);
        setValorTotalProdutos(resultadoValorTotalProdutos ?? 0);

        const resultadoValorTotalEntregas = await consultarValorTotal(ReferenciaItem.entrega);
        setValorTotalEntregas(resultadoValorTotalEntregas ?? 0);

        const resultadoTotalClientes = await consultarTotal();
        setTotalClientes(resultadoTotalClientes ?? 0);

        const resultadoVendasMes = await consultarVendasMes();
        if (resultadoVendasMes) {
            setVendasMes(resultadoVendasMes);
        }
    }

    const { real } = useMoeda();

    useEffect(() => {
        if(perfil) {
            const acesso = verificarAcessoFuncionalidade("Dashboard inicial"); 
            if(acesso) {
                efetuarConsultas();
                const intervalId = setInterval(() => {
                    efetuarConsultas();
                }, 30000);
                return () => clearInterval(intervalId);
            }
        }
    }, [perfil]);

    const variantResultado = useMemo(() => {
        if (!valorTotalProdutos || valorTotalProdutos < 1000) {
            return "h5";
        }

        if (valorTotalProdutos < 10000000) {
            return "h5";
        }

        return "h6"
    }, [valorTotalProdutos])

    const CustomToolTip = (props: any) => {
        const { active, payload, label } = props;

        return (
            <Box>
                {payload?.map((item: any, i: number) => {
                    return (
                        <Typography variant="subtitle2" fontWeight={"bold"}>
                            {real(item.value)}
                        </Typography>
                    );
                })}
            </Box>
        );
    };

    return (
        <Grid
            sx={{
                maxWidth: "98vw",
                justifyContent: "center",
                margin: "1rem auto"
            }}
            spacing={3}
            container
        >
            {perfil && !verificarAcessoFuncionalidade("Dashboard inicial") ? (
                <Grid item xs={12}>
                    <AlertPermissaoFuncionalidade funcionalidade="Dashboard inicial" />
                </Grid>
            ) : ""}
            <DashboardGrid item xs={12} md={3} sm={6}>
                <DashboardCard>
                    <Box>
                        <Typography variant="subtitle2">
                            PRODUTOS VENDIDOS
                        </Typography>
                        <Typography variant={variantResultado} fontWeight={"bold"}>
                            {real(valorTotalProdutos)}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/produtos.png`}
                            alt="Produtos"
                        />
                    </Box>
                </DashboardCard>
            </DashboardGrid>
            <DashboardGrid item xs={12} md={3} sm={6}>
                <DashboardCard>
                    <Box>
                        <Typography variant="subtitle2">
                            ENTREGAS REALIZADAS
                        </Typography>
                        <Typography variant={variantResultado} fontWeight={"bold"}>
                            {real(valorTotalEntregas)}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/delivery.png`}
                            alt="Entregas"
                        />
                    </Box>
                </DashboardCard>
            </DashboardGrid>
            <DashboardGrid item xs={12} md={3} sm={6}>
                <DashboardCard>
                    <Box>
                        <Typography variant="subtitle2">
                            TICKET MÉDIO
                        </Typography>
                        <Typography variant={variantResultado} fontWeight={"bold"}>
                            {real(ticketMedio)}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/ticket-medio.png`}
                            alt="Ticker médio"
                        />
                    </Box>
                </DashboardCard>
            </DashboardGrid>
            <DashboardGrid item xs={12} md={3} sm={6}>
                <DashboardCard>
                    <Box>
                        <Typography variant="subtitle2">
                            CLIENTES
                        </Typography>
                        <Typography variant={variantResultado} fontWeight={"bold"}>
                            {totalClientes}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/cliente.png`}
                            alt="Clientes"
                        />
                    </Box>
                </DashboardCard>
            </DashboardGrid>
            <DashboardGrid item xs={12} md={8} sx={{ width: "100%", height: "100%" }}>
                <Card sx={{
                    width: "100%",
                    height: "500px",
                    padding: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}>
                    <Typography variant="h5" fontWeight={"bold"} sx={{ marginBottom: "1rem" }}>Vendas nos ultimos {modeloTela == "desktop" ? 12 : 3} meses</Typography>
                    <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                            data={modeloTela == "desktop" ? vendasMes : vendasMes?.slice(-3)}
                            margin={{ top: 5, right: 40, left: 60, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="mes" angle={-45} height={60} interval={0} tick={{ fontSize: 12 }} />
                            <YAxis
                                dataKey="valor"
                                tickFormatter={(valor: string) => real(valor)}
                            />
                            <Tooltip content={CustomToolTip} />
                            <Legend />
                            <Bar 
                                dataKey="valor" 
                                fill={theme.palette.secondary.main}
                                activeBar={{ fill: "pink", stroke: "blue" }}
                                name="Valor de Vendas" 
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            </DashboardGrid>
        </Grid>
    );
}