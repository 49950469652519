import { useEffect, useMemo, useState } from "react";
import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch"
import { ICategoria } from "../interfaces/ICategoria";
import { IITemControleEstoque } from "../interfaces/IItemControleEstoque";
import { TipoItemControleEstoque } from "../enum/TipoItemControleEstoque";
import { TipoProduto } from "../enum/TipoProduto";
import { v4 } from "uuid";
import { TipoControleEstoque } from "../enum/TipoControleEstoque";

export const useFetchItensControleEstoque = () => {
    const apiProdutos = ApiFactory.getApi("MS_PRODUTOS")
    const [itens, setItens] = useState<undefined | IITemControleEstoque[]>(undefined);
    const { data: categorias, error, isLoading, handleRefresh, isFetching } = useFetch<ICategoria[]>(apiProdutos, '/categorias');

    useEffect(() => {
        let novosItens: IITemControleEstoque[] = [];

        categorias?.map(categoria => {
            return categoria.produtos?.map(produto => {
                if (produto.controleEstoque != undefined) {
                    const itemExiste = novosItens.find(item => item.uuid == produto.uuid && item.tipo == TipoItemControleEstoque.produto);
                    if (!itemExiste && produto.controleEstoque) {
                        novosItens.push({
                            nome: produto.descricao,
                            controleEstoque: produto.controleEstoque,
                            tipo: TipoItemControleEstoque.produto,
                            uuid: produto.uuid,
                            fichaTecnica: produto.fichaTecnica,
                            preco: produto.preco,
                            quantidade: produto.quantidade ?? 0
                        });
                    }

                    if (produto.tipo == TipoProduto.pizza && produto.controleEstoque == TipoControleEstoque.completo) {
                        produto.sabores?.map(sabor => {
                            const itemExiste = novosItens.find(item => item.uuid == sabor.uuid && item.tipo == TipoItemControleEstoque.sabor);
                            if (!itemExiste && produto.controleEstoque) {
                                novosItens.push({
                                    nome: sabor.descricao,
                                    controleEstoque: TipoControleEstoque.completo,
                                    tipo: TipoItemControleEstoque.sabor,
                                    uuid: sabor.uuid ?? v4(),
                                    fichaTecnica: sabor.fichaTecnica,
                                    preco: sabor.preco,
                                    quantidade: sabor.quantidade ?? 0,
                                    uuidProdutoReferencia: produto.uuid
                                });
                            }
                        })

                        produto.bordas?.map(borda => {
                            const itemExiste = novosItens.find(item => item.uuid == borda.uuid && item.tipo == TipoItemControleEstoque.borda);
                            if (!itemExiste && produto.controleEstoque) {
                                novosItens.push({
                                    nome: borda.descricao,
                                    controleEstoque: TipoControleEstoque.completo,
                                    tipo: TipoItemControleEstoque.borda,
                                    uuid: borda.uuid ?? v4(),
                                    fichaTecnica: borda.fichaTecnica,
                                    preco: borda.preco,
                                    quantidade: borda.quantidade ?? 0,
                                    uuidProdutoReferencia: produto.uuid
                                });
                            }
                        })
                    }

                    if (produto.tipo == TipoProduto.preparado && produto.controleEstoque == TipoControleEstoque.completo) {
                        produto?.complementos?.map(complemento => {
                            complemento.itens.map(itemComplemento => {
                                const itemExiste = novosItens.find(item => item.uuid == itemComplemento.uuid && item.tipo == TipoItemControleEstoque.item);
                                if (!itemExiste && produto.controleEstoque) {
                                    novosItens.push({
                                        nome: itemComplemento.descricao,
                                        controleEstoque: TipoControleEstoque.completo,
                                        tipo: TipoItemControleEstoque.item,
                                        uuid: itemComplemento.uuid ?? v4(),
                                        fichaTecnica: itemComplemento.fichaTecnica,
                                        preco: itemComplemento.preco,
                                        quantidade: itemComplemento.quantidade ?? 0,
                                        uuidProdutoReferencia: produto.uuid
                                    });
                                }
                            })
                        })
                    }
                }
            })
        })

        setItens(novosItens);
    }, [categorias]);

    return { itens, error, isLoading, handleRefresh, isFetching, categorias }
}