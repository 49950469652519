import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const ListarCategoriasContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export const GridProdutos = styled(Grid)`
    overflow-y: auto;
    margin: 0 auto; 
    height: 100%;
    width: 100%;
    padding: 0rem 1rem; 
    border-bottom: 1px solid ${props => props.theme.palette.divider};
`

export const GridCarrinho = styled(Grid)`
    margin: auto auto auto 0;
    width: 100%;
    height: 660px;
    min-height: 160px;
    position: relative;
    overflow-y: auto;

    @media(max-width: 800px) {
        display: none;
    }
`