import { Alert, Box, Button, CardActionArea, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import { StatusPedido } from "../../../../enum/StatusPedido";
import { IPedido } from "../../../../interface/IPedido";
import { NenhumProdutoEmAndamento, PedidoCard, PedidosNaoConcluidos } from "./styles";
import AddIcon from '@mui/icons-material/Add';
import { useLetras } from "../../../../../core/hooks/useLetras";
interface PedidosNaoConcluidosContainerProps {
    pedidos: IPedido[];
    pedidoSelecionado?: string;
    selecionarPedido: (uuid: string | undefined) => void;
}

export const PedidosNaoConcluidosContainer = ({ pedidos, pedidoSelecionado, selecionarPedido }: PedidosNaoConcluidosContainerProps) => {
    const { titulo } = useLetras();
    return (
        <PedidosNaoConcluidos>
            {pedidos?.map((pedido, index) => {
                return (
                    <Tooltip title={`Pedido para ${pedido.cliente?.nome}`}>
                        <PedidoCard
                            selecionado={pedido.uuid == pedidoSelecionado}
                            key={index}
                            onClick={() => selecionarPedido(pedido.uuid)}
                            variant="outlined"
                        >
                            <CardActionArea sx={{ height: "100%", borderRadius: "2rem" }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem", }}>
                                        <Typography variant="subtitle2" component="div" sx={{ fontSize: 15 }}>
                                            {pedido.cliente?.nome
                                                .slice(0, 13)
                                                .split(" ")
                                                .map(item => titulo(item))
                                                .join(" ")
                                            }
                                            {pedido.cliente?.nome?.length && pedido.cliente?.nome?.length >= 13 && "..."}
                                        </Typography>
                                        {pedido.numeroPedidoDiario && (
                                            <Typography variant="subtitle1" color="gray">
                                                #{pedido?.numeroPedidoDiario}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Alert icon={false} severity="warning">{StatusPedido[pedido.status]}</Alert>
                                </CardContent>
                            </CardActionArea>
                        </PedidoCard>
                    </Tooltip>
                );
            })}
            {!pedidos || !pedidos.length && (
                <NenhumProdutoEmAndamento>
                    <Typography fontWeight={"bold"} variant="subtitle2">
                        Nenhum pedido em andamento
                    </Typography>
                </NenhumProdutoEmAndamento>
            )}
        </PedidosNaoConcluidos>
    )
}