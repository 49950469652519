import { useMemo, useState } from "react";
import { useFetch } from "../../core/hooks/useFetch"
import { ApiFactory } from "../../core/config/ApiFactory";
import { useMascara } from "../../core/hooks/useMascara";

export const useFetchClientes = () => {
    const apiCliente = useMemo(() => ApiFactory.getApi("MS_CLIENTES"), []);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [nome, setNome] = useState<string | undefined>();
    const [observacao, setObservacao] = useState<string | undefined>();
    const [telefone, setTelefone] = useState<string | undefined>();
    const { mascaraTelefone } = useMascara();

    const urlInicial = `/paginado?paginaAtual=${paginaAtual}`; 
    const [url, setUrl] = useState(urlInicial);
    
    const handlePaginaChange = (event: React.ChangeEvent<unknown>, pagina: number) => {
        setUrl(url.replace(/(paginaAtual=)\d+/, `paginaAtual=${pagina}`));
        setPaginaAtual(pagina);
    }

    const handleNomeChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setNome(e.target.value);
    const handleObservacaoChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setObservacao(e.target.value);
    const handleTelefoneChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setTelefone(mascaraTelefone(e.target.value));

    const handleLimpar = () => {
        setUrl(urlInicial);
        setPaginaAtual(1);
        setNome('');
        setObservacao('');
        setTelefone('');
    }

    const handleFiltrar = () => {
        let novaUrl = urlInicial;

        if(nome) {
            novaUrl = `${novaUrl}&nome=${nome}`;
        }

        if(observacao) {
            novaUrl = `${novaUrl}&observacao=${observacao}`;
        }

        if(telefone) {
            novaUrl = `${novaUrl}&telefone=${telefone}`;
        }

        setUrl(novaUrl);
    }
    
    const { data: dados, isLoading, handleRefresh } = useFetch(apiCliente, url);
    
    return { 
        dados, 
        isLoading, 
        handleRefresh, 
        handlePaginaChange, 
        paginaAtual, 
        handleNomeChange,
        handleObservacaoChange,
        handleTelefoneChange,
        handleLimpar,
        handleFiltrar,
        nome,
        observacao,
        telefone
    }
}