import { Upload } from "@mui/icons-material";
import { DropzoneContainer, UploadBox } from "./styled";
import { Typography } from "@mui/material";
import { MIMETypes, MIMETypesDescricao } from "../../enum/MIMETypes";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "../../contexts/SnackbarContext";

interface DropzoneUploadProps {
    mimetypes: MIMETypes[];
    limiteArquivos?: number;
    onDrop: (files: File[]) => void;
    descricao?: string;
    tamanhoMaximoMB: number
}

export const DropzoneUpload = ({ mimetypes, limiteArquivos, onDrop: handleDrop, descricao, tamanhoMaximoMB }: DropzoneUploadProps) => {
    const tamanhoMaximo = tamanhoMaximoMB * 1024 * 1024;
    const mimeDescricoes = mimetypes.map(mimetype => MIMETypesDescricao[mimetype]);
    const mimeList = mimeDescricoes.join(', ').replace(/,([^,]*)$/, ' e$1');
    const showSnackbar = useSnackbar();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const validMimetypes = new Set(mimetypes);

        if (limiteArquivos && (acceptedFiles.length > limiteArquivos)) {
            return showSnackbar(`O número máximo de arquivos permitidos é ${limiteArquivos}`, { severity: 'error' });
        }

        const invalidFiles = acceptedFiles.filter(file => !validMimetypes.has(file.type as MIMETypes));

        if (invalidFiles.length > 0) {
            showSnackbar('Arquivo(s) não suportado(s). Por favor, envie arquivos dos tipos: ' + mimeList, { severity: 'error' });
            return;
        }

        const oversizedFiles = acceptedFiles.filter(file => file.size > tamanhoMaximo);

        if (oversizedFiles.length > 0) {
            showSnackbar(`Arquivo(s) excede(m) o tamanho máximo permitido de ${(tamanhoMaximo / 1024 / 1024).toFixed(2)} MB.`, { severity: 'error' });
            return;
        }

        handleDrop(acceptedFiles);
    }, [mimetypes, mimeList, showSnackbar]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


    return (
        <DropzoneContainer {...getRootProps()}>
            <UploadBox>
                <Upload fontSize={"large"} />
            </UploadBox>
            <input {...getInputProps()} />
            <Typography sx={{ mt: 2 }}>
                <b>Clique ou solte aqui</b>
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Arquivos suportados: {mimeList}
            </Typography>
            {descricao && (
                <Typography sx={{ mt: 1, fontSize: 12 }}>
                    {descricao}
                </Typography>
            )}
        </DropzoneContainer>
    );
}