import { AxiosInstance } from "axios";
import useSWR from "swr";
import { BareFetcher, PublicConfiguration } from "swr/_internal";

export function useFetch<Data = any>(api: AxiosInstance, url: string, config?: Partial<PublicConfiguration<Data, any, BareFetcher<Data>>>) {
    const { data, error, mutate, isLoading } = useSWR<Data>(url, async url => {
        const { data } = await api.get(url);
        return data;
    }, { ...config, revalidateOnFocus: true });
    
    const isFetching = !data;

    const handleRefresh = () => {
        mutate(undefined, true);
    };

    return { data, error, mutate, isLoading, isFetching, handleRefresh }
}