import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SelecionarProdutosBox = styled(Box)`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media(max-width: 768px) {
        flex-direction: column;
    }
`

export const PagadoresContainer = styled(Box)`
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media(max-width: 768px) {
        width: 100%;
    }
`

export const ProdutosContainer = styled(Box)`
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media(max-width: 768px) {
        width: 100%;
    }
`

export const SelecionarProdutosTools = styled(Box)`
    display: flex;
    width: 520px;

    @media(max-width: 768px) {
        width: 250px;
        height: 150px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
`