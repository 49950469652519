import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { ConsultaContainer } from "../ConsultarIndustrializadoContainer/styles";
import { ButtonsContainer, Formulario } from "./styles";

interface PrecificacaoIndustrializadoContainerProps {

}

export const PrecificacaoIndustrializadoContainer = ({ }: PrecificacaoIndustrializadoContainerProps) => {
    const { preco, setPreco, handleVoltar, resetIndustrializado, produto, formErrors, handleProximo } = useCadastrarEditarProdutoContext();

    const voltar = () => {
        resetIndustrializado();
        handleVoltar();
    }

    return (
        <ConsultaContainer>
            <Formulario>
                <Typography
                    variant="h6"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                >
                    Precificação
                </Typography>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                    <InputLabel error={!!formErrors?.preco} htmlFor="preco-industrializado">Preço</InputLabel>
                    <OutlinedInput
                        id="preco-industrializado"
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        label="Preço"
                        value={preco}
                        onChange={(e) => setPreco(e.target.value)}
                        error={!!formErrors?.preco}
                    />
                    {!!formErrors?.preco && (
                        <FormHelperText error={true}>{formErrors.preco}</FormHelperText>
                    )}
                </FormControl>
                <ButtonsContainer>
                    <Button variant="outlined" onClick={voltar} disabled={produto ? true : false}>
                        Voltar
                    </Button>
                    <Button variant="contained" onClick={handleProximo}>
                        Próximo
                    </Button>
                </ButtonsContainer>
            </Formulario>
        </ConsultaContainer>
    );
}