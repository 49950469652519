import { ReactNode, useEffect, useMemo, useState } from "react";
import { IPagamentoPedido } from "../../../../../../interface/IPagamentoPedido";
import { PagadoresContainer, SelecionarValoresBox, ValoresContainer } from "./styles";
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { useTamanhoTela } from "../../../../../../../core/hooks/useTamanhoTela";
import { useOperacaoContext } from "../../../../../../contexts/OperacaoContext";
import { IPagadorValor } from "../../../../../../interface/IPagadorValor";
import { useMoeda } from "../../../../../../../core/hooks/useMoeda";
import { useMascara } from "../../../../../../../core/hooks/useMascara";
import { MetodoPagamentoSelect } from "../../../../../../components/MetodoPagamentoSelect";
import { CardPagadorValor } from "../CardPagadorValor";
import { Add } from "@mui/icons-material";
import { MetodoPagamento } from "../../../../../../../core/enum/MetodoPagamento";
import { useSnackbar } from "../../../../../../../core/contexts/SnackbarContext";
import { v4 } from "uuid";
import { validarMoedaReal } from "../../../../../../../core/utils/validarMoedaReal";

interface SelecionarValoresContainerProps {
    fecharPedido: (pagamentos: IPagamentoPedido[]) => void;
    setTools: (conteudo: ReactNode) => void;
}

export const SelecionarValoresContainer = ({ setTools, fecharPedido }: SelecionarValoresContainerProps) => {
    const { modeloTela } = useTamanhoTela();
    const { valorTotalPedido } = useOperacaoContext();
    const [valor, setValor] = useState<string>('');
    const [pagadores, setPagadores] = useState<IPagadorValor[]>([]);
    const [pagamento, setPagamento] = useState<undefined | IPagamentoPedido>(undefined);
    const { real, number } = useMoeda();
    const snackbar = useSnackbar();

    const handleValorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValor(e.target.value);
    }

    const handlePagamento = (pagamento: IPagamentoPedido | undefined) => {
        setPagamento(pagamento);
    }

    const valorPagadores = useMemo(() => {
        let total = 0;
        pagadores.map(pagador => {
            total += pagador.pagamento.valor ?? 0;
        })
        return total;
    }, [pagadores])

    const valorPendente = useMemo(() => {
        return valorTotalPedido - valorPagadores;
    }, [valorTotalPedido, valorPagadores])

    const criarPagador = () => {
        let erroTroco = false;

        if (pagamento?.metodoPagamento == MetodoPagamento.Dinheiro) {
            if (pagamento?.valorTroco && pagamento?.valorTroco < 0) {
                erroTroco = true;
            }
        }

        if (erroTroco) {
            return snackbar("Troco inválido", { severity: "error" });
        }

        const validacaoMoeda = validarMoedaReal(valor?.trim(), 0.01, undefined, true);
        if(validacaoMoeda) {
            return snackbar(validacaoMoeda, { severity: "error" });
        }

        setPagadores([...pagadores, {
            nome: `Pagador ${pagadores.length + 1}`,
            uuid: v4(),
            pagamento: {
                ...pagamento,
                valor: number(valor)
            } ?? { valor: number(valor) }
        }]);
        setPagamento(undefined);
        setValor('');
    }

    const excluirPagador = (uuid: string) => {
        const novosPagadores = pagadores.filter(pagador => pagador.uuid !== uuid);
        setPagadores(novosPagadores);
    }

    const handleFecharPedido = () => {
        const pagamentos = pagadores.map(pagador => pagador.pagamento);
        fecharPedido(pagamentos);
    }

    useEffect(() => {
        setTools(
            <Button
                sx={{ width: "200px", height: "40px" }}
                variant="contained"
                onClick={handleFecharPedido}
                disabled={valorPendente != 0}
            >
                CONCLUIR
            </Button>
        );
    }, [valorPendente, valorPagadores, pagadores])

    return (
        <SelecionarValoresBox >
            <ValoresContainer>
                <Box>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                        {real(valorPendente)}
                    </Typography>
                    <Typography variant="subtitle2">
                        Pendentes
                    </Typography>
                </Box>
                <FormControl sx={{ ml: 2, mr: 2 }}>
                    <InputLabel htmlFor="valor">Preço</InputLabel>
                    <OutlinedInput
                        id="valor"
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        label="Preço"
                        value={valor}
                        onChange={handleValorChange}
                    />
                </FormControl>
                <Box sx={{ width: "250px", mr: 2 }}>
                    <MetodoPagamentoSelect
                        valorTotal={number(valor)}
                        onChange={handlePagamento}
                        pagamento={pagamento}
                        disabled={valorPendente == 0}
                    />
                </Box>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={criarPagador}
                >
                    PAGADOR
                </Button>
            </ValoresContainer>
            <Divider orientation={modeloTela == "desktop" ? "vertical" : "horizontal"} flexItem />
            <PagadoresContainer>
                <Box>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                        {real(valorPagadores)}
                    </Typography>
                    <Typography variant="subtitle2">Pagadores</Typography>
                </Box>
                <Grid
                    container
                    spacing={1}
                    sx={{ marginTop: "1rem" }}
                >
                    {pagadores.map((pagador, key) => {
                        return (
                            <Grid item md={6} xs={12} key={key}>
                                <CardPagadorValor
                                    pagador={pagador}
                                    valorTotal={pagador.pagamento.valor ?? 0}
                                    excluirPagador={excluirPagador}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </PagadoresContainer>
        </SelecionarValoresBox>
    );
}