import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const PaperContainer = styled(Paper)`
    padding: 1rem;
    min-width: 40%;
    min-height: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media(max-width: 1080px) {
        min-width: 70%;
    }

    @media(max-width: 768px) {
        min-width: 90%;
    }
`