import styled from "@emotion/styled";
import { Box, Paper, TableContainer as TableContainerMaterialUi } from "@mui/material";

export const PageContainer = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
` 

export const HeaderContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
`

export const FiltrosContainer = styled(Paper)`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 0;

    .filtros-clientes {
        display: flex;
        align-items: center;

        @media(max-width: 768px) {
            flex-direction: column;
        }
    }

    .input-cliente {
        margin-right: 20px;
        width: 200px;

        @media(max-width: 768px) {
            margin-right: 0;
            margin-top: 0.5rem;
        }
    }
`

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    margin-left: 1rem;

    button:first-child {
        margin-right: 0.5rem;
    }

    @media(max-width: 600px) {
        margin-top: 1rem;
        width: 100%;
        margin-left: 0;
    }
`

export const TableContainer = styled(TableContainerMaterialUi)`
    width: 100%;
    min-height: 400px;
`

export const NenhumCliente = styled(Box)`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Loading = styled(Box)`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`