import { useMemo, useState } from "react";
import { IColunaBoardPedidos } from "../../interface/IColunaBoardPedidos";
import { BoardContainer } from "./styles";
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { ColunaStatusPedido } from "./components/ColunaStatusPedido";
import { IPedido } from "../../interface/IPedido";
import { StatusPedido } from "../../enum/StatusPedido";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { useDataHora } from "../../../core/hooks/useDataHora";
import { createPortal } from "react-dom";
import { CardPedido } from "./components/CardPedido";
import { IPagamentoPedido } from "../../interface/IPagamentoPedido";
import { Box, Drawer } from "@mui/material";
import { PedidoDetalhado } from "../PedidoDetalhado";
import { useThemeContext } from "../../../core/contexts/ThemeContext";

interface PedidosBoardProps {
    atualizarStatus: (pedidoUuid: string, status: string | StatusPedido, pagamentos?: IPagamentoPedido[] | undefined) => Promise<any>

    pedidos: IPedido[];
    setPedidos: any;

    colunasStatus: IColunaBoardPedidos[];
    setColunasStatus: any;
}

export const PedidosBoard = ({ atualizarStatus, colunasStatus, pedidos, setColunasStatus, setPedidos }: PedidosBoardProps) => {
    const { dataEua } = useDataHora();
    const { theme } = useThemeContext();

    const [uuidPedidoDrawer, setUuidPedidoDrawer] = useState<string | undefined>(undefined);
    const handleOpenDrawer = (uuid: string) =>  setUuidPedidoDrawer(uuid);
    const handleCloseDrawer = () =>  setUuidPedidoDrawer(undefined);

    const [colunaAtiva, setColunaAtiva] = useState<IColunaBoardPedidos | null>(null);
    const [pedidoAtivo, setPedidoAtivo] = useState<IPedido | null>(null);
    const idsColunas = useMemo(() => colunasStatus.map(coluna => coluna.id), [colunasStatus]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    )

    const onDragStart = (event: DragStartEvent) => {
        if (event.active.data.current?.type == "Coluna") {
            setColunaAtiva(event.active.data.current?.coluna);
            return;
        }

        if (event.active.data.current?.type == "Pedido") {
            setPedidoAtivo(event.active.data.current?.pedido);
            return;
        }
    }

    const onDragEnd = async (event: DragEndEvent) => {
        setColunaAtiva(null);
        setPedidoAtivo(null);

        const { active, over } = event;

        if (!over) return;

        if (active.data.current?.type == "Coluna") {
            const idColunaAtiva = active.id;
            const idColunaDestino = over.id;

            if (idColunaAtiva == idColunaDestino) return;

            setColunasStatus((colunas: IColunaBoardPedidos[]) => {
                const colunaAtivaIndex = colunas.findIndex(col => col.id == idColunaAtiva);
                const colunaDestinoIndex = colunas.findIndex(col => col.id == idColunaDestino);

                return arrayMove(colunas, colunaAtivaIndex, colunaDestinoIndex);
            })
        }

        if (over.data.current?.type == "Pedido") {
            const pedidoUuid = over.data.current?.pedido.uuid;
            const pedidoStatus = over.data.current?.pedido.status;

            if (pedidoUuid && (pedidoStatus != undefined)) {
                await atualizarStatus(pedidoUuid, StatusPedido[pedidoStatus]);
            }
        }
    }

    const onDragOver = async (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over) return;

        if (active.data.current?.type == "Pedido") {
            const uuidPedidoAtivo = active.id;
            const uuidPedidoDestino = over.id;

            if (!uuidPedidoAtivo) {
                return;
            }

            if (uuidPedidoAtivo && uuidPedidoDestino) {
                setPedidos((colunas: IColunaBoardPedidos[]) => {
                    const indexPedidoAtivo = pedidos?.findIndex(pedido => pedido.uuid == uuidPedidoAtivo);
                    const indexPedidoDestino = pedidos?.findIndex(pedido => pedido.uuid == uuidPedidoDestino);

                    pedidos[indexPedidoAtivo].status = pedidos[indexPedidoDestino]?.status;

                    return arrayMove(colunas, indexPedidoAtivo, indexPedidoDestino);
                })
            }


            if (pedidoAtivo && over.data.current?.type === "Coluna") {
                setPedidos((pedidos: IPedido[]) => {
                    const pedidoAtivoIndex = pedidos.findIndex(p => p.uuid === uuidPedidoAtivo);
                    pedidos[pedidoAtivoIndex].status = over.id as StatusPedido;
                    return arrayMove(pedidos, pedidoAtivoIndex, pedidoAtivoIndex);
                });
            }
        }
    }

    return (
        <BoardContainer>
            <DndContext
                sensors={sensors}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
            >
                <SortableContext items={idsColunas}>
                    {colunasStatus.map(coluna => {
                        return <ColunaStatusPedido
                            onClickPedido={handleOpenDrawer}
                            key={coluna.id}
                            coluna={coluna}
                            pedidos={pedidos?.filter(pedido => pedido.status == coluna.id) ?? []}
                        />
                    })}
                </SortableContext>
                {createPortal(
                    <DragOverlay>
                        {colunaAtiva && (
                            <ColunaStatusPedido
                                onClickPedido={handleOpenDrawer}
                                coluna={colunaAtiva}
                                pedidos={pedidos?.filter(pedido => pedido.status == colunaAtiva.id) ?? []}
                            />
                        )}
                        {pedidoAtivo && (
                            <CardPedido pedido={pedidoAtivo} />
                        )}
                    </DragOverlay>,
                    document.body
                )}
            </DndContext>
            {uuidPedidoDrawer && (
                <Drawer
                    anchor="right"
                    open={true}
                    onClose={handleCloseDrawer}
                    sx={{ zIndex: 9999 }}
                >
                    <Box sx={{ width: '50vw', padding: '1rem', backgroundColor: theme.palette.background.default }}>
                        <PedidoDetalhado uuid={uuidPedidoDrawer} />
                    </Box>
                </Drawer>
            )}
        </BoardContainer>
    );
}