import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useMoeda } from "../../../../../../../core/hooks/useMoeda";
import { PagadoresPaper, QuantidadePessoasBox, QuantidadePessoasPaper } from "./styles";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "../../../../../../../core/contexts/SnackbarContext";
import { useOperacaoContext } from "../../../../../../contexts/OperacaoContext";
import { CardPagadorValor } from "../CardPagadorValor";
import { v4 } from "uuid";
import { IPagadorValor } from "../../../../../../interface/IPagadorValor";
import { IPagamentoPedido } from "../../../../../../interface/IPagamentoPedido";

interface QuantidadePessoasContainerProps {
    fecharPedido: (pagamentos: IPagamentoPedido[]) => void;
    setTools: (conteudo: ReactNode) => void;
}

function dividirPedido(valorTotal: number, quantidadeDePartes: number) {
    var valorPorParte = valorTotal / quantidadeDePartes;
    return valorPorParte.toFixed(2);
}

export const QuantidadePessoasContainer = ({ fecharPedido, setTools }: QuantidadePessoasContainerProps) => {
    const { real } = useMoeda();
    const { valorTotalPedido } = useOperacaoContext();
    const snackbar = useSnackbar();
    const [qtdPessoas, setQtdPessoas] = useState<string>("2");
    const [pagadores, setPagadores] = useState<IPagadorValor[]>([]);

    const valorPedidoDivididoQtdPessoas = useMemo(() => {
        return dividirPedido(valorTotalPedido, parseInt(qtdPessoas));
    }, [qtdPessoas, valorTotalPedido]);

    const handleFecharPedido = () => {
        if (!qtdPessoas || !qtdPessoas.length) {
            snackbar("Quantidade inválida", { severity: "error" });
            return false;
        }

        if (parseInt(qtdPessoas) <= 0) {
            snackbar("A quantidade deve ser maior ou igual a 1", { severity: "error" });
            return false;
        }

        if (!Number.isInteger(parseInt(qtdPessoas))) {
            snackbar("A quantidade deve ser um número inteiro", { severity: "error" });
            return false;
        }

        if (!parseFloat(valorPedidoDivididoQtdPessoas) || parseFloat(valorPedidoDivididoQtdPessoas) <= 0) {
            snackbar("Valor final inválido", { severity: "error" });
            return false;
        }

        const pagamentos = pagadores.map(pagador => pagador.pagamento);
        fecharPedido(pagamentos);
    };

    useEffect(() => {
        setTools(
            <Button
                sx={{ width: "200px", height: "40px" }}
                variant="contained"
                onClick={handleFecharPedido}
            >
                CONCLUIR
            </Button>
        );
    }, [setTools, handleFecharPedido]);

    useEffect(() => {
        const valorPorPessoa = parseFloat(valorPedidoDivididoQtdPessoas);
        const novoArrayPagadores = Array.from({ length: parseInt(qtdPessoas) }, (_, index) => ({
            nome: `Pagador ${index + 1}`,
            uuid: v4(),
            pagamento: {
                valor: valorPorPessoa
            } as IPagamentoPedido,
        }));
        setPagadores(novoArrayPagadores);
    }, [qtdPessoas, valorPedidoDivididoQtdPessoas]);

    const handleAlterarPagamento = (index: number, pagamentoRecebido?: IPagamentoPedido) => {
        const valorPorPessoa = parseFloat(valorPedidoDivididoQtdPessoas);
        setPagadores((prevPagadores) => {
            const novosPagadores = [...prevPagadores];
            novosPagadores[index] = {
                ...novosPagadores[index],
                pagamento: { 
                    ...pagamentoRecebido,
                    valor: valorPorPessoa 
                },
            };
            return novosPagadores;
        });
    };

    return (
        <QuantidadePessoasBox>
            <QuantidadePessoasPaper variant="outlined">
                <Typography variant="h6">Quantidade de pessoas</Typography>
                <TextField
                    sx={{ mt: 3 }}
                    fullWidth
                    type="number"
                    value={qtdPessoas}
                    onChange={(e) => setQtdPessoas(e.target.value)}
                    label="Qtd. de pessoas"
                />
                <Box sx={{ mt: 4, width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Typography variant="h4" fontWeight={"bold"}>
                        {real(valorTotalPedido)}
                    </Typography>
                    <Typography variant="subtitle2">Total</Typography>
                </Box>
                <Box sx={{ mt: 4, width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Typography variant="h5" fontWeight={"bold"}>
                        {parseInt(qtdPessoas) === 0 ? "Valor inválido" : real(valorPedidoDivididoQtdPessoas)}
                    </Typography>
                    <Typography variant="subtitle2">por pessoa</Typography>
                </Box>
            </QuantidadePessoasPaper>
            <PagadoresPaper variant="outlined">
                <Typography variant="h6">Pagadores</Typography>
                {pagadores.map((pagador, key) => (
                    <CardPagadorValor
                        pagador={pagador}
                        key={key}
                        valorTotal={parseFloat(valorPedidoDivididoQtdPessoas)}
                        alterarPagamento={(pagamento) => handleAlterarPagamento(key, pagamento)}
                    />
                ))}
            </PagadoresPaper>
        </QuantidadePessoasBox>
    );
};
