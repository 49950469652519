import { Breadcrumbs, Grid, Typography, Link, Paper, Box, Chip, Tooltip, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IMarketplace } from "../../interfaces/IMarketplace";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { DadoContainer, PageContainer } from "./styles";
import { TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../enum/TipoMarketplace";
import { StatusMarketplacePaper } from "./container/StatusMarketplacePaper";
import { Edit } from "@mui/icons-material";
import { HorariosMarketplacePaper } from "./container/HorariosMarketplacePaper";
import { PersonalizacaoMarketplacePaper } from "./container/PersonalizacaoPaper";
import { EnderecoMarketplacePaper } from "./container/EnderecoMarketplacePaper";
import { QRCodeMarketplacePaper } from "./container/QRCodeMarketplacePaper";
import { MetodosPagamentoMarketplacePaper } from "./container/MetodosPagamentoMarketplacePaper";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { RegioesEntregaMarketplacePaper } from "./container/RegioesEntregaMarketplacePaper";
import { VincularMarketplace } from "../../components/ifood/VincularMerchantIfood";
import { useMarketplaces } from "../../hooks/useMarketplaces";
import { useFetchMarketplacesVinculo } from "../../hooks/useFetchMarketplacesVinculo";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";

export const DetalhesMarketplace = () => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const {
        loading: loadingVincular,
        vincular,
        listarEstabelecimentos,
        salvar,
        listarCatalogos,
    } = useMarketplaces();
    const { marketplaces, loading: loadingFetch, handleRefresh } = useFetchMarketplacesVinculo();
    const [marketplace, setMarketplace] = useState<IMarketplace | undefined>(undefined);
    const { uuid } = useParams();
    const [dadosVinculo, setDadosVinculo] = useState<{
        accessToken: string | undefined;
        merchantId: string | undefined;
        refreshToken: string | undefined;
    }>({
        accessToken: undefined,
        merchantId: undefined,
        refreshToken: undefined, 
    });
    const [estabelecimentos, setEstabelecimentos] = useState<{
        id: string;
        nome: string;
    }[]>([]);
    const [catalogos, setCatalogos] = useState<{
        id: string;
        contexto: string;
    }[]>([]);
    const [authorizationCodeVerifier, setAuthorizationCodeVerifier] = useState<string>("");

    useEffect(() => {
        if (uuid && loadingFetch == false) {
            const marketplaceEncontrado = marketplaces?.find(marketplaceProcurado => marketplaceProcurado.uuid === uuid);
            if (!marketplaceEncontrado) {
                navigate("/marketplace");
                return snackbar("Marketplace não encontrado", { severity: "error" })
            }
            setMarketplace(marketplaceEncontrado);
            setDadosVinculo({
                accessToken: marketplaceEncontrado?.configuracao?.integracao?.accessToken,
                merchantId: marketplaceEncontrado?.configuracao?.integracao?.merchantId,
                refreshToken: marketplaceEncontrado?.configuracao?.integracao?.refreshToken,
            });

            if (!estabelecimentos.length && marketplaceEncontrado?.configuracao?.integracao?.accessToken && !marketplaceEncontrado?.configuracao?.integracao?.merchantId) {
                listarEstabelecimentosMarketplace();
            }

            if (!catalogos.length && marketplaceEncontrado?.configuracao?.integracao?.merchantId && !marketplaceEncontrado?.configuracao?.integracao?.catalogId) {
                listarCatalogosMarketplace();
            }
        }
    }, [uuid, marketplaces, loadingFetch])

    const { real } = useMoeda();

    const listarEstabelecimentosMarketplace = async () => {
        if (marketplace?.uuid) {
            const estabelecimentosMarketplace = await listarEstabelecimentos(marketplace.uuid);
            if (estabelecimentosMarketplace) {
                setEstabelecimentos(estabelecimentosMarketplace);
            }
        }
    };

    const listarCatalogosMarketplace = async () => {
        if (marketplace?.uuid) {
            const catalogosMarketplace = await listarCatalogos(marketplace.uuid);
            if (catalogosMarketplace) {
                setCatalogos(catalogosMarketplace);
            }
        }
    };

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Gestão de marketplace")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    const handleVincularMarketplace = async (authorizationCode?: string) => {
        if (!marketplace) return

        if (marketplace.configuracao?.integracao?.accessToken && marketplace.uuid) {
            await listarEstabelecimentosMarketplace();
        }

        const resposta = await vincular(
            marketplace,
            {
                authorizationCode,
                authorizationCodeVerifier,
            }
        );

        await handleRefresh();

        if (resposta?.authorizationCodeVerifier) {
            setAuthorizationCodeVerifier(resposta.authorizationCodeVerifier);
        }

        setDadosVinculo({
            accessToken: resposta?.accessToken || dadosVinculo?.accessToken,
            merchantId: dadosVinculo.merchantId,
            refreshToken: resposta?.refreshToken || dadosVinculo.refreshToken,
        });

        if (resposta?.verificationUrlComplete) {
            window.open(resposta.verificationUrlComplete);
        }
    };

    const handleSalvarMarketplace = async (novoMarketplace: IMarketplace) => {
        if (!marketplace) return;

        await salvar(novoMarketplace);

        await handleRefresh();

        return snackbar("Vinculo realizado.");
    };

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/marketplace">
                    Marketplaces
                </Link>
                {marketplace && (
                    <Typography color="text.primary">{marketplace.nome}</Typography>
                )}
            </Breadcrumbs>
            <Grid container spacing={2} sx={{ p: 2, mt: 2 }}>
                {!marketplace ? (
                    <div>Carregando...</div>
                ) : (
                    <>
                        <Grid item xs={12} md={6}>
                            <StatusMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <QRCodeMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Typography variant="h6">Dados</Typography>
                                    <Button onClick={() => navigate(`/marketplace/editar/${marketplace.uuid}`)} endIcon={<Edit />}>Editar</Button>
                                </Box>
                                <DadoContainer>
                                    <Typography>Nome público</Typography>
                                    <Typography>{marketplace.configuracao?.nomePublico}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Nome</Typography>
                                    <Typography>{marketplace.nome}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Telefone</Typography>
                                    <Typography>{marketplace.configuracao?.telefone ?? "-"}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Valor mínimo</Typography>
                                    <Typography>{real(marketplace.configuracao?.valorMinimo) ?? "-"}</Typography>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>Tipo</Typography>
                                    <Tooltip title={TipoMarketplaceDescricao[marketplace.tipo]}>
                                        <Chip
                                            label={TipoMarketplaceTitulo[marketplace.tipo]}
                                            style={{ background: TipoMarketplaceCor[marketplace.tipo], color: "white" }}
                                        />
                                    </Tooltip>
                                </DadoContainer>
                                <DadoContainer>
                                    <Typography>{marketplace.descricao}</Typography>
                                </DadoContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PersonalizacaoMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MetodosPagamentoMarketplacePaper refresh={handleRefresh} marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HorariosMarketplacePaper refresh={handleRefresh} marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EnderecoMarketplacePaper marketplace={marketplace} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <RegioesEntregaMarketplacePaper isLoading={loadingFetch} refresh={handleRefresh} marketplace={marketplace} />
                        </Grid>
                    </>
                )}
            </Grid>
        </PageContainer>
    )
}