import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const MesaPaperContainer = styled(Paper)`
    margin-top: 3rem;
    padding: 2.2rem;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;

    div:first-child {
        width: 60px;
        height: 60px;
    }

    div:last-child {
        width: 100%;
        margin-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`