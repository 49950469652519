import { yupResolver } from "@hookform/resolvers/yup";
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Autocomplete, Breadcrumbs, Button, Checkbox, CircularProgress, FormControl, FormHelperText, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useLetras } from "../../../core/hooks/useLetras";
import { TipoPedido } from "../../../pedidos/enum/TipoPedido";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper } from "./styles";
import { usuarioSchema } from "../../schemas/usuarioSchema";
import { useUsuarios } from "../../hooks/useUsuarios";
import { useMascara } from "../../../core/hooks/useMascara";
import { useFetchPerfis } from "../../../perfis/hooks/useFetchPerfis";
import { cadastrarUsuarioSchema } from "../../schemas/cadastrarUsuarioSchema";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { Plataforma } from "../../../core/enum/Plataforma";

export const CadastrarUsuario = () => {
    const navigate = useNavigate();

    const { estabelecimento, verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    const { perfis } = useFetchPerfis();
    const snackbar = useSnackbar();
    const { titulo } = useLetras();
    const { setValue, reset, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(cadastrarUsuarioSchema),
        mode: "onChange",
    });

    const { loading, cadastrarUsuario, liberarAcessos } = useUsuarios();
    const { mascaraCPF, mascaraTelefone } = useMascara();

    const handleSubmitForm = async (usuario: FieldValues) => {
        if(!estabelecimento) {
            return;
        }

        const resultadoCadastro = await cadastrarUsuario({ 
            email: usuario.email,
            nome: usuario.nome,
            telefone: usuario.telefone,
            cpf: usuario.cpf
        });

        if(!resultadoCadastro) {
            return;
        }

        const resultadoAcesso = await liberarAcessos(usuario.email, [{
            estabelecimentoUuid: estabelecimento.uuid,
            perfilUuid: usuario.perfil,
            plataforma: Plataforma.ERP
        }])

        if(resultadoAcesso) {
            snackbar("Usuário cadastrado com sucesso", { severity: "success" })
            reset();
        }
    }

    const perfisOptions = perfis?.map((perfil) => {
        return (
            <MenuItem key={perfil.uuid} value={perfil.uuid}>
                {perfil.nome}
            </MenuItem>
        );
    });

    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Cadastro de usuários")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <PageContainer>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" href="/usuarios/">
                        Base de usuários
                    </Link>
                    <Typography color="text.primary">Cadastrar usuário</Typography>
                </Breadcrumbs>
                <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                    Cadastrar usuário
                </Typography>
                <ContentContainer>
                    <FormContainer>
                        <Accordion
                            expanded={true}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary>
                                <Typography variant="h6">Dados do usuário</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Nome*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("nome")}
                                    error={!!errors.nome}
                                    helperText={<>{errors.nome?.message}</>}
                                />
                                <TextField
                                    label="E-mail*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("email")}
                                    error={!!errors.email}
                                    helperText={<>{errors.email?.message}</>}
                                />
                                <TextField
                                    label="Telefone*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("telefone")}
                                    value={mascaraTelefone(watch("telefone"))}
                                    error={!!errors.telefone}
                                    helperText={<>{errors.telefone?.message}</>}
                                />
                                <TextField
                                    label="CPF"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("cpf")}
                                    error={!!errors.cpf}
                                    value={mascaraCPF(watch("cpf"))}
                                    helperText={<>{errors.cpf?.message}</>}
                                />
                                <FormControl sx={{ width: "100%", margin: "0.7rem 0" }}>
                                    <InputLabel
                                        id="perfil-label"
                                        error={!!errors.perfil}
                                    >
                                        Perfil*
                                    </InputLabel>
                                    <Select
                                        labelId="perfil-label"
                                        id="perfil"
                                        placeholder="Selecione um perfil"
                                        {...register("perfil")}
                                        value={watch("perfil") || ''}
                                        error={!!errors.perfil}
                                        label="Perfil"
                                        defaultValue={undefined}
                                    >
                                        {perfisOptions}
                                    </Select>
                                    {errors.perfil && <FormHelperText error><>{errors.perfil.message}</></FormHelperText>}
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </FormContainer>
                    <ResumoPaper>
                        <Typography variant="h6">Resumo</Typography>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Nome</b>: {watch("nome")?.length ? watch("nome") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>E-mail</b>: {watch("email")?.length ? watch("email") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Telefone</b>: {watch("telefone")?.length ? watch("telefone") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>CPF</b>: {watch("cpf")?.length ? watch("cpf") : "-"}
                            </Typography>
                        </Paper>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Perfil</b>: {watch("perfil")?.length ? perfis?.find(perfil => perfil.uuid == watch("perfil"))?.nome : "-"}
                            </Typography>
                            <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                                <Typography
                                    variant="subtitle1"
                                >
                                    <b>Módulos</b>
                                </Typography>
                                {watch("perfil")?.length ? perfis?.find(perfil => perfil.uuid == watch("perfil"))?.modulos?.map((modulo, index) => {
                                    return (
                                        <Typography>
                                            {index + 1}. {modulo.nome}
                                        </Typography>
                                    )
                                }) : ""}
                            </Paper>
                        </Paper>
                        <ButtonsContainer>
                            <Button size="large" variant="outlined" onClick={() => navigate("/usuarios/")}>
                                Cancelar
                            </Button>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button size="large" variant="contained" type="submit">
                                    Cadastrar
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
        </PageContainer>
    );
}