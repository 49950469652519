import { useState } from "react";
import { ICategoria } from "../interfaces/ICategoria";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";

export const useCategorias = () => {
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const apiProdutos = ApiFactory.getApi("MS_PRODUTOS");

    const salvar = async (categoria: ICategoria) => {
        if(!categoria) {
            snackbar("Categoria inválida", { severity: "error" })
            return null;
        }

        setLoading(true)

        try {
            const { data } = await apiProdutos.post("/categorias", categoria);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const buscar = async (uuid: string) => {
        if(!uuid) {
            snackbar("Categoria inválida", { severity: "error" })
            return null;
        }

        setLoading(true)

        try {
            const { data } = await apiProdutos.get(`/categorias/${uuid}`);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluir = async (categoriaUuid: string) => {
        if(!categoriaUuid) {
            snackbar("Categoria inválida", { severity: "error" })
            return false;
        }

        setLoading(true)
        
        try {
            const { data } = await apiProdutos.delete(`/categorias/${categoriaUuid}`);
            
            if(data) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
    }

    return { salvar, excluir, loading, buscar }
}