import { BoxIcon } from "../BoxIcon";
import { CardContainer, TextContainer } from "./styles";
import { useThemeContext } from '../../contexts/ThemeContext';

interface CardProps {
    titulo: React.ReactNode;
    subtitulo: React.ReactNode;
    icone: React.ReactNode
}

export const CardIcon = ({ subtitulo, titulo, icone }: CardProps) => {
    return (
        <CardContainer>
           <BoxIcon>
                {icone}
           </BoxIcon>         
           <TextContainer>
                {titulo}
                {subtitulo}
           </TextContainer>
        </CardContainer>
    )
}