import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch"
import { ICategoria } from "../interfaces/ICategoria";

export const useFetchCategorias = () => {
    const apiProdutos = ApiFactory.getApi("MS_PRODUTOS")
    const { data: categorias, error, isLoading, handleRefresh, isFetching } = useFetch<ICategoria[]>(apiProdutos, '/categorias');

    return { 
        categorias, 
        error, 
        isLoading, 
        handleRefresh, 
        isFetching 
    }
}