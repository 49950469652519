import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { usuarioSchema } from '../../../../schemas/usuarioSchema';
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useUsuarios } from '../../../../hooks/useUsuarios';
import CircularProgress from '@mui/material/CircularProgress';
import { FieldValues } from "react-hook-form/dist/types";
import { useMascara } from '../../../../../core/hooks/useMascara';

interface Props {
    nome: string;
    email: string;
    telefone: string;
    alterarFormulario: (formulario: 'alterar_dados' | 'alterar_senha' | undefined) => void;
  }
  
  
export const AlterarDados: React.FC<Props> = ({alterarFormulario, email, nome, telefone}: Props) => {
    const { atualizarUsuario } = useUsuarios();
    const [loading, setLoading] = useState(false);
    const { setValue, watch, register, handleSubmit, formState: { errors  }} = useForm({
        resolver: yupResolver(usuarioSchema),
        mode: "onChange",
    });
    const { mascaraTelefone } = useMascara();

    const telefoneForm = watch("telefone");

    useEffect(() => {
        setValue('email', email);
        setValue('nome', nome);
    }, [])

    useEffect(() => {
        setValue('telefone', mascaraTelefone(telefoneForm ?? telefone));
    }, [telefoneForm])

    const handleAtualizarUsuario = async ({nome, telefone, email}: FieldValues) => {
        setLoading(true);
        await atualizarUsuario({nome, telefone, email}, true)
        setLoading(false);
        alterarFormulario(undefined)
    }

    return (
        <Card>
            <CardContent>
                <Stack direction="row" spacing={2} sx={{mb: 4}}>
                    <Typography variant="h5" fontWeight={700} component="h2" gutterBottom>
                        Alterar dados
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleAtualizarUsuario)}>
                    <Stack direction="column" justifyContent="center" spacing={2} sx={{mt: 4}}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Endereço de e-mail"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("email")}
                            error={!!errors.email}
                            helperText={<>{errors.email?.message}</>}
                            disabled={true}
                        />
                        <TextField
                            sx={{mt: 3}}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nome"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("nome")}
                            error={!!errors.nome}
                            helperText={<>{errors.nome?.message}</>}
                        />
                        <TextField
                            sx={{mt: 3}}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Telefone"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("telefone")}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={2} sx={{mt: 4}}>
                        <Button
                            variant={"outlined"} 
                            color="primary"
                            size="large"
                            onClick={() => alterarFormulario(undefined)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant={"contained"} 
                            color="primary"
                            size="large"
                            type="submit"
                        >
                            {loading == false ? (
                                'Alterar' 
                            ) : (
                                <CircularProgress color="inherit" />
                            )}
                        </Button>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    );
}