import { createContext, useState, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';

interface SnackbarContextProps {
  showSnackbar: (message: string, options?: AlertProps) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  showSnackbar: () => {},
});

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [options, setOptions] = useState<AlertProps>({});

  const handleClose = () => {
    setIsOpen(false);
  };

  const showSnackbar = (msg: string, opts: AlertProps = {}) => {
    setMessage(msg);
    setOptions(opts);
    setIsOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={isOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} {...options}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  return showSnackbar;
};