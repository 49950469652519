import { Paper } from "@mui/material"
import { CardContainer } from "./styles"
import { useThemeContext } from "../../contexts/ThemeContext"

interface BoxIconProps {
    children: React.ReactNode
}

export const BoxIcon = ({ children }: BoxIconProps) => {
  return (
    <CardContainer elevation={0}>
      {children}
    </CardContainer>
  )
}
