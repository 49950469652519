import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { usePedidos } from "../../hooks/usePedidos";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useEffect, useMemo, useState } from "react";
import { IPedido } from "../../interface/IPedido";
import { Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Link, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ClienteContainer, Container, EnderecoEntregaContainer, HeaderContainer, OperadorContainer, ProductBox, ValorContainer } from "./styles";
import { useDataHora } from "../../../core/hooks/useDataHora";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { TipoPedido } from "../../enum/TipoPedido";
import { MetodoPagamento } from "../../../core/enum/MetodoPagamento";
import HistoricoPedidoTimeline from "../../components/HistoricoPedidoTimeline";
import { TipoAnexoDescricao, TipoAnexoPedido } from "../../enum/TipoAnexoPedido";
import { Download, Place, OpenInNew, WrongLocation, Print } from '@mui/icons-material';
import { useDownload } from "../../../core/hooks/useDownload";
import { useConfiguracoesPedidoContext } from "../../contexts/ConfiguracoesPedidoContext";
import { RadioCard } from "../../../core/components/RadioCard";
import { IDadosEmitirNotaFiscal } from "../../interface/IDadosEmitirNotaFiscal";
import { EmitirNotaFiscalModal } from "../../pages/Operacao/containers/EmitirNotaFiscalModal";
import { useMarketplaces } from "../../../marketplace/hooks/useMarketplaces";
import { useFetchMarketplaces } from "../../../marketplace/hooks/useFetchMarketplaces";
import { IMarketplace } from "../../../marketplace/interfaces/IMarketplace";
import { TipoMarketplace, TipoMarketplaceCor } from "../../../marketplace/enum/TipoMarketplace";

interface PedidoDetalhadoProps {
    uuid: string;
}

export const PedidoDetalhado = ({ uuid }: PedidoDetalhadoProps) => {
    const snackbar = useSnackbar();
    const { buscarPedido, gerarAnexo, emitirNotaFiscal, loading } = usePedidos();

    const [pedido, setPedido] = useState<undefined | IPedido>(undefined);
    const [imprimirOpen, setImprimirOpen] = useState<boolean>(false);

    const [configuracaoComanda, setConfiguracaoComanda] = useState<string | undefined>(undefined);
    const abrirModalImpressao = () => setImprimirOpen(true);
    const fecharModalImpressao = () => {
        setImprimirOpen(false);
        setConfiguracaoComanda(undefined);
    };

    const [notaFiscalOpen, setNotaFiscalOpen] = useState<boolean>(false);
    const abrirModalNotaFiscal = () => {
        setNotaFiscalOpen(true);
    }
    const fecharModalNotaFiscal = () => {
        setNotaFiscalOpen(false);
        fecharModalImpressao();
    };


    const { configuracao } = useConfiguracoesPedidoContext();

    const fluxoContexto = useMemo(() => {
        if (pedido && configuracao) {
            return configuracao
                .fluxoOperacao
                ?.fluxosContextos
                ?.find(fluxo => fluxo.tipo == pedido.tipo)
        }
    }, [pedido, configuracao])


    const handleBuscarPedido = async () => {
        if (!uuid) {
            snackbar("Pedido inválido", { severity: 'error' });
            return window.location.href = "/dashboard";
        }

        const pedidoEncontrado = await buscarPedido(uuid);
        if (!pedidoEncontrado) {
            return window.location.href = "/dashboard";
        }

        setPedido(pedidoEncontrado);
    }

    const { real } = useMoeda();
    const { dataHoraBr } = useDataHora();
    const { downloadPorUrl } = useDownload();

    useEffect(() => {
        handleBuscarPedido();
    }, [])

    const handleEmitirNotaFiscal = async (dados: IDadosEmitirNotaFiscal) => {
        if (loading) {
            return;
        }

        if (!pedido) {
            return;
        }

        await emitirNotaFiscal(pedido.uuid, dados);
        snackbar("Nota fiscal enviada para fila de impressão", { severity: "success" });
        fecharModalNotaFiscal();
        setTimeout(() => {
            handleBuscarPedido();
        }, 1000);
    }

    const handleImprimirGerarAnexo = async () => {
        if (loading) {
            return;
        }

        const configuracaoComandaEncontrada = fluxoContexto
            ?.configuracoesComandas
            ?.find(config => config.uuid == configuracaoComanda);

        if (!configuracaoComandaEncontrada || !pedido) {
            return snackbar("Nenhuma configuração de comanda selecionada", { severity: "error" });
        }

        if (configuracaoComandaEncontrada.tipo == TipoAnexoPedido.nota_fiscal) {
            return abrirModalNotaFiscal();
        }

        await gerarAnexo(pedido.uuid, configuracaoComandaEncontrada.tipo, configuracaoComandaEncontrada.uuid, pedido.produtos);
        snackbar("Comanda enviada para fila de impressão", { severity: "success" });
        fecharModalImpressao();
        setTimeout(() => {
            handleBuscarPedido();
        }, 1000);
    }

    const componentesEndereco = [
        `${pedido?.endereco?.rua} ${pedido?.endereco?.numero}`,
        pedido?.endereco?.bairro,
        pedido?.endereco?.complemento,
        pedido?.endereco?.cep,
        pedido?.endereco?.cidade,
        pedido?.endereco?.regiao?.descricao
    ];
    const componentesEnderecosValidos = componentesEndereco.filter(value => value != undefined && value.length > 0);
    const endereco = componentesEnderecosValidos.join(', ')
    const enderecoCodificado = encodeURIComponent(endereco);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${enderecoCodificado}`;
    const { marketplaces, loading: loadingMarketplace } = useFetchMarketplaces();
    const marketplace = useMemo(() => {
        let marketplace: undefined | IMarketplace;

        marketplace = marketplaces?.find(marketplaceProcurado => marketplaceProcurado.uuid === pedido?.marketplace?.uuid);

        return marketplace
    }, [pedido, marketplaces])

    return (
        <Container>
            <HeaderContainer>
                <Box>
                    <Typography variant="h5">Pedido <b>#{pedido?.numeroPedido}</b></Typography>
                    <Typography sx={{ fontSize: 13 }} color="GrayText" >Número diário <b>#{pedido?.numeroPedidoDiario}</b></Typography>
                    <Typography sx={{ fontSize: 13 }} color="GrayText" >Pedido emitido às {pedido?.dataHora ? dataHoraBr(pedido?.dataHora) : "-"}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5rem" }}>
                    <Chip label={pedido?.tipo.toUpperCase()} />
                    <Chip 
                        label={marketplace?.nome ?? TipoMarketplace.PDV} 
                        sx={{
                            color: 'white',
                            background: TipoMarketplaceCor[marketplace?.tipo ?? TipoMarketplace.PDV] 
                        }}
                    />
                </Box>
            </HeaderContainer>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Typography variant="h6">Valores</Typography>
                        </Box>
                        <ValorContainer>
                            <Typography>Total dos produtos:</Typography>
                            <Typography>{real(pedido?.valorProdutos)}</Typography>
                        </ValorContainer>
                        {pedido?.tipo == TipoPedido.delivery && (
                            <ValorContainer>
                                <Typography>Taxa de entrega:</Typography>
                                <Typography>{real(pedido?.valorFrete)}</Typography>
                            </ValorContainer>
                        )}
                        <ValorContainer>
                            <Typography>Total:</Typography>
                            <Typography>{real(pedido?.valorTotal)}</Typography>
                        </ValorContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ width: "100%", p: 2, height: "100%", minHeight: "180px", overflowY: "auto" }}>
                        <Typography variant="h6">Cliente</Typography>
                        {pedido?.tipo && ["delivery", "retirada", "simples"].includes(pedido?.tipo) && (
                            <>
                                <ClienteContainer>
                                    <Typography>Nome:</Typography>
                                    <Typography>{pedido?.cliente?.nome ?? "Não identificado"}</Typography>
                                </ClienteContainer>
                                <ClienteContainer>
                                    <Typography>Telefone:</Typography>
                                    <Typography>{pedido?.cliente?.telefone?.length ? pedido.cliente.telefone : "-"}</Typography>
                                </ClienteContainer>
                                <ClienteContainer>
                                    <Typography>CPF:</Typography>
                                    <Typography>{pedido?.cliente?.cpf?.length ? pedido.cliente.cpf : "-"}</Typography>
                                </ClienteContainer>
                                <ClienteContainer>
                                    <Typography>Observação:</Typography>
                                    <Typography>{pedido?.cliente?.observacao?.length ? pedido.cliente.observacao : "-"}</Typography>
                                </ClienteContainer>
                            </>
                        )}
                        {pedido?.tipo == TipoPedido.mesa && (
                            <ClienteContainer>
                                <Typography>Número da mesa:</Typography>
                                <Chip label={pedido.mesaId} />
                            </ClienteContainer>
                        )}
                    </Paper>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Paper sx={{ width: "100%", p: 2, height: "200px", overflowX: "auto" }}>
                        <Typography variant="h6">Linha do tempo</Typography>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {pedido?.historicoStatus && (
                                <HistoricoPedidoTimeline dataHoraEmissao={pedido?.dataHora} historico={pedido?.historicoStatus} />
                            )}
                        </Box>
                    </Paper>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {/* Operador e Endereço de Entrega */}
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={2}>
                            {/* Operador */}
                            <Grid item>
                                <Paper sx={{ p: 2, width: "100%" }}>
                                    <Typography variant="h6">Operador</Typography>
                                    <OperadorContainer>
                                        <Typography>Nome:</Typography>
                                        <Typography>{pedido?.operador?.nome}</Typography>
                                    </OperadorContainer>
                                    <OperadorContainer>
                                        <Typography>E-mail:</Typography>
                                        <Typography>{pedido?.operador?.email}</Typography>
                                    </OperadorContainer>
                                </Paper>
                            </Grid>
                            {/* Endereço de Entrega */}
                            <Grid item>
                                <Paper sx={{ p: 2, width: "100%" }}>
                                    <Typography variant="h6">Endereço de entrega</Typography>
                                    {pedido?.tipo == TipoPedido.delivery ? (
                                        <>
                                            <EnderecoEntregaContainer>
                                                <Typography>{endereco}</Typography>
                                            </EnderecoEntregaContainer>
                                            <EnderecoEntregaContainer>
                                                <Typography><Link target="_blank" href={googleMapsUrl}>Abrir no mapa</Link></Typography>
                                                <Place color="primary" />
                                            </EnderecoEntregaContainer>
                                        </>
                                    ) : (
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            pb: 2
                                        }}>
                                            <WrongLocation sx={{ fontSize: 35, mb: 2 }} />
                                            <Typography>Nenhum endereço de entrega utilizado</Typography>
                                        </Box>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Produtos */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 2, height: "100%" }}>
                            <Typography variant="h6">Produtos</Typography>
                            <Box sx={{ mt: 2 }}>
                                {pedido?.produtos?.map(produto => {
                                    let precoTotal = produto.preco ?? 0;

                                    produto.itens?.map(item => {
                                        precoTotal = precoTotal + ((item.preco ?? 0) * (item.quantidade ?? 1))
                                    })

                                    return (
                                        <ProductBox key={produto.uuid}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Typography variant="subtitle1">{produto.quantidade}X - {produto.descricao.toUpperCase()}</Typography>
                                                <Typography fontWeight={"bold"} variant="subtitle1">{real(precoTotal)}</Typography>
                                            </Box>
                                            {produto.itens?.length && (
                                                <Box sx={{ mt: 2 }}>
                                                    {produto.itens.map(item => (
                                                        <Typography
                                                            key={item.uuid}
                                                            variant="subtitle2"
                                                            fontWeight={200}
                                                            color={"GrayText"}
                                                        >
                                                            {item.quantidade}X - {item.descricao.toUpperCase()}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            )}
                                        </ProductBox>
                                    )
                                })}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid item md={12} sx={{ width: "100%", overflowX: "auto" }}>
                    <Paper sx={{ width: "100%", p: 2, overflowX: "auto" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6">Pagamentos</Typography>
                        </Box>
                        <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Valor</TableCell>
                                        <TableCell align="center">Método de pagamento</TableCell>
                                        <TableCell align="center">Data/Hora</TableCell>
                                        <TableCell align="center">Valor recebido</TableCell>
                                        <TableCell align="center">Valor troco</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pedido?.pagamentos?.map((pagamento, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">{real(pagamento.valor)}</TableCell>
                                            <TableCell align="center">{pagamento.metodoPagamento ?? "-"}</TableCell>
                                            <TableCell align="center">{pagamento.dataHora ? dataHoraBr(pagamento.dataHora as string) : "-"}</TableCell>
                                            <TableCell align="center">{pagamento.metodoPagamento == MetodoPagamento.Dinheiro ? real(pagamento.valorRecebido) : "-"}</TableCell>
                                            <TableCell align="center">{pagamento.metodoPagamento == MetodoPagamento.Dinheiro ? real(pagamento.valorTroco) : "-"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                <Grid item md={12} sx={{ width: "100%", overflowX: "auto" }}>
                    <Paper sx={{ width: "100%", p: 2, overflowX: "auto" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6">Anexos</Typography>
                            <Button
                                startIcon={<Print />}
                                variant="contained"
                                size="small"
                                onClick={abrirModalImpressao}
                            >
                                IMPRIMIR
                            </Button>
                        </Box>
                        <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Descrição</TableCell>
                                        <TableCell align="center">Nome do arquivo</TableCell>
                                        <TableCell align="center">Tipo</TableCell>
                                        <TableCell align="center">Tipo do arquivo</TableCell>
                                        <TableCell align="center">Data/Hora</TableCell>
                                        <TableCell align="center">Download</TableCell>
                                        <TableCell align="center">Visualizar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pedido?.anexos?.map(anexo => (
                                        <TableRow
                                            key={anexo.uuid}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">{anexo.descricao}</TableCell>
                                            <TableCell align="center">{anexo.nome}</TableCell>
                                            <TableCell align="center">{TipoAnexoDescricao[anexo.tipo]}</TableCell>
                                            <TableCell align="center">{anexo.mimetype}</TableCell>
                                            <TableCell align="center">{dataHoraBr(anexo.dataHora)}</TableCell>
                                            <TableCell align="center">
                                                <Download
                                                    sx={{ cursor: 'pointer', color: 'primary.main' }}
                                                    onClick={() => downloadPorUrl(anexo.url, anexo.nome)}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <OpenInNew
                                                    sx={{ cursor: 'pointer', color: 'primary.main' }}
                                                    onClick={() => window.open(anexo.url, '_blank')}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={imprimirOpen} onClose={fecharModalImpressao} sx={{ zIndex: 9999 }}>
                <DialogTitle>Imprimir comanda</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <FormLabel id="imprimir-anexos">Qual comanda você deseja imprimir?</FormLabel>
                        <RadioGroup
                            aria-labelledby="imprimir-anexos"
                            name="imprimir-anexos-group"
                            value={configuracaoComanda}
                            onChange={(e, value) => setConfiguracaoComanda(value)}
                        >
                            {fluxoContexto?.configuracoesComandas.map(configuracao => {
                                return (
                                    <Box sx={{ mt: 2 }}>
                                        <RadioCard
                                            labelTitle={TipoAnexoDescricao[configuracao.tipo]}
                                            labelValue={configuracao.uuid}
                                            key={configuracao.uuid}
                                            selected={configuracao.uuid == configuracaoComanda}
                                            onClick={() => setConfiguracaoComanda(configuracao.uuid)}
                                        />
                                    </Box>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharModalImpressao}>Cancelar</Button>
                    <Button variant={!loading ? "contained" : "outlined"} disabled={!configuracaoComanda} onClick={handleImprimirGerarAnexo}>
                        {!loading ? "Imprimir" : <CircularProgress size={25} />}
                    </Button>
                </DialogActions>
            </Dialog>
            <EmitirNotaFiscalModal
                cancelar={fecharModalNotaFiscal}
                concluir={handleEmitirNotaFiscal}
                open={notaFiscalOpen}
                loading={loading}
            />
        </Container>
    );
}