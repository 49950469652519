import { DragOverlay, useDroppable } from "@dnd-kit/core";
import { IColunaBoardPedidos } from "../../../../interface/IColunaBoardPedidos"
import { BoardColumn, BoardColumnDragging, ColorColumn, ColumnHeader } from "./styles";
import { Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { IPedido } from "../../../../interface/IPedido";
import { CardPedido } from "../CardPedido";
import { createPortal } from "react-dom";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface ColunaStatusPedidoProps {
    coluna: IColunaBoardPedidos;
    pedidos: IPedido[];
    onClickPedido: (uuid: string) => void;
}

export const ColunaStatusPedido = ({ coluna, pedidos, onClickPedido }: ColunaStatusPedidoProps) => {
    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: coluna.id,
        data: {
            type: "Coluna",
            coluna
        }
    })

    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    const uuidsPedidos = useMemo(() => pedidos.map(pedido => pedido.uuid), [pedidos])

    if (isDragging) {
        return <BoardColumnDragging ref={setNodeRef} style={style}></BoardColumnDragging>
    }
    
    return (
        <>
            <BoardColumn ref={setNodeRef} style={style}>
                <ColumnHeader {...attributes} {...listeners} variant="outlined">
                    <Typography fontWeight={"bold"}>{coluna.titulo}</Typography>
                    {coluna.cor && (<ColorColumn cor={coluna.cor} />)}
                </ColumnHeader>
                <Box sx={{
                    height: "100%",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "1rem 0rem 4rem 0rem"
                }}>
                    <SortableContext items={uuidsPedidos}>
                        {pedidos.map(pedido => {
                            return <CardPedido onClick={() => onClickPedido(pedido.uuid)} key={pedido.uuid} pedido={pedido} />
                        })}
                    </SortableContext>
                </Box>
            </BoardColumn>
        </>
    );
}