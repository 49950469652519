import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import { ICategoria } from "../../interfaces/ICategoria";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { IProduto } from "../../interfaces/IProduto";
import { useEffect, useState } from "react";
import { useMarketplaces } from "../../../marketplace/hooks/useMarketplaces";
import { useCategorias } from "../../hooks/useCategorias";
import { TipoMarketplace } from "../../../marketplace/enum/TipoMarketplace";

interface ExcluirProdutoModalProps {
    open: boolean;
    handleClose: () => void;
    excluirProduto: (produtoUuid: string) => Promise<boolean>
    produto: IProduto| undefined;
    handleRefresh?: () => void;
}

export const ExcluirProdutoModal = ({ handleClose, open, excluirProduto, produto, handleRefresh }: ExcluirProdutoModalProps) => {
    const snackbar = useSnackbar();
    const [confirmacao, setConfirmacao] = useState('');
    const [categoria, setCategoria] = useState<ICategoria | undefined>(undefined)
    const { buscar } = useCategorias();
    const {
        excluirProduto: excluirProdutoMarketplace,
    } = useMarketplaces();

    const handleCloseExclusao = () => {
        setConfirmacao('');
        handleClose();
    }

    const buscarCategoria = async (categoriaUuid: string) => {
        if (!categoriaUuid) {
            return;
        }

        const dados = await buscar(categoriaUuid);
        if (!dados) {
            return snackbar("Ocorreu um erro ao excluir a categoria", { severity: "error" });
        }
        setCategoria(dados);
    }

    const handleExcluirProduto = async () => {
        if(produto?.uuid) {
            const resultado = await excluirProduto(produto.uuid);

            if(!resultado)
            return snackbar("Ocorreu um erro ao excluir a categoria", { severity: "error" });
            
            await categoria?.marketplaces?.forEach(async marketplace => {
                if ([TipoMarketplace.IFOOD].includes(marketplace.tipo)) {
                    await excluirProdutoMarketplace(marketplace.uuid as string, produto.uuid, categoria.uuid as string);
                }
            });
            
            if(handleRefresh) {
                handleRefresh();
            }

            handleCloseExclusao();
            return snackbar("Categoria excluída com sucesso", { severity: "success"});
        }
    }

    useEffect(() => {
        if (produto?.categoriaUuid) {
            buscarCategoria(produto.categoriaUuid as string);
        }
    }, [produto]);

    return (
        <Dialog open={open} onClose={handleCloseExclusao}>
            <DialogTitle>
                Excluir produto: {produto?.descricao}
            </DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    A exclusão não pode ser revertida
                </Alert>
                <Typography sx={{marginTop: "1rem"}}>
                    Para confirmar a exclusão, digite <b>{produto?.descricao}</b>  no campo.
                </Typography>
                <TextField 
                    value={confirmacao} 
                    onChange={(e) => setConfirmacao(e.target.value)}
                    sx={{ width: "100%", marginTop: "0.5rem"}}
                    size="small"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseExclusao}>Cancelar</Button>
                <Button 
                    onClick={handleExcluirProduto} 
                    type="button" 
                    variant="contained"
                    disabled={produto?.descricao !== confirmacao}
                >
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
    )
}