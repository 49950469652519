import { Global, css, Theme } from '@emotion/react';
import { useThemeContext } from '../contexts/ThemeContext';

const GlobalStyles = () => {
  const { theme } = useThemeContext();

  const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
    box-sizing: border-box;
  }

  a { 
    text-decoration: none;
    color: ${theme.palette.text.primary};
  }
`;

  return <Global styles={globalStyles} />
};

export default GlobalStyles;