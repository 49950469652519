import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const MesasComponentContainer = styled(Box)`
  height: 87vh;
  padding: 1rem;
  width: 100%;
  min-height: 0;
  border-radius: 30px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media(max-width: 768px) {
    height: 100%;
  }
`;

interface MesaCardProps {
  selecionado?: boolean;
  ocupado?: boolean;
}

export const MesaCard = styled(Paper) <MesaCardProps>`
  align-self: flex-start;
  width: 80px;
  height: 80px;
  color: ${props => props.theme.palette.common.white};
  
  ${({ selecionado, theme, ocupado }) =>
    selecionado ?
    `
        background-color: ${theme.palette.primary.main};
    ` : `
        background-color: ${ocupado ? theme.palette.error.main : theme.palette.success.main};
    `
  }
`;