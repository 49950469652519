import { IconeButton, Numeros } from "./styles";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, CardActionArea, Paper, Typography } from "@mui/material";

interface NumerosInputProps {
    valor: number;
    adicionar: () => void;
    remover: () => void;
}

export const NumerosInput = ({ adicionar, remover, valor }: NumerosInputProps) => {
    return (
        <Numeros>
            <IconeButton>
                <CardActionArea 
                    onClick={remover}
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <RemoveIcon />
                </CardActionArea>
            </IconeButton>
            <Paper className="numero-display" variant="outlined">
                <Typography variant="subtitle1" fontWeight={"bold"}>{valor}</Typography>
            </Paper>
            <IconeButton>
                <CardActionArea 
                    onClick={adicionar}
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <AddIcon />
                </CardActionArea>
            </IconeButton>
        </Numeros>
    );
}