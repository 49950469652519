import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { IPerfil } from "../../../../../perfis/interfaces/IPerfil";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { alterarPerfilUsuarioSchema } from "../../../../schemas/alterarPerfilUsuarioSchema";
import { IUsuario } from "../../../../interfaces/IUsuario";
import { useEffect } from "react";

interface ModalInativacaoProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (perfilUuid: string) => void;
    perfis: IPerfil[];
    usuario: IUsuario;
    estabelecimentoUuid?: string;
}

export const ModalAlterarPerfil = ({ handleClose, handleConfirm, open, perfis, usuario, estabelecimentoUuid  }: ModalInativacaoProps) => {
    const perfisOptions = perfis?.map((perfil) => {
        return (
            <MenuItem key={perfil.uuid} value={perfil.uuid}>
                {perfil.nome}
            </MenuItem>
        );
    });

    const { reset, register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(alterarPerfilUsuarioSchema),
        mode: "onChange",
    });

    const handleAlterarPerfil = ({ perfil }: FieldValues) => {
        handleConfirm(perfil);
        reset();
    }

    useEffect(() => {
        if(usuario) {
            const acessoAtual = usuario.acessos?.find(acesso => acesso.estabelecimentoUuid == estabelecimentoUuid);
            if(acessoAtual) {
                setValue("perfil", acessoAtual.perfilUuid);
            }
        }
    }, [usuario])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-confirmar-inativacao"
            aria-describedby="alter-confirmar-inativacao-descricao"
        >
            <form onSubmit={handleSubmit(handleAlterarPerfil)}>
                <DialogTitle id="alert-confirmar-inativacao">
                    Alterar perfil do usuário (<b>{usuario?.nome}</b>)
                </DialogTitle>
                <DialogContent sx={{ minWidth: "400px", display: "flex", justifyContent: "center" }}>
                    <FormControl sx={{ width: "100%", m: "0.5rem auto" }}>
                        <InputLabel
                            id="perfil-label"
                            error={!!errors.perfil}
                        >
                            Perfil*
                        </InputLabel>
                        <Select
                            labelId="perfil-label"
                            id="perfil"
                            placeholder="Selecione um perfil"
                            {...register("perfil")}
                            value={watch("perfil") || ''}
                            error={!!errors.perfil}
                            label="Perfil*"
                            defaultValue={undefined}
                        >
                            {perfisOptions}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCELAR</Button>
                    <Button type="submit" variant="contained" autoFocus>
                        ALTERAR
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}