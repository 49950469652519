import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const BoardColumn = styled(Paper)`
 flex-shrink: 0; 
 width: 350px;
 height: 82vh;
 padding: 0.8rem;
`

export const BoardColumnDragging = styled(Paper)`
 flex-shrink: 0; 
 width: 350px;
 height: 82vh;
 padding: 0.8rem;
 opacity: calc(0.6);
 border: 1px solid ${props => props.theme.palette.primary.main};
`

export const ColumnHeader = styled(Paper)`
    display: flex; 
    align-items: center;
    padding: 0.5rem;
    border-radius: 1rem;
    cursor: grab;
`

interface ColorColumnProps {
    cor: string;
}

export const ColorColumn = styled(Box) <ColorColumnProps>`
    width: 12px;
    height: 12px;
    background-color: ${props => props.cor};
    border-radius: 6px;
    margin-left: 0.5rem;
` 