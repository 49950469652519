import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const ToolbarPaper = styled(Paper)`
    margin-bottom: 1rem; 
    width: 100%; 
    display: flex; 
    align-items: center;
    padding: 2rem; 
    border-radius: 0;
    justify-content: space-between;
    height: 70px;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.palette.divider};

    @media (max-width: 1280px) {
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        form {
            width: 100%;
        }
    }
`

export const ToolbarBox = styled(Box)`
    display: flex;
    align-items: center;

    .input-toolbar {
        @media (max-width: 728px) {
            &:first-child {
                margin-top: 1rem;
            }
            margin: 0;
            margin-bottom: 0.5rem;
            width: 100%;
        }
    }

    @media (max-width: 728px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const AcoesBox = styled(Box)`
    @media (max-width: 728px) {
        margin-top: 0.5rem;
    }
`
