import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import { useState } from "react";
import { useLetras } from '../../../core/hooks/useLetras';
import { IProdutoPedido } from '../../../pedidos/interface/IProdutoPedido';
import { IProduto } from "../../interfaces/IProduto";
import { DrawerPizzaOperacao } from '../DrawerPizzaOperacao';

interface CardPizzaOperacaoProps {
  produto: IProduto;
  adicionarProduto: (produto: IProdutoPedido) => void;
}

export const CardPizzaOperacao = ({ produto, adicionarProduto }: CardPizzaOperacaoProps) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  }

  const { maiusculas } = useLetras();

  return (
    <>
      <Card sx={{ maxWidth: 300, height: 250 }} variant='outlined' onClick={toggleDrawer}>
        <CardActionArea sx={{ height: "100%", width: "100%" }}>
          {produto.imagens?.length ? (
            <CardMedia
              component="img"
              height="140px"
              image={`${produto.imagens[0].url}`}
              sx={{ objectFit: "cover" }}
              alt="Pizza"
            />
          ) : (
            <CardMedia
              component="img"
              height="140px"
              image={`${process.env.PUBLIC_URL}/images/produtos/pizza.png`}
              sx={{ objectFit: "contain" }}
              alt="Pizza"
            />
          )}
          <CardContent sx={{ height: "100%" }}>
            <Typography gutterBottom variant="subtitle1" fontWeight={"bold"} component="div">
              {maiusculas(produto.descricao, 25)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <DrawerPizzaOperacao 
        onSubmit={adicionarProduto}
        toggleDrawer={toggleDrawer}
        produto={produto}
        open={open}
      />
    </>
  );
}