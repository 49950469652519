import { useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";
import { useRegex } from "../../core/hooks/useRegex";
import { ICliente } from "../interface/ICliente";
import { IEndereco } from "../../core/interfaces/IEndereco";

export const useClientes = () => {
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const apiClientes = ApiFactory.getApi("MS_CLIENTES");

    const salvar = async (cliente: ICliente) => {
        setLoading(true);
        try {
            const { data } = await apiClientes.post('/', cliente);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao cadastrar o cliente", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluir = async (clienteUuid: string) => {
        setLoading(true);
        try {
            await apiClientes.delete(`/${clienteUuid}`);
            return true;
        } catch (error) {
            snackbar("Ocorreu um erro ao cadastrar o cliente", { severity: "error" });
            return false;
        } finally {
            setLoading(false);
        }
    }

    const buscarClientesPorNomeOuTelefone = async (filtro: string, filtroTipo: 'nome' | 'telefone') => {
        if(!filtro || !filtro.length) {
            return;
        }

        setLoading(true);
        try {
            const { data } = await apiClientes.get(`?${filtroTipo}=${filtro}`);
            return data ?? [];
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar os clientes", { severity: "error" });
            return [];
        } finally {
            setLoading(false);
        }
    } 

    const listarEnderecos = async (clienteUuid: string): Promise<IEndereco[]> => {
        if(!clienteUuid) {
            snackbar("Cliente inválido", { severity: "error" });
        }
        
        setLoading(true);
        try {
            const { data } = await apiClientes.get(`/${clienteUuid}/enderecos`);
            return data ?? [];
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar os endereços do cliente", { severity: "error" });
            return [];
        } finally {
            setLoading(false);
        }
    }

    const salvarEndereco = async (endereco: IEndereco, clienteUuid: string) => {
        if(!endereco) {
            snackbar("Endereço inválido", { severity: "error" });
        }
        
        setLoading(true);
        try {
            const { data } = await apiClientes.post(`/${clienteUuid}/endereco`, endereco);
            return data.endereco;
        } catch (error) {
            snackbar("Ocorreu um erro ao salvar o endereço do cliente", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluirEndereco = async (enderecoUuid: string, clienteUuid: string) => {
        if(!enderecoUuid) {
            snackbar("Endereço inválido", { severity: "error" });
        }

        if(!clienteUuid) {
            snackbar("Cliente inválido", { severity: "error" });
        }
        
        setLoading(true);
        try {
            const { data } = await apiClientes.delete(`/${clienteUuid}/endereco/${enderecoUuid}`);
            if(data) {
                snackbar("Endereço excluído com sucesso");
            }
            return true;
        } catch (error) {
            snackbar("Ocorreu um erro ao excluir o endereço do cliente", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const consultarTotal = async () => {
        setLoading(true);
        try {
            const { data } = await apiClientes.get(`/total`);
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao consultar o número total de clientes", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    return { buscarClientesPorNomeOuTelefone, excluirEndereco, loading, salvar, listarEnderecos, salvarEndereco, excluir, consultarTotal }
}