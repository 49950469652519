import styled from "@emotion/styled";

export const CardapioContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
`

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CadastrarCategoriaContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem 0;
`

export const NenhumaCategoriaContainer = styled.div`
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
`

export const AccordionContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  .MuiAccordion-root {
    border: none;
    box-shadow: none;
  }
`;