export const useMascara = () => {
    function mascaraTelefone(v: string) {
      if(!v || v.length >= 15) {
        return v;
      }

      let r = v.replace(/\D/g, "");
      r = r.replace(/^0/, "");
    
      if (r.length > 11) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (r.length > 7) {
        r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (v.trim() !== "") {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
  }

  function mascaraHexadecimal(hex: string) {
    hex = hex.replace(/[^0-9a-fA-F]/g, "");
    
    if (hex.length > 6) {
        hex = hex.substring(0, 6);
    }

    return `#${hex}`;
  }

  function mascaraCPF(cpf: string){
      if(!cpf) {
        return cpf;
      }
      cpf=cpf.replace(/\D/g,"")
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
      cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
      return cpf
  }

  function mascaraCNPJ(cnpj: string){
    if(!cnpj) {
      return cnpj;
    }
    cnpj=cnpj.replace(/\D/g,"")
    cnpj=cnpj.replace(/^(\d{2})(\d)/,"$1.$2")
    cnpj=cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    cnpj=cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")
    cnpj=cnpj.replace(/(\d{4})(\d)/,"$1-$2")
    return cnpj
  }

  function mascaraCEP(cep: string){
    if(!cep) {
      return cep;
    }
    cep=cep.replace(/\D/g,"")
    cep=cep.replace(/(\d{5})(\d)/,"$1-$2")
    return cep
  }

  return { mascaraCPF, mascaraTelefone, mascaraCNPJ, mascaraCEP, mascaraHexadecimal}
}