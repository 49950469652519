import { useMemo } from 'react';

// Definindo tipos para as paletas de cores
type PaletteType = 'default' | 'vibrant' | 'pastel';

// Definindo tipos para as cores dentro das paletas
type PaletteColors = {
    default: string[];
    vibrant: string[];
    pastel: string[];
    // Adicione mais paletas conforme necessário
};

// Objeto com as paletas de cores
const PALETTES: PaletteColors = {
    default: ['#8d70ff', '#da4167', '#64b5f6', '#81c784', '#ffb74d', '#e57373'],
    vibrant:  ['#8d70ff', '#da4167', '#64b5f6', '#81c784', '#ffb74d', '#e57373'],
    pastel: ['#a020f0', '#8a2be2', '#5f9ea0', '#f08080', '#ffd700', '#ff6347'],
    // Adicione mais paletas conforme necessário
};

// Tipo para a função useCores
type UseCoresHook = (palette?: PaletteType, numberOfColors?: number) => string[];

const useCores: UseCoresHook = (palette = 'default', numberOfColors = 6) => {
    const colors = useMemo(() => {
        let selectedPalette = PALETTES[palette] || PALETTES['default'];

        if (palette === 'default') {
            // Embaralha a paleta default
            selectedPalette = shuffleArray(selectedPalette);
        }

        return selectedPalette.slice(0, numberOfColors);
    }, [palette, numberOfColors]);

    return colors;
};

// Função para embaralhar um array
function shuffleArray(array: any[]) {
    const shuffledArray = array.slice(); // Cria uma cópia do array original
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

export default useCores;
