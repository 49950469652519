export const validarMoedaReal = (valor: string | null | undefined, valorMinimo?: number, valorMaximo?: number, required?: boolean) => {
    if (required && (valor === null || valor === undefined || valor?.length === 0)) {
        return 'Campo obrigatório';
    }

    if (valor === null || valor === undefined || valor?.length === 0) {
        return undefined; 
    }

    if (!/^[0-9.,-]*$/.test(valor)) {
        return 'Valor inválido';
    }

    const numericValue = valor.replace(',', '.');

    const parsedValue = parseFloat(numericValue);

    if (isNaN(parsedValue)) {
        return 'Valor inválido';
    }

    if (valorMinimo !== undefined && parsedValue < valorMinimo) {
        return `O valor deve ser no mínimo R$ ${valorMinimo}`;
    }

    if (valorMaximo !== undefined && parsedValue > valorMaximo) {
        return `O valor deve ser no máximo R$ ${valorMaximo}`;
    }

    return undefined;
};
