import { useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IMarketplace } from "../interfaces/IMarketplace";
import { AxiosError } from "axios";
import { IImagemMarketplace } from "../interfaces/IImagemMarketplace";
import { ReferenciaImagemMarketplace } from "../enum/ReferenciaImagemMarketplace";
import { ICategoria } from "../../produtos/interfaces/ICategoria";
import { IMarketplaceCategoria } from "../../produtos/interfaces/IMarketplaceCategoria";
import { IProduto } from "../../produtos/interfaces/IProduto";

export function useMarketplaces () {
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const apiMarketplaces = useMemo(() => ApiFactory.getApi("MS_MARKETPLACES"), []);
    
    const salvar = async (marketplace: IMarketplace): Promise<null | IMarketplace> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/`, marketplace);
            if(!data) {
                showSnackbar("Ocorreu um erro ao salvar o marketplace", { severity: "error" });
                return null;
            }

            return data.marketplace;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao salvar o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const upload = async (uuid: string, arquivo: File, referencia: ReferenciaImagemMarketplace) => {
        if(loading) {
            return;
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('arquivo', arquivo);

        try { 
            const { data } = await apiMarketplaces.post(`/${uuid}/imagens/upload/${referencia}`, formData);
            if(!data) {
                showSnackbar("Ocorreu um erro ao realizar o upload", { severity: "error" });
                return null;
            }

            return data.imagem;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao realizar o upload", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const vincular = async (
        marketplace: IMarketplace,
        dadosVinculo: {
            authorizationCode?: string;
            authorizationCodeVerifier?: string;
        }
    ): Promise<null | {
        userCode?: string;
        authorizationCodeVerifier?: string;
        verificationUrlComplete?: string;
        accessToken?: string;
        refreshToken?: string;
    }> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/vincular/`, {
                ...marketplace,
                ...dadosVinculo,
            });
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarEstabelecimentos = async (marketplaceUuid: string): Promise<null | {
        id: string;
        nome: string;
    }[]> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/estabelecimentos/${marketplaceUuid}/`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarCatalogos = async (marketplaceUuid: string): Promise<null | {
        id: string;
        contexto: string;
    }[]> => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.get(`/catalogos/${marketplaceUuid}/`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                showSnackbar(error.response?.data.message, { severity: 'error' })
                return null;
            }

            showSnackbar("Ocorreu um erro ao vincular o marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const salvarCategoria = (marketplace: IMarketplaceCategoria, categoria: ICategoria) => {
        if (marketplace.codigoExterno) {
            return atualizarCategoria(marketplace, categoria);
        }

        return criarCategoria(marketplace, categoria);
    }

    const criarCategoria = async (marketplace: IMarketplaceCategoria, categoria: ICategoria) => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.post(`/categoria/${marketplace.uuid}`, {
                codigoExterno: categoria.uuid,
                nome: categoria.descricao,
            });

            if(!data) {
                showSnackbar("Ocorreu um erro ao criar a categoria do marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            showSnackbar("Ocorreu um erro ao criar a categoria do marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const atualizarCategoria = async (marketplace: IMarketplaceCategoria, categoria: ICategoria) => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.put(`/categoria/${marketplace.uuid}`, {
                codigoExterno: marketplace.codigoExterno,
                status: 'AVAILABLE',
                nome: categoria.descricao,
                id: marketplace.uuid,
            });

            if(!data) {
                showSnackbar("Ocorreu um erro ao atualizar a categoria do marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            showSnackbar("Ocorreu um erro ao atualizar a categoria do marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluirCategoria = async (marketplaceUuid: string, codigoExterno: string) => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.delete(`/categoria/${marketplaceUuid}/${codigoExterno}`);
            if(!data) {
                showSnackbar("Ocorreu um erro ao excluir a categoria do marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            showSnackbar("Ocorreu um erro ao excluir a categoria do marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const salvarProduto = (marketplace: IMarketplaceCategoria, produto: IProduto) => {
        return atualizarProduto(marketplace, produto);
    }

    const criarProduto = async (marketplace: IMarketplaceCategoria, produto: IProduto) => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try {
            const { data } = await apiMarketplaces.post(`/produto/${marketplace.uuid}`, {
                codigoExterno: produto.uuid,
                nome: produto.descricao,
                descricao: produto.descricao,
                imagens: produto.imagens?.map(imagem => imagem.url),
                serve: 1,
                categoriaId: marketplace.codigoExterno,
                preco: produto.preco,
                quantidadeMinima: 1,
                tipoItem: produto.tipo,
            });

            if(!data) {
                showSnackbar("Ocorreu um erro ao criar o produto no marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            showSnackbar("Ocorreu um erro ao criar o produto no marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const atualizarProduto = async (marketplace: IMarketplaceCategoria, produto: IProduto) => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { data } = await apiMarketplaces.put(`/produto/${marketplace.uuid}`, {
                codigoExterno: produto.uuid,
                descricao: produto.descricao,
                imagens: produto.imagens?.map(imagem => imagem.url),
                nome: produto.descricao,
                serve: 1,
                categoriaId: marketplace.codigoExterno,
                preco: produto.preco,
                quantidadeMinima: 1,
                tipoItem: produto.tipo,
            });

            if(!data) {
                showSnackbar("Ocorreu um erro ao atualizar a categoria do marketplace", { severity: "error" });
                return null;
            }

            return data;
        } catch (error) {
            showSnackbar("Ocorreu um erro ao atualizar a categoria do marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluirProduto = async (marketplaceUuid: string, codigoExterno: string, categoriaUuid: string) => {
        if(loading) {
            return null;
        }
        setLoading(true);

        try { 
            const { status } = await apiMarketplaces.delete(`/produto/${marketplaceUuid}/${codigoExterno}/${categoriaUuid}`);
            if(status != 204) {
                showSnackbar("Ocorreu um erro ao excluir o produto do marketplace", { severity: "error" });
                return null;
            }

            return true;
        } catch (error) {
            showSnackbar("Ocorreu um erro ao excluir o produto do marketplace", { severity: "error" });
            return null;
        } finally {
            setLoading(false);
        }
    }

    return {
        salvar,
        vincular,
        loading,
        listarEstabelecimentos,
        listarCatalogos,
        criarCategoria,
        atualizarCategoria,
        excluirCategoria,
        salvarCategoria,
        upload,
        salvarProduto,
        excluirProduto,
    }
}