import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { TipoProduto } from "../enum/TipoProduto";
import { MIMETypes } from "../../core/enum/MIMETypes";
import { IImagemProduto } from "../interfaces/IImagemProduto";
import { IBorda } from "../interfaces/IBorda";
import { ISabor } from "../interfaces/ISabor";
import { SelectChangeEvent } from "@mui/material";
import { v4 } from "uuid";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { configuracaoPizzaSchema } from "../schema/configuracaoPizzaSchema";
import { ModeloPrecificacaoPizza } from "../enum/ModeloPrecificacaoPizza";
import { useNavigate, useParams } from "react-router-dom";
import { useProdutos } from "../hooks/useProdutos";
import { useFetchCategorias } from "../hooks/useFetchCategorias";
import { ICategoria } from "../interfaces/ICategoria";
import { IProduto } from "../interfaces/IProduto";
import { useCategorias } from "../hooks/useCategorias";
import { IImpostoProduto } from "../interfaces/IImpostoProduto";
import { useMoeda } from "../../core/hooks/useMoeda";
import { validarMoedaReal } from "../../core/utils/validarMoedaReal";
import { IComplemento } from "../interfaces/IComplemento";
import { TipoControleEstoque } from "../enum/TipoControleEstoque";
import { IInsumo } from "../interfaces/IInsumo";
import { useMarketplaces } from "../../marketplace/hooks/useMarketplaces";
import { TipoMarketplace } from "../../marketplace/enum/TipoMarketplace";

interface ICadastrarEditarProdutoContext {
    handleTipoProduto: (tipo: TipoProduto | undefined) => void;
    handleExcluirImagem: (imagemExcluida: File | IImagemProduto) => void;
    handleUploadImagem: (arquivo: File) => void;
    tipoProduto: TipoProduto | undefined;
    step: number;
    descricao: string | undefined
    modeloPrecificacao: ModeloPrecificacaoPizza | undefined
    quantidadeFatias: number | undefined
    quantidadeSabores: number | undefined
    imagens: IImagemProduto[] | File[];
    marca?: string;
    codigoBarras?: string;
    preco?: string;
    detalhes?: string;

    handleVoltar: () => void;
    handleProximo: () => void;

    consultarIndustrializado: ({ codigoBarras }: FieldValues) => void;

    editarBorda: (borda: IBorda) => void;
    excluirBorda: (borda: IBorda) => void;
    adicionarBorda: (borda: IBorda) => void;
    bordas: IBorda[];

    editarSabor: (sabor: ISabor) => void;
    excluirSabor: (borda: ISabor) => void;
    adicionarSabor: (borda: ISabor) => void;
    sabores: ISabor[];

    complementos?: IComplemento[];

    registrarConfiguracoesPizza: (pizza: FieldValues) => void;
    registrarConfiguracoesPreparado: (preparado: FieldValues) => void;
    setDescricao: (descricao: string) => void;
    setModeloPrecificacao: (modeloPrecificacao: ModeloPrecificacaoPizza) => void;
    setQuantidadeFatias: (qtd: number) => void;
    setQuantidadeSabores: (qtd: number) => void;
    setPreco: (preco: string) => void;
    setCodigoBarras: (codigo: string) => void;
    setMarca: (marca: string) => void;
    setDetalhes: (detalhes: string) => void;
    salvarHabilitado: boolean;
    loading: boolean;
    salvarProduto: () => void;
    setImagens: (imagens: IImagemProduto[] | File[]) => void;

    categoriaUuid: string | undefined;
    setCategoriaUuid: (categoriaUuid: string) => void;
    categoria: ICategoria | undefined;
    loadingCategoria: boolean;

    uuid: string | undefined;
    setUuid: (uuid: string) => void;

    produto: IProduto | undefined;
    resetIndustrializado: () => void;
    validarIndustrializado: () => void;
    formErrors?: FormErrors;

    setHabilitarComplementos: (habilitar: boolean) => void;
    habilitarComplementos: boolean;
    setHabilitarObservacao: (habilitar: boolean) => void;
    habilitarObservacao: boolean;
    setComplementos: (complementos: IComplemento[]) => void;
    salvarComplemento: (complemento: IComplemento) => void;
    excluirComplemento: (uuid: string) => void;

    controleEstoque: TipoControleEstoque | undefined;
    setControleEstoque: (tipo: TipoControleEstoque | undefined) => void;

    adicionarInsumo: (insumo: IInsumo) => void;
    excluirInsumo: (uuid: string) => void;
    fichaTecnica: IInsumo[];
}

interface FormErrors {
    preco?: string;
}

export const useCadastrarEditarProdutoContext = () => {
    return useContext(CadastrarEditarProdutoContext)
}
export const CadastrarEditarProdutoContext = createContext({} as ICadastrarEditarProdutoContext)

export const CadastrarEditarProdutoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [uuid, setUuid] = useState<string | undefined>(undefined);
    const [categoriaUuid, setCategoriaUuid] = useState<string | undefined>(undefined);
    const [categoria, setCategoria] = useState<ICategoria | undefined>(undefined)
    const { salvar, loading, consultarIndustrializado: consultarIndustrializadoHook } = useProdutos();
    const { buscar, loading: loadingCategoria } = useCategorias();
    const [step, setStep] = useState(0);
    const [tipoProduto, setTipoProduto] = useState<TipoProduto | undefined>(undefined)
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const navigate = useNavigate();
    const {
        salvarProduto: salvarProdutoMarketplace,
    } = useMarketplaces();

    const handleTipoProduto = (tipo: TipoProduto | undefined) => {
        setTipoProduto(tipo);
    }

    const { number } = useMoeda();
    const [imagens, setImagens] = useState<IImagemProduto[] | File[]>([]);
    const [bordas, setBordas] = useState<IBorda[]>([]);
    const [sabores, setSabores] = useState<ISabor[]>([]);
    const [descricao, setDescricao] = useState<string | undefined>('');
    const [modeloPrecificacao, setModeloPrecificacao] = useState<ModeloPrecificacaoPizza | undefined>(undefined);
    const [quantidadeFatias, setQuantidadeFatias] = useState(0);
    const [quantidadeSabores, setQuantidadeSabores] = useState(0);
    const [preco, setPreco] = useState('');
    const [codigoBarras, setCodigoBarras] = useState<string | undefined>('');
    const [marca, setMarca] = useState<string | undefined>('');
    const [imposto, setImposto] = useState<IImpostoProduto | undefined>(undefined);
    const [complementos, setComplementos] = useState<IComplemento[]>([]);
    const [habilitarComplementos, setHabilitarComplementos] = useState<boolean>(false);
    const [habilitarObservacao, setHabilitarObservacao] = useState<boolean>(false);
    const [detalhes, setDetalhes] = useState<string | undefined>('');
    const [controleEstoque, setControleEstoque] = useState<TipoControleEstoque | undefined>(undefined);
    const [fichaTecnica, setFichaTecnica] = useState<IInsumo[]>([]);

    const snackbar = useSnackbar();
    const buscarCategoria = async (categoriaUuid: string) => {
        if (!categoriaUuid) {
            return;
        }

        const dados = await buscar(categoriaUuid);
        if (!dados) {
            navigate("/cardapio");
            snackbar("Categoria não encontrada", { severity: "error" })
            return;
        }
        setCategoria(dados);
    }

    const adicionarInsumo = (insumo: IInsumo) => {
        setFichaTecnica(ficha => [...ficha, insumo]);
    }

    const excluirInsumo = (uuid: string) => {
        setFichaTecnica(ficha => ficha.filter(insumo => insumo.uuid !== uuid));
    }
    
    const produto = useMemo(() => {
        return categoria?.produtos?.find(produto => produto.uuid === uuid);
    }, [uuid, categoria])

    const validarIndustrializado = (): boolean => {
        const currentErrors: FormErrors = {};

        const validacaoMoeda = validarMoedaReal(preco?.trim(), 0.00, undefined, true);
        if (validacaoMoeda) {
            currentErrors.preco = validacaoMoeda;
        }

        setFormErrors(currentErrors);
        return Object.keys(currentErrors).length === 0;
    };


    const consultarIndustrializado = async ({ codigoBarras }: FieldValues) => {
        const produto = await consultarIndustrializadoHook(codigoBarras.trim());

        if (!produto) {
            return snackbar("Produto não encontrado", { severity: "error" });
        }

        setImagens(produto.imagens);
        setDescricao(produto.descricao);
        setCodigoBarras(codigoBarras);
        setMarca(produto.marca);
        setUuid(produto.uuid);
        setImposto(produto.imposto);
        handleProximo();
    }

    const resetIndustrializado = () => {
        setPreco('');
        setDescricao(undefined);
        setImagens([]);
        setMarca(undefined);
        setCodigoBarras(undefined);
        setUuid(undefined);
    }

    useEffect(() => {
        if (categoriaUuid) {
            buscarCategoria(categoriaUuid);
        }
    }, [categoriaUuid])

    useEffect(() => {
        if (produto) {
            setTipoProduto(produto?.tipo);
            setDescricao(produto?.descricao);
            setModeloPrecificacao(produto?.configuracao?.modeloPrecificacao);
            setQuantidadeFatias(produto?.quantidadeFatias ?? 0);
            setQuantidadeSabores(produto?.quantidadeSabores ?? 0);
            setImagens(produto?.imagens as any);
            setBordas(produto?.bordas ?? []);
            setSabores(produto?.sabores ?? []);
            setMarca(produto?.marca);
            setCodigoBarras(produto?.codigoBarras);
            setPreco(produto?.preco ? String(produto?.preco) : '');
            setImposto(produto?.imposto);
            setDetalhes(produto?.detalhes);
            setComplementos(produto?.complementos ?? []);
            setHabilitarComplementos(produto?.configuracao?.habilitarComplementos ?? false);
            setHabilitarObservacao(produto?.configuracao?.habilitarObservacao ?? false);
            setControleEstoque(produto?.controleEstoque);
            setFichaTecnica(produto?.fichaTecnica ?? []);
        }
    }, [produto])

    const mimetypesValidos = [
        MIMETypes["image/jpeg"],
        MIMETypes["image/jpg"],
        MIMETypes["image/png"]
    ] as string[];

    const salvarProduto = async () => {
        if (!categoriaUuid) {
            return;
        }

        const uuidSalvar = uuid ?? v4();
        if (tipoProduto == TipoProduto.pizza) {
            if (!descricao) {
                return snackbar("Tamanho inválido", { severity: "error" })
            }

            if (!modeloPrecificacao) {
                return snackbar("Modelo de precificação inválido", { severity: "error" })
            }

            const resultado = await salvar(categoriaUuid, {
                tipo: tipoProduto,
                descricao,
                uuid: uuidSalvar,
                bordas,
                sabores,
                quantidadeFatias,
                quantidadeSabores,
                imagens: imagens as any,
                controleEstoque,
                fichaTecnica,
                configuracao: {
                    modeloPrecificacao
                },
            });

            if (!resultado) {
                return snackbar("Ocorreu um erro ao salvar o produto", { severity: "error" })
            }

            await categoria?.marketplaces?.forEach(async marketplace => {
                if ([TipoMarketplace.IFOOD].includes(marketplace.tipo)) {
                    await salvarProdutoMarketplace(marketplace, {
                        tipo: tipoProduto,
                        descricao,
                        uuid: uuidSalvar,
                        bordas,
                        sabores,
                        quantidadeFatias,
                        quantidadeSabores,
                        preco: Number(preco),
                        imagens: imagens as any,
                        controleEstoque,
                        fichaTecnica,
                        configuracao: {
                            modeloPrecificacao
                        },
                    });
                }
            });
        }

        if (tipoProduto == TipoProduto.industrializado) {
            if (!validarIndustrializado()) {
                return;
            }

            setFormErrors({});

            if (!codigoBarras) {
                return snackbar("Código de barras inválido", { severity: "error" })
            }

            if (!descricao) {
                return snackbar("Descrição inválida", { severity: "error" })
            }

            const resultado = await salvar(categoriaUuid, {
                tipo: tipoProduto,
                descricao,
                uuid: uuidSalvar,
                codigoBarras,
                marca,
                imagens: imagens as any,
                imposto,
                preco: preco.length ? number(preco) : 0,
                controleEstoque,
                fichaTecnica,
            });

            if (!resultado) {
                return snackbar("Ocorreu um erro ao salvar o produto", { severity: "error" })
            }

            await categoria?.marketplaces?.forEach(async marketplace => {
                if ([TipoMarketplace.IFOOD].includes(marketplace.tipo)) {
                    await salvarProdutoMarketplace(marketplace, {
                        tipo: tipoProduto,
                        descricao,
                        uuid: uuidSalvar,
                        codigoBarras,
                        marca,
                        imagens: imagens as any,
                        imposto,
                        preco: Number(preco),
                        controleEstoque,
                        fichaTecnica,
                    });
                }
            });
        }

        if (tipoProduto == TipoProduto.preparado) {
            if (!descricao) {
                return snackbar("Descrição inválida", { severity: "error" })
            }

            if(detalhes && (detalhes?.length > 100)) {
                return snackbar("O campo 'detalhes' não pode ter mais que 100 caracteres", { severity: "error" })
            }

            const resultado = await salvar(categoriaUuid, {
                tipo: tipoProduto,
                descricao,
                uuid: uuidSalvar,
                preco: preco.length ? number(preco) : 0,
                complementos,
                detalhes,
                imagens: imagens as any,
                controleEstoque,
                fichaTecnica,
                configuracao: {
                    habilitarComplementos,
                    habilitarObservacao
                }
            });

            if (!resultado) {
                return snackbar("Ocorreu um erro ao salvar o produto", { severity: "error" })
            }

            await categoria?.marketplaces?.forEach(async marketplace => {
                if ([TipoMarketplace.IFOOD].includes(marketplace.tipo)) {
                    await salvarProdutoMarketplace(marketplace, {
                        tipo: tipoProduto,
                        descricao,
                        uuid: uuidSalvar,
                        preco: Number(preco),
                        complementos,
                        detalhes,
                        imagens: imagens as any,
                        controleEstoque,
                        fichaTecnica,
                        configuracao: {
                            habilitarComplementos,
                            habilitarObservacao
                        }
                    });
                }
            });
        }

        snackbar("Produto salvo com sucesso", { severity: "success" })
        navigate("/cardapio");
    }

    const adicionarBorda = (borda: IBorda) => {
        setBordas((bordas) => [...bordas, {
            ...borda,
            uuid: v4()
        }]);
    }

    const adicionarSabor = (sabor: ISabor) => {
        setSabores((sabores) => [...sabores, {
            ...sabor,
            uuid: v4()
        }]);
    }

    const editarBorda = (borda: IBorda) => {
        const index = bordas.findIndex(bordaIndex => bordaIndex.uuid === borda.uuid)

        if (index != -1) {
            const novasBordas = bordas;
            bordas[index] = borda;
            setBordas([...novasBordas]);
        }
    }

    const editarSabor = (sabor: ISabor) => {
        const index = sabores.findIndex(saborIndex => saborIndex.uuid === sabor.uuid)

        if (index != -1) {
            const novosSabores = sabores;
            novosSabores[index] = sabor;
            setSabores([...novosSabores]);
        }
    }

    const excluirBorda = (borda: IBorda) => {
        const index = bordas.findIndex(bordaIndex => bordaIndex.uuid === borda.uuid)

        if (index != -1) {
            const novasBordas = bordas;
            novasBordas.splice(index, 1);
            setBordas([...novasBordas]);
        }
    }

    const excluirSabor = (sabor: ISabor) => {
        const index = sabores.findIndex(saborIndex => saborIndex.uuid === sabor.uuid);

        if (index != -1) {
            const novosSabores = sabores;
            novosSabores.splice(index, 1);
            setSabores([...novosSabores]);
        }
    }

    const handleExcluirImagem = (imagemExcluida: File | IImagemProduto) => {
        setImagens([]);
    }

    const handleUploadImagem = (arquivo: File) => {
        if (!mimetypesValidos.includes(arquivo.type)) {
            return snackbar(`Arquivo inválido, tipos aceitos: (PNG, JPEG)`, { severity: "error" })
        }

        setImagens([arquivo]);
    }

    const handleVoltar = () => {
        if ((step - 1) < 0) {
            if (tipoProduto) {
                return setTipoProduto(undefined);
            }
            return navigate("/cardapio");
        }

        setStep(step - 1);
    }

    const handleProximo = () => {
        setStep(step + 1);
    }

    const registrarConfiguracoesPizza = (pizza: FieldValues) => {
        setDescricao(pizza.descricao);
        setModeloPrecificacao(pizza.modeloPrecificacao);
        setQuantidadeSabores(Number(pizza.quantidadeSabores));
        setQuantidadeFatias(Number(pizza.quantidadeFatias));
        handleProximo();
    }

    const registrarConfiguracoesPreparado = (preparado: FieldValues) => {
        setDescricao(preparado.descricao);
        setDetalhes(preparado.detalhes);
        setPreco(preparado.preco);
        handleProximo();
    }

    const salvarHabilitado = useMemo(() => {
        if (tipoProduto == TipoProduto.pizza) {
            if (!descricao?.length) {
                return false;
            }

            if (!modeloPrecificacao?.length) {
                return false;
            }

            if (!quantidadeFatias || quantidadeFatias <= 0) {
                return false;
            }

            if (!quantidadeSabores || quantidadeSabores <= 0) {
                return false;
            }

            if (!uuid && step != 3) {
                return false;
            }

            return true;
        }

        if (tipoProduto == TipoProduto.industrializado) {
            if (!codigoBarras) {
                return false;
            }

            if (!descricao) {
                return false;
            }

            if (step != 2) {
                return false;
            }

            return true;
        }

        if(tipoProduto == TipoProduto.preparado) {
            if(!descricao) {
                return false;
            }       

            if(!uuid && step != 2) {
                return false;
            }

            return true;
        }

        return false;
    }, [
        tipoProduto,
        step,
        descricao,
        modeloPrecificacao,
        quantidadeFatias,
        quantidadeSabores
    ])


    const salvarComplemento = (complemento: IComplemento) => {
        const indexComplemento = complementos?.findIndex(item => item.uuid === complemento.uuid);

        if (indexComplemento != -1) {
            const novosComplementos = complementos;
            novosComplementos[indexComplemento] = complemento;
            setComplementos([...novosComplementos]);
            return;
        }

        setComplementos((estado) => [...estado, complemento]);
    }

    const excluirComplemento = (uuid: string) => {
        const index = complementos?.findIndex(complemento => complemento.uuid == uuid);

        if (index == -1) {
            return snackbar("Complemento não encontrado", { severity: "error" })
        }

        const novoArray = complementos;
        novoArray.splice(index, 1);
        setComplementos([...novoArray]);
    }


    return (
        <CadastrarEditarProdutoContext.Provider value={{
            tipoProduto,
            handleTipoProduto,
            step,
            handleExcluirImagem,
            handleUploadImagem,
            descricao,
            modeloPrecificacao,
            quantidadeFatias,
            quantidadeSabores,
            imagens,
            handleVoltar,
            handleProximo,
            bordas,
            adicionarBorda,
            editarBorda,
            excluirBorda,
            registrarConfiguracoesPizza,
            setDescricao,
            setModeloPrecificacao,
            setQuantidadeFatias,
            setQuantidadeSabores,
            sabores,
            adicionarSabor,
            editarSabor,
            excluirSabor,
            salvarHabilitado,
            loading,
            salvarProduto,
            uuid,
            setUuid,
            setCategoriaUuid,
            categoriaUuid,
            categoria,
            loadingCategoria,
            produto,
            consultarIndustrializado,
            marca,
            codigoBarras,
            preco,
            setPreco,
            setMarca,
            setCodigoBarras,
            resetIndustrializado,
            validarIndustrializado,
            formErrors,
            setImagens,
            setComplementos,
            complementos,
            habilitarComplementos,
            habilitarObservacao,
            setHabilitarComplementos,
            setHabilitarObservacao,
            setDetalhes,
            detalhes,
            registrarConfiguracoesPreparado,
            salvarComplemento,
            excluirComplemento,
            controleEstoque,
            setControleEstoque,
            fichaTecnica,
            adicionarInsumo,
            excluirInsumo
        }}>
            {children}
        </CadastrarEditarProdutoContext.Provider>
    );
} 