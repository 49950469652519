import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IInsumo } from "../interfaces/IInsumo";

interface IAcoesInsumosContext {
    insumosSelecionados: IInsumo[];
    selecionarInsumo: (insumo: IInsumo) => void;
    limparInsumosSelecionados: () => void;
}

export const useAcoesInsumosContext = () => {
    return useContext(AcoesInsumosContext)
}
export const AcoesInsumosContext = createContext({} as IAcoesInsumosContext)

export const AcoesInsumosProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [insumosSelecionados, setInsumosSelecionados] = useState<IInsumo[]>([]);

    const selecionarInsumo = (insumo: IInsumo) => {
        const selectedIndex = insumosSelecionados.findIndex(s => s.uuid === insumo.uuid);
        let newSelected: IInsumo[] = [];

        if (selectedIndex === -1) {
            newSelected = [...insumosSelecionados, insumo];
        } else {
            newSelected = [...insumosSelecionados.slice(0, selectedIndex), ...insumosSelecionados.slice(selectedIndex + 1)];
        }

        setInsumosSelecionados(newSelected);
    };

    const limparInsumosSelecionados = () => setInsumosSelecionados([]);

    return (
        <AcoesInsumosContext.Provider value={{
            selecionarInsumo,
            limparInsumosSelecionados,
            insumosSelecionados
        }}>
            {children}
        </AcoesInsumosContext.Provider>
    );
} 