import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { ButtonsContainer, ControleEstoqueBox, Formulario } from "./styles";
import { TipoControleEstoque } from "../../enum/TipoControleEstoque";
import { useLetras } from "../../../core/hooks/useLetras";
import { TipoProduto } from "../../enum/TipoProduto";
import { FichaTecnica } from "../../containers/FichaTecnica";

export const ControleEstoqueContainer = () => {
    const { tipoProduto, handleVoltar, controleEstoque, setControleEstoque, handleProximo, fichaTecnica, adicionarInsumo, excluirInsumo } = useCadastrarEditarProdutoContext();
    const { titulo } = useLetras();

    const opcaoDesabilitada = { label: 'Desabilitado', value: undefined, disabled: false };

    const opcoesControleEstoque = [opcaoDesabilitada, ...Object.entries(TipoControleEstoque).map(([key, value]) => {
        return {
            label: titulo(key),
            value: value,
            disabled: (value === TipoControleEstoque.completo && tipoProduto === TipoProduto.industrializado) || (value === TipoControleEstoque.simplificado && tipoProduto === TipoProduto.pizza)
        };
    })];

    return (
        <ControleEstoqueBox>
            <Formulario>
                <Typography
                    variant="h6"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                >
                    Controle de estoque
                </Typography>
                <Autocomplete
                    options={opcoesControleEstoque}
                    getOptionLabel={(option) => option.label}
                    getOptionDisabled={(option) => option.disabled}
                    value={opcoesControleEstoque.find(option => option.value === controleEstoque)}
                    onChange={(event, newValue) => {
                        setControleEstoque(newValue ? newValue.value : undefined);
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ marginTop: "1rem" }} label="Tipo" />}
                    fullWidth
                />
                {controleEstoque == TipoControleEstoque.completo && (
                    <FichaTecnica 
                        adicionarInsumo={adicionarInsumo} 
                        excluirInsumo={excluirInsumo}
                        fichaTecnica={fichaTecnica} 
                    />
                )}
                <ButtonsContainer>
                    <Button variant="outlined" onClick={handleVoltar}>
                        Voltar
                    </Button>
                    {tipoProduto != TipoProduto.industrializado && (
                        <Button variant="contained" onClick={handleProximo}>
                            Próximo
                        </Button>
                    )}
                </ButtonsContainer>
            </Formulario>
        </ControleEstoqueBox>
    );
}