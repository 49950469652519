import moment from "moment";

export function useDataHora() {
    function dataHoraBr(data?: string) {
        return moment(data ?? new Date()).format("DD/MM/YYYY HH:mm")
    }

    function horaBr(data?: string) {
        return moment(data ?? new Date()).format("HH:mm")
    }

    function dataBr(data?: string) {
        return moment(data ?? new Date()).format("DD/MM/YYYY")
    }

    function dataEua(data?: string) {
        return moment(data ?? new Date()).format("YYYY-MM-DD")
    }

    function diaAnteriorEua(data?: string) {
        return moment(data ?? new Date()).subtract(1, 'days').format("YYYY-MM-DD");
    }

    function diaAtualEua() {
        return moment(new Date()).format("YYYY-MM-DD");
    }

    return { dataHoraBr, horaBr, dataBr, dataEua, diaAnteriorEua, diaAtualEua }
}