import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, CardActionArea, CardMedia, IconButton, Typography } from "@mui/material";
import { CardContainer, CardContentContainer } from "./styles";
import React, { useRef } from 'react';
import { useLetras } from '../../hooks/useLetras';

interface CardUploadImagemProps {
    titulo: string;
    descricao?: string;
    url?: string;
    upload?: (arquivo: File) => void;
    excluir?: (imagem: File | any) => void;
    objectFit?: "contain" | "cover"
}

export const CardUploadImagem = ({ titulo, url, descricao, excluir, upload, objectFit }: CardUploadImagemProps) => {
    const imagemInputRef = useRef<HTMLInputElement | null>(null);
    const { minusculas } = useLetras();

    const handleImagemChange = (evento: React.ChangeEvent<HTMLInputElement>) => {
        const imagem = evento.target.files?.[0];
        if (imagem && upload) {
            upload(imagem);
        }
    }

    const handleUploadClick = () => {
        if (imagemInputRef.current) {
            imagemInputRef.current.click();
        }
    }

    return (
        <CardContainer variant="outlined">
            {!upload ? (
                <CardMedia
                    sx={{ height: 140, objectFit: objectFit ?? "cover" }}
                    image={
                        url ?? `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                    }
                    title={titulo}
                    component={"img"}
                />
            ) : (!url ? (
                <CardActionArea onClick={handleUploadClick}>
                    <CardMedia
                        sx={{ height: 140, objectFit: objectFit ?? "cover" }}
                        image={
                            url ?? `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                        }
                        title={titulo}
                        component={"img"}
                    />
                </CardActionArea>
            ) : (
                <CardMedia
                    sx={{ height: 140, objectFit: objectFit ?? "cover" }}
                    image={
                        url ?? `${process.env.PUBLIC_URL}/images/produtos/generico.png`
                    }
                    title={titulo}
                    component={"img"}
                />
            ))}
            <CardContentContainer>
                <Box>
                    <Typography variant="h6" fontWeight={"bold"}>
                        {titulo}
                    </Typography>
                    {descricao && (
                        <Typography variant="subtitle2">
                            {minusculas(descricao, 20)}
                        </Typography>
                    )}
                </Box>
                <input
                    type="file"
                    id="imagemInput"
                    ref={imagemInputRef}
                    style={{ display: "none" }}
                    onChange={handleImagemChange}
                />
                <IconButton>
                    {upload && (
                        url ? (
                            <DeleteIcon fontSize="inherit" color='error' onClick={excluir} />
                        ) : (
                            <FileUploadIcon onClick={handleUploadClick} fontSize="inherit" color='primary' />
                        )
                    )}
                </IconButton>
            </CardContentContainer>
        </CardContainer>
    );
}