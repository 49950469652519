import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { CardContainer } from "./styles";
import React from "react";

interface RadioCardProps {
    labelValue: string;
    labelTitle: string;
    labelDescription?: string;
    selected?: boolean;
    onClick?: () => any;
    tools?: React.ReactNode
}

export const RadioCard = ({ labelTitle, labelValue, labelDescription, selected, onClick, tools }: RadioCardProps) => {
    return (
        <CardContainer variant="outlined" onClick={onClick} selected={selected}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel value={labelValue} control={<Radio checked={selected} />} label={labelTitle}/>
                {tools}
            </Box>
            {labelDescription && <Typography sx={{ fontSize: "12px", p: "0.5rem" }}>{labelDescription}</Typography>}
        </CardContainer>
    );
}