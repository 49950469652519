import axios from "axios";
import { useSnackbar } from "../contexts/SnackbarContext";

export const useDownload = () => {
    const snackbar = useSnackbar();
    
    const downloadPorUrl = async (url: string, nomeArquivo: string) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob', 
            });
            
            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', nomeArquivo); 
            
            document.body.appendChild(link);
            link.click();
            
            document.body.removeChild(link);
            window.URL.revokeObjectURL(urlBlob); 
        } catch (error) {
            snackbar('Erro ao fazer download do arquivo:', { severity: "error"});
            
        }
    };

    return { downloadPorUrl }
}