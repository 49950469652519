import { Box, Divider, Grid, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { TipoPedido } from "../../../pedidos/enum/TipoPedido";
import { IProdutoPedido } from "../../../pedidos/interface/IProdutoPedido";
import { CardIndustrializadoOperacao } from "../../components/CardIndustrializadoOperacao";
import { CardPizzaOperacao } from "../../components/CardPizzaOperacao";
import { CardProdutoCarrinhoOperacao } from "../../components/CardProdutoCarrinhoOperacao";
import { useFetchCategorias } from "../../hooks/useFetchCategorias";
import { ICategoria } from "../../interfaces/ICategoria";
import { GridCarrinho, GridProdutos, ListarCategoriasContainer } from "./styles";
import { TipoProduto } from "../../enum/TipoProduto";
import { IProduto } from "../../interfaces/IProduto";
import { CardPreparadoOperacao } from "../../components/CardPreparadoOperacao";
import SearchIcon from '@mui/icons-material/Search';
import { useLetras } from "../../../core/hooks/useLetras";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { ProdutosCarrinhoContainer } from "../ProdutosCarrinhoContainer";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { TipoMarketplace } from "../../../marketplace/enum/TipoMarketplace";
import moment from "moment";
import { DiaSemanaOrdem } from "../../../core/enum/DiaSemana";
interface OperacaoCategoriasProdutosProps {
    adicionarProduto: (produto: IProdutoPedido) => void;
    produtosCarrinho: IProdutoPedido[];
    aumentarQuantidade: (produtoUuid: string) => void;
    diminuirQuantidade: (produtoUuid: string) => void;
    removerProduto: (produtoUuid: string) => void;
    editarProduto: (produto: IProdutoPedido) => void;
    contexto: TipoPedido;
    categorias: ICategoria[];
}

const renderizarProdutos = (produtos: IProduto[] | undefined, adicionarProduto: (produto: IProdutoPedido) => void) => {
    return produtos?.map(produto => {
        return (
            <Grid item xs={6} md={4} lg={12/5} key={produto.uuid}>
                {produto.tipo == TipoProduto.pizza && (
                    <CardPizzaOperacao produto={produto} adicionarProduto={adicionarProduto} />
                )}
                {produto.tipo == TipoProduto.preparado && (
                    <CardPreparadoOperacao produto={produto} adicionarProduto={adicionarProduto} />
                )}
                {produto.tipo == TipoProduto.industrializado && (
                    <CardIndustrializadoOperacao produto={produto} adicionarProduto={adicionarProduto} />
                )}
            </Grid>
        );
    })
}

export const OperacaoCategoriasProdutosContainer = ({ categorias, contexto, adicionarProduto, produtosCarrinho, aumentarQuantidade, diminuirQuantidade, removerProduto, editarProduto }: OperacaoCategoriasProdutosProps) => {
    const [categoria, setCategoria] = useState<ICategoria>();
    const { maiusculas, removerAcentos, removerCedilha } = useLetras();
    const [pesquisa, setPesquisa] = useState<string>('');

    const diaAtual = moment().day(); 
    const horaAtual = moment(); 

    const categoriasFiltradas = useMemo(() => {
        const filtradas = categorias?.filter(categoria => {
            if (!categoria.periodosExibicao?.length) {
                return true; 
            }
    
            return categoria.periodosExibicao.some(periodo => {
                if (DiaSemanaOrdem[periodo.diaSemana] !== diaAtual) {
                    return false; 
                }

                return periodo.horarios.some(horario => {
                    const horaInicio = moment(horario.inicio, 'HH:mm');
                    const horaFim = moment(horario.fim, 'HH:mm');

                    return horaAtual.isBetween(horaInicio, horaFim); 
                });
            });
        })

        return filtradas;
    }, [categorias]);

    const produtosAtivos = categoria?.produtos?.filter(produto => produto.inativadoAs === undefined);

    const handleChange = (event: React.SyntheticEvent, novaTab: string) => {
        setPesquisa('');
        setCategoria(categorias.find(categoria => categoria.uuid == novaTab));
    };

    function tabProps(uuid: string) {
        return {
            id: `simple-tab-${uuid}`,
            'aria-controls': `simple-tabpanel-${uuid}`,
        };
    }

    const produtosFiltrados = useMemo(() => {
        const produtos: IProduto[] = [];

        if (!pesquisa?.length) {
            return produtos;
        }

        categoriasFiltradas.map(categoria => {
            const categoriaPDV = categoria.marketplaces?.filter(marketplace => marketplace.tipo == TipoMarketplace.PDV)
            if (categoriaPDV?.length && categoria.contextos?.includes(contexto)) {
                categoria.produtos?.filter(produto => {
                    const strDescricao = removerAcentos(removerCedilha(maiusculas(produto.descricao)));
                    const strPesquisa = removerAcentos(removerCedilha(maiusculas(pesquisa)));

                    if (strDescricao.includes(strPesquisa) && produto.inativadoAs == undefined) {
                        return true;
                    }

                    return false;
                })
                .map(produto => produtos.push(produto));
            }
        })

        return produtos;
    }, [categoria, pesquisa])

    useEffect(() => {
        if (categoriasFiltradas && categoriasFiltradas.length) {
            const indexCategoria = categoriasFiltradas.findIndex(categoria => 
                categoria?.contextos?.includes(contexto) && 
                categoria?.marketplaces &&
                (categoria?.marketplaces?.filter(marketplace => marketplace.tipo == TipoMarketplace.PDV).length) > 0
            );
            if (indexCategoria != -1) {
                setCategoria(categoriasFiltradas[indexCategoria]);
            }
        }
    }, [categoriasFiltradas])

    useEffect(() => {
        if (pesquisa?.length) {
            setCategoria(undefined);
            return;
        } else {
            setTimeout(() => {
                const indexCategoria = categoriasFiltradas.findIndex(categoria => 
                    categoria?.contextos?.includes(contexto) && 
                    categoria?.marketplaces &&
                    (categoria?.marketplaces?.filter(marketplace => marketplace.tipo == TipoMarketplace.PDV).length) > 0
                );
                if (indexCategoria != -1 && !categoria) {
                    setCategoria(categoriasFiltradas[indexCategoria]);
                }
            }, 500)
        }

    }, [categoria, pesquisa])

    return (
        <ListarCategoriasContainer>
            <Box sx={{ padding: "0.5rem 1rem", display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Tabs
                    value={categoria?.uuid}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    sx={{ width: "80%" }}
                >
                    {categoriasFiltradas?.map(categoriaMap => {
                        const categoriaPDV = categoriaMap.marketplaces?.filter(marketplace => marketplace.tipo == TipoMarketplace.PDV)
                        if (categoriaMap?.contextos?.includes(contexto) == true && categoriaPDV?.length && categoriaMap.uuid) {
                            return (
                                <Tab
                                    {...tabProps(categoriaMap.uuid)}
                                    key={categoriaMap.uuid}
                                    value={categoriaMap.uuid}
                                    label={categoriaMap.descricao}
                                />
                            )
                        }
                    })}
                </Tabs>
                <TextField
                    size="small"
                    placeholder="Pesquisa"
                    value={pesquisa}
                    onChange={(e) => setPesquisa(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: "200px" }}
                />
            </Box>
            <GridProdutos container spacing={2}>
                {produtosFiltrados.length ? renderizarProdutos(produtosFiltrados, adicionarProduto) : renderizarProdutos(produtosAtivos, adicionarProduto)}
            </GridProdutos>
            <GridCarrinho >
                <Grid container sx={{ marginTop: "0.2rem" }}>
                    <ProdutosCarrinhoContainer
                        categorias={categoriasFiltradas}
                        aumentarQuantidade={aumentarQuantidade}
                        diminuirQuantidade={diminuirQuantidade}
                        editarProduto={editarProduto}
                        produtosCarrinho={produtosCarrinho}
                        removerProduto={removerProduto}
                    />
                </Grid>
            </GridCarrinho>
        </ListarCategoriasContainer>
    );
}