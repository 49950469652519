import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { Caixa } from "../pages/Caixa/index";
import { RelatorioCaixaDiarioFinanceiro } from "../pages/RelatorioCaixaDiarioFinanceiro";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { IMenu } from "../../core/interfaces/IMenu";
import { useState } from "react";
import { AppDrawer } from "../../core/components/Drawer";
import { Layout } from "../../core/components/Layout";

interface IFinanceiroRoutesProps {
    autenticado: boolean;
}

export const FinanceiroRoutes = ({ autenticado }: IFinanceiroRoutesProps) => {
    const { modeloTela } = useTamanhoTela();

    const itensMenu: IMenu[] = [
        {
            titulo: "Caixa",
            url: "/financeiro/caixa",
            subitens: []
        },
        {
            titulo: "Relatórios",
            url: "/financeiro/relatorios",
            inicializarAberto: true,
            subitens: [
                {
                    titulo: "Caixa diário",
                    url: "/financeiro/relatorios/caixa-diario"
                },
            ]
        }
    ];

    return (
        <Routes>
            <Route element={
                <Layout
                    titulo="Financeiro"
                    modeloTela={modeloTela}                    
                    itensMenu={itensMenu}
                    drawer
                >
                    <PrivateRoutes
                        autenticado={autenticado}
                    />
                </Layout>}>
                <Route path="/caixa" element={<Caixa />} />
                <Route path="/relatorios/caixa-diario" element={<RelatorioCaixaDiarioFinanceiro />} />
                <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
            </Route>
        </Routes>
    );
}