import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import { Divider, Paper } from '@mui/material';
import { IUsuario } from '../../../../interfaces/IUsuario';
import { IEstabelecimento } from '../../../../../estabelecimento/interfaces/IEstabelecimento';

interface Props {
  usuario: IUsuario,
  alterarFormulario: (formulario: 'alterar_dados' | 'alterar_senha') => void;
}

export const CardUsuario: React.FC<Props>  = ({ usuario, alterarFormulario }: Props) => {
  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2} sx={{mb: 4}}>
            <Avatar>{usuario.nome?.charAt(0).toUpperCase()}</Avatar>
            <Typography variant="h5" fontWeight={700} component="h2" gutterBottom>
            {usuario.nome}
            </Typography>
        </Stack>
        <Box sx={{ 
            display: 'flex', 
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <Paper elevation={1} variant="outlined" sx={{p: 5}}>  
              <Typography variant="h6" fontWeight={700} gutterBottom>
                Dados do usuário
              </Typography>
              <Typography variant="body1" component="p">
                <b>Email</b>: {usuario.email}
              </Typography>
              <Typography variant="body1" component="p">
                <b>Nome</b>: {usuario.nome}
              </Typography>
              <Typography variant="body1" component="p">
                  <b>Telefone</b>: {usuario.telefone ?? "Não cadastrado"}
              </Typography>
            
              <Stack direction="row" justifyContent="center" spacing={2} sx={{mt: 4}}>
                <Button
                  variant={"contained"} 
                  color="primary"
                  size="large"
                  onClick={() => alterarFormulario('alterar_dados')}
                >
                  Alterar dados
                </Button>
                <Button
                  variant={"outlined"} 
                  color="primary"
                  size="large"
                  onClick={() => alterarFormulario('alterar_senha')}
                >
                  Alterar senha
                </Button>
              </Stack>
            </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};