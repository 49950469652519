import { useState } from "react"
import { ApiFactory } from "../../core/config/ApiFactory";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { IControleEstoqueInsumo } from "../interfaces/IControleEstoqueInsumo";
import { IInsumo } from "../interfaces/IInsumo"

export const useInsumos = () => {
    const apiProdutos = ApiFactory.getApi("MS_PRODUTOS");
    const [loading, setLoading] = useState<boolean>(false);
    const snackbar = useSnackbar();

    const salvar = async (insumo: IInsumo) => {
        if(!insumo) {
            snackbar("Insumo inválido", { severity: "error" });
            return null;
        }
        setLoading(true);
        try {
            const { data } = await apiProdutos.post("/insumos", insumo);
            
            if(data) {
                snackbar("Insumo salvo com sucesso",{ severity: "success" });
                return data;
            }

            snackbar("Ocorreu um erro ao salvar o insumo", { severity: "error" })
            return null;
        } catch (error) {
            snackbar("Ocorreu um erro ao salvar o insumo", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const reabastecer = async (insumos: IControleEstoqueInsumo[]) => {
        if(!insumos.length) {
            snackbar("Nenhum insumo recebido para o reabastecimento", { severity: "error" });
            return null;
        }
        setLoading(true);
        try {
            const { data } = await apiProdutos.put("/estoque/reabastecer/insumos", insumos);
            
            if(data) {
                snackbar("Insumo(s) reabastecido(s) com sucesso",{ severity: "success" });
                return data;
            }

            snackbar("Ocorreu um erro ao reabastecer o(s) insumo(s)", { severity: "error" })
            return null;
        } catch (error) {
            snackbar("Ocorreu um erro ao reabastecer o(s) insumo(s)", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const consumir = async (insumos: IControleEstoqueInsumo[]) => {
        if(!insumos.length) {
            snackbar("Nenhum insumo recebido para o consumo", { severity: "error" });
            return null;
        }
        setLoading(true);
        try {
            const { data } = await apiProdutos.put("/estoque/consumir/insumos", insumos);
            
            if(data) {
                snackbar("Insumo(s) consumido(s) com sucesso",{ severity: "success" });
                return data;
            }

            snackbar("Ocorreu um erro ao consumir o(s) insumo(s)", { severity: "error" })
            return null;
        } catch (error) {
            snackbar("Ocorreu um erro ao consumir o(s) insumo(s)", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const excluir = async (uuids: string[]) => {
        if(!uuids.length) {
            snackbar("Nenhum insumo recebido para a exclusão", { severity: "error" });
            return null;
        }

        setLoading(true);

        try {
            const { data } = await apiProdutos.delete("/insumos", { data: uuids });
            
            if(data) {
                snackbar("Insumo(s) excluído(s) com sucesso",{ severity: "success" });
                return data;
            }

            snackbar("Ocorreu um erro ao excluir o(s) insumo(s)", { severity: "error" })
            return null;
        } catch (error) {
            snackbar("Ocorreu um erro ao excluir o(s) insumo(s)", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const buscar = async (uuid: string) => {
        if(!uuid) {
            snackbar("Uuid inválido", { severity: "error" });
            return null;
        }

        setLoading(true);

        try {
            const { data } = await apiProdutos.get(`/insumos/${uuid}`);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    return { salvar, loading, reabastecer, consumir, excluir, buscar }
}