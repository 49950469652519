import * as yup from "yup";

export const validarSlug = (valor: string | null | undefined, required?: boolean) => {
    if (required && (!valor || valor.trim().length === 0)) {
        return 'Slug é obrigatório.';
    }

    if (valor && valor.length > 40) {
        return 'Slug não pode ter mais que 40 caracteres.';
    }

    const padraoSlug = /^[a-z0-9\-]+$/;
    if (valor && !padraoSlug.test(valor)) {
        return 'Slug deve conter apenas letras minúsculas, números e hífens.';
    }

    return undefined; // Retorna undefined se não houver erros
};
