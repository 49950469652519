import { Add, AddBox, AddCircleOutline, AddCircleOutlined, AddCircleOutlineOutlined, ArrowDropDown, Delete, Edit, FilterList, RemoveCircleOutline, Search } from "@mui/icons-material";
import { Alert, AlertTitle, Button, ButtonGroup, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, ListItemIcon, Menu, MenuItem, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDataHora } from "../../../core/hooks/useDataHora";
import { useAcoesInsumosContext } from "../../contexts/AcoesInsumosContext";
import { UnidadeInsumoDescricao } from "../../enum/UnidadeInsumo";
import { useFetchInsumos } from "../../hooks/useFetchInsumos";
import { useInsumos } from "../../hooks/useInsumos";
import { IInsumo } from "../../interfaces/IInsumo";
import { ModalExcluirInsumos } from "./containers/ModalExcluirInsumos";
import { InsumosContainer } from "./styles";
import { ToolbarInsumos } from "./containers/ToolbarInsumos";
import { useUnidade } from "../../../core/hooks/useUnidade";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";

export const Insumos = () => {
    const { insumos, isLoading, handleRefresh } = useFetchInsumos();
    const { dataHoraBr } = useDataHora();
    const navigate = useNavigate();

    const { excluir, loading } = useInsumos();
    const { insumosSelecionados, selecionarInsumo, limparInsumosSelecionados } = useAcoesInsumosContext();

    const [search, setSearch] = useState('');
    
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('quantidade');

    const [excluirOpen, setExcluirOpen] = useState<boolean>(false);
    const handleOpenExclusao = () => setExcluirOpen(true);
    const handleCloseExclusao = () => setExcluirOpen(false);

    const handleExcluir = async () => {
        const uuids = insumosSelecionados?.map(insumo => insumo.uuid);
        const resultado = await excluir(uuids);
        if (resultado) {
            limparInsumosSelecionados();
            handleRefresh();
            handleCloseExclusao();
        }
    }

    const handleRequestSort = (tipo: string) => {
        if (tipo !== orderBy) {
            setOrderBy(tipo);
            return
        }

        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const insumosFiltrados = insumos?.filter((insumo: IInsumo) =>
        insumo.nome.toLowerCase().includes(search.toLowerCase())
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sortInsumos = (a: IInsumo, b: IInsumo) => {
        if (orderBy === 'quantidade') {
            return order === 'asc'
                ? (a.quantidade ?? 0) - (b.quantidade ?? 0)
                : (b.quantidade ?? 0) - (a.quantidade ?? 0);
        } else if (orderBy === 'unidade') {
            return order === 'asc' ? a.unidade.localeCompare(b.unidade) : b.unidade.localeCompare(a.unidade);
        } else if (orderBy === 'nome') {
            return order === 'asc' ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome);
        } else if (orderBy === 'cadastradoAs') {
            return order === 'asc'
                ? new Date(a.cadastradoAs ?? new Date()).getTime() - new Date(b.cadastradoAs ?? new Date()).getTime()
                : new Date(b.cadastradoAs ?? new Date()).getTime() - new Date(a.cadastradoAs ?? new Date()).getTime();
        }
        return 0;
    };

    const insumosOrdenados = insumosFiltrados?.sort(sortInsumos);

    const insumosPaginados = insumosOrdenados?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    useEffect(() => {
        setPage(0);
    }, [search]);

    useEffect(() => {
        limparInsumosSelecionados();
    }, [])

    const { formatar } = useUnidade();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Controle de estoque completo")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <InsumosContainer>
            <ToolbarInsumos
                selected={insumosSelecionados}
                handleSearchChange={handleSearchChange}
                handleOpenExclusao={handleOpenExclusao}
                search={search}
            />
            <TableContainer component={Paper} sx={{ width: "90%", mt: 2 }}>
                <Table sx={{ minWidth: 650 }} size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'nome'}
                                    direction={order}
                                    onClick={() => handleRequestSort("nome")}
                                >
                                    Nome
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'unidade'}
                                    direction={order}
                                    onClick={() => handleRequestSort("unidade")}
                                >
                                    Unidade
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'quantidade'}
                                    direction={order}
                                    onClick={() => handleRequestSort("quantidade")}
                                >
                                    Quantidade
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'cadastradoAs'}
                                    direction={order}
                                    onClick={() => handleRequestSort("cadastradoAs")}
                                >
                                    Cadastrado em
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : insumosPaginados.length > 0 ? (
                            insumosPaginados.map((insumo) => {
                                const isItemSelected = insumosSelecionados.some(s => s.uuid === insumo.uuid);

                                return (
                                    <TableRow
                                        key={insumo.uuid}
                                        selected={isItemSelected}
                                        onClick={() => selecionarInsumo(insumo)}
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {insumo.nome}
                                        </TableCell>
                                        <TableCell>{UnidadeInsumoDescricao[insumo.unidade]}</TableCell>
                                        <TableCell>{formatar(insumo.quantidade ?? 0, insumo.unidade)}</TableCell>
                                        <TableCell>{dataHoraBr(insumo.cadastradoAs)}</TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    Nenhum insumo cadastrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={insumosFiltrados?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página"
                />
            </TableContainer>
            <ModalExcluirInsumos
                loading={loading}
                handleExcluir={handleExcluir}
                handleClose={handleCloseExclusao}
                open={excluirOpen}
                tamanhoSelecionados={insumosSelecionados?.length}
            />
        </InsumosContainer>
    );
}