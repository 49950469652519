import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { ListarClientes } from "../pages/ListarClientes";
import { ConfiguracoesPedidoProvider } from "../../pedidos/contexts/ConfiguracoesPedidoContext";
import { Layout } from "../../core/components/Layout";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";

interface IClienteRoutesProps {
    autenticado: boolean;
}

export const ClienteRoutes = ({ autenticado }: IClienteRoutesProps) => {
    const { modeloTela } = useTamanhoTela();
    return (
        <ConfiguracoesPedidoProvider>
            <Routes>
                <Route element={
                    <Layout
                        drawer={false}
                        titulo="Clientes"
                        modeloTela={modeloTela}
                    >
                        <PrivateRoutes
                            autenticado={autenticado}
                        />
                    </Layout>
                }>
                    <Route path="/listar" element={<ListarClientes />} />
                    <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
                </Route>
            </Routes>
        </ConfiguracoesPedidoProvider>
    );
}