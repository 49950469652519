import { AddCircleOutlined, ArrowDropDown, RemoveCircleOutline } from "@mui/icons-material";
import { Alert, AlertTitle, Autocomplete, Box, Button, Divider, Link, ListItemIcon, Menu, MenuItem, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useMemo, useState } from "react";
import { Toolbar, Tools } from "./styles";
import { IITemControleEstoque } from "../../../../interfaces/IItemControleEstoque";
import { TipoItemControleEstoque } from "../../../../enum/TipoItemControleEstoque";
import { TipoControleEstoque } from "../../../../enum/TipoControleEstoque";
import { useLetras } from "../../../../../core/hooks/useLetras";
import { useThemeContext } from "../../../../../core/contexts/ThemeContext";

interface IToolbarProps {
    selected: IITemControleEstoque[];
    filtroNome: string;
    onFiltroNomeChange: (event: ChangeEvent<HTMLInputElement>) => void;
    filtroControleEstoque: { label: string; value: string } | null;
    onFiltroControleEstoqueChange: (event: ChangeEvent<any>, value: { label: string; value: string } | null) => void;
    filtroTipo: { label: string; value: string } | null;
    onFiltroTipoChange: (event: ChangeEvent<any>, value: { label: string; value: string } | null) => void;
}

export const ToolbarProdutos = ({
    selected,
    filtroControleEstoque,
    filtroNome,
    filtroTipo,
    onFiltroControleEstoqueChange,
    onFiltroNomeChange,
    onFiltroTipoChange
}: IToolbarProps) => {
    const navigate = useNavigate();
    const { titulo } = useLetras();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const opcoesTipo = Object.entries(TipoItemControleEstoque).map(([key, value]) => ({
        label: titulo(key),
        value: value
    }));

    const opcoesControleEstoque = Object.entries(TipoControleEstoque).map(([key, value]) => ({
        label: titulo(key),
        value: value
    }));

    const apenasItensTipoSimples = useMemo(() => {
        return selected.every(item => item.controleEstoque === TipoControleEstoque.simplificado);
    }, [selected]);

    const { theme } = useThemeContext();

    return (
        <Toolbar>
            {selected?.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {selected?.length} {selected?.length == 1 ? "item" : "itens"} {selected?.length == 1 ? "selecionado" : "selecionados"}
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Itens e produtos
                </Typography>
            )}
            <Tools>
                <Button
                    onClick={handleClick}
                    endIcon={<ArrowDropDown />}
                    variant={"contained"}
                    disabled={selected?.length <= 0}
                >
                    Ações
                </Button>
                <Menu
                    id={`insumos-menu`}
                    MenuListProps={{
                        'aria-labelledby': `insumos-menu`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem disabled>
                        <Typography variant="inherit" noWrap>
                            {selected?.length} {selected?.length == 1 ? "item" : "itens"} {selected?.length == 1 ? "selecionado" : "selecionados"}
                        </Typography>
                    </MenuItem>
                    <Divider />
                    {!apenasItensTipoSimples && (
                        <Box sx={{ p: 1 }}>
                            <Alert severity="info">
                                <AlertTitle>Disponível para o tipo <b>simplificado</b></AlertTitle>
                                As operações de reabastecer e consumir estão disponíveis apenas para itens do tipo <b>simplificado</b>. <br />
                                Para itens do tipo <b>completo</b>, utilize a seção <Link href="/cardapio/estoque/insumos">insumos</Link>
                            </Alert>
                        </Box>
                    )}
                    <Tooltip title='Reabasteca o estoque do(s) item(ns)'>
                        <MenuItem disabled={selected?.length < 1 || !apenasItensTipoSimples} onClick={() => navigate("reabastecer")}>
                            <ListItemIcon>
                                <AddCircleOutlined fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Reabastecer
                            </Typography>
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title='Consuma o estoque do(s) produtos(s)'>
                        <MenuItem disabled={selected?.length < 1 || !apenasItensTipoSimples} onClick={() => navigate("consumir")}>
                            <ListItemIcon>
                                <RemoveCircleOutline fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                Consumir
                            </Typography>
                        </MenuItem>
                    </Tooltip>
                </Menu>
                <Autocomplete
                    options={opcoesControleEstoque}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Controle"
                        />
                    )}
                    size="small"
                    value={filtroControleEstoque}
                    onChange={onFiltroControleEstoqueChange}
                    className="input-itens"
                />
                <Autocomplete
                    options={opcoesTipo}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo"
                        />
                    )}
                    size="small"
                    value={filtroTipo}
                    onChange={onFiltroTipoChange}
                    className="input-itens"
                />
                <TextField
                    label="Nome"
                    placeholder="Filtre por nome"
                    size="small"
                    value={filtroNome}
                    onChange={onFiltroNomeChange}
                    className="input-itens"
                />
            </Tools>
        </Toolbar>
    );
}