import { Button, CircularProgress } from "@mui/material"
import PrintIcon from '@mui/icons-material/Print';

interface ImprimirAnexoButtonProps {
    gerarAnexo: () => void;
    loading: boolean;
    titulo: string;
    color?: "primary" | "secondary";
}

export const ImprimirAnexoButton = ({gerarAnexo, loading, titulo, color}: ImprimirAnexoButtonProps) => {
    return (
        <Button 
            sx={{ width: "100%", marginTop: "1rem", height: "40px", borderRadius: "8px"}}
            onClick={gerarAnexo} 
            variant="outlined"
            color={color ?? "primary"}
            startIcon={<PrintIcon />}
            disabled={loading}
        >
            {titulo}
        </Button>
    )
}