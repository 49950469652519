import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { Cardapio } from "../pages/Cardapio";
import { CadastrarEditarCategoria } from "../pages/CadastrarEditarCategoria";
import { CadastrarEditarProduto } from "../pages/CadastrarEditarProduto";
import { CadastrarEditarProdutoProvider } from "../contexts/CadastrarEditarProdutoContext";
import { Insumos } from "../pages/Insumos";
import { AcoesInsumosProvider } from "../contexts/AcoesInsumosContext";
import { CadastrarEditarInsumo } from "../pages/CadastrarEditarInsumo";
import { ReabastecerInsumos } from "../pages/ReabastecerInsumos";
import { ConsumirInsumos } from "../pages/ConsumirInsumos";
import { Itens } from "../pages/Itens";
import { AcoesItensControleEstoqueProvider } from "../contexts/AcoesItensControleEstoqueContext";
import { ReabastecerProdutos } from "../pages/ReabastecerProdutos";
import { ConsumirProdutos } from "../pages/ConsumirProdutos";
import { Layout } from "../../core/components/Layout";
import { IMenu } from "../../core/interfaces/IMenu";
import { useMemo } from "react";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";

interface IProdutosRoutesProps {
  autenticado: boolean;
}

export const ProdutosRoutes = ({ autenticado }: IProdutosRoutesProps) => {
  const { modeloTela } = useTamanhoTela();
  const { perfil, verificarAcessoModulo } = useAutenticacaoContext();

  const itensMenu: IMenu[] = useMemo(() => {
    const itens: IMenu[] = [
      {
        titulo: "Cardápio",
        url: "/cardapio",
        subitens: []
      }
    ];

    if (verificarAcessoModulo("Controle de estoque simples")) {
      itens.push(
        {
          titulo: "Controle de estoque",
          url: "/cardapio/estoque",
          inicializarAberto: true,
          subitens: [
            { titulo: "Itens e produtos", url: "/cardapio/estoque/itens" }
          ]
        }
      )

      return itens;
    }

    if (verificarAcessoModulo("Controle de estoque completo")) {
      itens.push({
        titulo: "Controle de estoque",
        url: "/cardapio/estoque",
        inicializarAberto: true,
        subitens: [
          { titulo: "Itens e produtos", url: "/cardapio/estoque/itens" },
          { titulo: "Insumos e ingredientes", url: "/cardapio/estoque/insumos" }
        ]
      })
    }

    return itens;
  }, [perfil])

  return (
    <AcoesItensControleEstoqueProvider>
      <AcoesInsumosProvider>
        <Routes>
          <Route element={
            <Layout
              titulo="Cardápio"
              itensMenu={itensMenu}
              modeloTela={modeloTela}
              drawer
            >
              <PrivateRoutes
                autenticado={autenticado}
              />
            </Layout>
          }>
            <Route path="/categorias/:categoriaUuid/produtos/:uuid" element={
              <CadastrarEditarProdutoProvider>
                <CadastrarEditarProduto />
              </CadastrarEditarProdutoProvider>
            } />
            <Route path="/categorias/:categoriaUuid/produtos" element={
              <CadastrarEditarProdutoProvider>
                <CadastrarEditarProduto />
              </CadastrarEditarProdutoProvider>
            } />
            <Route path="/categorias/:uuid" element={<CadastrarEditarCategoria />} />
            <Route path="/categorias" element={<CadastrarEditarCategoria />} />
            <Route path="/" element={<Cardapio />} />
            <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />

            <Route path="/estoque/insumos" element={<Insumos />} />
            <Route path="/estoque/insumos/cadastrar" element={<CadastrarEditarInsumo />} />
            <Route path="/estoque/insumos/editar/:uuid" element={<CadastrarEditarInsumo />} />
            <Route path="/estoque/insumos/reabastecer" element={<ReabastecerInsumos />} />
            <Route path="/estoque/insumos/consumir" element={<ConsumirInsumos />} />

            <Route path="/estoque/itens" element={<Itens />} />
            <Route path="/estoque/itens/reabastecer" element={<ReabastecerProdutos />} />
            <Route path="/estoque/itens/consumir" element={<ConsumirProdutos />} />
          </Route>
        </Routes>
      </AcoesInsumosProvider>
    </AcoesItensControleEstoqueProvider>
  );
}