import { useMemo } from "react"
import { StatusPedido, StatusPedidoDescricao, StatusPedidosCores } from "../../../enum/StatusPedido"

export const useColunasStatusEsteiraPedidos = (parametros?: string[]) => {
    const configuracoes = [
        {
            id: String(StatusPedido["Aguardando confirmação"]),
            titulo: StatusPedidoDescricao['Aguardando confirmação'],
            cor: StatusPedidosCores["Aguardando confirmação"]
        },
        {
            id: String(StatusPedido["Em preparo"]),
            titulo: StatusPedidoDescricao['Em preparo'],
            cor: StatusPedidosCores["Em preparo"]
        },
        {
            id: String(StatusPedido["Em transporte"]),
            titulo: StatusPedidoDescricao['Em transporte'],
            cor: StatusPedidosCores["Em transporte"]
        },
        {
            id: String(StatusPedido["Concluído"]),
            titulo: StatusPedidoDescricao['Concluído'],
            cor: StatusPedidosCores["Concluído"]
        },
        {
            id: String(StatusPedido["Cancelado"]),
            titulo: StatusPedidoDescricao['Cancelado'],
            cor: StatusPedidosCores["Cancelado"]
        },
        {
            id: String(StatusPedido["Em andamento"]),
            titulo: StatusPedidoDescricao['Em andamento'],
            cor: StatusPedidosCores["Em andamento"]
        }
    ];

    const colunas = useMemo(() => {
        return configuracoes.filter(config => parametros?.includes(StatusPedido[config.id as unknown as number])) ?? [];
    }, [parametros])

    return { colunas, configuracoes };
}