import { useState } from "react"
import { ApiFactory } from "../../core/config/ApiFactory";
import { useSnackbar } from "../../core/contexts/SnackbarContext";

export const useEstabelecimentos = () => {
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const apiEstabelecimentos = ApiFactory.getApi("MS_ESTABELECIMENTOS");
    
    const buscarEstabelecimentosUsuario = async () => {
        setLoading(true);
        try {
            const { data } = await apiEstabelecimentos.get("/meus-estabelecimentos");
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao buscar os seus estabelecimentos", { severity: "error" })
            return [];
        } finally {
            setLoading(false);
        }
    }

    return {
        buscarEstabelecimentosUsuario,
        loading
    };
}