import MenuIcon from '@mui/icons-material/Menu';
import { Box, Collapse, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuList, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../../../core/contexts/ThemeContext";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { IMaterialUi } from "../../../core/interfaces/IMaterialUi";
import { Close } from '@mui/icons-material';
import React from "react";
import { IMenu } from '../../../core/interfaces/IMenu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { HeaderDrawer } from './styles';

interface AppDrawerProps {
  itensMenu: IMenu[];
  titulo: string
  menuMinimizado: boolean;
  minimizar: () => void;
  aumentar: () => void;
}

export function AppDrawer({ itensMenu, titulo, aumentar, menuMinimizado, minimizar }: AppDrawerProps) {
  const subMenusInicializadosAberto: any = {}

  itensMenu.map(item => {
    if(item.inicializarAberto) {
      subMenusInicializadosAberto[item.titulo] = true;
    } else {
      subMenusInicializadosAberto[item.titulo] = false;
    }
  })

  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { modeloTela } = useTamanhoTela();
  const [menuAberto, setMenuAberto] = useState(false);
  const [subMenuAberto, setSubMenuAberto] = useState<any>(subMenusInicializadosAberto);
  const handleClose = () => setMenuAberto(false);
  const handleOpen = () => setMenuAberto(true);

  const handleNavigate = (url: string) => {
    navigate(url);
    handleClose();
  };

  const toggleSubMenu = (titulo: string) => {
    setSubMenuAberto({ ...subMenuAberto, [titulo]: !subMenuAberto[titulo] });
  };

  function renderizarItensMenu(itens: IMenu[], handleNavigate: (url: string) => void, theme: IMaterialUi, isSubItem?: boolean) {

    return (
      <List>
        {itens.map((item: IMenu) => (
          <React.Fragment key={item.titulo}>
            <ListItem disablePadding onClick={() => item.subitens && item.subitens?.length > 0 ? toggleSubMenu(item.titulo) : handleNavigate(item.url)}>
              <ListItemButton sx={{ height: "50px" }}>
                <ListItemText
                  primary={item.titulo}
                  primaryTypographyProps={{
                    fontSize: '0.9rem',
                    fontWeight: "500",
                    pl: isSubItem ? 2 : 0,
                    color: item.url === window.location.pathname
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }}
                />
                {item.subitens && item.subitens?.length > 0 && (
                  <ArrowForwardIosIcon sx={{
                    width: "12px",
                    height: "12px",
                    transform: `rotate(${subMenuAberto[item.titulo] ? "90" : "0"}deg)`,
                    transition: '0.3s'
                  }} />
                )}
              </ListItemButton>
            </ListItem>
            {item.subitens && item.subitens?.length > 0 && (
              <Collapse
                in={subMenuAberto[item.titulo]}
                timeout="auto"
                unmountOnExit
                sx={{ background: theme.palette.background.default }}
              >
                {renderizarItensMenu(item.subitens, handleNavigate, theme, true)}
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    );
  }

  return (
    <>
      {modeloTela == "desktop" ? (
        <Hidden xsDown>
          <Drawer
            variant="permanent"
            anchor="left"
            sx={{
              width: !menuMinimizado ? 250 : 50,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: !menuMinimizado ? 250 : 50,
                boxSizing: 'border-box',
                marginTop: '50px'
              },
            }}
          >
            {menuMinimizado ? (
              <Box sx={{ padding: "0.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <IconButton onClick={aumentar} size='small'>
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : (
              <>
                <Box sx={{ height: "70px", padding: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="subtitle1" fontWeight={"bold"}>{titulo}</Typography>
                  <IconButton onClick={minimizar} size='small'>
                    <Close />
                  </IconButton>
                </Box>
                <Divider variant='middle' />
                {renderizarItensMenu(itensMenu, handleNavigate, theme)}
              </>
            )}
          </Drawer>
        </Hidden >
      ) : (
        <>
          <HeaderDrawer>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOpen}
            >
              <MenuIcon />
            </IconButton>
          </HeaderDrawer>
          <Hidden>
            <Drawer
              variant="temporary"
              anchor="left"
              open={menuAberto}
              onClose={handleClose}
              ModalProps={{
                keepMounted: true
              }}
              sx={{
                width: 250,
                '& .MuiDrawer-paper': {
                  width: 250,
                  boxSizing: 'border-box',
                  marginTop: '50px',
                },
              }}
            >
              <Box sx={{ padding: "1rem" }}>
                <Typography variant="subtitle1" fontWeight={"bold"}>{titulo}</Typography>
              </Box>
              {renderizarItensMenu(itensMenu, handleNavigate, theme)}
            </Drawer>
          </Hidden>
        </>
      )
      }
    </>
  );
}
