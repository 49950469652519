import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Grid,
    Tooltip,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Box,
    Switch,
    Divider,
    FormControlLabel
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useLetras } from "../../../core/hooks/useLetras";
import { IProduto } from "../../interfaces/IProduto";
import { CopiarProdutoModal } from '../CopiarProdutoModal';
import { ICategoria } from '../../interfaces/ICategoria';
import { ConfirmacaoAcaoModal } from '../../../core/components/ConfirmacaoAcaoModal';
import { useProdutos } from '../../hooks/useProdutos';

interface CardPizzaCardapioProps {
    pizza: IProduto;
    excluir: (produto: IProduto) => void;
    categorias: ICategoria[];
    categoriaUuid: string;
    handleRefresh: () => void;
}

export const CardPizzaCardapio = ({
    pizza,
    excluir,
    categoriaUuid,
    categorias,
    handleRefresh,
}: CardPizzaCardapioProps) => {
    const { salvar, loading } = useProdutos();
    const navigate = useNavigate();
    const { titulo } = useLetras();

    const [copiarProduto, setCopiarProduto] = useState<boolean>(false);
    const handleAbrirCopiarProduto = () => setCopiarProduto(true);
    const handleFecharCopiarProduto = () => setCopiarProduto(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openAtivar, setOpenAtivar] = useState(false);
    const handleOpenAtivar = () => setOpenAtivar(true);
    const handleCloseAtivar = () => setOpenAtivar(false);

    const ativar = async () => {
        await salvar(categoriaUuid, {
            ...pizza,
            inativadoAs: undefined
        });
        handleRefresh();
    }

    const inativar = async () => {
        await salvar(categoriaUuid, {
            ...pizza,
            inativadoAs: new Date()
        });
        handleRefresh();
    }


    return (
        <>
            <CopiarProdutoModal handleRefresh={handleRefresh} categorias={categorias} open={copiarProduto} produto={pizza} handleCancelar={handleFecharCopiarProduto} />
            <ConfirmacaoAcaoModal 
                titulo={pizza?.inativadoAs == undefined ? "Inativar produto" : "Ativar produto"}
                descricao={pizza?.inativadoAs == undefined 
                    ? "Nenhum marketplace pode visualizar um produto quando ele está inativo." 
                    : "O produto estará disponível em nos marketplaces configurados."
                }
                open={openAtivar}
                onCancelar={handleCloseAtivar}
                onConfirmar={pizza?.inativadoAs == undefined ? inativar : ativar}
                loading={loading}
            />
            <Grid item xs={12} md={4} xl={3}>
                <Card variant="outlined">
                    <CardHeader
                        action={
                            <>
                                <IconButton
                                    aria-label="settings"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => {
                                        navigate(`/cardapio/categorias/${categoriaUuid}/produtos/${pizza.uuid}`);
                                        handleClose();
                                    }}>
                                        <EditIcon fontSize="small" sx={{ marginRight: "10px" }} /> Editar
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        excluir(pizza);
                                        handleClose();
                                    }}>
                                        <DeleteIcon fontSize="small" sx={{ marginRight: "10px" }} /> Excluir
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleAbrirCopiarProduto();
                                    }}>
                                        <CopyAllIcon fontSize="small" sx={{ marginRight: "10px" }} /> Copiar
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                        title={
                            <Tooltip title={titulo(pizza.descricao)}>
                                <Typography variant="h6">{titulo(pizza.descricao, 20)}</Typography>
                            </Tooltip>
                        }
                        subheader={
                            <FormControlLabel
                                control={<Switch checked={pizza?.inativadoAs == undefined ? true : false} size='small' />}
                                label={pizza?.inativadoAs == undefined ? "Ativo" : "Inativo"}
                                onClick={handleOpenAtivar}
                            />
                        }
                        sx={{ height: "90px" }}
                    />
                    {pizza.imagens?.length ? (
                        <CardMedia
                            component="img"
                            height="140"
                            image={`${pizza.imagens[0].url}`}
                            alt="Pizza"
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            height="140"
                            image={`${process.env.PUBLIC_URL}/images/produtos/pizza.png`}
                            alt="Pizza"
                        />
                    )}
                    <CardContent sx={{ height: "180px" }}>
                        <Chip label={`${pizza.sabores?.length} Opções de sabores`} sx={{ margin: "0.3rem" }} />
                        <Chip label={`${pizza.quantidadeFatias} Fatias`} sx={{ margin: "0.3rem" }} />
                        <Chip label={`${pizza.bordas?.length} Opções de bordas`} sx={{ margin: "0.3rem" }} />
                        <Chip label={`${pizza.quantidadeSabores} Sabores por pizza`} sx={{ margin: "0.3rem" }} />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};
