import { Breadcrumbs, Button, CircularProgress, FormControl, FormHelperText, FormLabel, InputAdornment, Link, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAcoesItensContrleEstoqueContext } from "../../contexts/AcoesItensControleEstoqueContext";
import { useProdutos } from "../../hooks/useProdutos";
import { IConsumirControleEstoqueProduto } from "../../interfaces/IConsumirControleEstoqueProduto";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper } from "./styles";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";

export const ConsumirProdutos = () => {
    const { itensSelecionados } = useAcoesItensContrleEstoqueContext();
    const [inputs, setInputs] = useState<{ [key: string]: string }>({});
    const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
    const { consumir, loading } = useProdutos();
    const navigate = useNavigate();

    const resumo = useMemo(() => {
        return itensSelecionados.map(item => {
            const quantidadeConsumida = parseFloat(inputs[item.uuid]) || 0;
            const quantidadeFinal = (item.quantidade || 0) - quantidadeConsumida;

            return {
                nome: item.nome,
                quantidadeAnterior: item.quantidade || 0,
                quantidadeConsumida,
                quantidadeFinal,
            };
        });
    }, [inputs, itensSelecionados]);

    const handleInputChange = (uuid: string, value: string) => {
        const newInputs = { ...inputs, [uuid]: value };
        setInputs(newInputs);
        if (errors[uuid]) {
            setErrors({ ...errors, [uuid]: null });
        }
    };

    const validateInput = (value: string): boolean => {
        if (!value) {
            return false;
        }

        const numValue = parseFloat(value);
        return !isNaN(numValue) && numValue > 0;
    };


    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
    
        let hasErrors = false;
        const newErrors = { ...errors };
        const dadosConsumo: IConsumirControleEstoqueProduto[] = [];
    
        itensSelecionados.forEach(({ uuid }) => {
            const value = inputs[uuid] || '';
            if (!validateInput(value)) {
                newErrors[uuid] = 'Insira um número válido (maior que 0)';
                hasErrors = true;
            } else {
                dadosConsumo.push({ uuidProdutoReferencia: uuid, quantidade: parseFloat(value) });
            }
        });
    
        setErrors(newErrors);
        if (hasErrors) {
            return;
        }

        const resultado = await consumir(dadosConsumo);
        if(resultado) {
            navigate("/cardapio/estoque/itens");
        }
    };


    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Controle de estoque simples")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/cardapio/estoque/itens">
                    Itens e produtos
                </Link>
                <Typography color="text.primary">Consumir estoque</Typography>
            </Breadcrumbs>
            <form style={{ width: "100%", height: "100%" }} onSubmit={handleSubmit}>
                <ContentContainer>
                    <FormContainer>
                        <Paper sx={{
                            padding: "1rem",
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            maxHeight: "80vh",
                            overflowY: "auto"
                        }}>
                            <Typography variant="h6">
                                Estoque de produtos
                            </Typography>
                            {itensSelecionados.map(item => (
                                <FormControl
                                    fullWidth
                                    key={item.uuid}
                                    error={!!errors[item.uuid]}
                                    sx={{ mt: 2 }}
                                >
                                    <FormLabel>{item.nome}</FormLabel>
                                    <OutlinedInput
                                        endAdornment={<InputAdornment position="end">item</InputAdornment>}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        type="number"
                                        value={inputs[item.uuid] || ''}
                                        onChange={(e) => handleInputChange(item.uuid, e.target.value)}
                                        error={!!errors[item.uuid]}
                                        placeholder="Digite a quantidade consumida"
                                    />
                                    {errors[item.uuid] && <FormHelperText>{errors[item.uuid]}</FormHelperText>}
                                </FormControl>
                            ))}
                        </Paper>
                    </FormContainer>
                    <ResumoPaper>
                        <TableContainer variant="outlined" component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right">Qtd. atual</TableCell>
                                        <TableCell align="right">Qtd. consumida</TableCell>
                                        <TableCell align="right">Qtd. final</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resumo.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.nome}
                                            </TableCell>
                                            <TableCell align="right">{row.quantidadeAnterior}</TableCell>
                                            <TableCell align="right">{row.quantidadeConsumida}</TableCell>
                                            <TableCell align="right">{row.quantidadeFinal}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ButtonsContainer>
                            <Button size="large" variant="outlined" onClick={() => navigate("/cardapio/estoque/itens")}>
                                Cancelar
                            </Button>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button size="large" variant="contained" type="submit">
                                    Consumir
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
        </PageContainer>
    );
}