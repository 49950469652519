import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IInsumo } from "../interfaces/IInsumo";
import { IITemControleEstoque } from "../interfaces/IItemControleEstoque";

interface IAcoesItensControleEstoqueContext {
    itensSelecionados: IITemControleEstoque[];
    selecionarItem: (insumo: IITemControleEstoque) => void;
    limparItensSelecionados: () => void;
}

export const useAcoesItensContrleEstoqueContext = () => {
    return useContext(AcoesItensControleEstoqueContext)
}
export const AcoesItensControleEstoqueContext = createContext({} as IAcoesItensControleEstoqueContext)


export const AcoesItensControleEstoqueProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [itensSelecionados, setItensSelecionados] = useState<IITemControleEstoque[]>([]);

    const selecionarItem = (item: IITemControleEstoque) => {
        const selectedIndex = itensSelecionados.findIndex(s => s.uuid === item.uuid);
        let newSelected: IITemControleEstoque[] = [];

        if (selectedIndex === -1) {
            newSelected = [...itensSelecionados, item];
        } else {
            newSelected = [...itensSelecionados.slice(0, selectedIndex), ...itensSelecionados.slice(selectedIndex + 1)];
        }

        setItensSelecionados(newSelected);
    };

    const limparItensSelecionados = () => setItensSelecionados([]);

    return (
        <AcoesItensControleEstoqueContext.Provider value={{
            selecionarItem,
            limparItensSelecionados,
            itensSelecionados
        }}>
            {children}
        </AcoesItensControleEstoqueContext.Provider>
    );
} 