import { Alert, Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Link, Paper, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardUploadImagem } from "../../../core/components/CardUploadImagem";
import { useLetras } from "../../../core/hooks/useLetras";
import { CadastrarPizzaContainer } from "./containers/CadastrarPizzaContainer";
import { TipoProdutoContainer } from "../../containers/TipoProdutoContainer";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { ModeloPrecificacaoPizzaDescricao } from "../../enum/ModeloPrecificacaoPizza";
import { TipoProduto } from "../../enum/TipoProduto";
import { useFetchCategorias } from "../../hooks/useFetchCategorias";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper, ResumoFlex } from "./styles";
import { Save } from "@mui/icons-material";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { CadastrarIndustrializadoContainer } from "./containers/CadastrarIndustrializadoContainer";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { CadastrarPreparadoContainer } from "./containers/CadastrarPreparadoContainer";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";

export const CadastrarEditarProduto = () => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const [openSair, setOpenSair] = useState(false);

    const handleOpenSair = () => setOpenSair(true);
    const handleCloseSair = () => setOpenSair(false);

    const {
        tipoProduto,
        handleTipoProduto,
        descricao,
        quantidadeFatias,
        quantidadeSabores,
        modeloPrecificacao,
        imagens,
        sabores,
        bordas,
        salvarHabilitado,
        loading,
        salvarProduto,
        setUuid,
        setCategoriaUuid,
        categoria,
        loadingCategoria,
        produto,
        marca,
        codigoBarras,
        preco,
        habilitarComplementos,
        habilitarObservacao,
        detalhes,
        controleEstoque
    } = useCadastrarEditarProdutoContext();
    const { uuid, categoriaUuid } = useParams();

    const { real } = useMoeda();
    const { titulo } = useLetras();

    useEffect(() => {
        if (categoriaUuid) {
            setCategoriaUuid(categoriaUuid);
        }

        if (uuid) {
            setUuid(uuid);
        }
    }, [categoriaUuid, uuid])


    useEffect(() => {
        if (categoria && !produto && uuid) {
            navigate("/cardapio");
            snackbar("Produto não encontrado", { severity: "error" })
        }
    }, [loadingCategoria])

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Gestão de produtos")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/cardapio">
                    Cardápio
                </Link>
                <Link
                    color="primary"
                    href={`/cardapio/categorias/${categoria?.uuid}`}
                >
                    {categoria?.descricao}
                </Link>
                <Typography color="text.primary">{uuid ? "Editar" : "Cadastrar"} produto</Typography>
            </Breadcrumbs>
            <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                {uuid ? "Editar" : "Cadastrar"} produto
            </Typography>
            <ContentContainer>
                <FormContainer>
                    {!tipoProduto && (
                        <TipoProdutoContainer handleTipoProduto={handleTipoProduto} />
                    )}
                    {tipoProduto == TipoProduto.pizza && (
                        <CadastrarPizzaContainer />
                    )}
                    {tipoProduto == TipoProduto.industrializado && (
                        <CadastrarIndustrializadoContainer />
                    )}
                    {tipoProduto == TipoProduto.preparado && (
                        <CadastrarPreparadoContainer />
                    )}
                </FormContainer>
                <ResumoPaper>
                    <Typography variant="h6">Resumo</Typography>
                    <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                        <Typography
                            variant="subtitle1"
                        >
                            <b>Tipo</b>: {tipoProduto?.length ? titulo(tipoProduto) : "-"}
                        </Typography>
                    </Paper>
                    {tipoProduto == TipoProduto.pizza && (
                        <>
                            <ResumoFlex>
                                <Paper variant="outlined" sx={{ padding: "1rem", width: "55%", marginRight: "0.5rem" }}>
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        <b>Tamanho</b>: {descricao?.length ? descricao : "-"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Modelo de precificação</b>: {modeloPrecificacao ? ModeloPrecificacaoPizzaDescricao[modeloPrecificacao] : "-"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Número de sabores</b>: {quantidadeSabores}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Número de fatias</b>: {quantidadeFatias}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Opções de bordas</b>: {bordas.length}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Opções de sabores</b>: {sabores.length}
                                    </Typography>
                                </Paper>
                                <CardUploadImagem
                                    titulo="Imagem"
                                    descricao={
                                        imagens[0]
                                            ? (imagens[0] instanceof File ? imagens[0]?.name : (imagens[0]?.nomeOriginal ?? imagens[0]?.nome))
                                            : TipoProduto.industrializado
                                    }
                                    url={imagens[0] instanceof File ? URL.createObjectURL(imagens[0]) : imagens[0]?.url}
                                />
                            </ResumoFlex>
                        </>
                    )}
                    {tipoProduto == TipoProduto.preparado && (
                        <>
                            <ResumoFlex>
                                <Paper variant="outlined" sx={{ padding: "1rem", width: "55%", marginRight: "0.5rem" }}>
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        <b>Nome</b>: {descricao?.length ? descricao : "-"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Complementos habilitados</b>: {habilitarComplementos ? "Sim" : "Não"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Observação habilitada</b>: {habilitarObservacao ? "Sim" : "Não"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Preço</b>: {real(preco)}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem", width: "100%", overflowX: "auto" }}
                                    >
                                        <b>Detalhes</b>: {detalhes?.length ? detalhes : "-"}
                                    </Typography>
                                </Paper>
                                <CardUploadImagem
                                    titulo="Imagem"
                                    descricao={
                                        imagens[0]
                                            ? (imagens[0] instanceof File ? imagens[0]?.name : (imagens[0]?.nomeOriginal ?? imagens[0]?.nome))
                                            : TipoProduto.industrializado
                                    }
                                    url={imagens[0] instanceof File ? URL.createObjectURL(imagens[0]) : imagens[0]?.url}
                                />
                            </ResumoFlex>
                        </>
                    )}
                    {tipoProduto == TipoProduto.industrializado && (
                        <>
                            <ResumoFlex>
                                <Paper variant="outlined" sx={{ padding: "1rem", width: "55%", marginRight: "0.5rem" }}>
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        <b>Descrição</b>: {descricao?.length ? descricao : "-"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Marca</b>: {marca?.length ? marca : "-"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Código de barras</b>: {codigoBarras?.length ? codigoBarras : "-"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        <b>Preço</b>: {real(preco)}
                                    </Typography>
                                </Paper>
                                <CardUploadImagem
                                    titulo="Imagem"
                                    descricao={
                                        imagens?.length
                                            ? (imagens[0] instanceof File ? imagens[0]?.name : (imagens[0]?.nomeOriginal ?? imagens[0]?.nome))
                                            : TipoProduto.industrializado
                                    }
                                    url={imagens[0] instanceof File ? URL.createObjectURL(imagens[0]) : imagens[0]?.url}
                                    objectFit={imagens[0] ? "contain" : "cover"}
                                />
                            </ResumoFlex>
                        </>
                    )}
                    <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                        <Typography
                            variant="subtitle1"
                        >
                            <b>Controle de estoque</b>: {controleEstoque ? titulo(controleEstoque) : "Desabilitado"}
                        </Typography>
                    </Paper>
                    <ButtonsContainer>
                        <Button variant="outlined" disabled={loading} onClick={handleOpenSair}>
                            SAIR
                        </Button>
                        {salvarHabilitado && loading ? (
                            <CircularProgress size={30} />
                        ) : (
                            <Button
                                variant={"contained"}
                                onClick={salvarProduto}
                                disabled={!salvarHabilitado}
                                startIcon={<Save />}
                                type="button"
                            >
                                SALVAR
                            </Button>
                        )}
                    </ButtonsContainer>
                </ResumoPaper>
            </ContentContainer>
            <Dialog
                open={openSair}
                onClose={handleCloseSair}
            >
                <DialogTitle>
                    Deseja sair?
                </DialogTitle>
                <DialogContent>
                    <Alert severity="warning">
                        As alterações que não foram salvas serão pedidas
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSair}>Cancelar</Button>
                    <Button onClick={() => navigate("/cardapio")} autoFocus variant="contained">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer >
    );
}