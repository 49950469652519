import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const RegioesEntraTools = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;

    .filtro-regiao-entrega {
        margin-right: 1rem;
    }

    @media(max-width: 1080px) {
        flex-direction: column;       
        
        .filtro-regiao-entrega {
            margin-right: 0;
            margin-bottom: 0.5rem;
            width: 150px;
        }
    }
`

export const RegioesEntregaTools = styled(Paper)`
    display: flex; 
    padding: 2rem; 
    height: 70px; 
    justify-content: space-between; 
    align-items: center;
    width: 100%;

    @media(max-width: 768px) {
        flex-direction: column;
        height: 100%;

        button {
            margin-left: 0;
            margin-top: 1rem;
        }

        div {
            display: flex; 
            flex-direction: column;
            margin-right: 0;
        }
    }

    border-radius: 0px;
`