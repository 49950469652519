import { Box, Button, Typography } from "@mui/material"
import { EnderecoPaperContainer } from "./styles"
import { IEndereco } from "../../../core/interfaces/IEndereco"

interface EnderecoPaperProps {
    endereco: IEndereco | undefined;
    alterarEndereco: () => void;
    desabilitar?: boolean | undefined;
}

export const EnderecoPaper = ({ alterarEndereco, endereco, desabilitar }: EnderecoPaperProps) => { 
    let enderecoString = `${endereco?.rua} ${endereco?.numero} ${endereco?.complemento}, ${endereco?.bairro} - ${endereco?.cidade}`;
    
    return (
        <EnderecoPaperContainer variant="outlined" title={enderecoString}>
            <img src={`${process.env.PUBLIC_URL}/images/location.png`} alt="Location" />
            <Box sx={{ height: "100%"}}>
                {!endereco ? (
                    <Typography variant="body2" >Endereço indefinido</Typography>
                ) : (
                    <Typography component={"p"} variant="body2" fontSize={12}>
                        {enderecoString}
                    </Typography>
                )}
                {endereco && <Button onClick={alterarEndereco} disabled={desabilitar}>ALTERAR</Button>}
            </Box>
        </EnderecoPaperContainer>
    )
}