import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReferenciaConta } from "../../enum/ReferenciaConta";
import { MetodoPagamento } from "../../../core/enum/MetodoPagamento";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Toolbar
} from '@mui/material';
import { IConta } from '../../interfaces/IConta';
import { useMoeda } from '../../../core/hooks/useMoeda';
import { useDataHora } from '../../../core/hooks/useDataHora';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { Money, OpenInNew, Payments } from '@mui/icons-material';

interface CaixaTableRowProps {
    conta: IConta;
}

const CaixaTableRow = ({ conta }: CaixaTableRowProps) => {
    const { real } = useMoeda();
    const { dataHoraBr } = useDataHora();
    
    const [openItens, setOpenItens] = useState(false);
    const handleOpenItens = () => setOpenItens(true);
    const handleCloseItens = () => setOpenItens(false);

    const [openPagamentos, setOpenPagamentos] = useState(false);
    const handleOpenPagamentos = () => setOpenPagamentos(true);
    const handleClosePagamentos = () => setOpenPagamentos(false);

    return (
        <>
            <Dialog open={openItens} onClose={handleCloseItens} maxWidth="md" sx={{ zIndex: 99999 }}>
                <DialogTitle>
                    <Grid sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", height: "25px" }}>
                        <Typography variant="h6" fontWeight={"800"}>
                            Itens
                        </Typography>
                        <IconButton onClick={handleCloseItens}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table sx={{ minWidth: 450, maxWidth: 750 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Valor total</TableCell>
                                    <TableCell>Quantidade</TableCell>
                                    <TableCell>Subitens</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {conta.itens.map(item => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item.descricao}</TableCell>
                                            <TableCell>{real(item.valorTotal)}</TableCell>
                                            <TableCell>{item.quantidade}</TableCell>
                                            <TableCell>
                                                {item.subitens?.length ? item.subitens?.map(item => {
                                                    return (
                                                        <>
                                                            <div>
                                                                {item.descricao} - {item.quantidade}X - {real(item.valor)}
                                                            </div>
                                                            <br />
                                                        </>
                                                    );
                                                }) : "-"}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
            <Dialog open={openPagamentos} onClose={handleClosePagamentos} maxWidth="md" sx={{ zIndex: 99999 }}>
                <DialogTitle>
                    <Grid sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", height: "25px" }}>
                        <Typography variant="h6" fontWeight={"800"}>
                            Pagamentos
                        </Typography>
                        <IconButton onClick={handleClosePagamentos}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table sx={{ minWidth: 450, maxWidth: 750 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Data/Hora</TableCell>
                                    <TableCell>Método de pagamento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {conta.pagamentos?.map(pagamento => {
                                    return (
                                        <TableRow>
                                            <TableCell>{real(pagamento.valor)}</TableCell>
                                            <TableCell>{dataHoraBr(pagamento.dataHora as string)}</TableCell>
                                            <TableCell>{pagamento.metodoPagamento ?? "-"}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>{conta.descricao}</TableCell>
                <TableCell>{dataHoraBr(conta.dataHora)}</TableCell>
                <TableCell>{real(conta.valor)}</TableCell>
                <TableCell>{ReferenciaConta[conta.referenciaTipo].toUpperCase()}</TableCell>
                <TableCell>
                    <IconButton onClick={handleOpenPagamentos} color='success'>
                        <Payments />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleOpenItens}>
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {conta.referenciaTipo == ReferenciaConta.pedido && (
                        <OpenInNew
                            sx={{ cursor: 'pointer', color: 'primary.main' }}
                            onClick={() => window.open(`/pedidos/${conta.referenciaUuid}`, '_blank')}
                        />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
}

export default React.memo(CaixaTableRow)