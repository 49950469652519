import { Box, Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { ContentDrawer, DividirPagamentoDrawer, HeaderDrawer, TipoContent, TiposBox } from "./styles"
import { TipoDivisaoPagamento, useOperacaoContext } from "../../../../contexts/OperacaoContext";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { ReactNode, useMemo, useState } from "react";
import { QuantidadePessoasContainer } from "./containers/QuantidadePessoasContainer";
import { SelecionarProdutosContainer } from "./containers/SelecionarProdutosContainer";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { RadioCard } from "../../../../../core/components/RadioCard";
import { IPagamentoPedido } from "../../../../interface/IPagamentoPedido";
import { TipoPedido } from "../../../../enum/TipoPedido";
import { SelecionarValoresContainer } from "./containers/SelecionarValoresContainer";
interface DividirPagamentoProps {
    aberto: boolean;
    fechar: () => void;
}

export const DividirPagamento = ({ aberto, fechar }: DividirPagamentoProps) => {
    const { fecharPedido, tipo: tipoPedido, valorFrete } = useOperacaoContext();
    const [tools, setTools] = useState<ReactNode | undefined>()
    const [tipo, setTipo] = useState<TipoDivisaoPagamento>("Quantidade de pessoas")

    const handleFecharPedido = (pagamentos?: IPagamentoPedido[]) => {
        fecharPedido(undefined, pagamentos);
        fechar();
    }

    return (
        <DividirPagamentoDrawer
            anchor="bottom"
            open={aberto}
            onClose={fechar}
        >
            <HeaderDrawer>
                <FormControl className="header-input">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={tipo}
                        onChange={(e, value) => setTipo(value as TipoDivisaoPagamento)}
                        row
                    >
                        <RadioCard
                            labelTitle="Quantidade de pessoas"
                            labelValue="Quantidade de pessoas"
                            selected={tipo == "Quantidade de pessoas"}
                            onClick={() => setTipo("Quantidade de pessoas")}
                            key={"Quantidade de pessoas"}
                        />
                        <RadioCard
                            labelTitle="Produtos selecionados"
                            labelValue="Produtos selecionados"
                            selected={tipo == "Produtos selecionados"}
                            onClick={() => setTipo("Produtos selecionados")}
                            key={"Produtos selecionados"}
                        />
                        <RadioCard
                            labelTitle="Valores selecionados"
                            labelValue="Valores selecionados"
                            selected={tipo == "Valores selecionados"}
                            onClick={() => setTipo("Valores selecionados")}
                            key={"Valores selecionados"}
                        />
                    </RadioGroup>
                </FormControl>
                {tools}
            </HeaderDrawer>
            <ContentDrawer>
                <TipoContent>
                    {tipo == "Quantidade de pessoas" && (
                        <QuantidadePessoasContainer
                            setTools={setTools}
                            fecharPedido={handleFecharPedido}
                        />
                    )}
                    {tipo == "Produtos selecionados" && (
                        <SelecionarProdutosContainer
                            setTools={setTools}
                            fecharPedido={handleFecharPedido}
                            tipo={tipoPedido}
                            valorFrete={valorFrete}
                        />
                    )}
                    {tipo == "Valores selecionados" && (
                        <SelecionarValoresContainer
                            setTools={setTools}
                            fecharPedido={handleFecharPedido}
                        />
                    )}
                </TipoContent>
            </ContentDrawer>
        </DividirPagamentoDrawer>
    );
}