import styled from "@emotion/styled";
import { Box, Card, Drawer, Paper } from "@mui/material";

export const DividirPagamentoDrawer = styled(Drawer)`
    width: 100%;
    overflow-y: auto;
`

export const HeaderDrawer = styled(Box)`
    padding: 1rem;
    display: flex;
    width: 100%;

    .header-input {
        width: 100%;
        display: flex;

        div:first-child {
            margin-right: 1rem;
        }

        div:last-child {
            margin-left: 1rem;
        }

        @media(max-width: 768px) {
            div:first-child {
                margin-right: 0;
            }

            div:last-child {
                margin-left: 0;
            }
        }
    }
`

export const ContentDrawer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`

export const DividirBox = styled(Box)``

export const TiposBox = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`

export const TipoContent = styled(Box)`
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 50vh;
    overflow-y: auto;
`
