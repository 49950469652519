import { createContext, useContext, useState } from "react";


interface IDrawerContext {
    openDrawer: () => void;
    closeDrawer: () => void;
    isOpen: boolean;
}

export const useDrawerContext = () => {
    return useContext(DrawerContext)
}
export const DrawerContext = createContext({} as IDrawerContext)

export const DrawerContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const openDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);

    return (
        <DrawerContext.Provider value={{isOpen, openDrawer, closeDrawer}}>
            {children}
        </DrawerContext.Provider>
    );
} 