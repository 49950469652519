import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Stack,
    IconButton,
    Box,
    Checkbox,
    Paper
} from '@mui/material';
import { Remove, Add, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IProdutoPedido } from '../../../pedidos/interface/IProdutoPedido';
import { useMemo, useState } from 'react';
import { useMoeda } from '../../../core/hooks/useMoeda';
import { TipoProduto } from '../../enum/TipoProduto';
import { DrawerPizzaOperacao } from '../DrawerPizzaOperacao';
import { IProduto } from '../../interfaces/IProduto';
import { IBorda } from '../../interfaces/IBorda';
import { ISabor } from '../../interfaces/ISabor';
import { DrawerPreparadoOperacao } from '../DrawerPreparadoOperacao';
import { IITemComplemento } from '../../interfaces/IITemComplemento';
import { TipoItemProduto } from '../../enum/TipoItemProduto';
interface CardPizzaOperacaoProps {
    produto: IProdutoPedido;
    produtoOriginal?: IProduto;
    aumentarQuantidade?: (produtoUuid: string) => void;
    diminuirQuantidade?: (produtoUuid: string) => void;
    removerProduto?: (produtoUuid: string) => void;
    editarProduto?: (produto: IProdutoPedido) => void;
    selecionado?: boolean;
    selecionar?: (produto: IProdutoPedido) => void;
}

export const CardProdutoCarrinhoOperacao = ({ produto, aumentarQuantidade, diminuirQuantidade, removerProduto, produtoOriginal, editarProduto, selecionado, selecionar }: CardPizzaOperacaoProps) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    }

    const { real } = useMoeda();

    const totalProduto = useMemo(() => {
        let totalProduto = (produto.preco ?? 0);

        produto.itens?.map(item => {
            totalProduto = totalProduto + ((item.preco ?? 0) * (item.quantidade ?? 1));
        })

        return totalProduto * (produto.quantidade ?? 1);
    }, [produto.quantidade]);

    const borda = useMemo(() => {
        if (produto.borda) {
            return produto.borda;
        }

        let bordaEncontrada: IBorda | undefined = undefined;

        produto.itens?.map(item => {
            if (!bordaEncontrada) {
                bordaEncontrada = produtoOriginal?.bordas?.find(borda => borda.descricao.toUpperCase() == item.descricao.toUpperCase());
            }
        })

        return bordaEncontrada
    }, [produto, produtoOriginal]);

    const sabores = useMemo(() => {
        if (produto.sabores) {
            return produto.sabores;
        }

        let saboresEncontrados: ISabor[] = [];

        produto.itens?.map(item => {
            produtoOriginal?.sabores?.map(sabor => {
                if (sabor.descricao.toUpperCase() == item.descricao.toUpperCase()) {
                    const saborJaAdicionado = saboresEncontrados.find(saborEncontrado => saborEncontrado.descricao == item.descricao);
                    if (!saborJaAdicionado) {
                        saboresEncontrados.push(sabor);
                    }
                }
            });
        })

        return saboresEncontrados;
    }, [produto, produtoOriginal]);

    const observacao = useMemo(() => {
        if (produto.observacao) {
            return produto.observacao;
        }

        const obs = produto.itens?.find(item => item.tipo == TipoItemProduto.observacao);
        return obs?.descricao;
    }, [produto])

    const valorAdicional = useMemo(() => {
        if (produto.valorAdicional) {
            return produto.valorAdicional;
        }

        const obs = produto.itens?.find(item => item.tipo == TipoItemProduto.observacao);
        return obs?.preco;
    }, [produto])

    const complementos = useMemo(() => {
        if (!produtoOriginal || !produtoOriginal.complementos) {
            return undefined;
        }

        let novosComplementos = [...produtoOriginal.complementos];

        if (!novosComplementos) {
            return undefined;
        }

        novosComplementos = novosComplementos?.map(complemento => {
            const itensSelecionados: IITemComplemento[] = [];

            produto.itens?.map(item => {
                const itemEncontrado = complemento.itens.find(itemComplemento => itemComplemento.descricao == item.descricao);
                if (itemEncontrado) {
                    itensSelecionados.push(itemEncontrado)
                }
            })

            return {
                ...complemento,
                itensSelecionados: itensSelecionados.length ? itensSelecionados : undefined
            }
        })

        return novosComplementos;
    }, [produto])

    const fontSize = useMemo(() => {
        if (produto.descricao.length >= 15) {
            return 12;
        }

        return 15;
    }, [produto.descricao])

    return (
        <>
            {produtoOriginal && produtoOriginal.tipo == TipoProduto.pizza && editarProduto && (
                <DrawerPizzaOperacao
                    toggleDrawer={toggleDrawer}
                    open={open}
                    produto={produtoOriginal}
                    onSubmit={editarProduto}
                    borda={borda}
                    sabores={sabores}
                    observacao={observacao}
                    valorAdicional={valorAdicional}
                    uuid={produto.uuid}
                />
            )}
            {
                produtoOriginal &&
                produtoOriginal.tipo == TipoProduto.preparado && editarProduto && (
                    <DrawerPreparadoOperacao
                        toggleDrawer={toggleDrawer}
                        open={open}
                        produto={produtoOriginal}
                        onSubmit={editarProduto}
                        observacao={observacao}
                        valorAdicional={valorAdicional}
                        uuid={produto.uuid}
                        complementos={complementos}
                    />
                )}
            <Card variant='outlined' sx={{ maxWidth: 300 }}>
                <Box sx={{ position: 'relative' }}>
                    {selecionar && (
                        <Checkbox
                            checked={selecionado}
                            onClick={() => selecionar(produto)}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 2,
                                color: 'primary.main',
                                '& .MuiSvgIcon-root': {
                                    backgroundColor: 'white',
                                    borderRadius: '2px',
                                },
                                '&:not(.Mui-checked)': {
                                    color: 'default',
                                }
                            }}
                        />
                    )}
                    {produto.imagens?.length && produto.imagens[0]?.url ? (
                        <CardMedia
                            component="img"
                            height="80"
                            image={`${produto.imagens[0]?.url}`}
                            sx={{ objectFit: (produto.tipo == TipoProduto.industrializado || produto.tipoProdutoReferencia == TipoProduto.industrializado) ? "contain" : "cover", width: '100%' }}
                            alt={produto.tipo}
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            height="80"
                            image={`${process.env.PUBLIC_URL}/images/produtos/${produto.tipo ?? produto.tipoProdutoReferencia}.png`}
                            sx={{ objectFit: "contain", width: '100%' }}
                            alt={produto.tipo}
                        />
                    )}
                </Box>
                <CardContent sx={{ height: "100%" }}>
                    <Stack
                        width={"100%"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Typography
                            gutterBottom
                            fontSize={fontSize}
                            fontWeight={"bold"}
                            component="div"
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {produto.descricao}
                        </Typography>
                        {editarProduto && removerProduto && (
                            <div>
                                {produtoOriginal && produtoOriginal.tipo == TipoProduto.pizza && (
                                    <IconButton color="primary" onClick={toggleDrawer} aria-label="edit" size="small">
                                        <Edit fontSize="inherit" />
                                    </IconButton>
                                )}
                                {produtoOriginal &&
                                    produtoOriginal.tipo == TipoProduto.preparado &&
                                    (produtoOriginal.configuracao?.habilitarComplementos || produtoOriginal.configuracao?.habilitarObservacao) && (
                                        <IconButton color="primary" onClick={toggleDrawer} aria-label="edit" size="small">
                                            <Edit fontSize="inherit" />
                                        </IconButton>
                                    )}
                                <IconButton color="error" onClick={() => removerProduto(produto.uuid)} aria-label="delete" size="small">
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </div>
                        )}
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent={"center"}>
                        <Typography variant="h6" fontWeight={"bold"} >
                            {real(totalProduto)}
                        </Typography>
                    </Stack>
                    {produto.itens && produto.itens.length > 0 && (
                        <Paper variant='outlined' sx={{ padding: "0.5rem", marginTop: "0.1rem" }}>
                            <Stack
                                width={"100%"}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                {produto.itens.map(item => {
                                    return (
                                        <Typography fontSize={13} variant="body1">
                                            {item.descricao}
                                        </Typography>
                                    )
                                })}
                            </Stack>
                        </Paper>
                    )}
                    <Stack direction="row" mt={"1rem"} spacing={2} alignItems="center" justifyContent={"center"}>
                        <IconButton color="primary" disabled={!diminuirQuantidade} onClick={() => {
                            if (diminuirQuantidade) {
                                diminuirQuantidade(produto.uuid)
                            }
                        }}>
                            <Remove />
                        </IconButton>
                        <b>{produto.quantidade}</b>
                        <IconButton color="primary" disabled={!aumentarQuantidade} onClick={() => {
                            if (aumentarQuantidade) {
                                aumentarQuantidade(produto.uuid)
                            }
                        }}>
                            <Add />
                        </IconButton>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
}