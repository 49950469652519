import * as yup from "yup";

export const categoriaSchema =  yup.object().shape({
  descricao: yup.string().required("O nome é obrigatório"),
  posicao: yup.number()
  .typeError("A posição deve ser um número")
  .required("A posição é obrigatória")
  .positive("A posição deve ser um número positivo")
  .integer("A posição deve ser um número inteiro")
  .min(1, "A posição deve ser maior que zero")
});
  