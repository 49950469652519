import styled from "@emotion/styled";
import { Box, Button, Grid, Paper } from "@mui/material";

export const CardContainer = styled(Paper)`
    width: 100%;
    padding: 1rem;
    margin-top: 0.9rem;
`

export const HeaderCardContainer = styled(Paper)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    @media(max-width: 1080px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const PeriodosExibicaoCardContainer = styled(Paper)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 1rem;

    @media(max-width: 1080px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const ContextosBox = styled(Box)`
    max-width: 33.3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    div {
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
    }

    @media(max-width: 768px) {
        margin: 0.9rem 0;
        max-width: 100%;
        justify-content: flex-start;
    }
`

export const DetalhesHeaderBox = styled(Box)`
    display: flex;
    flex-direction: column;
    max-width: 250px;
    overflow-x: auto;

    @media(max-width: 1080px) {
        max-width: 100%;
        margin-bottom: 2rem;
    }
`

export const ButtonsHeaderBox = styled(Box)`
    display: flex;
    display: flex;
    justify-content: flex-end;
    
    button {
        height: 35px;
    }

    button:first-child {
        margin-right: 5px;
    }

    button:last-child {
        margin-left: 5px;
    }
`

export const BodyCardContainer = styled(Grid)`
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
`

export const BodySemProdutosCardContainer = styled(Box)`
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
