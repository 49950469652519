import styled from "@emotion/styled"
import { Box, Paper } from "@mui/material"

export const ConfiguracaoContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const FormularioPaper = styled(Paper)`
    padding: 1rem;
    width: 100%;
`

export const Formulario = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const ImagemContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    width: 100%;
`

export const ButtonsContainerForm = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    button {
        &:first-child {
            margin-right: 0.5rem;
        }
    }
`
