import { CallMade, CallReceived, LocalOffer } from '@mui/icons-material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WalletIcon from '@mui/icons-material/Wallet';
import { Alert, AlertTitle, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useEffect, useMemo, useState } from "react";
import { CardIcon } from "../../../core/components/CardIcon";
import { CardIconTop } from '../../../core/components/CardIconTop';
import { RealInput } from "../../../core/components/RealInput";
import { useThemeContext } from '../../../core/contexts/ThemeContext';
import { useDataHora } from "../../../core/hooks/useDataHora";
import { useMoeda } from "../../../core/hooks/useMoeda";
import CaixaTableRow from "../../components/CaixaTableRow";
import { useCaixa } from "../../hooks/useCaixa";
import { useFetchCaixaAberto } from "../../hooks/useFetchCaixaAberto";
import { IItemConta } from '../../interfaces/IItemConta';
import { ISubitemConta } from '../../interfaces/ISubitemConta';
import { FormCaixaContainer } from "./styles";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAutenticacaoContext } from '../../../core/contexts/AutenticacaoContext';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useCores from '../../../core/hooks/useCores';

export const Caixa = () => {
    const { abrirCaixa, loading, fecharCaixa } = useCaixa();
    const {
        caixa,
        ultimoCaixa,
        handleRefresh,
        isLoadingContasAReceber,
        totalContasAReceber,
        isLoadingContasAPagar,
        totalContasAPagar,
        isLoading
    } = useFetchCaixaAberto();
    const { real } = useMoeda();
    const { horaBr, dataHoraBr } = useDataHora();
    const [descricao, setDescricao] = useState(caixa ? caixa?.descricao : `Intervalo de caixa do dia ${dataHoraBr()}`);
    const [valorInicial, setValorInicial] = useState<any>(0.00);
    const naviate = useNavigate();

    const handleCaixaChange = async () => {
        if (loading) {
            return;
        }

        if (caixa) {
            await fecharCaixa();
            return naviate(0)
        }

        await abrirCaixa(descricao, parseFloat(valorInicial));
        return handleRefresh();
    }

    useEffect(() => {
        if (ultimoCaixa && !valorInicial) {
            setValorInicial(ultimoCaixa.valorFinal)
            return
        }
        if (caixa) {
            setValorInicial(caixa.valorInicial)
            return
        }
    }, [caixa, ultimoCaixa])

    const { theme } = useThemeContext();

    const itensAgrupados = useMemo(() => {
        if (caixa) {
            const itensConta: IItemConta[] = [];

            caixa.contas.forEach(conta => {
                conta.itens.forEach(item => {
                    const indexItem = itensConta.findIndex(itemConta => itemConta.descricao === item.descricao);

                    if (indexItem === -1) {
                        itensConta.push({ ...item });
                    } else {
                        itensConta[indexItem].valorTotal += item.valorTotal;
                        itensConta[indexItem].quantidade += item.quantidade;
                    }
                });
            });

            return itensConta.sort((a, b) => b.quantidade - a.quantidade);
        }

        return [];
    }, [caixa]);

    const cores = useCores('vibrant', itensAgrupados?.length);

    const { number } = useMoeda();

    const metodosPagamentoAgrupados = useMemo(() => {
        if (caixa) {
            const pagamentosConta: IItemConta[] = [];

            caixa.contas.forEach(conta => {
                conta.pagamentos?.forEach(pagamento => {
                    const indexPagamento = pagamentosConta.findIndex(p => p.descricao === pagamento.metodoPagamento);

                    if (indexPagamento === -1) {
                        if (pagamento.metodoPagamento) {
                            pagamentosConta.push({
                                descricao: pagamento.metodoPagamento,
                                quantidade: 1,
                                valorTotal: number(pagamento.valor as any) ?? 0
                            });
                        } else {
                            const indexIndefinido = pagamentosConta.findIndex(p => p.descricao === "Indefinido");
                            if (indexIndefinido !== -1) {
                                pagamentosConta[indexIndefinido].valorTotal += number(pagamento.valor as any) ?? 0;
                                pagamentosConta[indexIndefinido].quantidade += 1;
                            } else {
                                pagamentosConta.push({
                                    descricao: "Indefinido",
                                    quantidade: 1,
                                    valorTotal: number(pagamento.valor as any) ?? 0
                                });
                            }
                        }
                    } else {
                        pagamentosConta[indexPagamento].valorTotal += number(pagamento.valor as any) ?? 0;
                        pagamentosConta[indexPagamento].quantidade += 1;
                    }
                });
            });

            return pagamentosConta.sort((a, b) => b.quantidade - a.quantidade);
        }

        return [];
    }, [caixa]);

    const subitensAgrupados = useMemo(() => {
        if (caixa) {
            const subitensConta: IItemConta[] = [];

            caixa.contas.map(conta => {
                conta.itens.map(item => {
                    item.subitens?.map(subitem => {
                        const indexSubitem = subitensConta.findIndex(subitemConta => subitemConta.descricao === subitem.descricao);

                        if (indexSubitem === -1) {
                            subitensConta.push({
                                descricao: subitem.descricao,
                                quantidade: (subitem.quantidade * item.quantidade),
                                valorTotal: ((subitem.valor * subitem.quantidade) * item.quantidade)
                            })
                        } else {
                            subitensConta[indexSubitem].quantidade += (subitem.quantidade * item.quantidade)
                            subitensConta[indexSubitem].valorTotal += ((subitem.valor * subitem.quantidade) * item.quantidade)
                        }
                    })
                })
            })

            return subitensConta.sort((a, b) => b.quantidade - a.quantidade);
        }


        return [];
    }, [caixa]);

    const navigate = useNavigate();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if (perfil && !verificarAcessoFuncionalidade("Gestão de caixa")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])


    const CustomToolTip = (props: any) => {
        const { active, payload, label } = props;

        return (
            <Box>
                {payload?.map((item: any, i: number) => {
                    return (
                        <Typography variant="subtitle2" fontWeight={"bold"}>
                            {real(item.value)}
                        </Typography>
                    );
                })}
            </Box>
        );
    };

    return (
        <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            maxWidth={"95%"}
            margin={"0 auto"}
        >
            <Grid item xs={12} sm={6} mt={2} display={"flex"} justifyContent={"center"}>
                {caixa ? (
                    <Alert elevation={1} severity="success" sx={{ width: "100%", height: "100px" }}>
                        <AlertTitle>Caixa aberto</AlertTitle>
                        Estabelecimento atendendo <strong>pedidos</strong>
                    </Alert>
                ) : (
                    <Alert elevation={1} severity="error" sx={{ width: "100%", height: "100px" }}>
                        <AlertTitle>Caixa fechado</AlertTitle>
                        Estabelecimento não atendendo <strong>pedidos</strong>
                    </Alert>
                )}
            </Grid>
            <Grid item xs={12} sm={6} mt={2} display={"flex"} justifyContent={"center"}>
                <Paper sx={{ width: "100%", padding: "1rem", height: "100px" }}>
                    {caixa ? (
                        <Typography
                            variant="h6"
                            fontWeight={"bold"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            {descricao}
                        </Typography>
                    ) : (
                        <FormCaixaContainer>
                            <TextField
                                label="Descrição"
                                variant='outlined'
                                sx={{ marginRight: "10px" }}
                                onChange={(e) => setDescricao(e.target.value)}
                                value={descricao}
                            />
                            <TextField
                                label="Valor inicial"
                                value={valorInicial}
                                onChange={(e) => setValorInicial(e.target.value)}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: RealInput as any,
                                }}
                            />
                        </FormCaixaContainer>
                    )}
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} mt={2} display={"flex"} justifyContent={"center"}>
                        <CardIcon
                            titulo={
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {real(valorInicial)}
                                </Typography>
                            }
                            subtitulo={
                                <Typography>
                                    Início do dia
                                </Typography>
                            }
                            icone={<WalletIcon />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} mt={2} display={"flex"} justifyContent={"center"}>
                        <CardIcon
                            titulo={
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {caixa ? real(caixa.valorFinal) : "R$ 0,00"}
                                </Typography>
                            }
                            subtitulo={
                                <Typography>
                                    Agora
                                </Typography>
                            }
                            icone={<ShowChartIcon />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} mt={2} display={"flex"} justifyContent={"center"}>
                        <CardIcon
                            titulo={
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {caixa ? `Aberto às ${horaBr(caixa.abertoAs)}` : "-"}
                                </Typography>
                            }
                            subtitulo={
                                <Button
                                    onClick={handleCaixaChange}
                                    variant='contained'
                                >
                                    {loading ? <CircularProgress /> : caixa ? "Fechar caixa" : "Abrir caixa"}
                                </Button>
                            }
                            icone={<ScheduleIcon />}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <CardIconTop
                            titulo='Contas a receber'
                            conteudo={
                                <Typography variant="h4" fontWeight={"bold"}>
                                    {isLoadingContasAReceber ? (
                                        <CircularProgress color="primary" />
                                    ) :
                                        <>{real(totalContasAReceber ?? 0.00)}</>
                                    }
                                </Typography>
                            }
                            icone={
                                <CallReceived sx={{
                                    color: theme.palette.success.main
                                }} />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CardIconTop
                            titulo='Contas a pagar'
                            conteudo={
                                <Typography variant="h4" fontWeight={"bold"}>
                                    {isLoadingContasAPagar ? (
                                        <CircularProgress color="primary" />
                                    ) :
                                        <>{real(totalContasAPagar ?? 0.00)}</>
                                    }
                                </Typography>
                            }
                            icone={
                                <CallMade sx={{
                                    color: theme.palette.error.main
                                }} />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CardIconTop
                            titulo='Número de vendas'
                            conteudo={
                                <Typography variant="h4" fontWeight={"bold"}>
                                    {isLoading ? (
                                        <CircularProgress color="primary" />
                                    ) :
                                        <>{caixa?.contas.length ?? 0}</>
                                    }
                                </Typography>
                            }
                            icone={
                                <LocalOffer sx={{
                                    color: theme.palette.primary.main
                                }} />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12} mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ padding: "0.5rem", height: "400px", overflowY: "auto" }}>
                            {caixa && caixa.contas?.length ? (
                                <TableContainer>
                                    <Toolbar
                                        sx={{
                                            pl: { sm: 2 },
                                            pr: { xs: 1, sm: 1 },
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Itens mais vendidos
                                        </Typography>
                                    </Toolbar>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="20%">Descrição</TableCell>
                                                <TableCell width="10%">Quantidade</TableCell>
                                                <TableCell width="10%">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {itensAgrupados.map(item => {
                                                return (
                                                    <TableRow>
                                                        <TableCell width="20%">{item.descricao}</TableCell>
                                                        <TableCell width="10%">{item.quantidade}</TableCell>
                                                        <TableCell width="10%">{real(item.valorTotal)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography sx={{ padding: "1rem" }} align='center'>
                                    Nenhum item registrado
                                </Typography>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper sx={{ padding: "0.5rem", height: "400px", overflowY: "auto" }}>
                            {caixa && caixa.contas?.length ? (
                                <TableContainer>
                                    <Toolbar
                                        sx={{
                                            pl: { sm: 2 },
                                            pr: { xs: 1, sm: 1 },
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Subitens mais vendidos
                                        </Typography>
                                    </Toolbar>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="20%">Descrição</TableCell>
                                                <TableCell width="10%">Quantidade</TableCell>
                                                <TableCell width="10%">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subitensAgrupados.map(subitem => {
                                                return (
                                                    <TableRow>
                                                        <TableCell width="20%">{subitem.descricao}</TableCell>
                                                        <TableCell width="10%">{subitem.quantidade}</TableCell>
                                                        <TableCell width="10%">{real(subitem.valorTotal)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography sx={{ padding: "1rem" }} align='center'>
                                    Nenhum subitem registrado
                                </Typography>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper sx={{ padding: "0.5rem", height: "400px", overflowY: "auto" }}>
                            {caixa && caixa.contas?.length ? (
                                <TableContainer>
                                    <Toolbar
                                        sx={{
                                            pl: { sm: 2 },
                                            pr: { xs: 1, sm: 1 },
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Principais métodos de pagamento
                                        </Typography>
                                    </Toolbar>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="20%">Descrição</TableCell>
                                                <TableCell width="10%">Quantidade</TableCell>
                                                <TableCell width="10%">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {metodosPagamentoAgrupados.map(metodoPagamento => {
                                                return (
                                                    <TableRow>
                                                        <TableCell width="20%">{metodoPagamento.descricao}</TableCell>
                                                        <TableCell width="10%">{metodoPagamento.quantidade}</TableCell>
                                                        <TableCell width="10%">{real(metodoPagamento.valorTotal)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography sx={{ padding: "1rem" }} align='center'>
                                    Nenhum método de pagamento registrado
                                </Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <Paper sx={{ padding: "0.5rem", height: "500px", overflowY: "auto" }}>
                            <Typography sx={{ padding: "1rem", position: "absolute" }} variant="subtitle1" fontWeight="bold">
                                Itens mais vendidos
                            </Typography>
                            <ResponsiveContainer width={"100%"} height={"100%"}>
                                <PieChart>
                                    <Pie
                                        data={itensAgrupados.slice(0, 5)}
                                        dataKey="quantidade"
                                        nameKey="descricao"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        label={({ name }) => name.length > 10 ? `${name.substring(0, 10)}...` : name}
                                    >
                                        {itensAgrupados.slice(0, 5).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={cores[index]} />
                                        ))}
                                    </Pie>
                                    <Tooltip formatter={(value: number) => `${value} unidades`} />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper sx={{ padding: "0.5rem", height: "500px", overflowY: "auto" }}>
                            <Typography sx={{ padding: "1rem", position: "absolute" }} variant="subtitle1" fontWeight="bold">
                                Principais métodos de pagamento
                            </Typography>
                            <ResponsiveContainer width={"100%"} height={"100%"}>
                                <PieChart>
                                    <Pie
                                        data={metodosPagamentoAgrupados.slice(0, 5)}
                                        dataKey="valorTotal"
                                        nameKey="descricao"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        label={({ name }) => name.length > 10 ? `${name.substring(0, 10)}...` : name}
                                    >
                                        {metodosPagamentoAgrupados.slice(0, 5).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={cores[index]} />
                                        ))}
                                    </Pie>
                                    <Tooltip formatter={(value) => real(value as string)} />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item xs={12}>
                <Paper sx={{ marginTop: "60px", padding: "0.5rem" }}>
                    {caixa && caixa.contas?.length ? (
                        <TableContainer sx={{ minWidth: 350 }}>
                            <Toolbar
                                sx={{
                                    pl: { sm: 2 },
                                    pr: { xs: 1, sm: 1 },
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                <Typography variant="h6" fontWeight={"800"}>
                                    Contas
                                </Typography>
                            </Toolbar>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Descrição</TableCell>
                                        <TableCell>Data/Hora</TableCell>
                                        <TableCell>Valor total</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Pagamentos</TableCell>
                                        <TableCell>Itens</TableCell>
                                        <TableCell>Visualizar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {caixa && caixa.contas.map(conta => <CaixaTableRow conta={conta} />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography sx={{ padding: "1rem" }} align='center'>
                            Nenhuma conta registrada no intervalo
                        </Typography>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
}