import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IComplemento } from "../../interfaces/IComplemento";
import { ButtonsContainer, Container, Formulario, NumerosContainer } from "./styles";
import { NumerosInput } from "../../../core/components/NumerosInput";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { v4 } from "uuid";
import { Add } from "@mui/icons-material";
import { ItensComplementosContainer } from "./containers/ItensComplementoContainer";
import { IITemComplemento } from "../../interfaces/IITemComplemento";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { complementoSchema } from "../../schema/complementoSchema";


interface CadastrarComplementoContainer {
    salvar: (complemento: IComplemento) => void;
    excluir: (uuid: string) => void;
    voltar: () => void;
    complemento?: IComplemento;
}

export const CadastrarComplementoContainer = ({ salvar, voltar, complemento, excluir }: CadastrarComplementoContainer) => {
    const snackbar = useSnackbar();
    const [confirmacao, setConfirmacao] = useState('');
    const [exclusao, setExclusao] = useState(false);
    const [uuid, setUuid] = useState<string | undefined>(undefined);
    const [obrigatorio, setObrigatorio] = useState<boolean>(false);
    const [itens, setItens] = useState<IITemComplemento[]>([]);
    const [numeroMaximo, setNumeroMaximo] = useState(10);
    const [numeroMinimo, setNumeroMinimo] = useState(0);

    const abrirExclusao = () => setExclusao(true);
    const fecharExclusao = () => {
        setExclusao(false);
        setConfirmacao("");
    }
    const adicionarMinimo = () => setNumeroMinimo(numeroMinimo + 1);
    const adicionarMaximo = () => setNumeroMaximo(numeroMaximo + 1);

    const { reset, register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange",
        resolver: yupResolver(complementoSchema)
    });

    const removerMinimo = () => {
        if ((numeroMinimo - 1) < 0) {
            return snackbar("O número mínimo não pode ser negativo", { severity: "error" })
        }

        if (obrigatorio && (numeroMinimo - 1) < 1) {
            return snackbar("Com o modo obrigatório ativo ao menos um item deverá ser selecionado", { severity: "error" })
        }

        setNumeroMinimo(numeroMinimo - 1)
    };
    const removerMaximo = () => {
        if ((numeroMaximo - 1) < 0) {
            return snackbar("O número máximo não pode ser negativo", { severity: "error" })
        }

        setNumeroMaximo(numeroMaximo - 1)
    };

    const handleObrigatorioChange = () => setObrigatorio(!obrigatorio);

    const salvarItem = (item: IITemComplemento) => {
        setItens((itensArray) => {
            const index = itensArray.findIndex(i => i.uuid === item.uuid);
            if (index !== -1) {
                const updatedItens = [...itensArray];
                updatedItens[index] = item;
                return updatedItens;
            } else {
                return [...itensArray, item];
            }
        });
    };

    const excluirItem = (uuid: string) => {
        const index = itens.findIndex(item => item.uuid === uuid);

        if (index === -1) {
            return snackbar("Item não encontrado", { severity: "error" });
        }

        const novosItens = itens;
        novosItens.splice(index, 1);
        setItens([...novosItens]);
    }

    const handleVoltar = () => {
        setValue("descricao", "");
        setUuid(undefined);
        setObrigatorio(false);
        setItens([]);
        setNumeroMaximo(10);
        setNumeroMinimo(1);
        fecharExclusao();
        voltar();
    }

    const handleSalvar = ({ descricao }: FieldValues) => {
        if (!descricao) {
            return snackbar("Descrição inválida", { severity: "error" });
        }

        salvar({
            numeroMinimoItens: numeroMinimo,
            numeroMaximoItens: numeroMaximo,
            uuid: uuid ?? v4(),
            obrigatorio,
            descricao,
            itens
        })

        handleVoltar();
    }

    const handleExcluir = () => {
        if (!uuid) {
            return snackbar("Complemento inválido", { severity: "error" });
        }

        excluir(uuid);
        handleVoltar();
    }

    useEffect(() => {
        if (obrigatorio) {
            if (numeroMinimo <= 0) {
                setNumeroMinimo(1);
            }
        }
    }, [obrigatorio])

    useEffect(() => {
        if (complemento) {
            setItens(complemento.itens ?? []);
            setValue("descricao", complemento.descricao ?? undefined);
            setObrigatorio(complemento.obrigatorio ?? false);
            setNumeroMinimo(complemento.numeroMinimoItens ?? 0);
            setNumeroMaximo(complemento.numeroMaximoItens ?? 0);
            setUuid(complemento.uuid);
        }
    }, [complemento])

    return (
        <Container>
            <Dialog
                open={exclusao}
                onClose={fecharExclusao}
            >
                <DialogTitle>
                    Excluir complemento: {complemento?.descricao}
                </DialogTitle>
                <DialogContent>
                    <Alert severity="warning">
                        A exclusão não pode ser revertida
                    </Alert>
                    <Typography sx={{ marginTop: "1rem" }}>
                        Para confirmar a exclusão, digite <b>{complemento?.descricao}</b>  no campo.
                    </Typography>
                    <TextField
                        value={confirmacao}
                        onChange={(e) => setConfirmacao(e.target.value)}
                        sx={{ width: "100%", marginTop: "0.5rem" }}
                        size="small"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharExclusao}>Cancelar</Button>
                    <Button
                        variant="contained"
                        onClick={handleExcluir}
                        disabled={complemento?.descricao !== confirmacao}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Formulario onSubmit={handleSubmit(handleSalvar)}>
                <Typography
                    variant="h6"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                >
                    {complemento?.uuid ? "Editar" : "Cadastrar"} complemento
                </Typography>
                <TextField
                    label="Nome"
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    {...register("descricao")}
                    placeholder="Ex: Adicionais, Complementos, etc..."
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.descricao}
                    helperText={<>{errors.descricao?.message}</>}
                />
                <FormControl sx={{ marginBottom: "1rem" }}>
                    <FormLabel id="preenchimento-obrigatorio-label">Preenchimento obrigatório</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="preenchimento-obrigatorio-label"
                        name="preenchimento-obrigatorio-group"
                        value={obrigatorio}
                        onChange={handleObrigatorioChange}
                    >
                        <FormControlLabel value={false} control={<Radio />} label={"Não"} />
                        <FormControlLabel value={true} control={<Radio />} label={"Sim"} />
                    </RadioGroup>
                </FormControl>
                <NumerosContainer variant="outlined">
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <FormControl sx={{ mr: "2rem" }}>
                            <FormLabel sx={{ marginBottom: "0.5rem" }}>Mínimo</FormLabel>
                            <NumerosInput
                                valor={numeroMinimo}
                                adicionar={adicionarMinimo}
                                remover={removerMinimo}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel sx={{ marginBottom: "1rem" }}>Máximo</FormLabel>
                            <NumerosInput
                                valor={numeroMaximo}
                                adicionar={adicionarMaximo}
                                remover={removerMaximo}
                            />
                        </FormControl>
                    </Box>
                    <Alert severity="info" sx={{ marginTop: "0.5rem" }}>
                        O número máximo e o número mínimo de itens serão utilizados na regra de montagem do produto
                    </Alert>
                </NumerosContainer>
                <ItensComplementosContainer
                    salvar={salvarItem}
                    excluir={excluirItem}
                    itens={itens}
                />
                <ButtonsContainer>
                    <Button
                        variant="outlined"
                        onClick={handleVoltar}
                    >
                        Cancelar
                    </Button>
                    <Box>
                        {uuid && (
                            <Button
                                variant="contained"
                                color={"error"}
                                onClick={abrirExclusao}
                                sx={{ mr: "0.5rem" }}
                            >
                                Excluir
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            Salvar
                        </Button>
                    </Box>
                </ButtonsContainer>
            </Formulario>
        </Container>
    );
}