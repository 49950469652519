import { Grid, Paper } from "@mui/material";
import { IProdutoPedido } from "../../../pedidos/interface/IProdutoPedido";
import { ICategoria } from "../../interfaces/ICategoria";
import { IProduto } from "../../interfaces/IProduto";
import { CardProdutoCarrinhoOperacao } from "../../components/CardProdutoCarrinhoOperacao";
import { useOperacaoContext } from "../../../pedidos/contexts/OperacaoContext";
import { TipoPedido } from "../../../pedidos/enum/TipoPedido";

interface ProdutosCarrinhoContainerProps {
    categorias: ICategoria[];
    produtosCarrinho: IProdutoPedido[];
    aumentarQuantidade: (produtoUuid: string) => void;
    diminuirQuantidade: (produtoUuid: string) => void;
    removerProduto: (produtoUuid: string) => void;
    editarProduto: (produto: IProdutoPedido) => void;
}

export const ProdutosCarrinhoContainer = ({ categorias, produtosCarrinho, aumentarQuantidade, diminuirQuantidade, editarProduto, removerProduto }: ProdutosCarrinhoContainerProps) => {
    const { selecionarProduto, produtosSelecionados, tipo } = useOperacaoContext();
    return (
        <Grid
            container
            spacing={2}
            sx={{
                padding: "1rem",
            }}
        >
            {produtosCarrinho?.map(produtoCarrinho => {
                let produtoOriginal: IProduto | undefined = undefined;

                categorias.map(categoria => {
                    if (!produtoOriginal) {
                        produtoOriginal = categoria.produtos?.find(produto => produto.uuid === produtoCarrinho.uuidProdutoReferencia);
                    }
                })

                return (
                    <Grid item xs={6} md={4} lg={(12 / 5)} key={produtoCarrinho.uuid}>
                        <CardProdutoCarrinhoOperacao
                            produto={produtoCarrinho}
                            aumentarQuantidade={aumentarQuantidade}
                            diminuirQuantidade={diminuirQuantidade}
                            removerProduto={removerProduto}
                            editarProduto={editarProduto}
                            produtoOriginal={produtoOriginal}
                            selecionar={tipo == TipoPedido.mesa ? selecionarProduto : undefined}
                            selecionado={produtosSelecionados.find(produtoSelecionado => produtoSelecionado.uuid == produtoCarrinho.uuid) ? true : false}
                        />
                    </Grid>
                )
            })}
        </Grid>
    );
}