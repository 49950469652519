import { useState } from "react";
import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch";
import { IFiltro } from "../../core/interfaces/IFiltro";
import { IDadosPaginacaoIntervaloCaixa } from "../interfaces/IDadosPaginacaoIntervaloCaixa";

export const useFetchInvervalosCaixa = () => {
    const [paginaAtual, setPaginaAtual] = useState(1);
    let urlInicial = `/caixa/intervalo?paginaAtual=${paginaAtual}`;
    const [url, setUrl] = useState<string>(urlInicial);
    const { data: dados, error, isLoading } = useFetch<IDadosPaginacaoIntervaloCaixa>(ApiFactory.getApi("MS_FINANCEIRO"), url);

    const handlePaginaChange = (event: unknown, newPage: number) => {
        setUrl(url.replace(/(paginaAtual=)\d+/, `paginaAtual=${newPage}`));
        setPaginaAtual(newPage);
    }

    const filtrar = (filtros: IFiltro[]) => {
        let filtrosUrl = urlInicial;

        filtros.map(filtro => {
            filtrosUrl = `${filtrosUrl}&${filtro.chave}=${filtro.valor}`;
        })

        setUrl(filtrosUrl);
        setPaginaAtual(1);
    }

    const limpar = () => {
        setUrl(urlInicial);
    }

    return { dados, error, isLoading, handlePaginaChange, filtrar, limpar }
}