import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const DetalhesContainer = styled(Paper)`
    height: 83vh;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
`

export const HeaderDetalhesContainer = styled.div`
    display: flex;
    width: 100%;
    height: 90px;
    align-items: flex-start;
    justify-content: flex-start;

    div {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-left: 1rem;
    }
`

export const BodyDetalhesContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`