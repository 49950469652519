export enum MIMETypes {
    'text/plain' = 'text/plain',
    'text/html' = 'text/html',
    'application/json' = 'application/json',
    'application/xml' = 'application/xml',
    'application/pdf' = 'application/pdf',
    'image/jpeg' = 'image/jpeg',
    'image/jpg' = 'image/jpg',
    'image/png' = 'image/png',
}

export enum MIMETypesDescricao {
    'text/plain' = 'PLAIN',
    'text/html' = 'HTML',
    'application/json' = 'JSON',
    'application/xml' = 'XML',
    'application/pdf' = 'PDF',
    'image/jpeg' = 'JPEG',
    'image/jpg' = 'JPG',
    'image/png' = 'PNG',
}