import { Breadcrumbs, Typography, Link, AccordionSummary, AccordionDetails, TextField, Accordion, FormControl, Button, CircularProgress, InputAdornment, FilledInput, FormLabel, FormHelperText, OutlinedInput, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAcoesInsumosContext } from "../../contexts/AcoesInsumosContext";
import { useInsumos } from "../../hooks/useInsumos";
import { IControleEstoqueInsumo } from "../../interfaces/IControleEstoqueInsumo";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper } from "./styles";
import { useUnidade } from "../../../core/hooks/useUnidade";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";

interface IInsumoResumo {
    nome: string;
    quantidadeAnterior: number;
    quantidadeReabastecida: number;
    quantidadeFinal: number;
}

export const ReabastecerInsumos = () => {
    const { insumosSelecionados } = useAcoesInsumosContext();
    const [inputs, setInputs] = useState<{ [key: string]: string }>({}); // Armazena as entradas do usuário como strings
    const [errors, setErrors] = useState<{ [key: string]: string | null }>({}); // Armazena os erros de validação
    const { reabastecer, loading } = useInsumos();
    const navigate = useNavigate();

    const resumo = useMemo(() => {
        return insumosSelecionados.map(insumo => {
            const quantidadeReabastecida = parseFloat(inputs[insumo.uuid]) || 0;
            const quantidadeFinal = parseFloat(((insumo.quantidade || 0) + quantidadeReabastecida).toFixed(3));

            return {
                nome: insumo.nome,
                quantidadeAnterior: insumo.quantidade || 0,
                quantidadeReabastecida,
                quantidadeFinal,
                unidade: insumo.unidade
            };
        });
    }, [inputs, insumosSelecionados]);

    const handleInputChange = (uuid: string, value: string) => {
        const formattedValue = value.replace(',', '.');
        const isValid = /^-?\d*(\.\d{0,3})?$/.test(formattedValue);
    
        if (isValid) {
            const newInputs = { ...inputs, [uuid]: formattedValue };
            setInputs(newInputs);
            if (errors[uuid]) {
                setErrors({ ...errors, [uuid]: null });
            }
        }
    };


    const validateInput = (value: string): boolean => {
        if (!value) {
            return false;
        }

        const numValue = parseFloat(value);
        return !isNaN(numValue) && numValue > 0;
    };


    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
    
        let hasErrors = false;
        const newErrors = { ...errors };
        const dadosReabastecimento: IControleEstoqueInsumo[] = [];
    
        insumosSelecionados.forEach(({ uuid }) => {
            const value = inputs[uuid] || '';
            if (!validateInput(value)) {
                newErrors[uuid] = 'Insira um número válido (maior que 0)';
                hasErrors = true;
            } else {
                dadosReabastecimento.push({ uuid, quantidade: parseFloat(value) });
            }
        });
    
        setErrors(newErrors);
        if (hasErrors) {
            return;
        }

        const resultado = await reabastecer(dadosReabastecimento);
        if(resultado) {
            navigate("/cardapio/estoque/insumos");
        }
    };

    const { formatar } = useUnidade();

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Controle de estoque completo")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/cardapio/estoque/insumos">
                    Insumos e ingredientes
                </Link>
                <Typography color="text.primary">Reabastecer estoque</Typography>
            </Breadcrumbs>
            <form style={{ width: "100%", height: "100%" }} onSubmit={handleSubmit}>
                <ContentContainer>
                    <FormContainer>
                        <Paper sx={{
                            padding: "1rem",
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            maxHeight: "80vh",
                            overflowY: "auto"
                        }}>
                            <Typography variant="h6">
                                Estoque de insumos
                            </Typography>
                            {insumosSelecionados.map(insumo => (
                                <FormControl
                                    fullWidth
                                    key={insumo.uuid}
                                    error={!!errors[insumo.uuid]}
                                    sx={{ mt: 2 }}
                                >
                                    <FormLabel>{insumo.nome}</FormLabel>
                                    <OutlinedInput
                                        endAdornment={<InputAdornment position="end">{insumo.unidade}</InputAdornment>}
                                        type="number"
                                        value={inputs[insumo.uuid] || ''}
                                        onChange={(e) => handleInputChange(insumo.uuid, e.target.value)}
                                        error={!!errors[insumo.uuid]}
                                        placeholder="Digite a quantidade reabastecida"
                                    />
                                    {errors[insumo.uuid] && <FormHelperText>{errors[insumo.uuid]}</FormHelperText>}
                                </FormControl>
                            ))}
                        </Paper>
                    </FormContainer>
                    <ResumoPaper>
                        <TableContainer variant="outlined" component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right">Qtd. atual</TableCell>
                                        <TableCell align="right">Qtd. reabastecida</TableCell>
                                        <TableCell align="right">Qtd. final</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resumo.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.nome}
                                            </TableCell>
                                            <TableCell align="right">{formatar(row.quantidadeAnterior, row.unidade)}</TableCell>
                                            <TableCell align="right">{formatar(row.quantidadeReabastecida, row.unidade)}</TableCell>
                                            <TableCell align="right">{formatar(row.quantidadeFinal, row.unidade)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ButtonsContainer>
                            <Button size="large" variant="outlined" onClick={() => navigate("/cardapio/estoque/insumos")}>
                                Cancelar
                            </Button>
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button size="large" variant="contained" type="submit">
                                    Reabastecer
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
        </PageContainer>
    );
}