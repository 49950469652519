import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const PaperResumo = styled(Paper)`
    padding: 1rem;
    overflow-y: auto;
    height: 100%;

    @media(max-width: 800px) {
        overflow-y: hidden;
    }
`

export const PaperMontagem = styled(Paper)`
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media(max-width: 800px) {
        overflow-y: hidden;
    }
`