import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch";
import { IInsumo } from "../interfaces/IInsumo";

export const useFetchInsumos = () => {
    const apiProdutos = ApiFactory.getApi("MS_PRODUTOS")
    const { 
        data: insumos, 
        error, 
        isLoading, 
        handleRefresh, 
        isFetching 
    } = useFetch<IInsumo[]>(apiProdutos, '/insumos/');

    return { insumos, error, isLoading, handleRefresh, isFetching }
}