import styled from "@emotion/styled";
import { Card, Grid } from "@mui/material";

export const DashboardGrid = styled(Grid)`
    display: flex;
    justify-content: center;
`

export const DashboardCard = styled(Card)`
    width: 400px;
    height: 160px;
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    div:last-child {
        display: flex;
        align-items: center;
        height: 100%;
        
        img {
            width: 120px;
            height: 120px;
        }
    }
`