import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Card, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useCadastrarEditarProdutoContext } from "../../contexts/CadastrarEditarProdutoContext";
import { IBorda } from "../../interfaces/IBorda";
import { bordaSchema } from "../../schema/bordaSchema";
import { ButtonsContainer, ButtonsContainerForm, ConfiguracaoBordas, FiltroContainer, FormularioCard, TabelaVazia } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTamanhoTela } from '../../../core/hooks/useTamanhoTela';
import { ControleEstoqueContainer } from '../ControleEstoqueContainer';
import { FichaTecnica } from '../FichaTecnica';
import { FormularioBorda } from './containers/FormularioBorda';

type Tabs = "tabela" | "formulario";

interface ConfiguracaoBordasContainerProps { }

export const ConfiguracaoBordasContainer = ({ }: ConfiguracaoBordasContainerProps) => {
    const [filtroBordas, setFiltroBordas] = useState("");
    const { bordas, adicionarBorda, excluirBorda, editarBorda, handleProximo, handleVoltar } = useCadastrarEditarProdutoContext();
    const [tab, setTab] = useState<Tabs>("tabela");
    const [bordaEdicao, setBordaEdicao] = useState<IBorda | undefined>(undefined)
    const [confirmacaoExclusao, setConfirmacaoExclusao] = useState<string | undefined>(undefined);
    const { real } = useMoeda();
    const { modeloTela } = useTamanhoTela();

    const handleTab = (tab: Tabs) => {
        setTab(tab)
        setBordaEdicao(undefined);
    }

    const handleExcluirBorda = async (borda: IBorda) => {
        excluirBorda(borda);
        setConfirmacaoExclusao(undefined);
        handleTab("tabela");
    }

    const handleAbrirEdicao = (borda: IBorda) => {
        setTab("formulario");
        setBordaEdicao(borda);
    }

    const renderizarLinha = (borda: IBorda) => {
        return (
            <TableRow>
                <TableCell align="center">{borda.descricao}</TableCell>
                <TableCell align="center">{real(borda.preco)}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => handleAbrirEdicao(borda)} aria-label="delete" size="small">
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    {confirmacaoExclusao && confirmacaoExclusao == borda.uuid ? (
                        <IconButton onClick={() => handleExcluirBorda(borda)} aria-label="delete" size="small">
                            <CheckIcon fontSize="inherit" />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => setConfirmacaoExclusao(borda.uuid)} aria-label="delete" size="small">
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
        );
    }

    return (
        <ConfiguracaoBordas>
            {tab == "tabela" && (
                <TableContainer variant='outlined' component={Paper} sx={{
                    width: "100%",
                    maxHeight: 450,
                    height: "100%",
                    minHeight: 350
                }}>
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <Typography variant="h6">
                            Bordas
                        </Typography>
                        <FiltroContainer>
                            {modeloTela == "desktop" && (
                                <TextField
                                    placeholder='Nome'
                                    size='small'
                                    value={filtroBordas}
                                    onChange={(e) => setFiltroBordas(e.target.value)}
                                    sx={{ width: "200px" }}
                                />
                            )}
                            <Tooltip title='Crie uma nova borda'>
                                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => handleTab("formulario")}>
                                    Cadastrar
                                </Button>
                            </Tooltip>
                        </FiltroContainer>
                    </Toolbar>
                    {bordas.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Preço</TableCell>
                                    <TableCell align="center">Editar</TableCell>
                                    <TableCell align="center">Excluir</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filtroBordas
                                    ? bordas.filter(borda => borda.descricao.toLowerCase().includes(filtroBordas.toLocaleLowerCase())).map(borda => {
                                        return renderizarLinha(borda);
                                    })
                                    : bordas.map(borda => {
                                        return renderizarLinha(borda);
                                    })}

                            </TableBody>
                        </Table>
                    ) : (
                        <TabelaVazia>
                            Nenhuma borda cadastrada
                        </TabelaVazia>
                    )}
                </TableContainer>
            )}
            {tab == "formulario" && <FormularioBorda 
                adicionarBorda={adicionarBorda}
                editarBorda={editarBorda}
                handleTab={handleTab}
                bordaEdicao={bordaEdicao}
            />}
            {tab == "tabela" && (
                <ButtonsContainerForm>
                    <Button size="medium" variant="outlined" onClick={handleVoltar}>
                        Voltar
                    </Button>
                    <Button size="medium" variant="contained" onClick={handleProximo}>
                        Próximo
                    </Button>
                </ButtonsContainerForm>
            )}
        </ConfiguracaoBordas>
    )
}