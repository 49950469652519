import { Autocomplete, Button, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useClientes } from "../../hooks/useClientes";
import { ICliente } from "../../interface/ICliente";
import { PaperContainer } from "./styles";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useRegex } from "../../../core/hooks/useRegex";
import { VincularCliente } from "../VincularCliente";
import { CadastrarEditarCliente } from "../CadastrarEditarCliente";
import { FieldValues } from "react-hook-form";

interface CadastrarVincularClienteProps {
  vincularCliente: (cliente: ICliente) => void;
}

export const CadastrarVincularCliente = ({
  vincularCliente,
}: CadastrarVincularClienteProps) => {
  const { loading, salvar } = useClientes();
  const [modo, setModo] = useState<"vincular" | "cadastrar">("vincular");
  const alterarModo = (modo: "vincular" | "cadastrar") => setModo(modo);

  const [novoCliente, setNovoCliente] = useState<ICliente>({
    uuid: "novo",
    cpf: "",
    email: "",
    estabelecimentoUuid: "",
    nome: "",
    telefone: "",
    observacao: ""
  })

  const handleVincularCliente = (cliente: ICliente) => {
    vincularCliente(cliente);
    setNovoCliente({
      uuid: "novo",
      cpf: "",
      email: "",
      estabelecimentoUuid: "",
      nome: "",
      telefone: "",
      observacao: ""
    });
  }

  const handleNovoCliente = (novoCliente: ICliente) => setNovoCliente(novoCliente) 
  const handleCancelar = () => {
    alterarModo("vincular");
  }

  const handleCadastrarCliente = async ({ nome, telefone, cpf, observacao }: FieldValues) => {
    if(loading) {
      return;
    }

    const retornoCadastro = await salvar({ nome, telefone, cpf, observacao });
    if(retornoCadastro && retornoCadastro.cliente && vincularCliente) {
      vincularCliente(retornoCadastro.cliente);
    }
  };


  return (
    <PaperContainer>
      {modo == "vincular" && <VincularCliente alterarModo={alterarModo} vincularCliente={handleVincularCliente} novoCliente={novoCliente} handleNovoCliente={handleNovoCliente} />}
      {modo == "cadastrar" && <CadastrarEditarCliente cancelar={handleCancelar} cadastrar={handleCadastrarCliente} loading={loading} dadosCliente={novoCliente} />}
    </PaperContainer>
  );
};
