import { Routes, Route, Navigate, BrowserRouter, Router } from "react-router-dom";
import { UsuarioRoutes } from "../../usuarios/routes";
import { useAutenticacaoContext } from "../contexts/AutenticacaoContext";
import { AlterarSenha } from "../pages/AlterarSenha";
import { Dashboard } from "../pages/Dashboard";
import { Login } from "./../pages/Login";
import PrivateRoutes from "./PrivateRoutes";
import { PedidosRoutes } from "../../pedidos/routes";
import { FinanceiroRoutes } from "../../financeiro/routes";
import { ProdutosRoutes } from "../../produtos/routes";
import { ClienteRoutes } from "../../clientes/routes";
import { CriarConta } from "../pages/CriarConta";
import { EstabelecimentosRoutes } from "../../estabelecimento/routes";
import { PaginaNaoEncontrada } from "../../pedidos/pages/PaginaNaoEncontrada";
import { NaoAutorizado } from "../../pedidos/pages/NaoAutorizado";
import { MarketplaceRoutes } from "../../marketplace/routes";
import { Layout } from "../components/Layout";

export const CoreRoutes = () => {
    const { autenticado, estabelecimento } = useAutenticacaoContext();

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/criar-conta" element={<CriarConta />} />
            <Route path="/recuperar-senha" element={<AlterarSenha />} />
            <Route path="/estabelecimentos/*" element={<EstabelecimentosRoutes autenticado={autenticado} />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route element={
                <Layout
                    drawer={false}
                    itensMenu={[]}
                    titulo="Dashboard"
                >
                    <PrivateRoutes
                        autenticado={autenticado}
                    />
                </Layout>
            }>
                <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route path="/usuarios/*" element={<UsuarioRoutes autenticado={autenticado} />} />
            <Route path="/pedidos/*" element={<PedidosRoutes autenticado={autenticado} />} />
            <Route path="/financeiro/*" element={<FinanceiroRoutes autenticado={autenticado} />} />
            <Route path="/cardapio/*" element={<ProdutosRoutes autenticado={autenticado} />} />
            <Route path="/clientes/*" element={<ClienteRoutes autenticado={autenticado} />} />
            <Route path="/marketplace/*" element={<MarketplaceRoutes autenticado={autenticado} />} />
            <Route path="/pagina-nao-encontrada" element={<PaginaNaoEncontrada />} />
            <Route path="/nao-autorizado" element={<NaoAutorizado />} />
        </Routes>
    );
} 