import styled from "@emotion/styled";
import { Card } from "@mui/material";

interface CardProps {
    selected?: boolean;
}


export const CardContainer = styled(Card)<CardProps>`
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: 0.3ms;
    cursor: pointer;
    width: 325px;

    ${props => props.selected && `
        background-color: ${props.theme.palette.primary.main + "11"};
        border: 1px solid ${props.theme.palette.primary.main};
        color: ${props.theme.palette.primary.main};
    `}
`;