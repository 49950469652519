import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const Container = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const Formulario = styled.form`
    width: 100%;
`

export const NumerosContainer = styled(Paper)`
    padding: 1rem;
    display: flex;
    flex-direction: column;
`

export const ButtonsContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
`