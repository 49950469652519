import styled from "@emotion/styled";
import { Box, Card, Paper } from "@mui/material";

export const CheckoutPaperContainer = styled(Paper)`
    width: 100%; 
    height: 87vh;
    border-radius: 0px;
    overflow-y: auto;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    border: 0;

    @media(max-width: 800px) {
        padding: 1rem;
        height: 70vh;
    }
`

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
`

export const DrawerTitle = styled(Card)`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.9rem;
    margin: 0 auto;
    z-index: 999;
    background-color: ${props => props.theme.palette.primary.main};
    border-radius: 0;
    color: ${props => props.theme.palette.common.white};
`