import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const Tools = styled(Paper)`
    display: flex; 
    padding: 2rem; 
    height: 70px; 
    justify-content: space-between; 
    align-items: center;
    width: 100%;
    border-radius: 0;

    @media(max-width: 768px) {
        flex-direction: column;
        height: 100%;

        button {
            margin-left: 0;
            margin-top: 1rem;
        }

        div {
            display: flex; 
            flex-direction: column;
            margin-right: 0;
        }
    }

    margin-bottom: 2rem;
`