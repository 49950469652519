import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const ToolbarPaper = styled(Paper)`
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    height: 70px;
    
    @media (max-width: 1280px) {
        height: 100%;
    }
`

export const Tools = styled(Box)`
    width: 70%;
    display: flex;
    justify-content: flex-end;
    
    @media(max-width: 1080px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 1rem;

        button {
            margin: 0;
            width: 150px;
            margin-top: 0.4rem;
        }

        div {
            width: 150px;
        }
    }
`