import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { ButtonsContainer, FormularioCard } from "../../styles";
import { FichaTecnica } from "../../../FichaTecnica";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { bordaSchema } from "../../../../schema/bordaSchema";
import { IBorda } from "../../../../interfaces/IBorda";
import { v4 } from "uuid";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { useEffect, useState } from "react";
import { IInsumo } from "../../../../interfaces/IInsumo";
import { useCadastrarEditarProdutoContext } from "../../../../contexts/CadastrarEditarProdutoContext";
import { TipoControleEstoque } from "../../../../enum/TipoControleEstoque";

interface FormularioBordaProps {
    adicionarBorda: (borda: IBorda) => void;
    editarBorda: (borda: IBorda) => void;
    bordaEdicao?: IBorda;
    handleTab: (tab: "tabela" | "formulario") => void;
}

export const FormularioBorda = ({ adicionarBorda, handleTab, bordaEdicao, editarBorda }: FormularioBordaProps) => {
    const { controleEstoque } = useCadastrarEditarProdutoContext();
    const { number } = useMoeda();

    const [fichaTecnica, setFichaTecnica] = useState<IInsumo[]>([]);

    const { reset, watch, setValue, register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(bordaSchema),
        mode: "onChange",
    });

    const adicionarInsumo = (insumo: IInsumo) => {
        setFichaTecnica(ficha => [...ficha, insumo]);
    }

    const excluirInsumo = (uuid: string) => {
        setFichaTecnica(ficha => ficha.filter(insumo => insumo.uuid !== uuid));
    }

    const handleCadastrarBorda = async (borda: FieldValues) => {
        adicionarBorda({
            descricao: borda.descricao,
            preco: number(borda.preco),
            uuid: v4(),
            fichaTecnica
        });
        handleTab("tabela")
    }

    const handleEditarBorda = async (borda: FieldValues) => {
        if (bordaEdicao) {
            editarBorda({
                descricao: borda.descricao,
                preco: number(borda.preco),
                uuid: bordaEdicao.uuid,
                fichaTecnica
            })
            handleTab("tabela")
        }
    }

    useEffect(() => {
        if (bordaEdicao) {
            setValue("descricao", bordaEdicao.descricao);
            setValue("preco", bordaEdicao.preco);
            setFichaTecnica(bordaEdicao.fichaTecnica ?? []);
        } else {
            reset();
        }
    }, [bordaEdicao])

    const descricao = watch("descricao");
    const preco = watch("preco");

    return (
        <FormularioCard>
            <Typography variant="h6">
                Cadastrar borda
            </Typography>
            <form onSubmit={handleSubmit(bordaEdicao ? handleEditarBorda : handleCadastrarBorda)}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nome"
                    type="text"
                    fullWidth
                    {...register("descricao")}
                    error={!!errors.descricao}
                    helperText={<>{errors.descricao?.message}</>}
                    value={descricao}
                />
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel error={!!errors.preco} htmlFor="preco-borda">Preço</InputLabel>
                    <OutlinedInput
                        id="preco-borda"
                        error={!!errors.preco}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        label="Preço"
                        value={preco}
                        {...register("preco")}
                    />
                    {!!errors.preco && <FormHelperText error={true}><>{errors.preco?.message}</></FormHelperText>}
                </FormControl>
                {controleEstoque == TipoControleEstoque.completo && (
                    <FichaTecnica fichaTecnica={fichaTecnica} adicionarInsumo={adicionarInsumo} excluirInsumo={excluirInsumo} />
                )}
                <ButtonsContainer>
                    <Button variant="outlined" type="button" onClick={() => handleTab("tabela")}>Cancelar</Button>
                    <Button variant="contained" type="submit">{bordaEdicao ? "Atualizar" : "Cadastrar"}</Button>
                </ButtonsContainer>
            </form>
        </FormularioCard>
    );
}