import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const HeaderDrawer = styled(Paper)`
    width: 100%;
    height: 3rem;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
`