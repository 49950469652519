import { Alert, AlertTitle, Box, Button, FormControlLabel, Paper, Switch, TextField, Typography } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace"
import { useMarketplaces } from "../../../../hooks/useMarketplaces";
import { useState } from "react";
import { Edit, Save } from "@mui/icons-material";

interface StatusMarketplaceProps {
    marketplace: IMarketplace
}

export const StatusMarketplacePaper = ({ marketplace }: StatusMarketplaceProps) => {
    const { salvar, loading } = useMarketplaces();

    const [edicaoHabilitada, setEdicaoHabilitada] = useState(false);
    const [mensagem, setMensagem] = useState<string | undefined>(marketplace.configuracao?.status?.mensagem);
    const [aberto, setAberto] = useState<boolean>(marketplace.configuracao?.status?.aberto ?? false);
    const handleEdicaoHabilitada = async () => {
        if (!edicaoHabilitada) {
            return setEdicaoHabilitada(true);
        }

        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                status: {
                    aberto,
                    mensagem
                }
            }
        })
        setEdicaoHabilitada(false);
    }

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography variant="h6">Status</Typography>
                <Button onClick={handleEdicaoHabilitada} endIcon={
                    edicaoHabilitada ? <Save />  : <Edit />
                }>
                    {edicaoHabilitada ? "Salvar" : "Editar"}
                </Button>
            </Box>
            <form>
                <Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
                    <FormControlLabel
                        control={<Switch checked={aberto} />}
                        label={aberto ? "Aberto" : "Fechado"}
                        value={aberto}
                        onChange={(e, v) => setAberto(v)}
                        disabled={!edicaoHabilitada}
                    />
                    <TextField
                        label="Mensagem"
                        value={mensagem}
                        sx={{ marginTop: "1rem" }}
                        onChange={(e) => setMensagem(e.target.value)}
                        disabled={!edicaoHabilitada}
                    />
                    <Alert sx={{ mt: "1rem" }} severity={aberto ? "success" : "error"}>
                        <AlertTitle>{aberto ? "Marketplace aberto" : "Marketplace fechado"}</AlertTitle>
                        {aberto 
                            ? "Disponível para receber novos pedidos" 
                            : "Indisponível para receber novos pedidos"
                        }
                    </Alert>
                </Box>
            </form>
        </Paper>
    );
}