import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Breadcrumbs, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardUploadImagem } from "../../../core/components/CardUploadImagem";
import { useLetras } from "../../../core/hooks/useLetras";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper, ResumoFlex } from "./styles";
import { ContentCopy, ExpandMore, Save } from "@mui/icons-material";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { marketplaceSchema } from "../../schema/marketplaceSchema";
import { FieldValues, useForm } from "react-hook-form";
import { TipoMarketplace, TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo, tiposMarketplaceEmBreve, tiposMarketplaceIndisponivel } from "../../enum/TipoMarketplace";
import { useMarketplaces } from "../../hooks/useMarketplaces";
import { useFetchMarketplaces } from "../../hooks/useFetchMarketplaces";
import { IMarketplace } from "../../interfaces/IMarketplace";
import { v4 } from "uuid";
import { useCopiar } from "../../../core/hooks/useCopiar";

export const CadastrarEditarMarketplace = () => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const [accordionValorMinimoOpen, setAccordionValorMinimoOpen] = useState(true);
    const handleAccordionValorMinimo = () => setAccordionValorMinimoOpen(!accordionValorMinimoOpen);
    const [marketplaceEdicao, setMarketplaceEdicao] = useState<IMarketplace | undefined>(undefined);

    const [openSair, setOpenSair] = useState(false);

    const handleOpenSair = () => setOpenSair(true);
    const handleCloseSair = () => setOpenSair(false);

    const { salvar, loading: loadingSalvar } = useMarketplaces();
    const { marketplaces, loading } = useFetchMarketplaces();
    const { uuid } = useParams();

    useEffect(() => {
        if (uuid && loading == false) {
            const marketplaceEncontrado = marketplaces?.find(marketplace => marketplace.uuid === uuid);
            if (!marketplaceEncontrado) {
                navigate("/marketplace");
                return snackbar("Marketplace não encontrado", { severity: "error" })
            }
            setMarketplaceEdicao(marketplaceEncontrado);
        }
    }, [uuid, marketplaces, loading])

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Gestão de marketplace")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    useEffect(() => {
        if (marketplaceEdicao) {
            setValue("nome", marketplaceEdicao.nome);
            setValue("tipo", marketplaceEdicao.tipo);
            if (marketplaceEdicao.descricao) {
                setValue("descricao", marketplaceEdicao.descricao);
            }

            if (marketplaceEdicao.configuracao?.slug) {
                setValue("slug", marketplaceEdicao.configuracao?.slug);
            }

            if (marketplaceEdicao.configuracao?.nomePublico) {
                setValue("nomePublico", marketplaceEdicao.configuracao?.nomePublico);
            }

            if (marketplaceEdicao.configuracao?.telefone) {
                setValue("telefone", marketplaceEdicao.configuracao?.telefone);
            }

            if (marketplaceEdicao.configuracao?.valorMinimo) {
                setValue("valorMinimo", marketplaceEdicao.configuracao?.valorMinimo);
            }
        }
    }, [marketplaceEdicao])

    const { real, number } = useMoeda();

    const handleSalvarMarketplace = async ({ tipo, nome, descricao, nomePublico, slug, telefone, valorMinimo }: FieldValues) => {
        const marketplace = await salvar({
            uuid,
            tipo,
            nome,
            descricao,
            configuracao: {
                status: {
                    aberto: false
                },
                nomePublico,
                slug,
                telefone,
                valorMinimo: number(valorMinimo),
                ...marketplaceEdicao?.configuracao
            }
        });

        if (marketplace?.uuid) {
            window.location.href = `/marketplace/${marketplace.uuid}`
        }
    }

    const { setValue, setError, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(marketplaceSchema),
        mode: "onChange",
    });

    const urlExterna = `https://pedir.spacefood.com.br/${watch('slug')}`;

    const copiar = useCopiar(
        urlExterna ?? "Link não mapeado",
        "Link copiado para a área de transferência!"
    );

    const permissaoIfood = verificarAcessoFuncionalidade('Marketplace iFood');
    const permissaoCardapioOnline = verificarAcessoFuncionalidade('Marketplace Cardápio Online');

    const tiposOptions = Object.keys(TipoMarketplace).map((tipo) => {
        let chip = <Chip label="Disponível" color="success" size="small" sx={{ ml: 1 }} />;
        let disabled = false;

        if (tiposMarketplaceEmBreve.includes(tipo as TipoMarketplace)) {
            chip = <Chip label="Em breve" color="warning" size="small" sx={{ ml: 1 }} />;
            disabled = true;
        } else if (tiposMarketplaceIndisponivel.includes(tipo as TipoMarketplace)) {
            chip = <Chip label="Indisponível" color="error" size="small" sx={{ ml: 1 }} />;
            disabled = true;
        } else if (tipo == TipoMarketplace.IFOOD && !permissaoIfood) {
            chip = <Chip label="Não contratado" color="error" size="small" sx={{ ml: 1 }} />;
            disabled = true;
        } else if (tipo == TipoMarketplace.CARDAPIO_DIGITAL_DELIVERY_RETIRADA && !permissaoCardapioOnline) {
            chip = <Chip label="Não contratado" color="error" size="small" sx={{ ml: 1 }} />;
            disabled = true;
        }



        return (
            <MenuItem key={tipo} value={tipo} disabled={disabled}>
                {TipoMarketplaceTitulo[tipo as TipoMarketplace]}
                {chip}
            </MenuItem>
        );
    });

    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/marketplace">
                    Marketplaces
                </Link>
                {marketplaceEdicao && (
                    <Link color="primary" href={`/marketplace/${marketplaceEdicao.uuid}`}>
                        {marketplaceEdicao.nome}
                    </Link>
                )}
                <Typography color="text.primary">{uuid ? "Editar" : "Cadastrar"}</Typography>
            </Breadcrumbs>
            <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                {uuid ? "Editar" : "Cadastrar"}
            </Typography>
            <form onSubmit={handleSubmit(handleSalvarMarketplace)}>
                <ContentContainer>
                    <FormContainer>
                        <Accordion
                            expanded={true}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary>
                                <Typography variant="h6">
                                    Dados de controle
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Nome*"
                                        style={{ width: "100%", margin: "0.7rem 0" }}
                                        {...register("nome")}
                                        error={!!errors.nome}
                                        helperText={<>{errors.nome?.message}</>}
                                        InputLabelProps={{ shrink: watch("nome")?.length ? true : undefined }}
                                    />
                                    <TextField
                                        label="Descrição"
                                        style={{ width: "100%", margin: "0.7rem 0" }}
                                        {...register("descricao")}
                                        error={!!errors.descricao}
                                        helperText={<>{errors.descricao?.message}</>}
                                        InputLabelProps={{ shrink: watch("descricao")?.length ? true : undefined }}
                                    />
                                    <FormControl sx={{ width: "100%", margin: "0.7rem 0" }}>
                                        <InputLabel
                                            id="tipo-marketplace-label"
                                            error={!!errors.tipo}
                                        >
                                            Tipo*
                                        </InputLabel>
                                        <Select
                                            labelId="tipo-marketplace-label"
                                            id="tipo"
                                            placeholder="Selecione um tipo"
                                            {...register("tipo")}
                                            value={watch("tipo") || ''}
                                            error={!!errors.tipo}
                                            label="Tipo*"
                                            defaultValue={undefined}
                                        >
                                            {tiposOptions}
                                        </Select>
                                        {errors.tipo && <FormHelperText error><>{errors.tipo.message}</></FormHelperText>}
                                        <Alert sx={{ mt: "1rem" }} severity={watch("tipo") ? "success" : "info"}>
                                            {!watch("tipo") ? "Nenhum tipo selecionado" : (
                                                <>
                                                    <AlertTitle>
                                                        {TipoMarketplaceTitulo[watch("tipo") as TipoMarketplace]}
                                                    </AlertTitle>
                                                    {TipoMarketplaceDescricao[watch("tipo") as TipoMarketplace]}
                                                </>
                                            )}
                                        </Alert>
                                    </FormControl>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={true}
                            sx={{
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary>
                                <Typography variant="h6">
                                    Dados públicos
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    label="Nome público*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("nomePublico")}
                                    error={!!errors.nomePublico}
                                    helperText={<>{errors.nomePublico?.message}</>}
                                    InputLabelProps={{ shrink: watch("nomePublico")?.length ? true : undefined }}
                                />
                                <TextField
                                    label="Telefone"
                                    placeholder="(99) 9999-9999"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("telefone")}
                                    error={!!errors.telefone}
                                    helperText={<>{errors.telefone?.message}</>}
                                    InputLabelProps={{ shrink: watch("telefone")?.length ? true : undefined }}
                                />
                                <TextField
                                    label="Slug*"
                                    style={{ width: "100%", margin: "0.7rem 0" }}
                                    {...register("slug")}
                                    error={!!errors.slug}
                                    helperText={<>{errors.slug?.message}</>}
                                    InputLabelProps={{ shrink: watch("slug")?.length ? true : undefined }}
                                />
                                <Alert severity="info">
                                    <AlertTitle>O que é um Slug?</AlertTitle>
                                    Um slug é uma versão simplificada do nome do marketplace, utilizado na URL para identificar a página de forma amigável e fácil de entender pelos clientes.
                                    <br />
                                    <br />
                                    <strong>Caracteres permitidos:</strong> letras minúsculas (a-z), números (0-9) e hífens (-).
                                    <br />
                                    <strong>Comprimento máximo:</strong> 40 caracteres.
                                    <br />
                                    <br />
                                    <strong>Importante:</strong> Cada slug deve ser único em todo o sistema. Isso significa que não pode haver dois marketplaces com o mesmo slug.
                                </Alert>

                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={accordionValorMinimoOpen}
                            sx={{
                                "&.Mui-expanded": {
                                    margin: 0,
                                },
                                "&:before": {
                                    display: "none",
                                },
                                "&.MuiAccordion-root::before": {
                                    display: "none",
                                },
                                borderRadius: "16px",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                onClick={handleAccordionValorMinimo}
                            >
                                <Typography variant="h6">
                                    Valor mínimo
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl fullWidth sx={{ margin: "0.7rem 0" }}>
                                    <InputLabel error={!!errors.valorMinimo} htmlFor="valor-minimo">Valor mínimo</InputLabel>
                                    <OutlinedInput
                                        id="valor-minimo"
                                        error={!!errors.valorMinimo}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                        label="Valor mínimo"
                                        fullWidth
                                        value={watch('valorMinimo')}
                                        {...register("valorMinimo")}
                                    />
                                    {!!errors.valorMinimo && <FormHelperText error={true}><>{errors.valorMinimo?.message}</></FormHelperText>}
                                </FormControl>
                                <Alert severity="info">
                                    <AlertTitle>Como o valor mínimo funciona?</AlertTitle>
                                    O valor mínimo definido verifica se o carrinho do cliente atende ao pedido mínimo necessário para transações rentáveis na loja.
                                </Alert>

                            </AccordionDetails>
                        </Accordion>
                    </FormContainer>
                    <ResumoPaper>
                        <Typography variant="h6">Resumo</Typography>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Nome</b>: {watch("nome")?.length ? watch("nome") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Descrição</b>: {watch("descricao")?.length ? watch("descricao") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Tipo</b>: {watch("tipo")?.length ? (
                                    <Tooltip title={TipoMarketplaceDescricao[watch("tipo") as TipoMarketplace]}>
                                        <Chip
                                            size="small"
                                            label={TipoMarketplaceTitulo[watch("tipo") as TipoMarketplace]}
                                            style={{
                                                background: TipoMarketplaceCor[watch("tipo") as TipoMarketplace],
                                                color: "white",
                                                marginLeft: "0.1rem"
                                            }}
                                        />
                                    </Tooltip>
                                ) : "-"}

                            </Typography>
                        </Paper>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Nome público</b>: {watch("nomePublico")?.length ? watch("nomePublico") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Telefone</b>: {watch("telefone")?.length ? watch("telefone") : "-"}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{ marginTop: "1rem" }}
                            >
                                <b>Slug</b>: {watch("slug")?.length ? watch("slug") : "-"}
                            </Typography>
                            <TextField
                                label="Link público"
                                disabled
                                value={urlExterna ?? "Link não mapeado"}
                                fullWidth
                                sx={{ mt: 4 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={copiar}>
                                                <ContentCopy />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Paper>
                        <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "2rem" }}>
                            <Typography
                                variant="subtitle1"
                            >
                                <b>Valor mínimo</b>: {watch("valorMinimo")?.length ? real(watch("valorMinimo")) : "R$ 0,00"}
                            </Typography>
                        </Paper>
                        <ButtonsContainer>
                            <Button variant="outlined" onClick={handleOpenSair}>
                                SAIR
                            </Button>
                            {loadingSalvar ? (
                                <CircularProgress size={30} />
                            ) : (
                                <Button
                                    variant={"contained"}
                                    startIcon={<Save />}
                                    type="submit"
                                >
                                    SALVAR
                                </Button>
                            )}
                        </ButtonsContainer>
                    </ResumoPaper>
                </ContentContainer>
            </form>
            <Dialog
                open={openSair}
                onClose={handleCloseSair}
            >
                <DialogTitle>
                    Deseja sair?
                </DialogTitle>
                <DialogContent>
                    <Alert severity="warning">
                        As alterações que não foram salvas serão pedidas
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSair}>Cancelar</Button>
                    <Button onClick={() => navigate("/marketplace")} autoFocus variant="contained">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer >
    );
}