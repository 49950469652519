import { Avatar, Box, Button, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { ContentCopy, Palette } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCopiar } from "../../../../../core/hooks/useCopiar";
import { CorInput } from "../../../../../core/components/CorInput";
import { useThemeContext } from "../../../../../core/contexts/ThemeContext";
import { ReferenciaImagemMarketplace } from "../../../../enum/ReferenciaImagemMarketplace";

interface PersonalizacaoMarketplaceProps {
    marketplace: IMarketplace
}

export const PersonalizacaoMarketplacePaper = ({ marketplace }: PersonalizacaoMarketplaceProps) => {
    const navigate = useNavigate();
    const { theme } = useThemeContext();

    const logoUrl = marketplace.
        configuracao?.
        personalizacao?.
        imagens?.
        find(imagem => imagem.referencia == ReferenciaImagemMarketplace.logo)?.
        url;

    const capaUrl = marketplace.
        configuracao?.
        personalizacao?.
        imagens?.
        find(imagem => imagem.referencia == ReferenciaImagemMarketplace.capa)?.
        url;

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography variant="h6">Personalização</Typography>
                <Button onClick={() => navigate(`/marketplace/personalizar/${marketplace.uuid}`)} endIcon={<Palette />}>
                    Personalizar
                </Button>
            </Box>
            <Box sx={{ mt: 2, flexDirection: "column" }}>                
                <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                    {logoUrl ? (
                        <img 
                            style={{ width: "80px", height: "80px", objectFit: "contain", borderRadius: "1rem" }} 
                            src={logoUrl} 
                        />
                    ) : (
                        <Avatar>
                        {marketplace.configuracao?.nomePublico?.charAt(0).toUpperCase()}
                        </Avatar>
                    )}
                    <Typography sx={{ ml: 2 }} fontWeight={"bold"}>
                        {marketplace.configuracao?.nomePublico}
                    </Typography>
                </Box>
                <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                    <CorInput 
                        cor={marketplace.configuracao?.personalizacao?.corPrimaria ?? theme.palette.primary.main}
                        label="Cor primária"
                        mensagemCopiar="Cor primária copiada com sucesso"
                    />
                </Box>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                    <CorInput 
                        cor={marketplace.configuracao?.personalizacao?.corSecundaria ?? theme.palette.secondary.main}
                        label="Cor secundária"
                        mensagemCopiar="Cor secundária copiada com sucesso"
                    />
                </Box>
            </Box>
        </Paper>
    );
}