import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { usuarioSchema } from '../../../../schemas/usuarioSchema';
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useUsuarios } from '../../../../hooks/useUsuarios';
import CircularProgress from '@mui/material/CircularProgress';
import { FieldValues } from "react-hook-form/dist/types";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from '../../../../../core/contexts/SnackbarContext';
import { novaSenhaSchema } from '../../../../schemas/novaSenhaSchema';

interface AlterarSenhaProps {
    alterarFormulario: (formulario: 'alterar_dados' | 'alterar_senha' | undefined) => void;
}

export const AlterarSenha: React.FC<AlterarSenhaProps> = ({ alterarFormulario }) => {
    const { alterarSenha } = useUsuarios();
    const [verNovaSenha, setVerNovaSenha] = useState(false);
    const [verConfirmacaoSenha, setVerConfirmacaoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();

    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(novaSenhaSchema),
        mode: "onChange",
    });

    const handleAlterarSenha = async ({novaSenha, confirmacaoSenha}: FieldValues) => {
        if(novaSenha != confirmacaoSenha) {
            showSnackbar('As senhas estão diferentes', {severity: 'error'});
            return;
        }

        setLoading(true);
        const result = await alterarSenha(confirmacaoSenha);
        setLoading(false);

        if(result == true) {
            alterarFormulario(undefined);
        }
    }

    return (
        <Card>
            <CardContent>
                <Stack direction="row" spacing={2} sx={{mb: 4}}>
                    <Typography variant="h5" fontWeight={700} component="h2" gutterBottom>
                        Alterar senha
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleAlterarSenha)}>
                    <Stack direction="column" justifyContent="center" spacing={2} sx={{mt: 4}}>
                        <TextField 
                            style={{ marginBottom: "1rem"}} 
                            variant="standard"
                            label="Nova senha" 
                            type={verNovaSenha ? 'text' : 'password'} 
                            {...register('novaSenha')}
                            error={!!errors.novaSenha}
                            helperText={<>{errors.novaSenha?.message}</>}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={() => setVerNovaSenha(!verNovaSenha)}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                    >
                                    {verNovaSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                        <TextField 
                            style={{ marginBottom: "1rem"}} 
                            variant="standard"
                            label="Confirme sua nova senha" 
                            type={verConfirmacaoSenha ? 'text' : 'password'} 
                            {...register('confirmacaoSenha')}
                            error={!!errors.confirmacaoSenha}
                            helperText={<>{errors.confirmacaoSenha?.message}</>}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={() => setVerConfirmacaoSenha(!verConfirmacaoSenha)}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                    >
                                    {verConfirmacaoSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={2} sx={{mt: 4}}>
                        <Button
                            variant={"outlined"} 
                            color="primary"
                            size="large"
                            onClick={() => alterarFormulario(undefined)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant={"contained"} 
                            color="primary"
                            size="large"
                            type="submit"
                        >
                            {loading == false ? (
                                'Alterar' 
                            ) : (
                                <CircularProgress color="inherit" />
                            )}
                        </Button>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    );
}