import { createContext, useContext, useState, useCallback, useMemo, useEffect } from "react";
import { DarkTheme, LightTheme } from "../themes";
import { Theme, ThemeProvider, createTheme } from "@mui/material";
import { Box } from "@mui/system";
import { IMaterialUi } from "../interfaces/IMaterialUi";
import { useAutenticacaoContext } from "./AutenticacaoContext";
import { ApiFactory } from "../config/ApiFactory";
import { useLocation } from "react-router-dom";

interface IThemeContext {
    themeName: 'light' | 'dark';
    toggleTheme: () => void;
    theme: IMaterialUi;
    logo: string;
    customTheme: boolean;
}

export const useThemeContext = () => {
    return useContext(ThemeContext)
}

const baseTheme = {
    components: {
        MuiAccordion: {
            styleOverrides: {
              root: {
                borderRadius: 6
              },
            },
        },
    },
    shape: {
        borderRadius: 10
    },
    shadows: [
        'none',
        '0px 1px 3px rgba(0, 0, 0, 0.08)', 
        '0px 2px 4px rgba(0, 0, 0, 0.08)', 
        '0px 3px 6px rgba(0, 0, 0, 0.08)', 
        '0px 4px 8px rgba(0, 0, 0, 0.08)', 
        '0px 6px 12px rgba(0, 0, 0, 0.08)', 
        '0px8px 16px rgba(0, 0, 0, 0.08)', 
        '0px 10px 20px rgba(0, 0, 0, 0.08)', 
        '0px 14px 28px rgba(0, 0, 0, 0.08)', 
        '0px 18px 36px rgba(0, 0, 0, 0.08)', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
    ], 
}

export const ThemeContext = createContext({} as IThemeContext)

export const AppThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { autenticado, estabelecimento } = useAutenticacaoContext();
    const apiEstabelecimentos = ApiFactory.getApi("MS_ESTABELECIMENTOS");
    const [themeName, setThemeName] = useState<'light' | 'dark'>('light');
    const [lightTheme, setLightTheme] = useState<Theme>(LightTheme);
    const [darkTheme, setDarkTheme] = useState<Theme>(DarkTheme);
    const [lightLogo, setLightLogo] = useState<string>(`${process.env.PUBLIC_URL}/images/logo.png`);
    const [darkLogo, setDarkLogo] = useState<string>(`${process.env.PUBLIC_URL}/images/logo.png`);
    const [customTheme, setCustomTheme] = useState(false);

    const toggleTheme = useCallback(() => {
        setThemeName(oldThemeName => oldThemeName === 'light' ? 'dark' : 'light');
    }, []) 

    const theme = useMemo(() => {
        if (themeName === 'dark') return darkTheme;

        return lightTheme;
    }, [themeName, lightTheme, darkTheme])

    const logo = useMemo(() => {
        if (themeName === 'dark') return darkLogo;

        return lightLogo;
    }, [themeName, lightLogo, darkLogo])

    const buscarTemas = async () => {
        if (autenticado) {
            const { data: light } = await apiEstabelecimentos.get("/tema/light");

            if(light) {
                setCustomTheme(true);
                setLightTheme(createTheme({
                    ...light.materialUi,
                    ...baseTheme
                }));
                const logo = light.imagens.find((imagem: any) => imagem.tipo == "Logo");
                setLightLogo(logo.url);
            }

            const { data: dark } = await apiEstabelecimentos.get("/tema/dark");
            if(dark) {
                setCustomTheme(true);
                setDarkTheme(createTheme({
                    ...dark.materialUi,
                    ...baseTheme
                }));
                const logo = dark.imagens.find((imagem: any) => imagem.tipo == "Logo");
                setDarkLogo(logo.url);
            }
        }
    }

    useEffect(() => {
        buscarTemas();
    }, [autenticado, estabelecimento])

    const location = useLocation();

    const possuiHeader = useMemo(() => {
        const urlsExcessoes = ['/login', '/criar-conta', '/', '/recuperar-senha', '/pagina-nao-encontrada', '/nao-autorizado'];
        return !urlsExcessoes.includes(location.pathname);
    }, [location.pathname]);

    return (
        <ThemeContext.Provider value={{ customTheme, logo, themeName, toggleTheme, theme: theme as IMaterialUi }}>
            <ThemeProvider theme={theme as IMaterialUi}>
                <Box
                    marginTop={!possuiHeader ? "0px" : "50px"}
                    width={'100%'}
                    bgcolor={theme.palette.background.default}
                    minHeight={"calc(100vh - 50px)"}
                    height={!possuiHeader ? "100%" : "calc(100% - 50px)"}
                    padding={0}
                    color={theme.palette.text.primary}>
                    {children}
                </Box>

            </ThemeProvider>
        </ThemeContext.Provider>
    );
} 