import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FieldValues } from "react-hook-form/dist/types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { recuperarSenhaSchema } from '../../../../schemas/recuperarSenhaSchema';

interface IRecuperarSenhaProps {
    modalRecuperarSenha: boolean;
    fecharRecuperarSenha: () => void;
    recuperarSenha: (email: string) => Promise<void>;
}

export const RecuperarSenha = ({ modalRecuperarSenha, fecharRecuperarSenha, recuperarSenha }: IRecuperarSenhaProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { register, handleSubmit, formState: { errors  }} = useForm({
        resolver: yupResolver(recuperarSenhaSchema),
        mode: "onChange",
    });

    const handleRecuperarSenha = async ({email}: FieldValues) => {
        setLoading(true);
        await recuperarSenha(email);
        setLoading(false);
    }
    
    return (
        <Dialog fullWidth open={modalRecuperarSenha} onClose={fecharRecuperarSenha}>
          <DialogTitle>Recuperar senha</DialogTitle>
            <form onSubmit={handleSubmit(handleRecuperarSenha)}>
                <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Endereço de e-mail"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("email")}
                            error={!!errors.email}
                            helperText={<>{errors.email?.message}</>}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharRecuperarSenha}>Cancelar</Button>
                    <Button type="submit" variant="contained">
                        {loading == false ? (
                            'Recuperar'
                        ) : (
                            <CircularProgress color="inherit" />
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}