import * as yup from "yup";
import { validarMoedaReal } from "../../core/utils/validarMoedaReal";

export const preparadoSchema =  yup.object().shape({
  descricao: yup.string().required("Nome inválido"),
  preco: yup.string().test('preco', 'Valor inválido', (valor) => {
    return !validarMoedaReal(valor, undefined, undefined, false);
  }),
  codigo: yup.string(),
  detalhes: yup.string().max(100)
});
  