import { useCallback, useState } from "react";
import { Button, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAutenticacaoContext } from "../../contexts/AutenticacaoContext";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { RecuperarSenha } from "./containers/RecuperarSenha";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { AxiosError } from "axios";
import { ApiFactory } from "../../config/ApiFactory";
import { LoginBox, LoginPage } from "./styles";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { autenticarUsuarioSchema } from "../../../usuarios/schemas/autenticarUsuarioSchema";
import { IErro } from "../../interfaces/IError";

export const Login = () => {
    const apiUsuarios = ApiFactory.getApi("MS_USUARIOS_ESTABELECIMENTO");

    const { setError, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(autenticarUsuarioSchema),
        mode: "onChange",
    });

    const [verSenha, setVerSenha] = useState(false);
    const showSnackbar = useSnackbar();
    const [modalRecuperarSenha, setModalRecuperarSenha] = useState(false);
    const { autenticar, loading } = useAutenticacaoContext();

    const abrirRecuperarSenha = () => setModalRecuperarSenha(true);
    const fecharRecuperarSenha = () => setModalRecuperarSenha(false);
    const recuperarSenha = useCallback(async (email: string) => {
        try {
            await apiUsuarios.post("/recuperar-senha", { email });
            showSnackbar('Um e-mail foi enviado para você alterar a sua senha', { severity: 'success' });
        } catch (e) {
            if (e instanceof AxiosError) {
                showSnackbar(e.response?.data.message, { severity: 'error' });
            } else {
                showSnackbar(
                    'Ocorreu um erro ao tentar enviar o e-mail de recuperação, entre em contato com o suporte',
                    { severity: 'error' }
                );
            }
        } finally {
            fecharRecuperarSenha();
        }
    }, [])

    const handleAutenticar = async ({ email, senha }: FieldValues) => {
        const resultado = await autenticar(email, senha);
        if(typeof resultado !== 'string') {
            if(resultado.status) {
                if(resultado.mensagem == "Usuário não encontrado") {
                    setError("email", { message: resultado.mensagem });
                    return;
                }
                if(resultado.mensagem == "Senha inválida") {
                    setError("senha", { message: resultado.mensagem });
                    return;
                }
                showSnackbar(resultado.mensagem ?? "Ocorreu um erro, tente novamente mais tarde.", { severity: "error" })
            }
        }
    }

    return (
        <LoginPage
            container
            spacing={0}
        >
            <RecuperarSenha recuperarSenha={recuperarSenha} fecharRecuperarSenha={fecharRecuperarSenha} modalRecuperarSenha={modalRecuperarSenha} />
            <LoginBox>
                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <Typography sx={{ mr: 2 }} variant="h4" fontWeight={"bold"}>Space Food</Typography>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/logo.png`}
                        style={{ width: "124px", height: "76px" }}
                        alt="Space Food"
                    />
                </Box>
                <Box sx={{ mt: 5 }}>
                    <Typography variant="h3" fontWeight={"bold"}>Faça seu login</Typography>
                    <Typography variant="h3" fontWeight={"bold"}>no sistema</Typography>
                </Box>
            </LoginBox>
            <Paper elevation={3}>
                <form onSubmit={handleSubmit(handleAutenticar)}>
                    <Box sx={{
                        pt: 3,
                        pb: 3,
                        pr: 3,
                        pl: 3,
                        mx: 'auto',
                        display: 'flex',
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "350px",
                    }}>
                        <TextField
                            style={{ margin: "0 0 1rem 0", width: "320px" }}
                            {...register('email')}
                            label="Email"
                            error={!!errors.email}
                            helperText={<>{errors.email?.message}</>}
                        />
                        <TextField
                            {...register('senha')}
                            style={{ marginBottom: "1rem", width: "320px" }}
                            label="Senha"
                            error={!!errors.senha}
                            helperText={<>{errors.senha?.message}</>}
                            type={verSenha ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setVerSenha(!verSenha)}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {verSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <Link onClick={abrirRecuperarSenha} fontWeight="400" fontSize={14} style={{ marginBottom: "1rem", width: "320px", cursor: "pointer" }} color="primary">
                            Esqueci minha senha
                        </Link>

                        <Button type="submit" style={{ maxWidth: "320px" }} size="large" variant="contained" color="primary" fullWidth>
                            {loading == true ? <CircularProgress color="inherit" /> : "Entrar"}
                        </Button>

                        <Typography sx={{ mt: 3, fontSize: 13 }}>
                            Não tem uma conta?
                            <Link href="/criar-conta" fontWeight="400" style={{ marginLeft: 3 }} color="primary">
                                Registre-se
                            </Link>
                        </Typography>
                    </Box>
                </form>
            </Paper>
        </LoginPage>
    );
}