import { Box, FormControl, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { useCopiar } from "../../hooks/useCopiar";
import { ContentCopy } from "@mui/icons-material";
import { useMascara } from "../../hooks/useMascara";

export interface CorInputProps {
    cor: string;
    label: string;
    mensagemCopiar: string;
    onChange?: (cor: string) => void;
}

export const CorInput = ({ cor, label, mensagemCopiar, onChange }: CorInputProps) => {
    const copiar = useCopiar(
        cor,
        mensagemCopiar
    );

    const { mascaraHexadecimal } = useMascara();

    const handleChange = (e: any) => {
        const cor = e.target.value;
        if(onChange) {
            onChange(mascaraHexadecimal(cor));
        }
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Paper variant="outlined" sx={{
                width: "35px",
                height: "35px",
                backgroundColor: cor,
                marginRight: "1rem",
                borderRadius: "10px"
            }}>

            </Paper>
            <TextField
                label={label}
                disabled={!onChange}
                value={cor}
                fullWidth
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={copiar}>
                                <ContentCopy />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </Box>
    );
}