import { Avatar, Button, Typography } from "@mui/material";
import { MesaPaperContainer } from "./styles";

interface MesaPaperProps {
    mesaId?: number;
}
 
export const MesaPaper = ({ mesaId }: MesaPaperProps) => {
    return (
        <MesaPaperContainer variant="outlined">
            <Avatar>{mesaId}</Avatar>
            <div>
                <Typography variant="subtitle1" >Mesa {mesaId}</Typography>
            </div>
        </MesaPaperContainer>
    );
}