import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch";

export const useFetchUsuarios = () => {
    const apiUsuarios = ApiFactory.getApi("MS_USUARIOS_ESTABELECIMENTO");
    const { data: usuarios, isLoading, handleRefresh } = useFetch(apiUsuarios, "/");
    return {
        usuarios,
        isLoading,
        handleRefresh
    }
}