import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { ListarEstabelecimentos } from "../pages/ListarEstabelecimentos";
import { IEstabelecimento } from "../interfaces/IEstabelecimento";
import { Layout } from "../../core/components/Layout";

interface IEstabelecimentosRoutesProps {
    autenticado: boolean;
}

export const EstabelecimentosRoutes = ({ autenticado }: IEstabelecimentosRoutesProps) => {
    const { modeloTela } = useTamanhoTela();

    return (
        <Routes>
            <Route element={
                <Layout
                    titulo="Estabelecimentos"
                    drawer={false}
                    modeloTela={modeloTela}
                >
                    <PrivateRoutes
                        autenticado={autenticado}
                    />
                </Layout>
            }>
                <Route path="/" index element={<ListarEstabelecimentos />} />
            </Route>
        </Routes>
    );
}