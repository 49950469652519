import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../contexts/SnackbarContext';
import { validarCep } from '../utils/validarCep';

interface CepResult {
    logradouro: string;
    localidade: string;
    bairro: string;
    uf: string;
}

interface CepData {
  rua: string;
  cidade: string;
  bairro: string;
  uf: string;
}

interface UseCEPResult {
  data: CepData | null;
  loading: boolean;
}

export const useCEP = (cep: string): UseCEPResult => {
  const [data, setData] = useState<CepData | null>(null);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      cep = cep.replace("-","");
      setLoading(true);
      try {
        const response = await axios.get<CepResult>(`https://viacep.com.br/ws/${cep}/json/`);
        const responseData = response.data;

        const cepData: CepData = {
          rua: responseData.logradouro,
          cidade: responseData.localidade,
          bairro: responseData.bairro,
          uf: responseData.uf,
        };

        setData(cepData);
      } catch (error) {
        snackbar("CEP não encontrado", { severity: "error" });
      }
      setLoading(false);
    };

    if (validarCep(cep, true)) {
      fetchData();
    } else {
      setData(null);
    }
  }, [cep]);

  return { data, loading };
};
