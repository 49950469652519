import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";

export const CriarContaPage = styled(Grid)`
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;

    @media(max-width: 1080px) {
        flex-direction: column;
    }
`

export const CriarContaBox = styled(Box)`
    max-width: 30vw;
    margin-right: 6rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    flex-direction: column;

    @media(max-width: 1080px) {
        max-width: 100%;
        margin-right: 0;
        text-align: center;
        margin-bottom: 2rem;

        h3 {
            width: 100%;
            font-size: 32px;
        }
        div {
            display: none;

            &:first-child {
                display: flex;
                width: 100%;
            }
        }
    }
`