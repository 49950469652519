import * as yup from "yup";
import { validarCpf } from "../../core/utils/validarCpf";
import { validarTelefone } from "../../core/utils/validarTelefone";

export const clienteSchema = yup.object().shape({
  nome: yup.string().required("O nome é obrigatório").max(50, "O nome não pode ter mais que 50 caracteres"),
  observacao: yup.string().max(50, "A observação não pode ter mais que 50 caracteres"),
  telefone: yup.string().test('telefone', 'Telefone inválido', (value) => validarTelefone(value, false)),
  email: yup.string().email("Endereço de e-mail inválido"),
  cpf: yup.string().test('cpf', 'CPF inválido', (value) => validarCpf(value, false))
});