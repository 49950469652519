import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)`
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`

export const HeaderContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const ValorContainer = styled(Box)`
    margin-top: 1rem;
    padding: 0.5rem 0; 
    display: flex; 
    justify-content: space-between;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};
    
    &:last-child {
        border-bottom: none;
        p {
            font-weight: bold;
        }
    }
`

export const OperadorContainer = styled(Box)`
    margin-top: 1rem;
    padding: 0.5rem 0; 
    display: flex; 
    justify-content: space-between;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};

    &:last-child {
        border-bottom: none;
    }
`

export const EnderecoEntregaContainer = styled(Box)`
    margin-top: 1rem;
    padding: 0.5rem 0; 
    display: flex; 
    justify-content: space-between;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};

    &:last-child {
        border-bottom: none;
    }
`


export const ClienteContainer = styled(Box)`
    margin-top: 1rem;
    padding: 0.5rem 0; 
    display: flex; 
    justify-content: space-between;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};

    &:last-child {
        border-bottom: none;
    }
`

export const ProductBox = styled(Box)`
    padding: 1rem; 
    border-top: 1px dashed ${props => props.theme.palette.divider};

    &:first-child {
        border-top: 0;
    }
`