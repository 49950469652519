import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { CardUsuario } from "./containers/CardUsuario";
import Container from '@mui/material/Container';
import { useState } from "react";
import { AlterarDados } from "./containers/AlterarDados";
import { AlterarSenha } from "./containers/AlterarSenha";

export const MeuPerfil: React.FC = () => {
    const [formulario, setFormulario] = useState<'alterar_dados' | 'alterar_senha' | undefined>(undefined);
    const { usuario } = useAutenticacaoContext();

    const alterarFormulario = (formulario?: 'alterar_dados' | 'alterar_senha' | undefined) => {
        setFormulario(formulario)
    }

    if(!usuario) {
        return <div></div>
    }

    return (
        <Container maxWidth="sm" sx={{p: 4}}>
            {!formulario && usuario && (
                <CardUsuario 
                    usuario={usuario}
                    alterarFormulario={alterarFormulario}
                />
            )}
            {formulario == 'alterar_dados' && usuario && (
                <AlterarDados 
                    email={usuario?.email}
                    nome={usuario?.nome}
                    telefone={usuario?.telefone}
                    alterarFormulario={alterarFormulario}
                />
            )}
            {formulario == 'alterar_senha' && usuario && (
                <AlterarSenha 
                    alterarFormulario={alterarFormulario}
                />
            )}

        </Container>
    );
}