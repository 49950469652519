import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch";
import { IPerfil } from "../interfaces/IPerfil";

export const useFetchPerfis = () => {
    const apiEstabelecimentos = ApiFactory.getApi("MS_ESTABELECIMENTOS");
    const { data: perfis, isLoading, handleRefresh } = useFetch<IPerfil[]>(apiEstabelecimentos, "/perfis");
    return {
        perfis,
        isLoading,
        handleRefresh
    }
}