import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Button, FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { useFetchInsumos } from "../../hooks/useFetchInsumos";
import { ChangeEvent, useMemo, useState } from "react";
import { IInsumo } from "../../interfaces/IInsumo";
import { Add, Delete } from "@mui/icons-material";
import { InsumoCard } from "./styles";
import { UnidadeInsumo } from "../../enum/UnidadeInsumo";
import { useUnidade } from "../../../core/hooks/useUnidade";

interface FichaTecnicaProps {
    fichaTecnica?: IInsumo[];
    adicionarInsumo: (insumo: IInsumo) => void;
    excluirInsumo: (uuid: string) => void;
}

export const FichaTecnica = ({ adicionarInsumo, fichaTecnica, excluirInsumo }: FichaTecnicaProps) => {
    const { insumos } = useFetchInsumos();

    const [insumo, setInsumo] = useState<undefined | IInsumo>(undefined);
    const [quantidade, setQuantidade] = useState<string>('');

    const [erroInsumo, setErroInsumo] = useState<boolean>(false);
    const [erroQuantidade, setErroQuantidade] = useState<boolean>(false);

    const opcoes = useMemo(() => {
        const insumosAdicionados = new Set(fichaTecnica?.map(insumoAdicionado => insumoAdicionado.uuid));
        return insumos?.filter(insumo => !insumosAdicionados.has(insumo.uuid)).map(insumo => ({
            label: insumo.nome,
            value: insumo.uuid
        })) ?? [];
    }, [insumos, fichaTecnica]);

    const handleInsumoChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: { label: string; value: string } | null
    ) => {
        if (value) {
            const selectedInsumo = insumos.find(i => i.uuid === value.value);
            setInsumo(selectedInsumo);
        } else {
            setInsumo(undefined);
        }
    };

    const handleQuantidadeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuantidade(event.target.value);
    };

    const validarQuantidade = (value: string): boolean => {
        if (!value.trim()) {
            return false;
        }

        const numValue = parseFloat(value);
        if (isNaN(numValue)) {
            return false;
        }

        if (numValue < 0.001) {
            return false;
        }

        return true;
    };

    const handleButtonClick = () => {
        if (!insumo) {
            setErroInsumo(true);
        }

        if (!validarQuantidade(quantidade)) {
            setErroQuantidade(true);
        }

        if (insumo && validarQuantidade(quantidade)) {
            setErroInsumo(false);
            setErroQuantidade(false);

            adicionarInsumo({
                ...insumo,
                quantidade: parseFloat(quantidade)
            });
            setQuantidade('');
            setInsumo(undefined);
        }
    };

    const { formatar } = useUnidade();

    return (
        <Box sx={{ marginTop: "2rem" }} >
            <Typography
                variant="h6"
                sx={{ width: "100%", marginBottom: "1rem" }}
            >
                Ficha técnica
            </Typography>
            <Box sx={{ width: "100%", display: "flex" }}>
                <FormControl
                    fullWidth
                    sx={{ mr: 1 }}
                    error={erroInsumo}
                >
                    <Autocomplete
                        value={opcoes.find(opcao => opcao.value === insumo?.uuid) ?? null}
                        options={opcoes}
                        renderInput={(params) => <TextField {...params} error={erroInsumo} fullWidth label="Nome do insumo" />}
                        onChange={handleInsumoChange}
                        noOptionsText="Nenhum insumo encontrado"
                    />
                    {erroInsumo && <FormHelperText error>Insumo inválido</FormHelperText>}
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{ width: "200px", mr: 1 }}
                    error={erroQuantidade}
                >
                    <OutlinedInput
                        endAdornment={<InputAdornment position="end">{insumo?.unidade}</InputAdornment>}
                        type="number"
                        placeholder="Qtd."
                        onChange={handleQuantidadeChange}
                        value={quantidade}
                        error={erroQuantidade}
                    />
                    {erroQuantidade && <FormHelperText error>Quantidade inválida</FormHelperText>}
                </FormControl>
                <IconButton onClick={handleButtonClick} color="primary" sx={{ width: "60px", height: "60px" }}>
                    <Add />
                </IconButton>
            </Box>
            {fichaTecnica?.map(insumoFicha => {
                return (
                    <InsumoCard variant="outlined">
                        <Box>
                            <Typography>
                                {insumoFicha.nome}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                                <Typography fontWeight={"bold"}>
                                    {formatar(insumoFicha.quantidade ?? 0, insumoFicha.unidade)}
                                </Typography>
                            </Box>
                        </Box>
                        <IconButton onClick={() => excluirInsumo(insumoFicha.uuid)}>
                            <Delete color="error" />
                        </IconButton>
                    </InsumoCard>
                );
            })}
        </Box>
    );
}