import * as yup from "yup";
import { validarCep } from "../../core/utils/validarCep";

export const enderecoClienteSchema =  yup.object().shape({
  rua: yup.string().required("Rua inválida"),
  cidade: yup.string().required("Cidade inválida"),
  bairro: yup.string().required("Bairro inválido"),
  numero: yup.string(),
  complemento: yup.string(),
  cep: yup.string().test('cep', 'CEP inválido', (value) => validarCep(value, false)),
  observacao: yup.string(),
  regiao: yup.object({
    uuid: yup.string().required("UUID é obrigatório")
  }).required("Região é obrigatória")
  .typeError("Região inválida"), 
});
  