import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const EmitirNotaFiscalForm = styled(Box)`
    width: 100%;
    min-width: 450px;
    height: 100%;
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
`