import { ApiFactory } from "../../core/config/ApiFactory";
import { useFetch } from "../../core/hooks/useFetch";
import { IMarketplace } from "../interfaces/IMarketplace";

export const useFetchMarketplacesVinculo = () => {
    const apiMarketplaces = ApiFactory.getApi("MS_MARKETPLACES");
    const { data: marketplaces, isLoading: loading, handleRefresh } = useFetch<IMarketplace[]>(apiMarketplaces, "/", { revalidateOnFocus: false });
    return {
        marketplaces,
        loading,
        handleRefresh
    }
}