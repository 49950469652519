import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ModalInativacaoProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
}

export const ModalInativacao = ({ handleClose, handleConfirm, open }: ModalInativacaoProps) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-confirmar-inativacao"
            aria-describedby="alter-confirmar-inativacao-descricao"
        >
            <DialogTitle id="alert-confirmar-inativacao">
                Deseja inativar os usuários
            </DialogTitle>
            <DialogContent>
                <Alert severity="info">
                    <AlertTitle>Essa ação pode ser revertida</AlertTitle>
                    Essa ação bloqueará o acesso a este estabelecimento para todos os usuários selecionados.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>CANCELAR</Button>
                <Button variant="contained" onClick={handleConfirm} autoFocus>
                    INATIVAR
                </Button>
            </DialogActions>
        </Dialog>
    );
}