import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { IIntervaloCaixa } from "../../interfaces/IIntervaloCaixa";
import { useDataHora } from '../../../core/hooks/useDataHora';
import { useMoeda } from '../../../core/hooks/useMoeda';
import { useState } from 'react';
import { IConta } from '../../interfaces/IConta';
import { useContas } from '../../hooks/useContas';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { 
    Grid, 
    TableCell, 
    TableRow, 
    Typography,
    Table,
    TableBody,
    TableContainer, 
    TableHead,
} from '@mui/material';
import CaixaTableRow from '../CaixaTableRow';

interface RelatorioCaixaTableRowProps {
    intervalo: IIntervaloCaixa;
}

export const RelatorioCaixaTableRow = ({ intervalo }: RelatorioCaixaTableRowProps) => {
    const [modalAberto, setModalAberto] = useState(false);
    const [contas, setContas] = useState<undefined | IConta[]>(undefined);
    const { dataHoraBr } = useDataHora();
    const { real } = useMoeda();
    const { buscarContasPorIntervalo, loading } = useContas();

    const handleOpen = async () => {
        setModalAberto(true);

        if(!contas) {
            setContas(await buscarContasPorIntervalo(intervalo.uuid));
        }
    }

    const handleClose = () => setModalAberto(false);

    return (
        <>
            <TableRow>
                <TableCell>{intervalo.descricao ?? "-"}</TableCell>
                <TableCell>{dataHoraBr(intervalo.abertoAs)}</TableCell>
                <TableCell>{intervalo.fechadoAs ? dataHoraBr(intervalo.fechadoAs) : "-"}</TableCell>
                <TableCell>{real(intervalo.valorInicial)}</TableCell>
                <TableCell>{real(intervalo.valorFinal)}</TableCell>
                <TableCell>
                    <IconButton onClick={handleOpen}>
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <Dialog open={modalAberto} onClose={handleClose} maxWidth="md" sx={{ zIndex: 9999 }}>
                <DialogTitle>
                    <Grid sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", height: "25px"}}>
                        <Typography variant="h6" fontWeight={"800"}>
                            Contas
                        </Typography>
                        <IconButton onClick={handleClose}>
                                <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Grid sx={{ 
                            minWidth: 450, 
                            maxWidth: 750, 
                            alignItems: "center", 
                            justifyContent: "center", 
                            display: "flex",
                            height: 300
                        }}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        contas && contas.length ? (
                            <TableContainer sx={{ maxHeight: 700 }}>
                                <Table sx={{ minWidth: 450, maxWidth: 750 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Descrição</TableCell>
                                        <TableCell>Data/Hora</TableCell>
                                        <TableCell>Valor total</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Pagamentos</TableCell>
                                        <TableCell>Itens</TableCell>
                                        <TableCell>Visualizar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {contas && contas.map(conta => <CaixaTableRow conta={conta} />)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            "Nenhuma conta registrada no intervalo"
                        )
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}