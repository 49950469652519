import { createContext, useContext, useState } from "react";


interface IHeaderContext {
    tools: React.ReactNode | undefined;
    setTools: (tools: React.ReactNode | undefined) => void;
}

export const useHeaderContext = () => {
    return useContext(HeaderContext)
}
export const HeaderContext = createContext({} as IHeaderContext)

export const HeaderContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [tools, setTools] = useState<React.ReactNode | undefined>(undefined);

    return (
        <HeaderContext.Provider value={{tools, setTools}}>
            {children}
        </HeaderContext.Provider>
    );
} 