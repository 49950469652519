import * as yup from "yup";
import { validarSlug } from "../../core/utils/validarSlug";
import { validarTelefone } from "../../core/utils/validarTelefone";
import { validarMoedaReal } from "../../core/utils/validarMoedaReal";

export const marketplaceSchema = yup.object().shape({
    nome: yup.string().required("Nome inválido").max(30, "O nome não pode ultrapassar o limite de 30 caracteres"),
    descricao: yup.string().max(100, "A descrição não pode ultrapassar o limite de 100 caracteres"),
    tipo: yup.string().required("Tipo inválido"),
    nomePublico: yup.string().required("Nome público inválido").max(30, "O nome público não pode ultrapassar o limite de 30 caracteres"),
    slug: yup.string().test('slug', 'Slug inválido', (value, context) => {
        const erro = validarSlug(value, true);
        return erro ? context.createError({ message: erro }) : true;
    }),
    telefone: yup.string().test('telefone', 'Telefone inválido', (value) => validarTelefone(value, false)),
    valorMinimo: yup.string().test('valorMinimo', 'Valor mínimo inválido', (value, context) => {
        const erro = validarMoedaReal(value, 0, undefined, false);
        return erro ? context.createError({ message: erro }) : true;
    }),
});
