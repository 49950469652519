import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const ProdutosPaper = styled(Paper)`
    height: 87vh;
    width: 100%; 
    position: relative;
    border-left: 0;
    border-bottom: 0;
    border-radius: 0 30px 0 0;

    @media(max-width: 768px) {
        height: 100vh;
        border-radius: 0;
        border-right: 0;
    }
`

export const CategoriasContainer = styled.div`
    position: relative; 
    width: 100%; 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 0 30px 0 0;

    @media(max-width: 768px) {
        border-radius: 0;
    }
`

export const Layer = styled.div`
    height: 87vh;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 30px 0 0;

    @media(max-width: 1080px) {
        align-items: flex-start;
        padding-top: 2rem;
    }
    
    @media(max-width: 768px) {
        border-radius: 0;
    }
`