import { Box, Button, Card, CardContent, CardMedia, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { PagadoresContainer, ProdutosContainer, SelecionarProdutosBox, SelecionarProdutosTools } from "./styles";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { IProdutoPedido } from "../../../../../../interface/IProdutoPedido";
import { useOperacaoContext } from "../../../../../../contexts/OperacaoContext";
import { TipoProduto } from "../../../../../../../produtos/enum/TipoProduto";
import { CardProdutoCarrinhoOperacao } from "../../../../../../../produtos/components/CardProdutoCarrinhoOperacao";
import { Add } from "@mui/icons-material";
import { v4 } from "uuid";
import { useMoeda } from "../../../../../../../core/hooks/useMoeda";
import { useTamanhoTela } from "../../../../../../../core/hooks/useTamanhoTela";
import { IPagadorProdutos } from "../../../../../../interface/IPagadorProdutos";
import CardPagadorProdutos from "../CardPagadorProdutos";
import { IPagamentoPedido } from "../../../../../../interface/IPagamentoPedido";
import { MetodoPagamentoSelect } from "../../../../../../components/MetodoPagamentoSelect";
import { MetodoPagamento } from "../../../../../../../core/enum/MetodoPagamento";
import { useSnackbar } from "../../../../../../../core/contexts/SnackbarContext";
import { TipoPedido } from "../../../../../../enum/TipoPedido";

interface SelecionarProdutosContainerProps {
    fecharPedido: (pagamentos: IPagamentoPedido[]) => void;
    tipo: TipoPedido;
    valorFrete?: number;
    setTools: (conteudo: ReactNode) => void;
}

export const SelecionarProdutosContainer = ({ fecharPedido, setTools, tipo, valorFrete }: SelecionarProdutosContainerProps) => {
    const [pagadores, setPagadores] = useState<IPagadorProdutos[]>([])
    const [produtosPendentes, setProdutosPendentes] = useState<IProdutoPedido[]>([]);
    const [produtosSelecionados, setProdutosSelecionados] = useState<IProdutoPedido[]>([]);
    const [pagamento, setPagamento] = useState<undefined | IPagamentoPedido>(undefined);
    const [pagarEntrega, setPagarEntrega] = useState<boolean>(false);
    const snackbar = useSnackbar();

    const { produtos } = useOperacaoContext();
    const { real } = useMoeda();

    useEffect(() => {
        const novosProdutos: IProdutoPedido[] = [];

        produtos?.forEach(produto => {
            for (let i = 0; i < (produto.quantidade ?? 1); i++) {
                novosProdutos.push({ ...produto, uuid: v4(), quantidade: 1 });
            }
        });

        setProdutosPendentes(novosProdutos)
        setProdutosSelecionados([]);
        setPagadores([]);
    }, [])

    useEffect(() => {
        setTools(
            <Button
                sx={{ width: "200px", height: "40px" }}
                variant="contained"
                onClick={handleFecharPedido}
                disabled={!(produtosPendentes?.length == 0 && pagadores?.length >= 1)}
            >
                CONCLUIR
            </Button>
        );
    }, [produtosPendentes, pagadores])

    const handleFecharPedido = () => {
        if (tipo == TipoPedido.delivery && !taxaEntregaJaAdicionada) {
            return snackbar("O valor do frete ainda não foi adicionado", { severity: "error" });
        }
        const pagamentos = pagadores.map(pagador => pagador.pagamento);
        fecharPedido(pagamentos);
    }

    const selecionarProduto = (produto: IProdutoPedido) => {
        if (pagamento) {
            setPagamento(undefined);
            snackbar("O método de pagamento foi removido", { severity: "info" })
        }
        const produtoIndex = produtosSelecionados.findIndex(produtoProcurado => produtoProcurado.uuid == produto.uuid);

        if (produtoIndex !== -1) {
            const novosProdutos = produtosSelecionados;
            novosProdutos.splice(produtoIndex, 1);
            setProdutosSelecionados([...novosProdutos]);
            return;
        }

        setProdutosSelecionados([...produtosSelecionados, produto]);
    }

    const criarPagador = () => {
        let erroTroco = false;

        if (pagamento?.metodoPagamento == MetodoPagamento.Dinheiro) {
            if (pagamento?.valorTroco && pagamento?.valorTroco < 0) {
                erroTroco = true;
            }
        }

        if (erroTroco) {
            return snackbar("Troco inválido", { severity: "error" });
        }

        if (pagarEntrega && taxaEntregaJaAdicionada) {
            return snackbar("A taxa de entrega já foi adicionada", { severity: "error" });
        }

        const total = pagarEntrega ? (valorTotalSelecionados + (valorFrete ?? 0)) : valorTotalSelecionados

        setPagadores([...pagadores, {
            nome: `Pagador ${pagadores.length + 1}`,
            produtos: produtosSelecionados,
            uuid: v4(),
            pagarEntrega,
            pagamento: {
                ...pagamento,
                valor: total
            } ?? { valor: valorTotalSelecionados }
        }]);
        setProdutosPendentes(produtosPendentes.filter(produtoPendente => !produtosSelecionados.find(produtoSelecionado => produtoSelecionado.uuid == produtoPendente.uuid)))
        setProdutosSelecionados([]);
        setPagamento(undefined);
        setPagarEntrega(false);
    }

    const excluirPagador = (uuid: string) => {
        const pagadorExcluido = pagadores.find(pagador => pagador.uuid === uuid);
        const novosPagadores = pagadores.filter(pagador => pagador.uuid !== uuid);
        setPagadores(novosPagadores);
        if (pagadorExcluido && pagadorExcluido.produtos.length > 0) {
            setProdutosPendentes([...produtosPendentes, ...pagadorExcluido.produtos]);
        }
    }

    const taxaEntregaJaAdicionada = useMemo(() => {
        let adicionada = false;
        pagadores?.map(pagador => {
            if (pagador.pagarEntrega) {
                adicionada = true;
            }
        })
        return adicionada;
    }, [pagadores])

    const valorTotalProdutosPendentes = useMemo(() => {
        let totalPendentes = 0;

        produtosPendentes.map(produto => {
            let totalProduto = (produto.preco ?? 0);

            produto.itens?.map(item => {
                totalProduto = totalProduto + ((item.preco ?? 0) * (item.quantidade ?? 1));
            })

            totalPendentes += totalProduto * (produto.quantidade ?? 1);
        })

        return totalPendentes
    }, [produtosPendentes.length])

    const valorTotalProdutosPagadores = useMemo(() => {
        let totalPagadores = 0;

        pagadores.map(pagador => {
            pagador.produtos.map(produto => {
                let totalProduto = (produto.preco ?? 0);

                produto.itens?.map(item => {
                    totalProduto = totalProduto + ((item.preco ?? 0) * (item.quantidade ?? 1));
                })

                totalPagadores += totalProduto * (produto.quantidade ?? 1);
            })
        })

        return totalPagadores
    }, [produtosPendentes.length])

    const valorTotalSelecionados = useMemo(() => {
        let total = 0;
        produtosSelecionados?.map(produto => {
            let totalProduto = (produto.preco ?? 0);

            produto.itens?.map(item => {
                totalProduto = totalProduto + ((item.preco ?? 0) * (item.quantidade ?? 1));
            })

            total += totalProduto * (produto.quantidade ?? 1);
        })
        return total;
    }, [produtosSelecionados])

    const { modeloTela } = useTamanhoTela();

    const handleChangePagamento = (pagamento: IPagamentoPedido | undefined) => {
        setPagamento(pagamento)
    }

    const handleChangePagarEntrega = (pagarEntrega: number) => {
        setPagarEntrega(pagarEntrega ? true : false);
    }

    return (
        <SelecionarProdutosBox>
            <ProdutosContainer>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography variant="subtitle1" fontWeight={"bold"}>
                            {tipo != TipoPedido.delivery
                                ? real(valorTotalProdutosPendentes)
                                : real(valorTotalProdutosPendentes + (taxaEntregaJaAdicionada ? 0 : (valorFrete ?? 0)))}
                        </Typography>
                        <Typography variant="subtitle2">
                            {!produtosSelecionados?.length ? "Pendentes" : `${produtosSelecionados.length} produto(s) selecionado(s) ${pagarEntrega ? "+ frete" : ""}`}
                        </Typography>
                    </Box>
                    <SelecionarProdutosTools>
                        {tipo == TipoPedido.delivery && (
                            <FormControl sx={{ mr: 1, maxWidth: "120px" }} fullWidth>
                                <InputLabel id="pagar-entrega-label">Pagar entrega</InputLabel>
                                <Select
                                    labelId="pagar-entrega-label"
                                    id="pagar-entrega-select"
                                    value={pagarEntrega ? 1 : 0}
                                    label="Pagar entrega"
                                    size="small"
                                    disabled={!produtosSelecionados.length || taxaEntregaJaAdicionada}
                                    onChange={(event) => handleChangePagarEntrega(event.target.value as number)}
                                >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <MetodoPagamentoSelect
                            pagamento={pagamento}
                            onChange={handleChangePagamento}
                            valorTotal={pagarEntrega ? (valorTotalSelecionados + (valorFrete ?? 0)) : valorTotalSelecionados}
                            size="small"
                            disabled={!produtosSelecionados.length}
                        />
                        <Button
                            variant="contained"
                            disabled={!produtosSelecionados.length}
                            startIcon={<Add />}
                            onClick={criarPagador}
                            sx={{ ml: 1, width: "180px", height: "40px" }}
                        >
                            Pagador
                        </Button>
                    </SelecionarProdutosTools>
                </Box>
                <Grid
                    container
                    spacing={1}
                    sx={{ marginTop: "1rem" }}
                >
                    {produtosPendentes.map(produto => {
                        return (
                            <Grid item xs={6} md={4} key={produto.uuid}>
                                <CardProdutoCarrinhoOperacao
                                    produto={produto}
                                    selecionar={selecionarProduto}
                                    selecionado={produtosSelecionados.find(produtoSelecionado => produtoSelecionado.uuid == produto.uuid) ? true : false}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </ProdutosContainer>
            <Divider orientation={modeloTela == "desktop" ? "vertical" : "horizontal"} flexItem />
            <PagadoresContainer>
                <Box>
                    <Typography variant="subtitle1" fontWeight={"bold"}>{
                        tipo != TipoPedido.delivery
                            ? real(valorTotalProdutosPagadores)
                            : real(valorTotalProdutosPagadores + (taxaEntregaJaAdicionada ? (valorFrete ?? 0) : 0))
                    }</Typography>
                    <Typography variant="subtitle2">Pagadores</Typography>
                </Box>
                <Grid
                    container
                    spacing={1}
                    sx={{ marginTop: "1rem" }}
                >
                    {pagadores.map((pagador, key) => {
                        return (
                            <Grid item md={4} xs={12} key={key}>
                                <CardPagadorProdutos excluirPagador={excluirPagador} pagador={pagador} />
                            </Grid>
                        )
                    })}
                </Grid>
            </PagadoresContainer>
        </SelecionarProdutosBox>
    );
}