import { Box, Button, FormControl, Grid, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { IFiltro } from "../../../core/interfaces/IFiltro";
import { useFetchInvervalosCaixa } from "../../hooks/useFetchInvervalosCaixa";
import { IIntervaloCaixa } from "../../interfaces/IIntervaloCaixa";
import { FiltrosContainer } from "./styles";
import { RelatorioCaixaTableRow } from "../../components/RelatorioCaixaTableRow";
import { useNavigate } from "react-router-dom";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { useThemeContext } from "../../../core/contexts/ThemeContext";

type DataInicio = {
    dataInicio: Moment | null;
};

type DataFim = {
    dataFim: Moment | null;
};

export const RelatorioCaixaDiarioFinanceiro = () => {
    const { dados, isLoading, filtrar, handlePaginaChange, limpar } = useFetchInvervalosCaixa();
    const snackbar = useSnackbar();

    const [descricao, setDescricao] = useState('');
    const [dataInicioState, setDataInicioState] = useState<DataInicio>({ dataInicio: null });
    const [dataFimState, setDataFimState] = useState<DataFim>({ dataFim: null });

    const handleDataInicioChange = (date: Moment | null) => {
        setDataInicioState((prevState) => ({ ...prevState, dataInicio: date }));
    };

    const handleDataFimChange = (date: Moment | null) => {
        setDataFimState((prevState) => ({ ...prevState, dataFim: date }));
    };

    const handleDescricaoChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescricao(e.target.value)
    }

    const handleLimpar = () => {
        setDescricao('');
        setDataInicioState({ dataInicio: null });
        setDataFimState({ dataFim: null });
        limpar();
    }

    const handleFiltrar = () => {
        const filtros: IFiltro[] = [];

        if (descricao) {
            filtros.push({
                chave: "descricao",
                valor: descricao
            });
        }

        if (dataInicioState.dataInicio && !dataFimState.dataFim) {
            snackbar("Selecione a data fim para poder filtrar por período");
            return;
        }

        if (dataFimState.dataFim && !dataInicioState.dataInicio) {
            snackbar("Selecione a data de início para poder filtrar por período");
            return;
        }

        if (dataFimState.dataFim && dataInicioState.dataInicio) {
            filtros.push({
                chave: "dataInicio",
                valor: dataInicioState.dataInicio.format("YYYY-MM-DD")
            })

            filtros.push({
                chave: "dataFim",
                valor: dataFimState.dataFim.format("YYYY-MM-DD")
            })
        }

        filtrar(filtros)
    }

    const navigate = useNavigate();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Relatório de caixa diário")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    const { theme } = useThemeContext();

    return (
        <>
            <Grid width={"100%"}>
                <FiltrosContainer>
                    <Paper sx={{ 
                        marginBottom: "2rem", 
                        width: "100%", 
                        display: "flex", 
                        alignItems: "center", 
                        padding: "1rem", 
                        borderRadius: 0, 
                        justifyContent: "space-between",
                        boxShadow: "none",
                        borderBottom: `1px solid ${theme.palette.divider}`
                    }}>
                        <Typography variant="h6">
                            Relatório diário do caixa
                        </Typography>
                        <Box className="filtros-caixa-diario">
                            <TextField
                                label="Descrição"
                                onChange={handleDescricaoChange}
                                value={descricao}
                                size="small"
                                className="input-caixa-diario"
                            />
                            <DatePicker
                                label="Data início"
                                value={dataInicioState.dataInicio}
                                onChange={handleDataInicioChange}
                                format="DD/MM/YYYY"
                                slotProps={{ textField: { size: 'small' } }}
                                className="input-caixa-diario"
                            />
                            <DatePicker
                                label="Data fim"
                                value={dataFimState.dataFim}
                                onChange={handleDataFimChange}
                                format="DD/MM/YYYY"
                                slotProps={{ textField: { size: 'small' } }}
                                className="input-caixa-diario"
                            />
                            <Box>
                                <Button
                                    onClick={handleLimpar}
                                    variant="outlined"
                                    sx={{ marginRight: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    onClick={handleFiltrar}
                                    variant="contained"
                                >
                                    Filtrar
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </FiltrosContainer>

                {dados && dados.itens.length ? (
                    <>
                        <TableContainer component={Paper} sx={{ minWidth: 350, maxWidth: "80%", margin: "0 auto" }}>
                            <Table sx={{ minWidth: 350, width: "100%" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" width="15%">Descrição</TableCell>
                                        <TableCell align="left" width="15%">Aberto às</TableCell>
                                        <TableCell align="left" width="15%">Fechado às</TableCell>
                                        <TableCell align="left" width="15%">Valor inicial</TableCell>
                                        <TableCell align="left" width="15%">Valor final</TableCell>
                                        <TableCell align="left" width="10%">Contas</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dados.itens.map((intervalo: IIntervaloCaixa) => <RelatorioCaixaTableRow intervalo={intervalo} />)}
                                </TableBody>
                            </Table>
                            {dados && (
                                <Pagination
                                    count={dados.quantidadeTotalPaginas}
                                    page={dados.paginaAtual}
                                    onChange={handlePaginaChange}
                                    color="primary"
                                    showFirstButton
                                    showLastButton
                                    siblingCount={2}
                                    boundaryCount={2}
                                    sx={{ marginTop: "1rem", justifyContent: "center", display: "flex", padding: "1rem" }}
                                />
                            )}
                        </TableContainer>
                    </>
                ) : (
                    <Typography sx={{ padding: "1rem", width: 750 }} align='center'>
                        {isLoading ? <CircularProgress /> : "Nenhum intervalo encontrado"}
                    </Typography>
                )}
            </Grid>
        </>
    );
}