import { Alert, AlertTitle, Box, Button, CircularProgress, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { CriarContaBox, CriarContaPage } from "./styles";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Check, CheckCircle } from "@mui/icons-material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usuarioSchema } from "../../../usuarios/schemas/usuarioSchema";
import { useMascara } from "../../hooks/useMascara";
import { useUsuarios } from "../../../usuarios/hooks/useUsuarios";
import { IUsuario } from "../../../usuarios/interfaces/IUsuario";

export const CriarConta = () => {
    const [usuarioCadastrado, setUsuarioCadastrado] = useState(false);
    const { loading, cadastrarUsuario } = useUsuarios();

    const { setValue, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(usuarioSchema),
        mode: "onChange",
    });

    const { mascaraTelefone } = useMascara();
    const telefoneValue = watch("telefone")
    useEffect(() => {
        setValue("telefone", mascaraTelefone(telefoneValue))
    }, [telefoneValue])

    const handleCadastrarUsuarios = async (dados: FieldValues) => {
        const dadosUsuario = dados as IUsuario;
        const resultadoCadastro = await cadastrarUsuario(dadosUsuario);
        setUsuarioCadastrado(resultadoCadastro);
    }

    return (
        <CriarContaPage
            container
            spacing={0}
        >
            <CriarContaBox>
                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <Typography sx={{ mr: 1 }} variant="h4" fontWeight={"bold"}>Space Food</Typography>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/logo.png`}
                        style={{ width: "124px", height: "76px" }}
                        alt="Space Food"
                    />
                </Box>
                <Box sx={{ mt: 5 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CheckCircle color="primary" />
                        <Box sx={{ ml: 1 }}>
                            <Typography variant="subtitle1" fontWeight={"bold"}>
                                Começe rápido
                            </Typography>
                            <Typography variant="subtitle2">
                                Cadastre seu primeiro estabelecimento, selecione um plano e prepare-se para decolar!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CheckCircle color="primary" />
                        <Box sx={{ ml: 1 }}>
                            <Typography variant="subtitle1" fontWeight={"bold"}>
                                Personalize a plataforma
                            </Typography>
                            <Typography variant="subtitle2">
                                Configure de forma simples e intuitiva as funcionalidades de acordo com as necessidades da sua operação.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CheckCircle color="primary" />
                        <Box sx={{ ml: 1 }}>
                            <Typography variant="subtitle1" fontWeight={"bold"}>
                                Junte-se a milhares de estabelecimentos
                            </Typography>
                            <Typography variant="subtitle2">
                                Faça parte da plataforma de Food Service que mais cresce no Brasil!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CriarContaBox>
            <form onSubmit={handleSubmit(handleCadastrarUsuarios)}>
                <Paper elevation={3}>
                    <Box sx={{
                        pt: 3,
                        pb: 3,
                        pr: 3,
                        pl: 3,
                        mx: 'auto',
                        display: 'flex',
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "350px",
                    }}>
                        {usuarioCadastrado ? (
                            <Box>
                                <Alert severity="success">
                                    <AlertTitle>Usuário criado com sucesso!</AlertTitle>
                                    Um e-mail foi enviado para <b>{watch('email')}</b> com os dados de acesso.
                                </Alert>

                                <Typography sx={{ mt: 3, fontSize: 14 }}>
                                    <Link href="/login" fontWeight="400" style={{ marginLeft: 2 }} color="primary">
                                        Acesse a plataforma
                                    </Link>
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <TextField
                                    style={{ margin: "0 0 1rem 0", width: "320px" }}
                                    label="Email"
                                    {...register('email')}
                                    value={watch('email')}
                                    error={!!errors.email}
                                    helperText={<>{errors.email?.message}</>}
                                />
                                <TextField
                                    style={{ marginBottom: "1rem", width: "320px" }}
                                    label="Nome"
                                    {...register('nome')}
                                    value={watch('nome')}
                                    error={!!errors.nome}
                                    helperText={<>{errors.nome?.message}</>}
                                />
                                <TextField
                                    style={{ marginBottom: "1rem", width: "320px" }}
                                    label="Telefone"
                                    {...register('telefone')}
                                    value={watch('telefone')}
                                    error={!!errors.telefone}
                                    helperText={<>{errors.telefone?.message}</>}
                                />

                                <Button type="submit" style={{ maxWidth: "320px" }} size="large" variant="contained" color="primary" fullWidth>
                                    {loading == true ? <CircularProgress color="inherit" /> : "Criar conta"}
                                </Button>

                                <Typography sx={{ mt: 3, fontSize: 14 }}>
                                    Já possui uma conta?
                                    <Link href="/login" fontWeight="400" style={{ marginLeft: 2 }} color="primary">
                                        Acesse
                                    </Link>
                                </Typography>
                            </>
                        )}
                    </Box>
                </Paper>
            </form>
        </CriarContaPage>
    );
}