import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const PageContainer = styled(Box)`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
`

export const DadoContainer = styled(Box)`
    margin-top: 1rem;
    padding: 0.5rem 0; 
    display: flex; 
    justify-content: space-between;
    border-bottom: 1px dashed ${props => props.theme.palette.divider};
    
    &:last-child {
        border-bottom: none;
    }
`