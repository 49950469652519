import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Chip, IconButton, ListItemIcon, Menu, MenuItem, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CardIndustrializadoCardapio } from '../../components/CardIndustrializadoCardapio';
import { CardPizzaCardapio } from '../../components/CardPizzaCardapio';
import { CardPreparadoCardapio } from '../../components/CardPreparadoCardapio';
import { ExcluirCategoriaModal } from '../../components/ExcluirCategoriaModal';
import { ExcluirProdutoModal } from '../../components/ExcluirProdutoModal';
import { TipoProduto } from '../../enum/TipoProduto';
import { useProdutos } from '../../hooks/useProdutos';
import { ICategoria } from "../../interfaces/ICategoria";
import { IProduto } from '../../interfaces/IProduto';
import { BodyCardContainer, BodySemProdutosCardContainer, ButtonsHeaderBox, CardContainer, ContextosBox, DetalhesHeaderBox, HeaderCardContainer, PeriodosExibicaoCardContainer } from "./styles";
import { useTamanhoTela } from '../../../core/hooks/useTamanhoTela';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { IMarketplace } from '../../../marketplace/interfaces/IMarketplace';
import { TipoMarketplaceCor, TipoMarketplaceTitulo } from '../../../marketplace/enum/TipoMarketplace';
import { ArrowDropDown } from '@mui/icons-material';
import { v4 } from 'uuid';
import { useLetras } from '../../../core/hooks/useLetras';
interface ICategoriaCard {
    categoria: ICategoria;
    marketplaces?: IMarketplace[];
    categorias: ICategoria[];
    excluirCategoria: (categoria: ICategoria) => Promise<boolean>
    handleRefresh: () => void;
}

export const CategoriaCard = ({ categoria, excluirCategoria, handleRefresh, categorias, marketplaces }: ICategoriaCard) => {
    const { excluir, salvar } = useProdutos();
    const navigate = useNavigate();
    const [dadosCategoria, setDadosCategoria] = useState<ICategoria | undefined>(undefined);
    const [produtoExclusao, setProdutoExclusao] = useState<IProduto | undefined>(undefined);
    const [excluirOpen, setExcluirOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const categoriaUuid = searchParams.get('categoriaUuid');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { modeloTela } = useTamanhoTela();

    const { titulo } = useLetras();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenExcluir = () => setExcluirOpen(true);
    const handleCloseExcluir = () => {
        setExcluirOpen(false);
        setTimeout(() => handleRefresh(), 300);
    }

    const handleCloseExcluirProduto = () => {
        setProdutoExclusao(undefined);
        setTimeout(() => handleRefresh(), 300);
    }

    const handleOpenExcluirProduto = (produto: IProduto) => {
        setProdutoExclusao(produto);
    }

    const handleExcluirProduto = async (uuid: string) => {
        if (!dadosCategoria || !dadosCategoria.uuid) {
            return false;
        }

        return await excluir(dadosCategoria.uuid, uuid);
    }

    const handleAccordionChange = (isExpanded: boolean) => {
        if (isExpanded) {
            navigate(`?categoriaUuid=${categoria.uuid}`);
        }
    };

    useEffect(() => {
        if (categoria) {
            setDadosCategoria(categoria);
        }
    }, [categoria])

    return (
        <Accordion
            sx={{ width: "100%" }}
            key={categoria.uuid}
            expanded={categoriaUuid === categoria.uuid}
            onChange={(_, isExpanded) => handleAccordionChange(isExpanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 2, width: "100%" }}>
                <Typography variant="h6" fontWeight={"600"}>
                    {dadosCategoria?.descricao?.toUpperCase()}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ width: "100%" }}>
                <HeaderCardContainer variant='outlined'>
                    <DetalhesHeaderBox>
                        <Typography fontWeight={"bold"}>
                            Posição:
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {categoria?.posicao}
                        </Box>
                    </DetalhesHeaderBox>
                    <DetalhesHeaderBox>
                        <Typography fontWeight={"bold"}>
                            Contextos:
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {dadosCategoria?.contextos?.length ? (
                                <Typography sx={{ overflowX: "auto", fontSize: 12 }}>
                                    {titulo(dadosCategoria?.contextos?.join(`, `))}
                                </Typography>
                            ) : "-"}
                        </Box>
                    </DetalhesHeaderBox>
                    <DetalhesHeaderBox>
                        <Typography fontWeight={"bold"}>
                            Marketplaces:
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {marketplaces?.length ? marketplaces?.map(marketplace => (
                                <Tooltip title={marketplace.nome} sx={{ mr: 0.3 }}>
                                    <Chip
                                        size='small'
                                        label={TipoMarketplaceTitulo[marketplace.tipo]}
                                        style={{ background: TipoMarketplaceCor[marketplace.tipo], color: "white" }}
                                    />
                                </Tooltip>
                            )) : "-"}
                        </Box>
                    </DetalhesHeaderBox>
                    <DetalhesHeaderBox>
                        <Typography fontWeight={"bold"}>
                            Nº de produtos:
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography>{dadosCategoria?.produtos?.length}</Typography>
                        </Box>
                    </DetalhesHeaderBox>
                    <ButtonsHeaderBox>
                        {dadosCategoria && (
                            <>
                                <Button
                                    onClick={handleClick}
                                    variant="contained"
                                    aria-label="more"
                                    id={`categoria-menu-button-${dadosCategoria.uuid}`}
                                    aria-controls={open ? `categoria-menu-${dadosCategoria.uuid}` : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    endIcon={
                                        <ArrowDropDown
                                            sx={{ transform: open ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                                        />
                                    }
                                >
                                    AÇÕES
                                </Button>
                                <Menu
                                    id={`categoria-menu-${dadosCategoria.uuid}`}
                                    MenuListProps={{
                                        'aria-labelledby': `categoria-menu-${dadosCategoria.uuid}`,
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <Tooltip title='Cadastre um novo produto na categoria'>
                                        <MenuItem onClick={() => navigate(`categorias/${dadosCategoria.uuid}/produtos`)}>
                                            <ListItemIcon>
                                                <AddIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" noWrap>
                                                Produto
                                            </Typography>
                                        </MenuItem>
                                    </Tooltip>
                                    <Tooltip title='Edite a categoria'>
                                        <MenuItem onClick={() => navigate(`categorias/${dadosCategoria.uuid}`)}>
                                            <ListItemIcon>
                                                <EditIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" noWrap>
                                                Editar
                                            </Typography>
                                        </MenuItem>
                                    </Tooltip>
                                    <Tooltip title='Exclua a categoria'>
                                        <MenuItem onClick={() => handleOpenExcluir()}>
                                            <ListItemIcon>
                                                <DeleteIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" noWrap>
                                                Excluir
                                            </Typography>
                                        </MenuItem>
                                    </Tooltip>
                                </Menu>
                                <ExcluirCategoriaModal handleRefresh={handleRefresh} excluirCategoria={excluirCategoria} categoria={dadosCategoria} open={excluirOpen} handleClose={handleCloseExcluir} />
                                <ExcluirProdutoModal
                                    handleRefresh={handleRefresh}
                                    produto={produtoExclusao}
                                    open={produtoExclusao ? true : false}
                                    excluirProduto={handleExcluirProduto}
                                    handleClose={handleCloseExcluirProduto}
                                />
                            </>
                        )}
                    </ButtonsHeaderBox>
                </HeaderCardContainer>
                {categoria?.periodosExibicao?.length ? (
                    <PeriodosExibicaoCardContainer variant='outlined'>
                        {categoria.periodosExibicao?.map(periodo => {
                            return (
                                <DetalhesHeaderBox>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {titulo(periodo.diaSemana)}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        {periodo?.horarios?.map(horario => {
                                            return (
                                                <Typography color={"GrayText"} sx={{ fontSize: 12 }}>
                                                    {horario.inicio} - {horario.fim}
                                                </Typography>
                                            );
                                        })}
                                    </Box>
                                </DetalhesHeaderBox>
                            )
                        })}
                    </PeriodosExibicaoCardContainer>
                ) : ''}
                {modeloTela === "mobile" && (
                    <Tooltip title='Contextos onde a categoria será apresentada'>
                        <ContextosBox>
                            {dadosCategoria?.contextos?.map(contexto => (
                                <Chip key={contexto} label={contexto.toUpperCase()} />
                            ))}
                        </ContextosBox>
                    </Tooltip>
                )}
                {dadosCategoria?.produtos?.length ? (
                    <BodyCardContainer container spacing={1}>
                        {dadosCategoria?.produtos?.map(produto => {
                            if (produto.tipo === TipoProduto.pizza && dadosCategoria.uuid) {
                                return <CardPizzaCardapio
                                    key={produto.uuid}
                                    handleRefresh={handleRefresh}
                                    categorias={categorias}
                                    categoriaUuid={dadosCategoria.uuid}
                                    pizza={produto}
                                    excluir={handleOpenExcluirProduto}
                                />
                            }
                            if (produto.tipo === TipoProduto.industrializado && dadosCategoria.uuid) {
                                return <CardIndustrializadoCardapio
                                    key={produto.uuid}
                                    handleRefresh={handleRefresh}
                                    categorias={categorias}
                                    categoriaUuid={dadosCategoria.uuid}
                                    produto={produto}
                                    excluir={handleOpenExcluirProduto}
                                />
                            }
                            if (produto.tipo === TipoProduto.preparado && dadosCategoria.uuid) {
                                return <CardPreparadoCardapio
                                    key={produto.uuid}
                                    handleRefresh={handleRefresh}
                                    categorias={categorias}
                                    categoriaUuid={dadosCategoria.uuid}
                                    produto={produto}
                                    excluir={handleOpenExcluirProduto}
                                />
                            }
                        })}
                    </BodyCardContainer>
                ) : (
                    <BodySemProdutosCardContainer>
                        Nenhum produto cadastrado
                    </BodySemProdutosCardContainer>
                )}
            </AccordionDetails>
        </Accordion>
    )
}
