import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { AutenticacaoProvider } from './features/core/contexts/AutenticacaoContext';
import { DrawerContextProvider } from './features/core/contexts/DrawerContext';
import { SnackbarProvider } from './features/core/contexts/SnackbarContext';
import { AppThemeProvider } from './features/core/contexts/ThemeContext';
import { CoreRoutes } from './features/core/routes/index';
import GlobalStyles from './features/core/styles/globalStyle';

function App() {
    return (
        <BrowserRouter>
            <AutenticacaoProvider>
                <AppThemeProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <SnackbarProvider>
                            <DrawerContextProvider>
                                <GlobalStyles />
                                <CoreRoutes />
                            </DrawerContextProvider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </AppThemeProvider>
            </AutenticacaoProvider>
        </BrowserRouter>
    );
}

export default App;
