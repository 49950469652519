import styled from "@emotion/styled";
import { Box, Paper, TableContainer } from "@mui/material";

export const ConfiguracaoBordas = styled(Paper)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;
    flex-direction: column;
`

export const FiltroContainer = styled(Box)`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        margin-left: 0.5rem;
    }

`

export const TabelaVazia = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 160px;
    height: 100%;
`

export const Formulario = styled.form`
    width: 100%;
    margin-top: 1rem;
`

export const FormularioCard = styled(Box)`
    width: 100%;
`

export const ButtonsContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-top: 2rem;

    button {
        &:first-child {
            margin-right: 0.5rem;
        }
    }
`

export const ButtonsContainerForm = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    button {
        &:first-child {
            margin-right: 0.5rem;
        }
    }
`

