import styled from "@emotion/styled";
import { Box, Card, CardActionArea, Paper } from "@mui/material";

export const ComplementosContainer = styled(Paper)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;
    flex-direction: column;
`

export const NovoComplementoCard = styled(Card)`
    width: 400px;
    height: 60px;
    color: ${props => props.theme.palette.primary.main};
    border: 1px solid ${props => props.theme.palette.primary.main};
`

export const NovoComplementoActionArea = styled(CardActionArea)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    h6 {
        font-weight: bold;
        margin-left: 1rem;
    }
`

export const NenhumComplementoCadastrado = styled(Card)`
    margin-top: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ComplementoCard = styled(Card)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 1rem;
    min-height: 80px;
    max-height: 80px;
`

export const FiltroContainer = styled(Box)`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        margin-left: 0.5rem;
    }

`

export const ButtonsContainerForm = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    button {
        &:first-child {
            margin-right: 0.5rem;
        }
    }
`
