import { Box } from "@mui/material";
import { ReactNode, useState } from "react"
import { ModulosDrawer } from "../ModulosDrawer";
import { Header } from "../Header";
import { AppDrawer } from "../Drawer";
import { IMenu } from "../../interfaces/IMenu";
import { useTamanhoTela } from "../../hooks/useTamanhoTela";

interface LayoutProps {
    modeloTela?: "mobile" | "desktop" | undefined;
    titulo: string;
    itensMenu?: IMenu[];
    drawer: ReactNode | boolean;
    children?: ReactNode
}

export const Layout = ({ titulo, itensMenu, drawer, children }: LayoutProps) => {
    const [menuMinimizado, setMenuMinimizado] = useState(false);
    const minimizar = () => setMenuMinimizado(true);
    const aumentar = () => setMenuMinimizado(false);
    const { modeloTela } = useTamanhoTela();

    return (
        <>
            <ModulosDrawer />
            <Header tituloModulo={titulo} />
            <Box sx={{
                paddingLeft: drawer && modeloTela == "desktop" ? (menuMinimizado ? "50px" : "240px") : "0px"
            }}>
                {typeof drawer !== "boolean" ? drawer : (
                    drawer == true ? (
                        <AppDrawer
                            titulo={titulo}
                            aumentar={aumentar}
                            minimizar={minimizar}
                            itensMenu={itensMenu ?? []}
                            menuMinimizado={menuMinimizado}
                        />
                    ) : ""
                )}
                {children}
            </Box>
        </>
    )
}