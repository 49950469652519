import styled from "@emotion/styled";
import { Box, Button, Card, Paper } from "@mui/material";

export const Numeros = styled(Box)`
    display: flex;
    width: 120px;
    height: 40px;

    .numero-display {
        padding: 1rem;
        border-radius: 0;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-top: 1px solid ${props => props.theme.palette.primary.main};
        border-bottom: 1px solid ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.main};
    }
`

export const IconeButton = styled(Card)`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};

    &:first-child {
        border-radius: 8px 0 0 8px;
    }
    
    &:last-child {
        border-radius: 0 8px 8px 0;
    }
`