import styled from "@emotion/styled"
import { Box, Paper } from "@mui/material"

export const ConfiguracaoContainer = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem auto;
`

export const Formulario = styled(Paper)`
    padding: 1rem;
    width: 100%;
`

export const ButtonsContainer = styled(Box)`
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`