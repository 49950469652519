import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import { ICategoria } from "../../interfaces/ICategoria";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useState } from "react";

interface ExcluirCategoriaModalProps {
    open: boolean;
    handleClose: () => void;
    categoria: ICategoria;
    handleRefresh?: () => void;
    excluirCategoria: (categoria: ICategoria) => Promise<boolean>
}

export const ExcluirCategoriaModal = ({ handleClose, open, categoria, excluirCategoria, handleRefresh }: ExcluirCategoriaModalProps) => {
    const snackbar = useSnackbar();
    const [confirmacao, setConfirmacao] = useState('');

    const handleCloseExclusao = () => {
        setConfirmacao('');
        handleClose();
    }

    const handleExcluirCategoria = async () => {
        if(categoria.uuid) {
            const resultado = await excluirCategoria(categoria);

            if(!resultado)
            return snackbar("Ocorreu um erro ao excluir a categoria", { severity: "error" });
            
            if(handleRefresh) {
                handleRefresh();
            }

            handleCloseExclusao();
            return snackbar("Categoria excluída com sucesso", { severity: "success"});
        }
    }

    return (
        <Dialog open={open} onClose={handleCloseExclusao}>
            <DialogTitle>
                Excluir categoria: {categoria.descricao}
            </DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    A exclusão não pode ser revertida
                </Alert>
                <Typography sx={{marginTop: "1rem"}}>
                    Para confirmar a exclusão, digite <b>{categoria.descricao}</b>  no campo.
                </Typography>
                <TextField 
                    value={confirmacao} 
                    onChange={(e) => setConfirmacao(e.target.value)}
                    sx={{ width: "100%", marginTop: "0.5rem"}}
                    size="small"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseExclusao}>Cancelar</Button>
                <Button 
                    onClick={handleExcluirCategoria} 
                    type="button" 
                    variant="contained"
                    disabled={categoria.descricao.trim() != confirmacao.trim()}
                >
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
    )
}