import styled from "@emotion/styled";
import { Box, Paper, lighten } from "@mui/material";

export const DropzoneContainer = styled(Box)`
    width: 100%;
    height: 100%;
    border: 2px dashed ${props => props.theme.palette.primary.main};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s;
    cursor: pointer;

    b {
        color: ${props => props.theme.palette.primary.main};
    }
    &:hover {
        background-color: ${props => lighten(props.theme.palette.primary.main, 0.95)};
    }
`

export const UploadBox = styled(Box)`
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => lighten(props.theme.palette.primary.main, 0.7)};
    color: ${props => props.theme.palette.primary.main};
`