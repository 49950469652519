import { useEffect, useMemo, useState } from "react";
import { useFetch } from "../../core/hooks/useFetch";
import { IIntervaloCaixa } from "../interfaces/IIntervaloCaixa";
import { useCaixa } from "./useCaixa";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IItemConta } from "../interfaces/IItemConta";

export function useFetchCaixaAberto() {
    const apiFinanceiro = useMemo(() => ApiFactory.getApi("MS_FINANCEIRO"), []);
    const [ultimoCaixa, setUltimoCaixa] = useState<IIntervaloCaixa | undefined | null>(undefined);
    const { buscarUltimoCaixaAberto } = useCaixa();
    const { data: caixa, isLoading, handleRefresh, error, mutate } = useFetch<IIntervaloCaixa>(apiFinanceiro, "/caixa/intervalo/aberto", { refreshInterval: 60000 })

    const handleBuscarUltimoCaixa = async () => {
        const ultimoCaixa = await buscarUltimoCaixaAberto();
        if (ultimoCaixa) {
            setUltimoCaixa(ultimoCaixa);
        }
    }

    useEffect(() => {
        if (error && error.response.status == 404) {
            handleBuscarUltimoCaixa();
        }
    }, [error])

    const {
        data: contasAPagar,
        isLoading: isLoadingContasAPagar
    } = useFetch<IItemConta[]>(ApiFactory.getApi("MS_FINANCEIRO"), '/contas/contas-a-pagar');

    const {
        data: contasAReceber,
        isLoading: isLoadingContasAReceber
    } = useFetch<IItemConta[]>(ApiFactory.getApi("MS_FINANCEIRO"), '/contas/contas-a-receber');

    const {
        data: ticketMedio,
        isLoading: isLoadingTicketMedio
    } = useFetch<number>(ApiFactory.getApi("MS_FINANCEIRO"), '/contas/ticket-medio');

    const totalContasAPagar = useMemo(() => {
        let total = 0;

        contasAPagar?.map(item => {
            total = total + item.valorTotal;
        })

        return total
    }, [contasAPagar])

    const totalContasAReceber = useMemo(() => {
        let total = 0;

        contasAReceber?.map(item => {
            total = total + item.valorTotal;
        })

        return total
    }, [contasAReceber])

    return {
        caixa, isLoading, handleRefresh, error, ultimoCaixa, mutate, contasAPagar,
        isLoadingContasAPagar,
        totalContasAPagar,
        contasAReceber,
        isLoadingContasAReceber,
        totalContasAReceber,
        ticketMedio,
        isLoadingTicketMedio
    }
}