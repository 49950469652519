import styled from "@emotion/styled";
import { Box, Grid, Paper, Stepper, Typography, lighten } from "@mui/material";

export const PageContainer = styled(Box)`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
`

export const ContentContainer = styled(Box)`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    @media(max-width: 1080px) {
        flex-direction: column;
    }
`

export const FormContainer = styled(Box)`
    width: 54%;

    @media(max-width: 1080px) {
        width: 100%;
    }
`

export const ResumoPaper = styled(Paper)`
    width: 44%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 50%;

    @media(max-width: 1080px) {
        width: 100%;
        height: 100%;
        margin-top: 1rem;
    }
`

export const ResumoFlex = styled(Box)`
    margin-top: 2rem;
    height: 100%;
    display: flex;
    justify-content: space-between;

    @media(max-width: 1080px) {
        flex-direction: column;

        div {
            width: 100%;

            &:first-child {
                margin-bottom: 2rem;
            }
        }
    }
`

export const ButtonsContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    
    button {
        width: 120px;
    }
`

export const StepperContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CustomStepper = styled(Stepper)`
    width: 100%;
`

export const HeaderBar = styled(Paper)`
    border-top: 0;
    border-left: 0;
    border-right: 0;
    display: flex;
    border-radius: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
`

export const StatusHeader = styled(Box)`
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 4px;

`

export const StatusLabel = styled(Typography)`
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.success.main};
    background-color: ${props => lighten(props.theme.palette.success.main, 0.9)};
    padding: 0.5rem 1rem;
    border-radius: 1rem;
`;

export const MinOrderContainer = styled(Box)`
    display: flex;
    align-items: center;
    font-weight: bold;
`;

export const Dot = styled(Box)`
    height: 8px;
    width: 8px;
    background-color: ${props => props.theme.palette.success.main};
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
`;

export const MinOrderText = styled(Typography)`
    margin-left: 8px;
`;

export const CategoriasContainer = styled(Box)`

`

export const BuscaContainer = styled(Box)`
    padding: 1rem;
`

export const CategoriaContainer = styled(Grid)`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
`

export const ButtonCarrinhoContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`