import styled from "@emotion/styled";
import { Box, Card, CardContent } from "@mui/material";

export const CardContainer = styled(Card)`
    width: 300px;
`

export const CardContentContainer = styled(CardContent)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`