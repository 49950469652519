import { Box, Button, TextField } from "@mui/material";
import { TecladoContainer, TecladoRow } from "./styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import CheckIcon from '@mui/icons-material/Check';
import { FormEvent, useEffect, useState } from "react";
import { useTamanhoTela } from "../../hooks/useTamanhoTela";

interface TecladoNumerosProps {
    handleNumeroChange: (numero: string) => void;
    concluir?: () => void;
}

export const TecladoNumeros = ({ handleNumeroChange, concluir }: TecladoNumerosProps) => {
    const [numero, setNumero] = useState<string>('');

    useEffect(() => {
        handleNumeroChange(numero);
    }, [numero])

    const adicionarNumero = (numeroAdicionado: number) => {
        setNumero((numeroAnterior) => `${numeroAnterior}${numeroAdicionado}`)
    }

    const removerNumero = () => {
        setNumero((numeroAnterior) => numeroAnterior.slice(0, -1));
    }

    const handleNumeroInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNumero(event.target.value)
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(concluir) {
            concluir();
        }
    }

    return (
        <TecladoContainer variant="outlined">
            <form onSubmit={handleSubmit}>
                <TextField
                    placeholder="Número"
                    sx={{ marginBottom: "1rem" }}
                    value={numero}
                    fullWidth
                    onChange={handleNumeroInputChange}
                />
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <TecladoRow>
                        <Button type="button" onClick={() => adicionarNumero(1)} variant="contained">1</Button>
                        <Button type="button" onClick={() => adicionarNumero(2)} variant="contained">2</Button>
                        <Button type="button" onClick={() => adicionarNumero(3)} variant="contained">3</Button>
                    </TecladoRow>
                    <TecladoRow>
                        <Button type="button" onClick={() => adicionarNumero(4)} variant="contained">4</Button>
                        <Button type="button" onClick={() => adicionarNumero(5)} variant="contained">5</Button>
                        <Button type="button" onClick={() => adicionarNumero(6)} variant="contained">6</Button>
                    </TecladoRow>
                    <TecladoRow>
                        <Button type="button" onClick={() => adicionarNumero(7)} variant="contained">7</Button>
                        <Button type="button" onClick={() => adicionarNumero(8)} variant="contained">8</Button>
                        <Button type="button" onClick={() => adicionarNumero(9)} variant="contained">9</Button>
                    </TecladoRow>
                    <TecladoRow>
                        <Button type="button" onClick={() => adicionarNumero(0)} variant="contained">0</Button>
                        <Button type="button" onClick={() => removerNumero()} variant="contained"><BackspaceIcon /></Button>
                        <Button type="submit" variant="contained"><CheckIcon /></Button>
                    </TecladoRow>
                </Box>
            </form>
        </TecladoContainer>
    );
}