import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Box } from "@mui/system";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUsuarios } from "../../../usuarios/hooks/useUsuarios";
import { novaSenhaSchema } from "../../../usuarios/schemas/novaSenhaSchema";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { useUrlToken } from "../../hooks/useUrlToken";
import { removeToken } from "../../contexts/AutenticacaoContext";

interface FormData {
    novaSenha: string;
    confirmacaoSenha: string;
}

export const AlterarSenha = () => {
    const { alterarSenha } = useUsuarios();
    const [verNovaSenha, setVerNovaSenha] = useState(false);
    const [verConfirmacaoSenha, setVerConfirmacaoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
        resolver: yupResolver(novaSenhaSchema),
        mode: "onChange"
    });

    useUrlToken();

    const handleAlterarSenha = async ({novaSenha, confirmacaoSenha}: FieldValues) => {
        if(novaSenha != confirmacaoSenha) {
            showSnackbar('As senhas estão diferentes', {severity: 'error'});
            return;
        }

        setLoading(true);
        const result = await alterarSenha(confirmacaoSenha);
        setLoading(false);
        removeToken("TOKEN_RECUPERAR_SENHA_KEY");
        if(result == true) {
            navigate('/login');
        }
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <Typography sx={{mr: 5}}variant="h4" fontWeight={"bold"}>Space Food</Typography>
                <img 
                    src={`${process.env.PUBLIC_URL}/images/logo.png`} 
                    style={{ width: "124px", height: "76px" }}
                    alt="Space Food" 
                />
            </Box>
            <Box sx={{mt: 2, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                <Typography variant="h6" fontWeight={"bold"}>Altere a sua senha</Typography>
            </Box>
            <Paper sx={{mt: 5}} elevation={0}>
                <form onSubmit={handleSubmit(handleAlterarSenha)}>
                        <Box sx={{ 
                            pt: 6, 
                            pb: 6, 
                            pr: 3, 
                            pl: 3, 
                            mx: 'auto', 
                            display: 'flex', 
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "400px", 
                        }}>
                            <TextField 
                                style={{ marginBottom: "1rem", width: "360px" }} 
                                label="Nova senha" 
                                type={verNovaSenha ? 'text' : 'password'} 
                                {...register('novaSenha')}
                                error={!!errors.novaSenha}
                                helperText={<>{errors.novaSenha?.message}</>}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        onClick={() => setVerNovaSenha(!verNovaSenha)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                        >
                                        {verNovaSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                    )
                                }}
                            />
                            <TextField 
                                style={{ marginBottom: "1rem", width: "360px" }} 
                                label="Confirme sua nova senha" 
                                type={verConfirmacaoSenha ? 'text' : 'password'} 
                                {...register('confirmacaoSenha')}
                                error={!!errors.confirmacaoSenha}
                                helperText={<>{errors.confirmacaoSenha?.message}</>}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        onClick={() => setVerConfirmacaoSenha(!verConfirmacaoSenha)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                        >
                                        {verConfirmacaoSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                    )
                                }}
                            />
                            <Button style={{ maxWidth: "360px" }} size="large" variant="contained" color="primary" type="submit" fullWidth>
                                {loading == true ? <CircularProgress color="inherit" /> : "Alterar"}
                            </Button>
                        </Box>
                    </form>
            </Paper>
        </Grid> 
    );
}