import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const ClientePaperContainer = styled(Paper)`
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;

    div:first-child {
        width: 30px;
        height: 30px;
    }

    div:last-child {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
`