import { Alert, Avatar, Box, Button, Chip, Container, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useFetchMarketplaces } from "../../hooks/useFetchMarketplaces";
import { NenhumMarketplaceGrid, ToolbarBox, ToolbarPaper } from "./styles";
import { Add, Lock, LockOpen, MoreVert, ShoppingCart } from "@mui/icons-material";
import { blue, red } from "@mui/material/colors";
import { TipoMarketplace, TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../enum/TipoMarketplace";
import { useNavigate } from "react-router-dom";
import { ReferenciaImagemMarketplace } from "../../enum/ReferenciaImagemMarketplace";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { useEffect } from "react";

export const ListarMarketplaces = () => {
    const { marketplaces, loading } = useFetchMarketplaces();

    const navigate = useNavigate();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Listagem de marketplaces")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <>
            <ToolbarPaper>
                <Typography variant="h6" fontWeight={"bold"}>
                    {loading ? "Carregando marketplaces..." : `${marketplaces.length == 1 ? `1 Marketplace` : `${marketplaces.length} Marketplaces`} `}
                </Typography>
                <ToolbarBox>
                    <Button onClick={() => navigate("/marketplace/cadastrar")} variant="contained" startIcon={<Add />}>
                        Cadastrar marketplace
                    </Button>
                </ToolbarBox>
            </ToolbarPaper>
            <Container>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        {loading ? (
                            <Grid container spacing={4}>
                                {Array.from({ length: 6 }, (_, index) => (
                                    <Grid item xs={12} md={4} sm={6} key={index}>
                                        <Skeleton variant="rounded" height={175} />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            marketplaces?.length ? (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Logo</TableCell>
                                                <TableCell>Nome</TableCell>
                                                <TableCell>Tipo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Detalhes</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {marketplaces.map((marketplace) => {
                                                const logoUrl = marketplace.
                                                   configuracao?.
                                                   personalizacao?.
                                                   imagens?.
                                                   find(imagem => imagem.referencia == ReferenciaImagemMarketplace.logo)?.
                                                   url;

                                                return (
                                                    (
                                                        <TableRow key={marketplace.uuid}>
                                                            <TableCell>
                                                                {logoUrl ? (
                                                                    <img
                                                                        style={{ width: "40px", height: "40px", objectFit: "contain", borderRadius: "1rem" }}
                                                                        src={logoUrl}
                                                                    />
                                                                ) : (
                                                                    <Avatar>
                                                                        {marketplace?.nome && marketplace.nome.charAt(0).toUpperCase()}
                                                                    </Avatar>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{marketplace.nome}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title={TipoMarketplaceDescricao[marketplace.tipo]}>
                                                                    <Chip
                                                                        size="small"
                                                                        label={TipoMarketplaceTitulo[marketplace.tipo]}
                                                                        style={{ background: TipoMarketplaceCor[marketplace.tipo], color: "white" }}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell>{marketplace.descricao ? marketplace.descricao : TipoMarketplaceDescricao[marketplace.tipo]}</TableCell>
                                                            <TableCell>
                                                                {marketplace?.configuracao?.status && (
                                                                    <Chip
                                                                        label={marketplace.configuracao.status.aberto ? "Aberto" : "Fechado"}
                                                                        color={marketplace.configuracao.status.aberto ? "success" : "error"}
                                                                        size="small"
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton onClick={() => navigate(`/marketplace/${marketplace.uuid}`)} disabled={marketplace.tipo == TipoMarketplace.PDV}>
                                                                    <MoreVert />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <NenhumMarketplaceGrid md={12}>
                                    <Typography variant="h4">Vamos Decolar com os Marketplaces?</Typography>
                                    <Box sx={{ maxWidth: "700px" }}>
                                        <Typography variant="subtitle1" sx={{ mt: 3 }}>Conecte seu restaurante com plataformas de vendas online como <b style={{ color: red[800] }}>iFood</b> e configure seu <b style={{ color: blue[800] }}>Cardápio SpaceFood</b>  para alcançar mais clientes, aumentar suas vendas e automatizar seu atendimento. Vamos decolar juntos?</Typography>
                                    </Box>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/bot.png`}
                                        alt="Bot"
                                        style={{ marginTop: "3rem", width: "250px" }}
                                    />
                                    <Button onClick={() => navigate("/marketplace/cadastrar")} startIcon={<ShoppingCart />} sx={{ mt: 3 }} size="large" variant="contained" color="secondary">
                                        Cadastrar marketplace
                                    </Button>
                                </NenhumMarketplaceGrid>
                            )
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
