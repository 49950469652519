import { Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormLabel, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useFetchUsuarios } from "../../hooks/useFetchUsuarios";
import { useEffect, useState } from "react";
import { IUsuario } from "../../interfaces/IUsuario";
import { useDataHora } from '../../../core/hooks/useDataHora';
import { useFetchPerfis } from '../../../perfis/hooks/useFetchPerfis';
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { filtrosUsuariosSchema } from "../../schemas/filtrosUsuariosSchema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowDropDown, Block, Lock, LockOpen, SwitchAccount } from "@mui/icons-material";
import { useUsuarios } from "../../hooks/useUsuarios";
import { ModalInativacao } from "./containers/ModalInativacao";
import { ModalAlterarPerfil } from "./containers/ModalAlterarPerfil";
import { ToolbarPaper, ToolbarBox, AcoesBox } from "./styles";

export const ListarUsuarios = () => {
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [usuariosPorPagina, setUsuariosPorPagina] = useState(5);
    const { usuarios, isLoading, handleRefresh } = useFetchUsuarios();
    const { perfis, isLoading: isLoadingPerfis } = useFetchPerfis();
    const { dataHoraBr } = useDataHora();
    const { estabelecimento, usuario: usuarioSessao } = useAutenticacaoContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [usuariosSelecionados, setUsuariosSelecionados] = useState<IUsuario[]>([]);

    const selecionarUsuario = (usuario: IUsuario) => {
        const selectedIndex = usuariosSelecionados.findIndex(u => u.uuid === usuario.uuid);
        let newSelected: IUsuario[] = [];

        if (selectedIndex === -1) {
            newSelected = [...usuariosSelecionados, usuario];
        } else {
            newSelected = [...usuariosSelecionados.slice(0, selectedIndex), ...usuariosSelecionados.slice(selectedIndex + 1)];
        }

        setUsuariosSelecionados(newSelected);
    };

    const limparUsuariosSelecionados = () => setUsuariosSelecionados([]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, novaPagina: number) => {
        setPaginaAtual(novaPagina);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsuariosPorPagina(parseInt(event.target.value, 10));
        setPaginaAtual(0);
    };

    const { setValue, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(filtrosUsuariosSchema),
        mode: "onChange",
    });

    const handleFiltrar = (values: FieldValues) => {
        setSearchParams(state => {
            if (values.nome) {
                state.set('nome', values.nome);
            } else {
                state.delete('nome');
            }
            if (values.email) {
                state.set('email', values.email);
            } else {
                state.delete('email');
            }
            if (values.perfil) {
                state.set('perfil', values.perfil);
            } else {
                state.delete('perfil');
            }
            return state;
        });
    }

    const handleLimpar = () => {
        setSearchParams(state => {
            state.delete('nome');
            state.delete('email');
            state.delete('perfil');
            return state;
        });

        setValue('nome', undefined)
        setValue('email', undefined)
        setValue('perfil', undefined)
        limparUsuariosSelecionados();
    }

    const nomeFiltro = searchParams.get('nome') || '';
    const emailFiltro = searchParams.get('email') || '';
    const perfilFiltro = searchParams.get('perfil') || '';

    useEffect(() => {
        if (nomeFiltro) setValue('nome', nomeFiltro);
        if (emailFiltro) setValue('email', emailFiltro);
        if (perfilFiltro) setValue('perfil', perfilFiltro);
    }, [nomeFiltro, emailFiltro, perfilFiltro, setValue]);

    const usuariosFiltrados = usuarios?.filter((usuario: IUsuario) => {
        const matchNome = nomeFiltro ? usuario.nome.toLowerCase().includes(nomeFiltro.toLowerCase()) : true;
        const matchEmail = emailFiltro ? usuario.email.toLowerCase().includes(emailFiltro.toLowerCase()) : true;
        const matchPerfil = perfilFiltro ? usuario.acessos?.find(acesso => acesso.perfilUuid == perfilFiltro) : true;
        return matchPerfil && matchNome && matchEmail;
    }) ?? [];

    const usuariosExibidos: IUsuario[] = usuariosFiltrados.slice(paginaAtual * usuariosPorPagina, (paginaAtual + 1) * usuariosPorPagina);

    const perfisOptions = perfis?.map((perfil) => {
        return (
            <MenuItem key={perfil.uuid} value={perfil.uuid}>
                {perfil.nome}
            </MenuItem>
        );
    });

    const { loading, inativarUsuarios, reativarUsuarios, alterarPerfil } = useUsuarios();
    const handleInativarUsuarios = async () => {
        if (loading) {
            return;
        }

        await inativarUsuarios(usuariosSelecionados);
        handleModalInativacao();
        handleRefresh();
        limparUsuariosSelecionados();
        handleClose();
    }

    const handleReativarUsuarios = async () => {
        if (loading) {
            return;
        }

        await reativarUsuarios(usuariosSelecionados);
        handleRefresh();
        limparUsuariosSelecionados();
        handleClose();
    }

    const handleAlterarPerfil = async (perfilUuid: string) => {
        if (loading) {
            return;
        }

        if (!usuariosSelecionados[0]?.uuid) {
            return;
        }

        await alterarPerfil(usuariosSelecionados[0].uuid, perfilUuid);
        handleRefresh();
        limparUsuariosSelecionados();
        handleModalAlterarPerfil();
        handleClose();
    }

    const [openModalInativacao, setOpenModalInativacao] = useState(false);

    const handleModalInativacao = () => {
        setOpenModalInativacao(!openModalInativacao);
    };

    const [openModalAlterarPerfil, setOpenModalAlterarPerfil] = useState(false);

    const handleModalAlterarPerfil = () => {
        setOpenModalAlterarPerfil(!openModalAlterarPerfil);
    };

    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Listagem de usuários")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <Box>
            <ModalInativacao
                open={openModalInativacao}
                handleClose={handleModalInativacao}
                handleConfirm={handleInativarUsuarios}
            />
            <ModalAlterarPerfil
                open={openModalAlterarPerfil}
                handleClose={handleModalAlterarPerfil}
                perfis={perfis}
                handleConfirm={handleAlterarPerfil}
                estabelecimentoUuid={estabelecimento?.uuid}
                usuario={usuariosSelecionados[0]}
            />
            <ToolbarPaper>
                <Typography variant="h6" fontWeight={"bold"}>
                    {isLoading ? "Carregando usuários..." : `${usuariosExibidos.length} Usuários`}
                </Typography>
                <form onSubmit={handleSubmit(handleFiltrar)}>
                    <ToolbarBox>
                        <FormControl className="input-toolbar" sx={{ mr: 2, minWidth: "200px" }}>
                            <InputLabel
                                id="perfil-label"
                                error={!!errors.perfil}
                                size="small"
                            >
                                Perfil*
                            </InputLabel>
                            <Select
                                labelId="perfil-label"
                                id="perfil"
                                placeholder="Selecione um perfil"
                                {...register("perfil")}
                                value={watch("perfil") || ''}
                                error={!!errors.perfil}
                                label="Perfil"
                                defaultValue={undefined}
                                size="small"
                            >
                                {perfisOptions}
                            </Select>
                        </FormControl>
                        <FormControl className="input-toolbar" sx={{ mr: 2 }}>
                            <TextField
                                id="email"
                                label="E-mail"
                                type="text"
                                fullWidth
                                {...register("email")}
                                error={!!errors.email}
                                helperText={<>{errors.email?.message}</>}
                                size="small"
                            />
                        </FormControl>
                        <FormControl className="input-toolbar">
                            <TextField
                                id="nome"
                                label="Nome"
                                type="text"
                                fullWidth
                                {...register("nome")}
                                error={!!errors.nome}
                                helperText={<>{errors.nome?.message}</>}
                                size="small"
                            />
                        </FormControl>
                        <Box sx={{ ml: "1rem" }}>
                            <Button
                                variant="outlined"
                                sx={{ marginRight: "10px" }}
                                onClick={() => handleLimpar()}
                            >
                                Limpar
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Filtrar
                            </Button>
                        </Box>
                        <Divider sx={{ ml: 2, mr: 2 }} orientation="vertical" flexItem />
                        <AcoesBox>
                            <Button
                                onClick={handleClick}
                                endIcon={<ArrowDropDown />}
                                variant={"contained"}
                                disabled={usuariosSelecionados?.length <= 0}
                            >
                                Ações
                            </Button>
                            <Menu
                                id={`usuarios-menu`}
                                MenuListProps={{
                                    'aria-labelledby': `usuarios-menu`,
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem disabled>
                                    <Typography variant="inherit" noWrap>
                                        {usuariosSelecionados?.length} {usuariosSelecionados?.length == 1 ? "usuário" : "usuários"} {usuariosSelecionados?.length == 1 ? "selecionado" : "selecionados"}
                                    </Typography>
                                </MenuItem>
                                <Divider />
                                <Tooltip title='Inative o acesso do usuário'>
                                    <MenuItem disabled={usuariosSelecionados?.length < 1} onClick={handleModalInativacao}>
                                        <ListItemIcon color="inherit">
                                            <Lock fontSize={"small"} />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Inativar
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip title='Reative o acesso do usuário'>
                                    <MenuItem disabled={usuariosSelecionados?.length < 1} onClick={handleReativarUsuarios}>
                                        <ListItemIcon color="inherit">
                                            <LockOpen fontSize={"small"} />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Reativar
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip title='Altere o perfil do usuário'>
                                    <MenuItem disabled={usuariosSelecionados?.length != 1} onClick={handleModalAlterarPerfil}>
                                        <ListItemIcon color="inherit">
                                            <SwitchAccount fontSize={"small"} />
                                        </ListItemIcon>
                                        <Typography variant="inherit" noWrap>
                                            Alterar perfil
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                            </Menu>
                        </AcoesBox>
                    </ToolbarBox>
                </form>
            </ToolbarPaper>
            <Box sx={{ maxWidth: "90%", margin: "0 auto" }}>
                <TableContainer sx={{ width: "100%", minHeight: 500 }} component={Paper}>
                    {isLoading ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: "500px"
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Telefone</TableCell>
                                    <TableCell>Cpf</TableCell>
                                    <TableCell>Perfil</TableCell>
                                    <TableCell>Ativo</TableCell>
                                    <TableCell>Criado às</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {usuarios && usuariosExibidos && perfis && estabelecimento && usuariosExibidos.map(usuario => {
                                    const perfil = perfis?.find(perfil => {
                                        const acesso = usuario.acessos?.find(acessoProcurado => {
                                            return acessoProcurado.perfilUuid == perfil.uuid;
                                        })

                                        if (acesso) return perfil;
                                    })

                                    const ativo = usuario.acessos?.
                                        filter(acesso => acesso.inativadoAs == undefined && acesso.estabelecimentoUuid == estabelecimento.uuid)
                                        .length ? true : false;

                                    const usuarioEstaSelecionado = usuariosSelecionados.some(u => u.uuid === usuario.uuid);

                                    return (
                                        <TableRow
                                            key={usuario.uuid}
                                            selected={usuarioEstaSelecionado}
                                            aria-checked={usuarioEstaSelecionado}
                                            role="checkbox"
                                            hover
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={usuarioEstaSelecionado}
                                                    onClick={() => selecionarUsuario(usuario)}
                                                    disabled={usuario.uuid == usuarioSessao?.uuid}
                                                />
                                            </TableCell>
                                            <TableCell>{usuario.nome}</TableCell>
                                            <TableCell>{usuario.email}</TableCell>
                                            <TableCell>{usuario.telefone ?? "-"}</TableCell>
                                            <TableCell>{usuario.cpf ?? "-"}</TableCell>
                                            <TableCell>{isLoadingPerfis ? "Carregando..." : perfil ? perfil.nome : "Não encontrado"}</TableCell>
                                            <TableCell><Chip size="small" label={ativo ? `Ativo` : `Inativo`} color={ativo ? "success" : "error"} /></TableCell>
                                            <TableCell>{dataHoraBr(usuario.cadastradoAs)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                    <TablePagination
                        component="div"
                        count={usuarios?.length ?? 0}
                        rowsPerPage={usuariosPorPagina}
                        page={paginaAtual}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    />
                </TableContainer>
            </Box>
        </Box>
    );
}