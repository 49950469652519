export const validarCnpj = (cnpj: string | undefined, required: boolean = false): boolean => {
  if (!cnpj) return !required;

  // Remove caracteres não numéricos
  const nums = cnpj.replace(/[^\d]+/g, '');

  // Verifica se tem a quantidade certa de caracteres
  if (nums.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (/^(\d)\1{13}$/.test(nums)) return false;

  // Valida DVs
  let tamanho = nums.length - 2;
  let numeros = nums.substring(0, tamanho);
  const digitos = nums.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado.toString() !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = nums.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado.toString() !== digitos.charAt(1)) return false;

  return true;
};
