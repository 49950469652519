import { Add, Delete } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { useState } from "react";
import { DiaSemana, DiaSemanaOrdem } from "../../../../../core/enum/DiaSemana";
import { useLetras } from "../../../../../core/hooks/useLetras";
import { TimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { useMarketplaces } from "../../../../hooks/useMarketplaces";

interface HorariosMarketplacePaperProps {
    marketplace: IMarketplace
    refresh: () => void;
}

export const HorariosMarketplacePaper = ({ marketplace, refresh }: HorariosMarketplacePaperProps) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setError(null);
        setHorario1Inicio(null);
        setHorario1Fim(null);
        setHorario2Inicio(null);
        setHorario2Fim(null);
        setHorario3Inicio(null);
        setHorario3Fim(null);
        setDiaSemana(undefined);
        refresh();
        setOpen(false)
    }
    const { titulo } = useLetras();
    const { salvar, loading } = useMarketplaces();

    const [diaSemana, setDiaSemana] = useState<string | undefined>(undefined);
    const [horario1Inicio, setHorario1Inicio] = useState<Moment | null>(null);
    const [horario1Fim, setHorario1Fim] = useState<Moment | null>(null);
    const [horario2Inicio, setHorario2Inicio] = useState<Moment | null>(null);
    const [horario2Fim, setHorario2Fim] = useState<Moment | null>(null);
    const [horario3Inicio, setHorario3Inicio] = useState<Moment | null>(null);
    const [horario3Fim, setHorario3Fim] = useState<Moment | null>(null);
    const [error, setError] = useState<string | null>(null);

    const diasSemanaOptions = Object.keys(DiaSemana).filter((diaSemana) => {
        return !marketplace.configuracao?.horarios?.find((horario) => horario.diaSemana === diaSemana);
    }).map((diaSemana) => {
        return (
            <MenuItem key={diaSemana} value={diaSemana}>
                {titulo(diaSemana)}
            </MenuItem>
        );
    });

    const validateHorarios = () => {
        if ((horario1Inicio && horario1Fim) && horario1Inicio.isAfter(horario1Fim)) {
            setError("O primeiro horário de início deve ser antes do horário de término.");
            return false;
        }
        if (horario2Inicio && horario2Fim && horario2Inicio.isAfter(horario2Fim)) {
            setError("O segundo horário de início deve ser antes do horário de término.");
            return false;
        }
        if (horario3Inicio && horario3Fim && horario3Inicio.isAfter(horario3Fim)) {
            setError("O terceiro horário de início deve ser antes do horário de término.");
            return false;
        }
        if (checkConflict(horario1Inicio, horario1Fim, horario2Inicio, horario2Fim) ||
            checkConflict(horario1Inicio, horario1Fim, horario3Inicio, horario3Fim) ||
            checkConflict(horario2Inicio, horario2Fim, horario3Inicio, horario3Fim)) {
            setError("Os horários não podem conflitar entre si.");
            return false;
        }
        setError(null);
        return true;
    };

    const checkConflict = (start1: Moment | null, end1: Moment | null, start2: Moment | null, end2: Moment | null) => {
        if (start1 && end1 && start2 && end2) {
            return start1.isBefore(end2) && start2.isBefore(end1);
        }
        return false;
    };

    const handleSalvar = async () => {
        const valido = validateHorarios();
        if (valido) {
            const newHorario = {
                diaSemana: diaSemana as DiaSemana,
                horarios: [
                    { inicio: horario1Inicio?.format("HH:mm") || "", fim: horario1Fim?.format("HH:mm") || "" },
                    { inicio: horario2Inicio?.format("HH:mm") || "", fim: horario2Fim?.format("HH:mm") || "" },
                    { inicio: horario3Inicio?.format("HH:mm") || "", fim: horario3Fim?.format("HH:mm") || "" }
                ].filter(horario => horario.inicio && horario.fim)
            };

            let updatedHorarios = marketplace.configuracao?.horarios || [];
            const existingIndex = updatedHorarios.findIndex(h => h.diaSemana === diaSemana);

            if (existingIndex !== -1) {
                updatedHorarios[existingIndex] = newHorario;
            } else {
                updatedHorarios.push(newHorario);
            }

            updatedHorarios = updatedHorarios.sort((a, b) => DiaSemanaOrdem[a.diaSemana] - DiaSemanaOrdem[b.diaSemana]);

            const updatedMarketplace = {
                ...marketplace,
                configuracao: {
                    ...marketplace.configuracao,
                    horarios: updatedHorarios
                }
            };

            try {
                await salvar(updatedMarketplace);
                handleClose();
            } catch (error) {
                setError("Erro ao salvar as configurações. Tente novamente.");
            }
        }
    }

    const handleExcluir = async (diaSemana: DiaSemana) => {
        let updatedHorarios = marketplace.configuracao?.horarios?.filter(horario => horario.diaSemana !== diaSemana) || [];

        const updatedMarketplace = {
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                horarios: updatedHorarios
            }
        };

        try {
            await salvar(updatedMarketplace);
            refresh();
        } catch (error) {
            setError("Erro ao excluir a configuração. Tente novamente.");
        }
    };

    return (
        <>
            <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography variant="h6">Horários</Typography>
                    <Button endIcon={<Add />} onClick={handleOpen}>
                        Adicionar
                    </Button>
                </Box>
                {!marketplace.configuracao?.horarios?.length ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Typography>Nenhum horário de funcionamento configurado</Typography>
                    </Box>
                ) : (
                    <Box>
                        {marketplace.configuracao?.horarios?.map(horario => {
                            return (
                                <Paper variant="outlined" sx={{ padding: "1rem", marginTop: "1rem" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        {titulo(horario.diaSemana)}
                                        <Button endIcon={<Delete />} onClick={() => handleExcluir(horario.diaSemana)}>Excluir</Button>
                                    </Box>
                                    {horario.horarios?.length ? horario.horarios.map((horario, index) => {
                                        return (
                                            <Paper variant="outlined" sx={{
                                                padding: "1rem",
                                                justifyContent: "space-between",
                                                display: "flex",
                                                marginTop: "1rem"
                                            }}>
                                                <Typography>{index + 1}º Horário</Typography>
                                                <Box sx={{
                                                    justifyContent: "space-between",
                                                    display: "flex",
                                                }}>
                                                    <Typography>{horario.inicio}</Typography>
                                                    <Typography sx={{ mr: "0.5rem", ml: "0.5rem"}}>-</Typography>
                                                    <Typography>{horario.fim}</Typography>
                                                </Box>
                                            </Paper>
                                        );
                                    }) : (
                                        <Paper variant="outlined" sx={{
                                            padding: "1rem",
                                            justifyContent: "space-between",
                                            display: "flex",
                                            marginTop: "1rem"
                                        }}>
                                            <Typography>Nenhum horário configurado</Typography>
                                        </Paper>
                                    )}
                                </Paper>
                            )
                        })}
                    </Box>
                )}
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-horario"
                aria-describedby="dialog-horario-descricao"
            >
                <DialogTitle id="dialog-horario">
                    Horário de funcionamento
                </DialogTitle>
                <DialogContent sx={{ minWidth: "400px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Alert severity="info">
                        <AlertTitle>A definição de horários é opcional</AlertTitle>
                        Os horários definidos não afetam a abertura ou fechamento do marketplace;
                        eles servem apenas para informar os clientes sobre o horário de atendimento do estabelecimento
                    </Alert>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <FormControl sx={{ width: "100%", m: "0.5rem auto", mt: "2rem" }}>
                        <InputLabel
                            id="dia-semana-label"
                        >
                            Dia da semana*
                        </InputLabel>
                        <Select
                            labelId="dia-semana-label"
                            id="diaSemana"
                            placeholder="Selecione um dia da semana"
                            label="Dia da semana*"
                            defaultValue={undefined}
                            value={diaSemana}
                            onChange={(e) => setDiaSemana(e.target.value)}
                        >
                            {diasSemanaOptions}
                        </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}>
                        <Typography>1º horário</Typography>
                        <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                                <TimePicker
                                    label="Início 1º horário"
                                    value={horario1Inicio}
                                    onChange={(newValue) => setHorario1Inicio(newValue)}
                                    disabled={!diaSemana}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "50%", display: "flex" }}>
                                <TimePicker
                                    label="Fim 1º horário"
                                    value={horario1Fim}
                                    onChange={(newValue) => setHorario1Fim(newValue)}
                                    disabled={!horario1Inicio}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}>
                        <Typography>2º horário</Typography>
                        <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                                <TimePicker
                                    label="Início 2º horário"
                                    value={horario2Inicio}
                                    onChange={(newValue) => setHorario2Inicio(newValue)}
                                    disabled={!horario1Fim}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "50%", display: "flex" }}>
                                <TimePicker
                                    label="Fim 2º horário"
                                    value={horario2Fim}
                                    onChange={(newValue) => setHorario2Fim(newValue)}
                                    disabled={!horario2Inicio}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: "1rem" }}>
                        <Typography>3º horário</Typography>
                        <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ width: "50%", display: "flex", mr: "0.5rem" }}>
                                <TimePicker
                                    label="Início 3º horário"
                                    value={horario3Inicio}
                                    onChange={(newValue) => setHorario3Inicio(newValue)}
                                    disabled={!horario2Fim}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "50%", display: "flex" }}>
                                <TimePicker
                                    label="Fim 3º horário"
                                    value={horario3Fim}
                                    onChange={(newValue) => setHorario3Fim(newValue)}
                                    disabled={!horario3Inicio}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCELAR</Button>
                    <Button onClick={handleSalvar} variant="contained" autoFocus>
                        SALVAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}