export enum UnidadeInsumo {
    "kg" = "kg",
    "L" = "L",
    "item" = "item"
}

export enum UnidadeInsumoDescricao {
    "kg" = "Quilo (kg)",
    "L" = "Litro (L)",
    "item" = "Item (Unidade)"
}