import { Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { ModulosDrawer } from "../components/ModulosDrawer";
import { Header } from "../components/Header";
import React, { ReactNode } from "react";
import { RouteContainer } from "./styles";
interface IPrivateRoutesProps {
    autenticado: boolean;
    layout?: ReactNode;
}

const PrivateRoutes = ({ autenticado }: IPrivateRoutesProps) => {
    if (!autenticado) {
        return <Navigate to="/login" replace />;
    }
    
    return <Outlet />;
};

export default PrivateRoutes;
