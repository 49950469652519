import { useSnackbar } from "../contexts/SnackbarContext";

export const useCopiar = (conteudo: string, mensagem?: string) => {
    const snackbar = useSnackbar();
    const copiar = () => {
        navigator.clipboard.writeText(conteudo).then(() => {
            snackbar(mensagem ?? "Conteúdo copiado com sucesso", { severity: "info" });
        }).catch((err) => {
          console.error('Failed to copy: ', err);
        });
    };

    return copiar
}