import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle"
import { ICliente } from "../../../../interface/ICliente";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";

interface ExcluirClienteModalProps {
    open: boolean;
    cancelar: () => void;
    excluir: (clienteUuid: string) => void;
    cliente?: ICliente;
}

export const ExcluirClienteModal = ({ open, cancelar, excluir, cliente }: ExcluirClienteModalProps) => {
    const snackbar = useSnackbar();
    const handleExcluirUsuario = () => {
        if(!cliente || !cliente.uuid) {
            return snackbar("Cliente inválido", { severity: "error" });
        }

        excluir(cliente.uuid);
    }

    return (
        <Dialog
            open={open}
            onClose={cancelar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Excluir cliente {cliente?.nome}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Realmente deseja excluir este cliente?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelar}>Não</Button>
                <Button onClick={handleExcluirUsuario} autoFocus>
                    Sim
                </Button>
            </DialogActions>
        </Dialog>
    );
}