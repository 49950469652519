import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box, Chip } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { MetodoPagamento } from '../../enum/MetodoPagamento';

function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly string[], b: readonly string[]) {
    return [...a, ...not(b, a)];
}

const metodosPagamento: string[] = Object.values(MetodoPagamento);

interface MetodosPagamentoTransferListProps {
    metodosPagamento: string[];
    salvar: (metodosPagamento: string[]) => void;
}

export const MetodosPagamentoTransferList = ({ metodosPagamento, salvar }: MetodosPagamentoTransferListProps) => {
    const [checked, setChecked] = React.useState<string[]>([]);
    const [left, setLeft] = React.useState<string[]>(Object.keys(MetodoPagamento));
    const [right, setRight] = React.useState<string[]>([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    React.useEffect(() => {
        if (metodosPagamento) {
            const newRight = metodosPagamento ?? [];
            const newLeft = left.filter(item => !newRight.includes(item));
            setRight(newRight);
            setLeft(newLeft);
        }
    }, [metodosPagamento]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly string[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: readonly string[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        salvar(right.concat(leftChecked)); 
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        salvar(not(right, rightChecked));
    };

    const customList = (title: React.ReactNode, items: readonly string[]) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionado(s)`}
            />
            <Divider />
            <List
                sx={{
                    width: 300,
                    height: 350,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: string) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                            {!Object.keys(MetodoPagamento).includes(value) && <Chip size='small' label="Customizado"/>}
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column"}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>{customList('Disponíveis', left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList('Escolhidos', right)}</Grid>
            </Grid>
        </Box>
    );
}
