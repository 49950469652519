import { Alert, AlertTitle, Badge, Box, Button, Container, Drawer, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { OperacaoDelivery } from "./containers/OperacaoDelivery";
import { OperacaoRetirada } from "./containers/OperacaoRetirada";
import { OperacaoMesa } from "./containers/OperacaoMesa";
import { useFetchCaixaAberto } from "../../../financeiro/hooks/useFetchCaixaAberto";
import { Link } from "react-router-dom";
import { Link as LinkMaterial } from "@mui/material";
import { HeaderGrid, OperacaoContainer } from "./styles";
import { AccessTimeFilled, Add, ReceiptLong } from "@mui/icons-material";
import { useOperacaoContext } from "../../contexts/OperacaoContext";
import { PedidosNaoConcluidosContainer } from "./containers/PedidosNaoConcluidosContainer";
import { TipoPedido } from "../../enum/TipoPedido";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { OperacaoSimples } from "./containers/OperacaoSimples";
import { useConfiguracoesPedidoContext } from "../../contexts/ConfiguracoesPedidoContext";

export function Operacao() {
    const [tab, setTab] = useState<number>(0);
    const { caixa } = useFetchCaixaAberto();
    const [isOpen, setOpen] = useState(false);
    const { configuracao } = useConfiguracoesPedidoContext();

    const closeDrawer = () => setOpen(false);
    const openDrawer = () => setOpen(true);

    const handleChange = (event: React.SyntheticEvent, novaTab: number) => {
        setTab(novaTab);
    };

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const { pedidoSelecionado, selecionarPedido, pedidos, resetarTudo, tipo } = useOperacaoContext();
    const handleSelecionarPedido = (uuid?: string) => {
        selecionarPedido(uuid);
        closeDrawer();
    }

    const { modeloTela } = useTamanhoTela();

    const renderizarComponentePorTipo = (tipo: string) => {
        switch (tipo) {
            case 'delivery':
                return <OperacaoDelivery />;
            case 'retirada':
                return <OperacaoRetirada />;
            case 'mesa':
                return <OperacaoMesa />;
            case 'simples':
                return <OperacaoSimples />;
            default:
                return <div>Componente não encontrado para o tipo {tipo}</div>;
        }
    };

    return (
        <OperacaoContainer>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={closeDrawer}
                sx={{ zIndex: 99999, width: "400px" }}
            >
                <PedidosNaoConcluidosContainer
                    pedidos={pedidos}
                    selecionarPedido={handleSelecionarPedido}
                    pedidoSelecionado={pedidoSelecionado}
                />
            </Drawer>
            <Grid
                container
                spacing={0}
                sx={{ width: "100%" }}
            >
                <HeaderGrid item xs={12} sx={{ padding: "0 1rem" }}>
                    <Tabs
                        value={tab}
                        sx={{
                            '.MuiTabs-indicator': {
                                display: 'none', //
                            },
                            minHeight: '7vh'
                        }}
                        textColor="secondary"
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        {configuracao?.fluxoOperacao?.fluxosContextos.map((fluxo, index) => (
                            <Tab label={fluxo.tipo.toUpperCase()} {...tabProps(index)} key={fluxo.uuid} />
                        ))}
                    </Tabs>
                    {tipo != TipoPedido.mesa && modeloTela == "desktop" && (
                        <Box className="button-area">
                            {pedidoSelecionado ? (
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    onClick={resetarTudo}
                                    color="secondary"
                                    size={"small"}
                                >
                                    Novo pedido
                                </Button>
                            ) : (
                                <Badge badgeContent={pedidos?.length} color="secondary">
                                    <Button
                                        onClick={openDrawer}
                                        variant="contained"
                                        size={"small"}
                                        startIcon={<AccessTimeFilled />}>
                                        Em espera
                                    </Button>
                                </Badge>
                            )}
                        </Box>
                    )}
                </HeaderGrid>
                <Grid item xs={12}>
                    {!caixa ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="83vh">
                            <Alert elevation={2} severity="error" sx={{ width: "500px" }}>
                                <AlertTitle>Caixa fechado</AlertTitle>
                                Você só pode operar com o <Link to={"/financeiro/caixa"}><LinkMaterial>caixa aberto. </LinkMaterial></Link><br />
                            </Alert>
                        </Box>
                    ) : (configuracao?.fluxoOperacao?.fluxosContextos?.length ?? 0) > 0 ? (
                        configuracao?.fluxoOperacao?.fluxosContextos.map((fluxo, index) => (
                            <div
                                role="tabpanel"
                                hidden={tab !== index}
                                id={`simple-tabpanel-${index}`}
                                aria-labelledby={`simple-tab-${index}`}
                                key={fluxo.uuid}
                            >
                                {tab === index && renderizarComponentePorTipo(fluxo.tipo)}
                            </div>
                        ))
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" height="83vh">
                            <Alert elevation={2} severity="warning" sx={{ width: "500px" }}>
                                <AlertTitle>Nenhum fluxo de operação configurado</AlertTitle>
                                Entre em contato para configurarmos um fluxo adequado à sua operação.<br />
                            </Alert>
                        </Box>
                    )}
                </Grid>

            </Grid>
        </OperacaoContainer>
    );


}