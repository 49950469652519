import { Box, Drawer, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Link } from "@mui/material";
import { IITemControleEstoque } from "../../../../interfaces/IItemControleEstoque";
import { useAcoesInsumosContext } from "../../../../contexts/AcoesInsumosContext";
import { useFetchInsumos } from "../../../../hooks/useFetchInsumos";
import { IInsumo } from "../../../../interfaces/IInsumo";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useInsumos } from "../../../../hooks/useInsumos";
import { useUnidade } from "../../../../../core/hooks/useUnidade";

interface RowFichaTecnicaProps {
    insumo: IInsumo;
}

const RowFichaTecnica = ({ insumo }: RowFichaTecnicaProps) => {
    const { formatar } = useUnidade();
    const [ativo, setAtivo] = useState(false);
    const [quantidadeEstoque, setQuantidadeEstoque] = useState(0);
    const { loading, buscar } = useInsumos();

    const checarInsumo = async () => {
        const dadosInsumo = await buscar(insumo.uuid);
        if (dadosInsumo) {
            setAtivo(true);
            setQuantidadeEstoque(dadosInsumo?.quantidade);
        }
    }

    useEffect(() => {
        checarInsumo();
    }, [insumo])

    return (
        <TableRow key={insumo.uuid}>
            <TableCell component="th" scope="row">
                {
                    !ativo
                        ? insumo.nome
                        : <Link href={`/cardapio/estoque/insumos/editar/${insumo.uuid}`}>
                            {insumo.nome}
                        </Link>
                }
            </TableCell>
            <TableCell align="right">{formatar(insumo.quantidade ?? 0, insumo.unidade)}</TableCell>
            <TableCell align="right">{formatar(quantidadeEstoque ?? 0, insumo.unidade)}</TableCell>
            <TableCell align="right">{insumo.unidade}</TableCell>
            <TableCell align="right">{loading
                ? (<CircularProgress size={15} />)
                : (ativo 
                    ? <Typography color="green">Sim</Typography> 
                    : <Typography color="red">Não</Typography>
                )
            }</TableCell>
        </TableRow>
    );
}

interface FichaTecnicaProps {
    open: boolean;
    item?: IITemControleEstoque;
    close: () => void;
}

export const FichaTecnica = ({ open, item, close }: FichaTecnicaProps) => {
    const { insumos, isLoading } = useFetchInsumos();

    return (
        <Drawer anchor={"right"} open={open} onClose={close}>
            <Box sx={{ width: "500px", marginTop: "4rem", padding: "1rem" }}>
                <Typography variant="h6">
                    Ficha técnica: <b>{item?.nome}</b>
                </Typography>

                <TableContainer component={Paper} variant="outlined" sx={{ width: '100%', mt: 2 }}>
                    {isLoading ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", width: "100%" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Table sx={{ maxWidth: 500 }} size="small" aria-label="ficha técnica">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right">Qtd. porção</TableCell>
                                        <TableCell align="right">Qtd. estoque</TableCell>
                                        <TableCell align="right">Unidade</TableCell>
                                        <TableCell align="right">Ativo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item?.fichaTecnica?.map((insumo) => <RowFichaTecnica insumo={insumo} />)
                                    }
                                </TableBody>
                            </Table>
                        </>
                    )}
                </TableContainer>
            </Box>
        </Drawer>
    );
};
