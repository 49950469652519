import { Alert, AlertTitle } from "@mui/material";

interface AlertPermissaoFuncionalidadeProps {
    funcionalidade: string
}

export const AlertPermissaoFuncionalidade = ({ funcionalidade }: AlertPermissaoFuncionalidadeProps) => {
    return (
        <Alert severity="error">
            <AlertTitle>Acesso negado</AlertTitle>
            Você não possui permissão para acessar a funcionalidade <b>{funcionalidade}</b>.
        </Alert>
    );
}