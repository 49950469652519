import AccountCircle from '@mui/icons-material/AccountCircle';
import AppsIcon from '@mui/icons-material/Apps';
import { Badge, Box, Button, Chip, Divider } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAutenticacaoContext } from "../../contexts/AutenticacaoContext";
import { useDrawerContext } from "../../contexts/DrawerContext";
import { useHeaderContext } from '../../contexts/HeaderContext';
import { useThemeContext } from "../../contexts/ThemeContext";
import { useTamanhoTela } from '../../hooks/useTamanhoTela';
import { Storefront } from '@mui/icons-material';
import { useLetras } from '../../hooks/useLetras';

interface IHeaderProps {
  tituloModulo: string
}

export const Header = ({ tituloModulo }: IHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { usuario, logout, estabelecimento } = useAutenticacaoContext();
  const { logo, customTheme } = useThemeContext();
  const { openDrawer } = useDrawerContext();
  const { modeloTela } = useTamanhoTela();
  const { tools } = useHeaderContext();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { titulo } = useLetras();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 9999,
        height: "50px",
        display: "flex",
        alignItems: "space-between",
        justifyContent: "center",
        background: "#fff",
        boxShadow: 'none',
        borderBottom: theme => `1px solid ${theme.palette.divider}` // Substitui o boxShadow pelo border-bottom
      }}
    >
      <Toolbar>
        <Link to={estabelecimento ? "/dashboard" : '/estabelecimentos'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography sx={{ mr: 1, fontSize: 14 }}>
            {!customTheme ? "Space Food" : titulo(estabelecimento?.nomeFantasia ?? "Space Food", 20, true)} 
          </Typography>
          <img
            src={logo}
            style={{ width: "55px", height: "40px", objectFit: "contain", marginRight: "1rem" }}
            alt="Space Food"
          />
        </Link>
        <Box sx={{ mr: "1rem", display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </Box>
        {estabelecimento && (
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            onClick={openDrawer}
          >
            <AppsIcon />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" component="div" sx={{ color: 'text.primary' }}>
            {tituloModulo}
          </Typography>
          {tools}
        </Box>
        {modeloTela == "desktop" && (
          <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
            Olá <b>{usuario?.nome}</b>
          </Typography>
        )}
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ zIndex: 9999, minWidth: "500px" }}
          >
            {estabelecimento && (
              <>
                <MenuItem style={{ pointerEvents: 'none' }}>
                  <Chip label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Storefront sx={{ mr: 1 }} />
                      <Typography fontWeight={"bold"} fontSize={12}>{estabelecimento.nomeFantasia}</Typography>
                    </Box>
                  } />
                </MenuItem>
                <Divider orientation='horizontal' />
              </>
            )}
            <Link to="/estabelecimentos"><MenuItem>Meus estabelecimentos</MenuItem></Link>
            <Link to="/usuarios/meu-perfil"><MenuItem>Meu perfil</MenuItem></Link>
            <MenuItem onClick={logout}>Sair</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>

  );
}