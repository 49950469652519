import { Box, Button, ButtonGroup, CardActionArea, CircularProgress, Drawer, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import { ButtonsContainer, CheckoutPaperContainer, DrawerTitle } from "./styles"
import { useMoeda } from "../../../core/hooks/useMoeda";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useEffect, useRef, useState } from "react";
import { useTamanhoTela } from "../../../core/hooks/useTamanhoTela";
import { IProdutoPedido } from "../../interface/IProdutoPedido";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
interface IOpcao {
    titulo: string;
    funcao: (() => void);
    desabilitado?: boolean | undefined;
}

interface CheckoutPaperProps {
    children: React.ReactNode;
    valorTotalPedido: number;
    numeroTotalItens: number;
    valorFrete?: number;
    loading: boolean;
    opcoes: IOpcao[];
    pedidoSelecionado?: string;
    numeroPedidoDiario?: number;
}

export const CheckoutPaper = ({ children, valorFrete, valorTotalPedido, opcoes, pedidoSelecionado, numeroTotalItens, numeroPedidoDiario }: CheckoutPaperProps) => {
    const { real } = useMoeda();
    const { modeloTela } = useTamanhoTela();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
        opcoes[selectedIndex].funcao();
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleToggleDrawer = () => {
        setDrawerOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => setSelectedIndex(0), [pedidoSelecionado])

    const renderizarConteudo = () => {
        return (
            <CheckoutPaperContainer variant="outlined">
                {numeroPedidoDiario && (
                    <Typography mb={0.1} color={"gray"} variant="subtitle2">
                        Pedido #{numeroPedidoDiario}
                    </Typography>
                )}
                <Typography variant="h4" fontWeight={"bold"}>{real(valorTotalPedido)}</Typography>
                {valorFrete != undefined && (
                    <Typography variant="subtitle1">
                        Frete de <b>{real(valorFrete)}</b> incluso
                    </Typography>
                )}
                {children && <>{children}</>}
                <ButtonsContainer>
                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                        <Button onClick={handleClick}>{opcoes[selectedIndex].titulo}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {opcoes.map((opcao, index) => (
                                                <MenuItem
                                                    key={opcao.titulo}
                                                    disabled={opcao.desabilitado}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {opcao.titulo}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ButtonsContainer>
            </CheckoutPaperContainer>
        );
    }

    const renderizarMobile = () => {
        return (
            <>
                <DrawerTitle variant="outlined" onClick={handleToggleDrawer}>
                    <CardActionArea sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "100%",
                        padding: "1rem"
                    }}>
                        <Typography variant="h5" fontWeight={"bold"}>{real(valorTotalPedido)}</Typography>
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<ShoppingCartIcon />}
                        >
                            {numeroTotalItens} {numeroTotalItens == 1 ? "ITEM" : "ITENS"}
                        </Button>
                    </CardActionArea>
                </DrawerTitle>
                <Drawer
                    anchor={"bottom"}
                    open={drawerOpen}
                    onClose={handleToggleDrawer}
                >
                    {renderizarConteudo()}
                </Drawer>
            </>
        )
    }

    if (modeloTela == "desktop") return renderizarConteudo();
    return renderizarMobile();
}