import styled from "@emotion/styled"
import { Card } from "@mui/material"

export const TipoProdutoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    width: 100%;

    @media(max-width: 1080px) {
        flex-direction: column;
    }
`

interface TipoProdutoCardProps {
    selecionado?: boolean
}

export const TipoProdutoCard = styled(Card)<TipoProdutoCardProps>`
    width: 260px;
    margin: 0.4rem;

    ${({ selecionado, theme }) =>
    selecionado &&
    `
        background-color: ${theme.palette.primary.main}09;
        border: 1px solid ${theme.palette.primary.main};
        color: ${theme.palette.primary.main};
    `
  }

  img {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
`