export const validarCpf = (value: string | undefined, required?: boolean) => {
    if (!value) return required == true ? false : true;
    value = value.replace(/[^\d]+/g, '');
  
    if (value.length !== 11) return false;

    if (/^(\d)\1+$/.test(value)) return false;

    let sum = 0;
    let remainder;
  
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(value.substring(i - 1, i)) * (11 - i);
    }
  
    remainder = (sum * 10) % 11;
  
    if (remainder === 10 || remainder === 11) remainder = 0;
  
    if (remainder !== parseInt(value.substring(9, 10))) return false;
  
    sum = 0;
  
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(value.substring(i - 1, i)) * (12 - i);
    }
  
    remainder = (sum * 10) % 11;
  
    if (remainder === 10 || remainder === 11) remainder = 0;
  
    if (remainder !== parseInt(value.substring(10, 11))) return false;
  
    return true;
  };