import { useMemo, useState } from "react";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { ApiFactory } from "../../core/config/ApiFactory";

export function useCaixa() {
    const [loading, setLoading] = useState(false);
    const notificar = useSnackbar();
    const apiFinanceiro = useMemo(() => ApiFactory.getApi("MS_FINANCEIRO"), [])
    
    const abrirCaixa = async (descricao: string, valorInicial: number) => {
        if(!descricao) {
            notificar("Descrição inválida", { severity: "error" })
            return null;
        }

        if(valorInicial == undefined || valorInicial == null) {
            notificar("Valor inicial inválido", { severity: "error" })
            return null;
        }

        try {
            setLoading(true);
            const { data } = await apiFinanceiro.post("/caixa/intervalo", { descricao, valorInicial });
            notificar("Caixa aberto")
            return data;
        } catch (error) {
            notificar("Ocorreu um erro ao abrir o caixa", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const buscarUltimoCaixaAberto = async () => {
        try {
            setLoading(true);
            const { data } = await apiFinanceiro.get("/caixa/intervalo/ultimo-aberto");
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const fecharCaixa = async () => {
        try {
            setLoading(true);
            const { data } = await apiFinanceiro.delete("/caixa/intervalo");
            notificar("Caixa fechado")
            return data;
        } catch (error) {
            notificar("Ocorreu um erro ao fechar o caixa", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    return { abrirCaixa, loading, fecharCaixa, buscarUltimoCaixaAberto }
}