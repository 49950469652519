import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const PaperContainer = styled(Paper)`
    padding: 1rem;
    min-width: 400px;
    min-height: 20%;
    max-height: 95%;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
`

export const FormContainer = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
`

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`