import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ICliente } from "../../../../../clientes/interface/ICliente";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useMascara } from "../../../../../core/hooks/useMascara";
import { EmitirNotaFiscalForm } from "./styles";
import { dadosEmitirNotaSchema } from "../../../../schemas/dadosEmitirNotaSchema";
import { useHotkeys } from "react-hotkeys-hook";
import { IDadosEmitirNotaFiscal } from "../../../../interface/IDadosEmitirNotaFiscal"
import { validarCpf } from "../../../../../core/utils/validarCpf";
import { validarCnpj } from "../../../../../core/utils/validarCnpj";

export type TipoPessoa = "CPF" | "CNPJ" | "Não declarado";

interface EmitirNotaFiscalModalProps {
    open: boolean;
    cancelar: () => void;
    concluir: (dados: IDadosEmitirNotaFiscal) => void;
    dadosCliente?: ICliente | undefined;
    loading: boolean;
}

export const EmitirNotaFiscalModal = ({ open, cancelar, concluir, dadosCliente, loading }: EmitirNotaFiscalModalProps) => {
    const { setError, setValue, register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        resolver: yupResolver(dadosEmitirNotaSchema),
        mode: "onChange",
        defaultValues: {
            nomePessoa: "",
            numeroPessoa: ""
        }
    });
    const nomePessoa = watch("nomePessoa");
    const numeroPessoa = watch("numeroPessoa");
    const [tipoPessoa, setTipoPessoa] = useState<TipoPessoa>("Não declarado");
    const { mascaraCPF, mascaraCNPJ } = useMascara();

    useEffect(() => {
        if (tipoPessoa == "CPF") {
            setValue("numeroPessoa", mascaraCPF(numeroPessoa));
        } else {
            setValue("numeroPessoa", mascaraCNPJ(numeroPessoa));
        }
    }, [numeroPessoa]);

    const handleCancelar = () => {
        setValue("nomePessoa", "");
        setValue("numeroPessoa", "");
        reset();
        cancelar();
    };

    const handleEmitirNotaFiscal = async () => {
        if(tipoPessoa == "CPF" && !validarCpf(numeroPessoa, true)) {
            return setError("numeroPessoa", { message: "CPF inválido" });
        }

        if(tipoPessoa == "CNPJ" && !validarCnpj(numeroPessoa, true)) {
            return setError("numeroPessoa", { message: "CNPJ inválido" });
        }

        concluir({
            nomePessoa,
            numeroPessoa,
            tipoPessoa
        });
    }

    useHotkeys(['enter'], () => handleEmitirNotaFiscal(), [open]);

    return (
        <Dialog
            open={open}
            onClose={cancelar}
            sx={{ zIndex: 99999 }}
        >
            <form onSubmit={handleSubmit(handleEmitirNotaFiscal)}>
                <EmitirNotaFiscalForm>
                    <Typography variant="h6">Emitir nota fiscal</Typography>
                    <FormControl fullWidth style={{ marginTop: "1rem" }}>
                        <InputLabel>Tipo pessoa</InputLabel>
                        <Select
                            label="Tipo pessoa"
                            value={tipoPessoa || ""}
                            onChange={(e) => setTipoPessoa(e.target.value as TipoPessoa)}
                            MenuProps={{ style: { zIndex: 999999 } }}
                        >
                            <MenuItem value="Não declarado" defaultChecked>Não declarado</MenuItem>
                            <MenuItem value="CPF">CPF</MenuItem>
                            <MenuItem value="CNPJ">CNPJ</MenuItem>
                        </Select>
                    </FormControl>
                    {["CPF", "CNPJ"].includes(tipoPessoa) && (
                            <TextField
                            label="Número do documento"
                            style={{ width: "100%", marginTop: "1rem" }}
                            {...register("numeroPessoa")}
                            error={!!errors.numeroPessoa}
                            helperText={<>{errors.numeroPessoa?.message}</>}
                        />
                    )}
                    <Stack flexDirection={"row"} sx={{ width: "100%", marginTop: "1rem" }} justifyContent={"space-between"}>
                        <Button
                            variant="outlined"
                            style={{ width: "47%" }}
                            type="button"
                            onClick={handleCancelar}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            style={{ width: "47%" }}
                            type="submit"
                            autoFocus={true}
                        >
                            {loading == true ? <CircularProgress sx={{ width: "15px", height: "15px" }} color="inherit" /> : "Concluir"}
                        </Button>
                    </Stack>
                </EmitirNotaFiscalForm>
            </form>
        </Dialog>
    );
}