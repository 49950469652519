import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { regiaoEntregaSchema } from "../../schemas/regiaoEntregaSchema";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { IRegiao } from "../../../core/interfaces/IRegiao";

interface FormularioRegioesEntregaProps {
    fecharFormulario: () => void;
    formularioAberto: boolean;
    cadastrarRegiao: (regiao: IRegiao) => Promise<IRegiao | null>
    editarRegiao?: (regiao: IRegiao) => Promise<boolean>
    valoresIniciais?: IRegiao;
    habilitarEdicao?: boolean
}

export const FormularioRegioesEntrega = ({ formularioAberto, fecharFormulario, cadastrarRegiao, valoresIniciais, editarRegiao, habilitarEdicao }: FormularioRegioesEntregaProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { number } = useMoeda();

    const { reset, register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            descricao: valoresIniciais?.descricao ?? '',
            preco: valoresIniciais?.preco ?? ''
        },
        resolver: yupResolver(regiaoEntregaSchema),
        mode: "onChange",
    });

    const handleCadastrarRegiao = async (regiao: FieldValues) => {
        setLoading(true);

        if(!habilitarEdicao && cadastrarRegiao) {
            const result = await cadastrarRegiao({
                descricao: regiao.descricao.trim(),
                preco: number(regiao.preco)
            });
        
            setLoading(false);
            if (result) {
                handleFecharFormulario();
            }
        }

        if(habilitarEdicao && valoresIniciais && editarRegiao) {
            const result = await editarRegiao({
                descricao: regiao.descricao.trim(),
                preco: number(regiao.preco),
                uuid: valoresIniciais.uuid
            });
        
            setLoading(false);
            if (result == true) {
                handleFecharFormulario();
            }
        }
    }

    const handleFecharFormulario = () => {
        reset();
        fecharFormulario();
    }

    useEffect(() => {
        if (valoresIniciais && formularioAberto) {
            setValue('descricao', valoresIniciais.descricao);
            setValue('preco', valoresIniciais.preco);
        }
    }, [valoresIniciais, formularioAberto, setValue]);

    useEffect(() => {
        if (formularioAberto) {
            reset({
                descricao: valoresIniciais?.descricao ?? '',
                preco: valoresIniciais?.preco ?? ''
            });
        }
    }, [formularioAberto, valoresIniciais, reset]);
    

    useEffect(() => {
        if (formularioAberto) {
            reset();
        }
    }, [formularioAberto, reset]);

    return (
        <Dialog fullWidth open={formularioAberto} onClose={handleFecharFormulario}>
            <DialogTitle>{habilitarEdicao ? "Editar" : "Cadastrar"} região</DialogTitle>
            <form onSubmit={handleSubmit(handleCadastrarRegiao)}>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Descrição"
                            type="text"
                            fullWidth
                            value={watch('descricao')}
                            {...register("descricao")}
                            error={!!errors.descricao}
                            helperText={<>{errors.descricao?.message}</>}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel error={!!errors.preco} htmlFor="preco-regiao">Preço</InputLabel>
                        <OutlinedInput
                            id="preco-regiao"
                            error={!!errors.preco}
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Preço"
                            fullWidth
                            value={watch('preco')}
                            {...register("preco")}
                        />
                        {!!errors.preco && <FormHelperText error={true}><>{errors.preco?.message}</></FormHelperText>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharFormulario} variant="outlined">Cancelar</Button>
                    <Button type="submit" variant="contained">
                        {loading == false ? (
                            <>{habilitarEdicao ? "Editar" : "Cadastrar"}</>
                        ) : (
                            <CircularProgress color="inherit" />
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}