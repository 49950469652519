import styled from "@emotion/styled";

export const FiltrosContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    .filtros-caixa-diario {
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 1080px) {
            flex-direction: column;
        }
    }

    .input-caixa-diario {
        width: 200px;
        margin-right: 20px;

        @media(max-width: 1080px) {
            width: 200px;
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }
` 