import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const PageContainer = styled(Box)`

`

export const Toolbar = styled(Paper)`
    margin-bottom: 1rem; 
    width: 100%; 
    display: flex; 
    align-items: center;
    padding: 2rem; 
    border-radius: 0;
    justify-content: space-between;
    height: 70px;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.palette.divider};

    @media (max-width: 1280px) {
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;

        h6 {
            margin-bottom: 1rem;
        }

        .form {
            flex-direction: column;
            align-items: center;
            height: 250px;
            justify-content: space-between;
            div {
                margin-right: 0;
            }
        }
    }
`