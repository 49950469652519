import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const EnderecoPaperContainer = styled(Paper)`
    margin-top: 1rem;
    padding: 1rem;
    min-height: 80px;
    width: 100%;
    display: flex;
    align-items: center;

    img:first-child {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }

    div:last-child {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
`