import styled from "@emotion/styled"
import { Box, Paper } from "@mui/material"

export const ItensPaper = styled(Paper)`
    width: 100%;
    height: 360px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    margin-top: 1rem;
`

export const ItemCard = styled(Paper)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 0.5rem;
`

export const ButtonsContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
`