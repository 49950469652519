import { Add, Delete } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { useState } from "react";
import { DiaSemana, DiaSemanaOrdem } from "../../../../../core/enum/DiaSemana";
import { useLetras } from "../../../../../core/hooks/useLetras";
import { TimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { useMarketplaces } from "../../../../hooks/useMarketplaces";
import { MetodosPagamentoTransferList } from "../../../../../core/components/MetodosPagamentoTransferList";
import { NovoMetodoPagamento } from "../../../../../pedidos/containers/NovoMetodoPagamento";

interface MetodosPagamentoMarketplacePaperProps {
    marketplace: IMarketplace
    refresh: () => void;
}

export const MetodosPagamentoMarketplacePaper = ({ marketplace, refresh }: MetodosPagamentoMarketplacePaperProps) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        refresh();
        setOpen(false)
    }
    const { salvar, loading } = useMarketplaces();

    const handleSalvar = async (metodosPagamento: string[]) => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                metodosPagamento
            }
        })
        refresh();
    }

    const handleAdicionarMetodoPagamento = async (metodosPagamento: string) => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                metodosPagamento: [...(marketplace.configuracao?.metodosPagamento ?? []), metodosPagamento]
            }
        })
        refresh();
    }

    return (
        <>
            <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography variant="h6">Métodos de pagamento</Typography>
                    <Button endIcon={<Add />} onClick={handleOpen}>
                        Customizado
                    </Button>
                </Box>
                <Box sx={{ padding: "1rem", marginTop: "1rem" }}>
                    <MetodosPagamentoTransferList 
                        metodosPagamento={marketplace?.configuracao?.metodosPagamento ?? []}
                        salvar={handleSalvar}
                    />
                </Box>
            </Paper>
            <NovoMetodoPagamento 
                metodosExistentes={marketplace?.configuracao?.metodosPagamento ?? []}
                formularioAberto={open}
                fecharFormulario={handleClose}
                adicionarMetodoPagamento={handleAdicionarMetodoPagamento}
            />
        </>
    );
}