import { Step, StepLabel } from "@mui/material";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";
import { useCadastrarEditarProdutoContext } from "../../../../contexts/CadastrarEditarProdutoContext";
import { CustomStepper, StepperContainer } from "../../styles";
import { ConfiguracaoPizzaContainer } from "../../../../containers/ConfiguracaoPizzaContainer";
import { ConfiguracaoBordasContainer } from "../../../../containers/ConfiguracaoBordasContainer";
import { ConfiguracaoSaboresContainer } from "../../../../containers/ConfiguracaoSaboresContainer";
import { ControleEstoqueContainer } from "../../../../containers/ControleEstoqueContainer";


interface CadastrarPizzaContainerProps {

}

export const CadastrarPizzaContainer = ({ }: CadastrarPizzaContainerProps) => {
    const { step } = useCadastrarEditarProdutoContext();

    return (
        <>
            <StepperContainer>
                <CustomStepper key={step} activeStep={step} orientation={"horizontal"}>
                    <Step key={0} completed={step > 0}>
                        <StepLabel color="inherit">
                            Configuração
                        </StepLabel>
                    </Step>
                    <Step key={1} completed={step > 1}>
                        <StepLabel color="inherit">
                            Controle de estoque
                        </StepLabel>
                    </Step>
                    <Step key={2} completed={step > 2}>
                        <StepLabel color="inherit">
                            Bordas
                        </StepLabel>
                    </Step>
                    <Step key={3} completed={step > 3}>
                        <StepLabel color="inherit">
                            Sabores
                        </StepLabel>
                    </Step>
                </CustomStepper>
            </StepperContainer>
            {step == 0 && (
                <ConfiguracaoPizzaContainer />
            )}
            {step == 1 && (
                <ControleEstoqueContainer />
            )}
            {step == 2 && (
                <ConfiguracaoBordasContainer />
            )}
            {step == 3 && (
                <ConfiguracaoSaboresContainer />
            )}
        </>
    );
}