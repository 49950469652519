import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { ListarMarketplaces } from "../pages/ListarMarketplaces";
import { CadastrarEditarMarketplace } from "../pages/CadastrarEditarMarketplace";
import { DetalhesMarketplace } from "../pages/DetalhesMarketplace";
import { PersonalizarMarketplace } from "../pages/PersonalizarMarketplace";
import { Layout } from "../../core/components/Layout";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { useMemo } from "react";
import { IMenu } from "../../core/interfaces/IMenu";

interface IMarketPlaceProps {
    autenticado: boolean;
}

export const MarketplaceRoutes = ({ autenticado }: IMarketPlaceProps) => {
    const { modeloTela } = useTamanhoTela();
    const { perfil, verificarAcessoModulo } = useAutenticacaoContext();

    const itensMenu: IMenu[] = useMemo(() => {
      const itens = [
        {
          titulo: "Marketplaces",
          url: "/marketplace",
          subitens: []
        },
        {
          titulo: "Cadastrar",
          url: "/marketplace/cadastrar",
          subitens: []
        }
      ];  
  
      return itens
    }, [perfil])

    return (
        <Routes>
            <Route element={
                <Layout
                    titulo="Marketplaces"
                    modeloTela={modeloTela}
                    itensMenu={itensMenu}
                    drawer
                >
                    <PrivateRoutes
                        autenticado={autenticado}
                    />
                </Layout>
            }>
                <Route path="/" element={<ListarMarketplaces />} />
                <Route path="/:uuid" element={<DetalhesMarketplace />} />
                <Route path="/cadastrar" element={<CadastrarEditarMarketplace />} />
                <Route path="/editar/:uuid" element={<CadastrarEditarMarketplace />} />
                <Route path="/personalizar/:uuid" element={<PersonalizarMarketplace />} />
                <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
            </Route>
        </Routes>
    );
}