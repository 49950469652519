import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";

export const Toolbar = styled(Paper)`
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    height: 70px;
    
    @media (max-width: 1280px) {
        height: 100%;
    }
`

export const Tools = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media(max-width: 1080px) {
        flex-direction: column;
    }

    .input-itens {
        width: 200px; 
        margin-left: 1rem;

        @media(max-width: 1080px) {
            width: 150px; 
            margin-left: 0rem;
            margin-top: 0.5rem;
        }
    }
`