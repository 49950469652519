import { TextField, Alert, Button, Dialog, DialogContent, DialogTitle, Typography, DialogActions, CircularProgress, AlertTitle } from "@mui/material";
import { useState } from "react";

interface ModalExcluirInsumosProps {
    open: boolean;
    handleClose: () => void;
    handleExcluir: () => void;
    tamanhoSelecionados: number;
    loading: boolean;
}

export const ModalExcluirInsumos = ({ handleClose, handleExcluir, open, tamanhoSelecionados, loading }: ModalExcluirInsumosProps) => {
    const [confirmacao, setConfirmacao] = useState<string>('');

    const onClose = () => {
        handleClose();
        setConfirmacao('');
    }

    const onExcluir = () => {
        handleExcluir();
        setConfirmacao('');
    }

    return (
        <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    Excluir {tamanhoSelecionados} insumo(s) selecionado(s)
                </DialogTitle>
                <DialogContent>
                    <Alert severity="info">
                        <AlertTitle>
                            As fichas técnicas não serão atualizadas
                        </AlertTitle>
                        O controle de estoque irá ignorar os insumos excluídos dentro das fichas técnicas
                    </Alert>
                    <Alert severity="warning" sx={{mt: 3}}>
                        A exclusão não pode ser revertida
                    </Alert>
                    <Typography sx={{mt: 3}}>
                        Para confirmar a exclusão, digite <b>Excluir permanentemente</b>  no campo.
                    </Typography>
                    <TextField
                        value={confirmacao}
                        onChange={(e) => setConfirmacao(e.target.value)}
                        sx={{ width: "100%", marginTop: "0.5rem" }}
                        size="small"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancelar</Button>
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        <Button
                            onClick={onExcluir}
                            type="button"
                            variant="contained"
                            disabled={"Excluir permanentemente" !== confirmacao}
                        >
                            Excluir
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
    );
}