import { Box, Button, Card, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import { memo, useMemo } from "react"
import { IProdutoPedido } from "../../../../../../interface/IProdutoPedido";
import { IPagadorProdutos } from "../../../../../../interface/IPagadorProdutos";
import { useMoeda } from "../../../../../../../core/hooks/useMoeda";
import { CardProdutoCarrinhoOperacao } from "../../../../../../../produtos/components/CardProdutoCarrinhoOperacao";
import { Delete, Remove } from "@mui/icons-material";
import { IPagadorValor } from "../../../../../../interface/IPagadorValor";
import { MetodoPagamentoSelect } from "../../../../../../components/MetodoPagamentoSelect";
import { IPagamentoPedido } from "../../../../../../interface/IPagamentoPedido";

interface CardPagadorProps {
    pagador: IPagadorValor;
    valorTotal: number;
    alterarPagamento?: (pagamento: IPagamentoPedido | undefined) => void;
    excluirPagador?: (uuid: string) => void;
}

export const CardPagadorValor = ({ pagador, alterarPagamento, valorTotal, excluirPagador }: CardPagadorProps) => {
    const { real } = useMoeda();
    return (
        <Card variant="outlined" sx={{ padding: "1rem", width: "300px", mt: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                <Typography variant="subtitle2">
                    {pagador.nome}
                </Typography>
                <Typography fontWeight={"bold"} variant="subtitle2">
                    {real(pagador.pagamento.valor ?? 0)}
                </Typography>
            </Box>
            {pagador.pagamento && alterarPagamento && (
                <MetodoPagamentoSelect
                    pagamento={pagador.pagamento}
                    valorTotal={valorTotal}
                    onChange={alterarPagamento}
                    size="small"
                />
            )}
            {pagador.pagamento.metodoPagamento && !alterarPagamento && (
                <MetodoPagamentoSelect
                    pagamento={pagador.pagamento}
                    valorTotal={valorTotal}
                    disabled={!alterarPagamento}
                    size="small"
                />
            )}
            {excluirPagador && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                    <Button variant="outlined" color="error" startIcon={<Delete />} onClick={() => excluirPagador(pagador.uuid)}>
                        Excluir
                    </Button>
                </Box>
            )}
        </Card>
    );
}