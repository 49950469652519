import { useState } from "react";
import { ApiFactory } from "../../core/config/ApiFactory";
import { useSnackbar } from "../../core/contexts/SnackbarContext"
import { ICategoria } from "../interfaces/ICategoria"
import { IProduto } from "../interfaces/IProduto";
import { v4 } from "uuid";
import { IImagemProduto } from "../interfaces/IImagemProduto";
import { IReabastecerControleEstoqueProduto } from "../interfaces/IReabastecerControleEstoqueProduto";
import { IConsumirControleEstoqueProduto } from "../interfaces/IConsumirControleEstoqueProduto";

export const useProdutos = () => {
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const apiProdutos = ApiFactory.getApi("MS_PRODUTOS");

    const uploadImagem = async (imagem: File, produtoUuid: string) => {
        setLoading(true);

        const formData = new FormData();

        formData.append('imagem', imagem);

        try {
            const { data } = await apiProdutos.post(`/${produtoUuid}/imagem`, formData);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const listarCategorias = async (): Promise<ICategoria[] | null> => {
        setLoading(true);

        try  {  
            const { data } = await apiProdutos.get('/categorias');
            return data;
        } catch (error) {
            snackbar("Ocorreu um erro ao listar as categorias", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const copiarProduto = async (categorias: ICategoria[], categoriaUuidDestino: string, produtoUuid: string) => {
        if (!categoriaUuidDestino) {
            snackbar("Categoria de destino inválida", { severity: "error" });
            return false;
        }
    
        if (!produtoUuid) {
            snackbar("Produto inválido", { severity: "error" });
            return false;
        }
    
        if(!categorias?.length) {
            snackbar("Nenhuma categoria encontrada", { severity: "error" });
            return false;
        }
    
        let produtoEncontrado: IProduto = undefined as unknown as IProduto;
    
        categorias.forEach(categoria => {
            const produtoProcurado = categoria.produtos?.find(produto => produto.uuid === produtoUuid);
            if (produtoProcurado) {
                produtoEncontrado = produtoProcurado;
            }
        });
    
        if (!produtoEncontrado) {
            snackbar("Produto não encontrado", { severity: "error" });
            return false;
        }
    
        const produtoParaSalvar = {
            ...produtoEncontrado,
            uuid: v4(),
            descricao: `${produtoEncontrado.descricao} - Cópia`
        };
    
        await salvar(categoriaUuidDestino, produtoParaSalvar);
    };

    const salvar = async (categoriaUuid: string, produto: IProduto) => {
        if (!categoriaUuid) {
            snackbar("Categoria inválida", { severity: "error" })
            return false;
        }

        if (!produto) {
            snackbar("Produto inválido", { severity: "error" })
            return false;
        }

        if (!produto.uuid) {
            produto.uuid = v4();
        }

        const imagens: IImagemProduto[] = [];

        if (produto.imagens?.length) {
            await Promise.all(produto.imagens.map(async imagem => {
                if (imagem instanceof File) {
                    const arquivo = await uploadImagem(imagem, produto.uuid);
                    if (arquivo) {
                        imagens.push(arquivo);
                    }
                } else {
                    imagens.push(imagem);
                }
            }))

            produto.imagens = imagens;
        }

        setLoading(true)

        try {
            const { data } = await apiProdutos.post(`/categorias/${categoriaUuid}`, produto);

            if (data) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
    }

    const excluir = async (categoriaUuid: string, produtoUuid: string) => {
        if (!categoriaUuid) {
            snackbar("Categoria inválida", { severity: "error" })
            return false;
        }

        if (!produtoUuid) {
            snackbar("Produto inválido", { severity: "error" })
            return false;
        }

        setLoading(true);

        try {
            const { data } = await apiProdutos.delete(`/categorias/${categoriaUuid}/${produtoUuid}`);

            if (data) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        } finally {
            setLoading(false);
        }
    }

    const consultarIndustrializado = async (codigoBarras: string) => {
        setLoading(true);

        try {
            const { data } = await apiProdutos.get(`/industrializados/${codigoBarras}`);
            return data;
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    }

    const reabastecer = async (produtos: IReabastecerControleEstoqueProduto[]) => {
        if(!produtos.length) {
            snackbar("Nenhum produto recebido para o reabastecimento", { severity: "error" });
            return null;
        }
        setLoading(true);
        try {
            const { data } = await apiProdutos.put("/estoque/reabastecer/produtos", produtos);
            
            if(data) {
                snackbar("Produto(s) reabastecido(s) com sucesso",{ severity: "success" });
                return data;
            }

            snackbar("Ocorreu um erro ao reabastecer o(s) produto(s)", { severity: "error" })
            return null;
        } catch (error) {
            snackbar("Ocorreu um erro ao reabastecer o(s) produto(s)", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    const consumir = async (produtos: IConsumirControleEstoqueProduto[]) => {
        if(!produtos.length) {
            snackbar("Nenhum produto recebido para o consumo", { severity: "error" });
            return null;
        }
        setLoading(true);
        try {
            const { data } = await apiProdutos.put("/estoque/consumir/produtos", produtos);
            
            if(data) {
                snackbar("Produto(s) consumido(s) com sucesso",{ severity: "success" });
                return data;
            }

            snackbar("Ocorreu um erro ao consumir o(s) produto(s)", { severity: "error" })
            return null;
        } catch (error) {
            snackbar("Ocorreu um erro ao consumir o(s) produto(s)", { severity: "error" })
            return null;
        } finally {
            setLoading(false);
        }
    }

    return { salvar, excluir, loading, consultarIndustrializado, consumir, reabastecer, listarCategorias, copiarProduto }
}