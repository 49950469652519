import { createTheme } from "@mui/material"

export const DarkTheme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
              root: {
                borderRadius: 6
              },
            },
        },
    },
    palette: {
        background: {
            default: '#202124',
            paper: '#303134'
        },
        text: {
            primary: '#fff',
            secondary: '#777',
            disabled: '#999'
        },
        action: {
            active: "#fff"
        },
        success: {
            main: '#00a76f'
        },
        common: {
            white: '#FFFFFF',
        },
        error: {
            main: '#f44336'
        },
        secondary: {
            main: '#da4167'
        },
    },
    shape: {
        borderRadius: 16
    },
    shadows: [
        'none',
        '0px 1px 3px rgba(0, 0, 0, 0.08)', 
        '0px 2px 4px rgba(0, 0, 0, 0.08)', 
        '0px 3px 6px rgba(0, 0, 0, 0.08)', 
        '0px 4px 8px rgba(0, 0, 0, 0.08)', 
        '0px 6px 12px rgba(0, 0, 0, 0.08)', 
        '0px8px 16px rgba(0, 0, 0, 0.08)', 
        '0px 10px 20px rgba(0, 0, 0, 0.08)', 
        '0px 14px 28px rgba(0, 0, 0, 0.08)', 
        '0px 18px 36px rgba(0, 0, 0, 0.08)', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
        'none', 
    ], 
})