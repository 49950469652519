import { Grid } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { CadastrarVincularCliente } from "../../../../../clientes/containers/CadastrarVincularCliente";
import { OperacaoCategoriasProdutosContainer } from "../../../../../produtos/containers/OperacaoCategoriasProdutosContainer";
import { CheckoutPaper } from "../../../../components/CheckoutPaper";
import { ClientePaper } from "../../../../components/ClientePaper";
import { MetodoPagamentoSelect } from "../../../../components/MetodoPagamentoSelect";
import { useOperacaoContext } from "../../../../contexts/OperacaoContext";
import { TipoPedido } from "../../../../enum/TipoPedido";
import { CategoriasContainer, Layer, ProdutosPaper } from "./styles";
import { PedidosNaoConcluidosContainer } from "../PedidosNaoConcluidosContainer";
import { useFetchCategorias } from "../../../../../produtos/hooks/useFetchCategorias";
import { useTamanhoTela } from "../../../../../core/hooks/useTamanhoTela";
import { CarrinhoMobilePaper } from "../../styles";
import { ProdutosCarrinhoContainer } from "../../../../../produtos/containers/ProdutosCarrinhoContainer";
import { EmitirNotaFiscalModal } from "../EmitirNotaFiscalModal";
import { SwitchEmitirNotaFiscalPaper } from "../../../../components/SwitchEmitirNotaFiscalPaper";
import { IPagamentoPedido } from "../../../../interface/IPagamentoPedido";
import { DividirPagamento } from "../DividirPagamento";

export const OperacaoRetirada = () => {
    const { modeloTela } = useTamanhoTela();
    const { categorias, isLoading } = useFetchCategorias();
    const {
        cliente,
        vincularCliente,
        adicionarProduto,
        removerCliente,
        valorTotalPedido,
        pagamentos,
        produtos,
        aumentarQuantidade,
        diminuirQuantidade,
        removerProduto,
        fecharPedido,
        loading,
        alterarTipo,
        agendadoPara,
        agendarHorario,
        pedidoSelecionado,
        salvarPedido,
        cancelarPedido,
        editarProduto,
        numeroPedidoDiario,
        numeroTotalItens,
        switchNotaFiscal,
        handleModalNotaFiscal,
        handleSwitchNotaFiscal,
        modalNotaFiscal,
        configuracaoNFCe,
        alterarPagamentos
    } = useOperacaoContext();

    const handleAlterarPagamento = (pagamento?: IPagamentoPedido) => {
        if (!pagamento?.metodoPagamento) {
            alterarPagamentos(undefined);
        } else {
            alterarPagamentos([pagamento]);
        }
    }

    const [drawerPagamentoAberto, setDrawerPagamento] = useState(false);

    const abrirDrawerPagamento = () => {
        setDrawerPagamento(true);
    }

    const fecharDrawerPagamento = () => {
        setDrawerPagamento(false);
    }

    useEffect(() => {
        alterarTipo(TipoPedido.retirada);
    }, [])

    return (
        <Grid
            container
            spacing={0}
            sx={{ width: "100%", height: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
        >

            <Grid item xs={12} md={9} lg={9}>
                <ProdutosPaper variant="outlined">
                    <CategoriasContainer>
                        <OperacaoCategoriasProdutosContainer
                            categorias={categorias}
                            produtosCarrinho={produtos}
                            adicionarProduto={adicionarProduto}
                            aumentarQuantidade={aumentarQuantidade}
                            diminuirQuantidade={diminuirQuantidade}
                            removerProduto={removerProduto}
                            editarProduto={editarProduto}
                            contexto={TipoPedido.retirada}
                        />
                        {!cliente && (
                            <Layer>
                                <CadastrarVincularCliente vincularCliente={vincularCliente} />
                            </Layer>
                        )}
                    </CategoriasContainer>
                </ProdutosPaper>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
                {modalNotaFiscal && (
                    <EmitirNotaFiscalModal
                        cancelar={handleModalNotaFiscal}
                        concluir={fecharPedido}
                        open={modalNotaFiscal}
                        dadosCliente={cliente}
                        loading={loading}
                    />
                )}
                <DividirPagamento
                    aberto={drawerPagamentoAberto}
                    fechar={fecharDrawerPagamento}
                />
                <CheckoutPaper
                    loading={loading}
                    pedidoSelecionado={pedidoSelecionado}
                    valorTotalPedido={valorTotalPedido}
                    numeroTotalItens={numeroTotalItens}
                    numeroPedidoDiario={numeroPedidoDiario}
                    opcoes={[
                        {
                            titulo: "Concluir",
                            funcao: fecharPedido
                        },
                        {
                            titulo: "Dividir pagamento",
                            funcao: abrirDrawerPagamento
                        },
                        {
                            titulo: "Salvar",
                            funcao: salvarPedido,
                            desabilitado: pedidoSelecionado ? true : false
                        },
                        {
                            titulo: "Cancelar",
                            funcao: cancelarPedido,
                            desabilitado: !pedidoSelecionado
                        }
                    ]}
                >
                    <ClientePaper
                        cliente={cliente}
                        removerCliente={removerCliente}
                        desabilitar={pedidoSelecionado ? true : false}
                    />
                    <TimePicker
                        sx={{ marginTop: "1rem", marginBottom: "1rem", width: "100%" }}
                        views={['hours', 'minutes']}
                        format="HH:mm"
                        label="Horário"
                        value={agendadoPara}
                        onChange={agendarHorario}
                        disabled={pedidoSelecionado ? true : false}
                    />
                    <MetodoPagamentoSelect
                        pagamento={pagamentos[0]}
                        onChange={handleAlterarPagamento}
                        valorTotal={valorTotalPedido}
                    />
                    {configuracaoNFCe && !configuracaoNFCe.obrigatorio && (
                        <SwitchEmitirNotaFiscalPaper
                            checked={switchNotaFiscal}
                            change={handleSwitchNotaFiscal}
                            disabled={configuracaoNFCe?.obrigatorio}
                        />
                    )}
                    {modeloTela == "mobile" && (produtos?.length > 0) && (
                        <CarrinhoMobilePaper variant="outlined" sx={{ marginTop: "1rem" }}>
                            <ProdutosCarrinhoContainer
                                categorias={categorias}
                                produtosCarrinho={produtos}
                                aumentarQuantidade={aumentarQuantidade}
                                diminuirQuantidade={diminuirQuantidade}
                                removerProduto={removerProduto}
                                editarProduto={editarProduto}
                            />
                        </CarrinhoMobilePaper>
                    )}
                </CheckoutPaper>
            </Grid>
        </Grid>
    );
}