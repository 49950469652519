export enum StatusPedido {
    "Aguardando confirmação" = 0,
    "Em preparo" = 1,
    "Em transporte" = 2,
    "Concluído" = 3,
    "Cancelado" = 4,
    "Em andamento" = 5
}

export enum StatusPedidoDescricao {
    "Aguardando confirmação" = "Aguardando confirmação",
    "Em preparo" = "Em preparo",
    "Em transporte" = "Em transporte",
    "Concluído" = "Concluído",
    "Cancelado" = "Cancelado",
    "Em andamento" = "Em andamento"
}

export const StatusPedidosCores = {
    "Aguardando confirmação": "#FDFD96", // Amarelo pastel
    "Em preparo": "#FFCC99", // Laranja pastel
    "Em transporte": "#ADD8E6", // Azul pastel
    "Concluído": "#98FB98", // Verde pastel
    "Cancelado": "#FFB6C1", // Vermelho pastel (mais suave para ser menos agressivo)
    "Em andamento": "#DA70D6" // Roxo pastel
  };
  