import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { IProduto } from '../../interfaces/IProduto';
import { useProdutos } from '../../hooks/useProdutos';
import { ICategoria } from '../../interfaces/ICategoria';

interface CopiarProdutoModalProps {
  handleCancelar: () => void;
  open: boolean;
  produto: IProduto;
  categorias: ICategoria[];
  handleRefresh: () => void;
}

export const CopiarProdutoModal = ({ handleCancelar, open, produto, categorias, handleRefresh }: CopiarProdutoModalProps) => {
    const { copiarProduto, loading } = useProdutos();
    const [categoriaSelecionada, setCategoriaSelecionada] = useState('');

    const handleCopiarProduto = async () => {
        await copiarProduto(categorias, categoriaSelecionada, produto.uuid);
        handleRefresh()
    }

    return (
        <Dialog open={open} onClose={handleCancelar} maxWidth="lg" sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
            <DialogTitle>Copiar produto: <b>{produto?.descricao}</b></DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <FormControl fullWidth>
                        <Select
                            value={categoriaSelecionada}
                            onChange={e => setCategoriaSelecionada(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="" disabled>Selecione uma categoria</MenuItem>
                            {categorias.map((categoria) => (
                                <MenuItem key={categoria.uuid} value={categoria.uuid}>{categoria.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelar} color="primary">Cancelar</Button>
                <Button variant="contained" onClick={() => handleCopiarProduto()} color="primary" disabled={loading || !categoriaSelecionada}>Copiar</Button>
            </DialogActions>
        </Dialog>
    );
};
