import styled from "@emotion/styled";
import { Box, Paper, Stepper } from "@mui/material";

export const PageContainer = styled(Box)`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
`

export const ContentContainer = styled(Box)`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    @media(max-width: 1080px) {
        flex-direction: column;
    }
` 

export const FormContainer = styled(Box)`
    width: 49%;

    @media(max-width: 1080px) {
        width: 100%;
    }
`

export const ResumoPaper = styled(Paper)`
    width: 49%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 50%;

    @media(max-width: 1080px) {
        width: 100%;
        height: 100%;
        margin-top: 1rem;
    }
`

export const ResumoFlex = styled(Box)`
    margin-top: 2rem;
    height: 100%;
    display: flex;
    justify-content: space-between;

    @media(max-width: 1080px) {
        flex-direction: column;

        div {
            width: 100%;

            &:first-child {
                margin-bottom: 2rem;
            }
        }
    }
`

export const ButtonsContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    
    button {
        width: 120px;
    }
`

export const StepperContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CustomStepper = styled(Stepper)`
    width: 100%;
`