import styled from "@emotion/styled";
import { Box, Card, Grid, Paper } from "@mui/material";

export const PaperEstabelecimento = styled(Card)`
    height: 175px;
    display: flex;
    border-radius: 9px;
    &:hover {
        color: ${props => props.theme.palette.primary.main};
        .store-icon {
            transition: 0.5s;
            background-color: ${props => props.theme.palette.primary.main};
            color: ${props => props.theme.palette.common.white};
        }
    }
`

export const TituloPagina = styled(Grid)`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    @media(max-width: 768px) {
        align-items: center;
        flex-direction: column;

        button {
            margin-top: 1rem;
        }
    }
`

export const PaperEstabelecimentoTitulo = styled(Box)`
    display: flex;
    align-items: center;
`

export const PaperEstabelecimentoDados = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
`

export const StoreIcon = styled(Box)`
    width: 40px;
    height: 40px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.palette.divider};
`