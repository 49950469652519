export enum DiaSemana {
    "domingo" = "domingo",
    "segunda-feira" = "segunda-feira",
    "terça-feira" = "terça-feira",
    "quarta-feira" = "quarta-feira",
    "quinta-feira" = "quinta-feira",
    "sexta-feira" = "sexta-feira",
    "sábado" = "sábado",
}

export enum DiaSemanaOrdem {
    "domingo" = 0,
    "segunda-feira" = 1,
    "terça-feira" = 2,
    "quarta-feira" = 3,
    "quinta-feira" = 4,
    "sexta-feira" = 5,
    "sábado" = 6,
}