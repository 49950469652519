import { Alert, Link, Paper, Switch, Tooltip, Typography } from "@mui/material";

interface SwitchEmitirNotaFiscalPaperProps {
    checked: boolean;
    change: () => void;
    disabled?: boolean;
}

export const SwitchEmitirNotaFiscalPaper = ({ checked, change, disabled }: SwitchEmitirNotaFiscalPaperProps) => {
    return (
        <Paper sx={{ 
            width: '100%', 
            marginTop: "1rem", 
            padding: "0.5rem 1rem", 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between',
            alignItems: 'center'
        }} variant="outlined">
            <Typography sx={{ width: '100%' }}>
                Nota Fiscal
            </Typography>
            <Switch disabled={disabled} onClick={change} checked={checked} defaultChecked={false}/>
        </Paper>
    )
}