import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const CardLocation = styled(Card)`
    border: 1px solid ${props => props.theme.palette.primary.main};
    color: 1px solid ${props => props.theme.palette.primary.main};
    padding: 0.7rem;
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
`