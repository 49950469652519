import styled from "@emotion/styled";
import { Paper, css } from "@mui/material";

export const CardContainer = styled(Paper)((props) => css`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`);


export const TextContainer = styled.div`
    width: 233px;
    text-align: center;
`