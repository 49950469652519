import styled from "@emotion/styled";
import { Paper, css } from "@mui/material";


export const MetodoPagamentoItem = styled(Paper)<{selecionado?: boolean}>`
  width: 100%;
  font-size: 17px;
  height: 40px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 5px;

  ${props =>
    props.selecionado &&
    css`
      background-color: ${props.theme.palette.primary.main}11;
      border: 1px solid ${props.theme.palette.primary.main};
    `}

  &:hover {
    ${props =>
      !props.selecionado &&
      css`
        background-color: ${props.theme.palette.primary.main}33;
        border: 1px solid ${props.theme.palette.primary.main};
      `}
  }
`;
