import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const CardContainer = styled(Card)`
    width: 100%;
    cursor: grab;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    height: 160px;

    &:first-child {
        margin-top: 0;
    }
`

export const CardDragging = styled(Card)`
    width: 100%;
    cursor: grab;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    height: 160px;
    opacity: calc(0.6);
    border: 1px solid ${props => props.theme.palette.primary.main};

    &:first-child {
        margin-top: 0;
    }
`

