import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import { useState } from "react";
import { IProdutoPedido } from '../../../pedidos/interface/IProdutoPedido';
import { IProduto } from "../../interfaces/IProduto";
import { DrawerPizzaOperacao } from '../DrawerPizzaOperacao';
import { useMoeda } from '../../../core/hooks/useMoeda';
import { DrawerPreparadoOperacao } from '../DrawerPreparadoOperacao';
import { TipoProduto } from '../../enum/TipoProduto';
import { v4 } from 'uuid';
import { useLetras } from '../../../core/hooks/useLetras';

interface CardPreparadoOperacaoProps {
  produto: IProduto;
  adicionarProduto: (produto: IProdutoPedido) => void;
}

export const CardPreparadoOperacao = ({ produto, adicionarProduto }: CardPreparadoOperacaoProps) => {
  const [open, setOpen] = useState(false);
  const { real } = useMoeda();
  const { maiusculas } = useLetras();

  const handleAdicionarProduto = () => {
    if(!produto.configuracao?.habilitarComplementos && !produto.configuracao?.habilitarObservacao) {
      return adicionarProduto({
        ...produto,
        tipo: TipoProduto.preparado,
        uuid: v4(),
        uuidProdutoReferencia: produto.uuid
      })
    }

    return toggleDrawer();
  }

  const toggleDrawer = () => {
    setOpen(!open);
  }

  return (
    <>
      <Card sx={{ maxWidth: 300, height: 250 }} variant='outlined' onClick={handleAdicionarProduto}>
        <CardActionArea sx={{ height: "100%", width: "100%" }}>
          {produto.imagens?.length ? (
            <CardMedia
              component="img"
              height="140px"
              image={`${produto.imagens[0].url}`}
              sx={{ objectFit: "cover" }}
              alt="Pizza"
            />
          ) : (
            <CardMedia
              component="img"
              height="140px"
              image={`${process.env.PUBLIC_URL}/images/produtos/preparado.png`}
              sx={{ objectFit: "contain" }}
              alt="Pizza"
            />
          )}
          <CardContent sx={{ height: "100%" }}>
            <Typography gutterBottom variant="subtitle2" fontWeight={"bold"} component="div">
              {maiusculas(produto.descricao, 25)}
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
              {real(produto.preco)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <DrawerPreparadoOperacao
        onSubmit={adicionarProduto}
        toggleDrawer={toggleDrawer}
        produto={produto}
        open={open}
      />
    </>
  );
}