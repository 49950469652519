import styled from "@emotion/styled";
import { Grid, Paper } from "@mui/material";

export const OperacaoContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: ${props => props.theme.palette.background.paper};
`

export const CarrinhoMobilePaper = styled(Paper)`
    
`

export const HeaderGrid = styled(Grid)`
    display: flex;
    justify-content: space-between;
    height: 5vh;
    margin-bottom: 1.5vh;

    @media(max-width: 768px) {
        flex-direction: column;
    }

    .button-area {
        display: flex; 
        align-items: center;
        justify-content: center;
        margin-top: 0.6rem;

        @media(max-width: 768px) {
        }
    }
`