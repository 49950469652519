import { Avatar, Button, Typography } from "@mui/material";
import { ICliente } from "../../../clientes/interface/ICliente"
import { ClientePaperContainer } from "./styles";

interface ClientePaperProps {
    cliente: ICliente | undefined;
    removerCliente: () => void;
    desabilitar?: boolean | undefined;
}
 
export const ClientePaper = ({ cliente, removerCliente, desabilitar }: ClientePaperProps) => {
    return (
        <ClientePaperContainer variant="outlined">
            <Avatar>{cliente && cliente.nome?.charAt(0).toUpperCase()}</Avatar>
            <div>
                {!cliente ? (
                    <Typography variant="body2">Cliente indefinido</Typography>
                ) : (
                    <Typography variant="body2" >{cliente.nome}</Typography>
                )}
                {cliente && <Button color="error" onClick={removerCliente} disabled={desabilitar}>REMOVER</Button>}
            </div>
        </ClientePaperContainer>
    );
}