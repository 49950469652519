import styled from "@emotion/styled";
import { Paper, css } from "@mui/material";

export const CardContainer = styled(Paper)((props) => css`
  width: 100px;
  height: 100px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props.theme.palette.primary.main}33;
  color: ${props.theme.palette.primary.main};
  svg {
    width: 40px;
    height: 40px;
  }
`);
