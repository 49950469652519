import { Box, Card, CardHeader, Grid, IconButton, Paper, Typography } from "@mui/material";
import { memo, useMemo } from "react"
import { IProdutoPedido } from "../../../../../../interface/IProdutoPedido";
import { IPagadorProdutos } from "../../../../../../interface/IPagadorProdutos";
import { useMoeda } from "../../../../../../../core/hooks/useMoeda";
import { CardProdutoCarrinhoOperacao } from "../../../../../../../produtos/components/CardProdutoCarrinhoOperacao";
import { Delete, LocationOn, Remove } from "@mui/icons-material";
import { MetodoPagamentoSelect } from "../../../../../../components/MetodoPagamentoSelect";
import { CardLocation } from "./styled";

interface CardPagadorProps {
    pagador: IPagadorProdutos;
    excluirPagador: (uuid: string) => void;
}

const CardPagadorProdutos = ({ pagador, excluirPagador }: CardPagadorProps) => {
    const totalProdutos = useMemo(() => {
        let totalPagador = 0;

        pagador.produtos.map(produto => {
            let totalProduto = (produto.preco ?? 0);

            produto.itens?.map(item => {
                totalProduto = totalProduto + ((item.preco ?? 0) * (item.quantidade ?? 1));
            })

            totalPagador += totalProduto * (produto.quantidade ?? 1);
        })

        return totalPagador
    }, [pagador?.produtos]);

    const { real } = useMoeda();

    return (
        <Card variant="outlined" sx={{ padding: "1rem" }}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="subtitle1">
                        {pagador.nome}
                    </Typography>
                    <IconButton onClick={() => excluirPagador(pagador.uuid)} size="small" color="error">
                        <Delete sx={{ width: "15px", height: "15px" }} />
                    </IconButton>
                </Box>
                <Typography variant="h6" fontWeight={"bold"} sx={{ mt: 1 }}>
                    {real(pagador.pagamento.valor ?? 0)}
                </Typography>
                {pagador.pagamento?.metodoPagamento != undefined && (
                    <Box sx={{ mt: "1rem" }}>
                        <MetodoPagamentoSelect
                            disabled
                            pagamento={pagador.pagamento}
                            valorTotal={pagador.pagamento?.valor ?? 0}
                            size="small"
                        />
                    </Box>
                )}
                {pagador.pagarEntrega && (
                    <CardLocation variant="outlined">
                        <Typography color="primary" fontWeight={"bold"}>{real((pagador.pagamento.valor ?? 0) - totalProdutos)}</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <LocationOn color="primary" />
                        </Box>
                    </CardLocation>
                )}
            </Box>
            <Grid
                container
                sx={{ marginTop: "1rem", width: "100%" }}
            >
                {pagador.produtos.map(produto => {
                    return (
                        <Grid item xs={12} key={produto.uuid} sx={{ mt: "0.5rem" }}>
                            <CardProdutoCarrinhoOperacao
                                produto={produto}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Card>
    );
}

export default memo(CardPagadorProdutos)