import { Checkbox, Chip, CircularProgress, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { useFetchItensControleEstoque } from "../../hooks/useFetchItensControleEstoque";
import { ToolbarInsumos } from "../Insumos/containers/ToolbarInsumos";
import { ToolbarProdutos } from "./containers/ToolbarProdutos";
import { ProdutosContainer } from "./styles";
import { ChangeEvent, useEffect, useState } from "react";
import { useAcoesItensContrleEstoqueContext } from "../../contexts/AcoesItensControleEstoqueContext";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { IProduto } from "../../interfaces/IProduto";
import { TipoControleEstoque } from "../../enum/TipoControleEstoque";
import { FichaTecnica } from "./containers/FichaTecnica";
import { IITemControleEstoque } from "../../interfaces/IItemControleEstoque";
import { MoreVert } from "@mui/icons-material";
import { useLetras } from "../../../core/hooks/useLetras";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { useNavigate } from "react-router-dom";

export const Itens = () => {
    const { isLoading, itens, categorias } = useFetchItensControleEstoque();
    const { itensSelecionados, limparItensSelecionados, selecionarItem } = useAcoesItensContrleEstoqueContext();

    const { real } = useMoeda();
    const { titulo } = useLetras();

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('quantidade');

    const [filtroNome, setFiltroNome] = useState('');
    const [filtroControleEstoque, setFiltroControleEstoque] = useState<{ label: string; value: string } | null>(null);
    const [filtroTipo, setFiltroTipo] = useState<{ label: string; value: string } | null>(null);

    const [fichaTecnicaOpen, setFichaTecnicaOpen] = useState<boolean>(false);
    const [itemFichaTecnica, setItemFichaTecnica] = useState<IITemControleEstoque | undefined>(undefined);
    const handleOpenFichaTecnica = (item: IITemControleEstoque) => {
        setItemFichaTecnica(item);
        setFichaTecnicaOpen(true);
    }
    const handleCloseFichaTecnica = () => {
        setItemFichaTecnica(undefined);
        setFichaTecnicaOpen(false);
    }

    const handleRequestSort = (tipo: string) => {
        if (tipo !== orderBy) {
            setOrderBy(tipo);
            return
        }

        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    const handleFiltroNomeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFiltroNome(event.target.value);
    };

    const handleFiltroControleEstoqueChange = (event: ChangeEvent<{}>, value: { label: string; value: string } | null) => {
        setFiltroControleEstoque(value);
    };
    
    const handleFiltroTipoChange = (event: ChangeEvent<{}>, value: { label: string; value: string } | null) => {
        setFiltroTipo(value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const itensFiltrados = itens?.filter(item => {
        const filtroNomePassa = item.nome.toLowerCase().includes(filtroNome.toLowerCase());
        const filtroControleEstoquePassa = filtroControleEstoque ? item.controleEstoque === filtroControleEstoque.value : true;
        const filtroTipoPassa = filtroTipo ? item.tipo === filtroTipo.value : true;
    
        return filtroNomePassa && filtroControleEstoquePassa && filtroTipoPassa;
    });

    const sortInsumos = (a: IITemControleEstoque, b: IITemControleEstoque) => {
        if (orderBy === 'quantidade') {
            return order === 'asc'
                ? (a.quantidade ?? 0) - (b.quantidade ?? 0)
                : (b.quantidade ?? 0) - (a.quantidade ?? 0);
        } else if (orderBy === 'tipo') {
            return order === 'asc' ? a.tipo.localeCompare(b.tipo) : b.tipo.localeCompare(a.tipo);
        } else if (orderBy === 'nome') {
            return order === 'asc' ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome);
        } else if (orderBy === 'controleEstoque') {
            return order === 'asc' ? a.controleEstoque.localeCompare(b.controleEstoque) : b.controleEstoque.localeCompare(a.controleEstoque);
        } else if (orderBy === 'preco') {
            return order === 'asc'
                ? (a.preco ?? 0) - (b.preco ?? 0)
                : (b.preco ?? 0) - (a.preco ?? 0);
        }

        return 0;
    };

    const itensOrdenados = itensFiltrados?.sort(sortInsumos);
    const itensPaginados = itensOrdenados?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    useEffect(() => {
        limparItensSelecionados();
    }, [])

    const navigate = useNavigate();
    const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
    useEffect(() => {
        if(perfil && !verificarAcessoFuncionalidade("Controle de estoque simples")) {
            navigate("/nao-autorizado")
        }
    }, [perfil])

    return (
        <ProdutosContainer>
            <ToolbarProdutos
                selected={itensSelecionados}
                filtroNome={filtroNome}
                onFiltroNomeChange={handleFiltroNomeChange}
                filtroControleEstoque={filtroControleEstoque}
                onFiltroControleEstoqueChange={handleFiltroControleEstoqueChange}
                filtroTipo={filtroTipo}
                onFiltroTipoChange={handleFiltroTipoChange}
            />
            <TableContainer component={Paper} sx={{ width: "90%", mt: 2 }}>
                <Table sx={{ minWidth: 650 }} size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'nome'}
                                    direction={order}
                                    onClick={() => handleRequestSort("nome")}
                                >
                                    Nome
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'tipo'}
                                    direction={order}
                                    onClick={() => handleRequestSort("tipo")}
                                >
                                    Tipo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'controleEstoque'}
                                    direction={order}
                                    onClick={() => handleRequestSort("controleEstoque")}
                                >
                                    Controle de estoque
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'preco'}
                                    direction={order}
                                    onClick={() => handleRequestSort("preco")}
                                >
                                    Preço
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'quantidade'}
                                    direction={order}
                                    onClick={() => handleRequestSort("quantidade")}
                                >
                                    Quantidade
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Produto ref.</TableCell>
                            <TableCell>Ficha técnica</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (itens?.length ?? 0) > 0 ? (
                            itensPaginados?.map((item) => {
                                const isItemSelected = itensSelecionados.some(s => s.uuid === item.uuid);
                                let produtoReferencia: any = undefined;
                                let categoriaReferencia: any = undefined;

                                if (item.uuidProdutoReferencia) {
                                    categorias?.map(categoria => {
                                        produtoReferencia = categoria?.produtos?.find(produto => produto.uuid == item.uuidProdutoReferencia);
                                        if (produtoReferencia) {
                                            categoriaReferencia = categoria;
                                        }
                                    })
                                }

                                return (
                                    <TableRow
                                        key={item.uuid}
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                        role="checkbox"
                                        hover
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                onClick={() => selecionarItem(item)}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.nome}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip label={titulo(item.tipo)} size="small" />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                label={titulo(item.controleEstoque)}
                                                color={
                                                    item.controleEstoque == TipoControleEstoque.completo
                                                        ? "primary"
                                                        : "success"
                                                }
                                                variant="outlined"
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.preco ? real(item.preco) : "-"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.quantidade}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {produtoReferencia
                                                ? <Link href={`/cardapio/categorias/${categoriaReferencia.uuid}/produtos/${produtoReferencia.uuid}`}>
                                                    {produtoReferencia.descricao}
                                                </Link>
                                                : "-"
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.fichaTecnica?.length
                                                ? (
                                                    <IconButton onClick={() => handleOpenFichaTecnica(item)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                )
                                                : ""}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                    Nenhum item cadastrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={itensFiltrados?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página"
                />
                <FichaTecnica
                    close={handleCloseFichaTecnica}
                    item={itemFichaTecnica}
                    open={fichaTecnicaOpen}
                />
            </TableContainer>
        </ProdutosContainer>
    );
}