import { useDraggable } from "@dnd-kit/core";
import { IPedido } from "../../../../interface/IPedido";
import { Box, Card, CardActionArea, Chip, Drawer, Tooltip, Typography } from "@mui/material";
import { CardContainer, CardDragging } from "./styles";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { TipoPedido } from "../../../../enum/TipoPedido";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { useEffect, useRef, useState } from "react";
import { PedidoDetalhado } from "../../../PedidoDetalhado";
import { useThemeContext } from "../../../../../core/contexts/ThemeContext";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { TipoMarketplace, TipoMarketplaceSigla, TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../../../../marketplace/enum/TipoMarketplace";

interface CardPedidoProps {
    pedido: IPedido
    onClick?: () => void;
}

export const CardPedido = ({ pedido, onClick }: CardPedidoProps) => {
    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: pedido.uuid,
        data: {
            type: "Pedido",
            pedido
        }
    })
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    const { real } = useMoeda();
    const { dataHoraBr } = useDataHora();

    if (isDragging) {
        return <CardDragging ref={setNodeRef} style={style} />
    }

    return (
        <>
            <CardContainer
                onClick={onClick}
                variant="outlined"
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
            >
                <CardActionArea sx={{ padding: "1rem", width: "100%", height: "100%" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="subtitle1" fontWeight={"bold"}>
                            #{pedido.numeroPedido}
                        </Typography>

                        <Typography variant="subtitle1" fontWeight={"bold"}>
                            {real(pedido.valorTotal)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4, alignItems: "flex-end" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="subtitle2" color={"GrayText"}>
                                {pedido.tipo == TipoPedido.delivery && (pedido.cliente?.nome)}
                                {pedido.tipo == TipoPedido.retirada && (pedido.cliente?.nome)}
                                {pedido.tipo == TipoPedido.mesa && (`Mesa ${pedido.mesaId}`)}
                                {pedido.tipo == TipoPedido.simples && (`-`)}
                            </Typography>
                            <Typography variant="subtitle2" fontSize={12} color={"GrayText"}>
                                {dataHoraBr(pedido.dataHora)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                            {pedido.tipo == TipoPedido.delivery && (
                                <Typography fontSize={12} variant="subtitle2" color={"GrayText"}>
                                    {pedido.endereco?.regiao?.descricao}
                                </Typography>
                            )}
                            <Typography variant="subtitle2" color={"GrayText"}>
                                {pedido.tipo.toUpperCase()}
                            </Typography>
                            <Chip
                                label={TipoMarketplaceSigla[pedido?.marketplace?.tipo ?? TipoMarketplace.PDV]}
                                size="small"
                                sx={{
                                    background: TipoMarketplaceCor[pedido?.marketplace?.tipo ?? TipoMarketplace.PDV],
                                    color: 'white'
                                }}
                            />
                        </Box>
                    </Box>
                </CardActionArea>
            </CardContainer>
        </>
    );
}