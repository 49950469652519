import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Avatar, Box, Breadcrumbs, Button, Card, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, Link, Paper, Select, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardUploadImagem } from "../../../core/components/CardUploadImagem";
import { useLetras } from "../../../core/hooks/useLetras";
import { ButtonsContainer, ContentContainer, FormContainer, PageContainer, ResumoPaper, ResumoFlex, HeaderBar, StatusHeader, StatusLabel, MinOrderContainer, MinOrderText, Dot, CategoriasContainer, BuscaContainer, CategoriaContainer, ButtonCarrinhoContainer } from "./styles";
import { Delete, ExpandMore, Menu, Save } from "@mui/icons-material";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { marketplaceSchema } from "../../schema/marketplaceSchema";
import { FieldValues, useForm } from "react-hook-form";
import { TipoMarketplace, TipoMarketplaceCor, TipoMarketplaceDescricao, TipoMarketplaceTitulo } from "../../enum/TipoMarketplace";
import { useMarketplaces } from "../../hooks/useMarketplaces";
import { useFetchMarketplaces } from "../../hooks/useFetchMarketplaces";
import { IMarketplace } from "../../interfaces/IMarketplace";
import { HexColorPicker } from "react-colorful";
import { useThemeContext } from "../../../core/contexts/ThemeContext";
import { CorInput } from "../../../core/components/CorInput";
import { DropzoneUpload } from "../../../core/components/DropzoneUpload";
import { MIMETypes } from "../../../core/enum/MIMETypes";
import { IPersonalizacaoMarketplace } from "../../interfaces/IPersonalizacaoMarketplace";
import { ReferenciaImagemMarketplace } from "../../enum/ReferenciaImagemMarketplace";
import { IImagemMarketplace } from "../../interfaces/IImagemMarketplace";

export const PersonalizarMarketplace = () => {
    const { theme } = useThemeContext();
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const [marketplace, setMarketplace] = useState<IMarketplace | undefined>(undefined);

    const [openSair, setOpenSair] = useState(false);

    const handleOpenSair = () => setOpenSair(true);
    const handleCloseSair = () => setOpenSair(false);

    const [loadingSalvar, setLoadingSalvar] = useState(false);
    const { salvar, upload } = useMarketplaces();
    const { marketplaces, loading, handleRefresh } = useFetchMarketplaces();
    const { uuid } = useParams();

    useEffect(() => {
        if (uuid && loading == false && !marketplace) {
            const marketplaceEncontrado = marketplaces?.find(marketplace => marketplace.uuid === uuid);
            if (!marketplaceEncontrado) {
                navigate("/marketplace");
                return snackbar("Marketplace não encontrado", { severity: "error" })
            }
            setMarketplace(marketplaceEncontrado);
            if (marketplaceEncontrado.configuracao?.personalizacao?.corPrimaria) {
                setCorPrimaria(marketplaceEncontrado.configuracao?.personalizacao?.corPrimaria)
            }
            if (marketplaceEncontrado.configuracao?.personalizacao?.corSecundaria) {
                setCorSecundaria(marketplaceEncontrado.configuracao?.personalizacao?.corSecundaria)
            }

            const logo = marketplaceEncontrado.
                configuracao?.
                personalizacao?.
                imagens?.
                find(imagem => imagem.referencia == ReferenciaImagemMarketplace.logo)

            if (logo) {
                setImagemLogo(logo)
            }

            const capa = marketplaceEncontrado.
                configuracao?.
                personalizacao?.
                imagens?.
                find(imagem => imagem.referencia == ReferenciaImagemMarketplace.capa)

            if (capa) {
                setImagemCapa(capa)
            }
        }
    }, [uuid, marketplaces, loading])

    // const { verificarAcessoFuncionalidade, tipo } = useAutenticacaoContext();
    // useEffect(() => {
    //     if(tipo && !verificarAcessoFuncionalidade("Gestão de produtos")) {
    //         navigate("/nao-autorizado")
    //     }
    // }, [tipo])

    const [corPrimaria, setCorPrimaria] = useState(
        marketplace?.configuracao?.personalizacao?.corPrimaria ??
        theme.palette.primary.main
    );

    const [corSecundaria, setCorSecundaria] = useState(
        marketplace?.configuracao?.personalizacao?.corSecundaria ??
        theme.palette.secondary.main
    );

    const [imagemLogo, setImagemLogo] = useState<undefined | File | IImagemMarketplace>(undefined);
    const [imagemCapa, setImagemCapa] = useState<undefined | File | IImagemMarketplace>(undefined);

    const onDrop = (files: File[], referencia: ReferenciaImagemMarketplace) => {
        const imagem = files[0];

        if (referencia == ReferenciaImagemMarketplace.logo) {
            setImagemLogo(imagem);
        }

        if (referencia == ReferenciaImagemMarketplace.capa) {
            setImagemCapa(imagem);
        }
    }

    const handleSalvarPersonalizacao = async () => {
        if (loadingSalvar) return;

        if (marketplace && marketplace.uuid) {
            setLoadingSalvar(true);

            let newImagens = [...(marketplace?.configuracao?.personalizacao?.imagens || [])];

            const logoIndex = newImagens.findIndex(imagem => imagem.referencia === ReferenciaImagemMarketplace.logo);
            const capaIndex = newImagens.findIndex(imagem => imagem.referencia === ReferenciaImagemMarketplace.capa);

            const indicesToRemove = [];
            if (imagemLogo === undefined && logoIndex !== -1) {
                indicesToRemove.push(logoIndex);
            }
            if (imagemCapa === undefined && capaIndex !== -1) {
                indicesToRemove.push(capaIndex);
            }

            indicesToRemove.sort((a, b) => b - a).forEach(index => {
                newImagens.splice(index, 1);
            });

            const resultado = await salvar({
                ...marketplace,
                configuracao: {
                    ...marketplace.configuracao,
                    personalizacao: {
                        corPrimaria,
                        corSecundaria,
                        imagens: newImagens
                    }
                }
            });

            if (!resultado?.uuid) {
                setLoadingSalvar(false);
                snackbar("Ocorreu um erro ao salvar a personalização", { severity: "error" });
                return;
            }

            if (imagemLogo instanceof File) {
                const imagemUpload = await upload(resultado.uuid, imagemLogo, ReferenciaImagemMarketplace.logo);
                if (imagemUpload) {
                    newImagens.push(imagemUpload);
                }
            }

            if (imagemCapa instanceof File) {
                const imagemUpload = await upload(resultado.uuid, imagemCapa, ReferenciaImagemMarketplace.capa);
                if (imagemUpload) {
                    newImagens.push(imagemUpload);
                }
            }

            handleRefresh();
            setTimeout(() => {
                setLoadingSalvar(false);
                navigate(`/marketplace/${marketplace.uuid}`);
            }, 1000)
        }
    };



    return (
        <PageContainer>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" href="/marketplace">
                    Marketplaces
                </Link>
                {marketplace && (
                    <Link color="primary" href={`/marketplace/${marketplace.uuid}`}>
                        {marketplace.nome}
                    </Link>
                )}
                <Typography color="text.primary">Personalizar</Typography>
            </Breadcrumbs>
            <Typography variant="h5" sx={{ marginTop: "2rem" }}>
                Personalizar
            </Typography>
            <ContentContainer>
                <FormContainer>

                    <Accordion
                        expanded={true}
                        sx={{
                            "&:before": {
                                display: "none",
                            },
                            "&.MuiAccordion-root::before": {
                                display: "none",
                            },
                            borderRadius: "16px",
                        }}
                    >
                        <AccordionSummary>
                            <Typography variant="h6">
                                Logo
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex" }}>
                            {!imagemLogo ? (
                                <Box sx={{ width: "100%", height: "300px" }}>
                                    <DropzoneUpload
                                        tamanhoMaximoMB={5}
                                        onDrop={files => onDrop(files, ReferenciaImagemMarketplace.logo)}
                                        limiteArquivos={1}
                                        mimetypes={[
                                            MIMETypes["image/jpeg"],
                                            MIMETypes["image/jpg"],
                                            MIMETypes["image/png"],
                                        ]}
                                        descricao="Tamanho ideal em pixels: 300 x 300"
                                    />
                                </Box>
                            ) : (
                                <Paper variant="outlined" sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 2 }}>
                                    <Paper sx={{ width: "80px", height: "80px", borderRadius: "35px" }}>
                                        <img style={{ width: "80px", height: "80px", objectFit: "cover", borderRadius: "35" }} src={
                                            imagemLogo instanceof File
                                                ? URL.createObjectURL(imagemLogo) :
                                                imagemLogo.url
                                        } />
                                    </Paper>
                                    <Box sx={{ p: 2, ml: 2, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                        <Typography>{imagemLogo instanceof File ? imagemLogo.name : imagemLogo.nomeOriginal}</Typography>
                                        <Button onClick={() => setImagemLogo(undefined)} size="small" startIcon={<Delete />} color="error">REMOVER</Button>
                                    </Box>
                                </Paper>
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={true}
                        sx={{
                            "&:before": {
                                display: "none",
                            },
                            "&.MuiAccordion-root::before": {
                                display: "none",
                            },
                            borderRadius: "16px",
                        }}
                    >
                        <AccordionSummary>
                            <Typography variant="h6">
                                Capa
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex" }}>
                            {!imagemCapa ? (
                                <Box sx={{ width: "100%", height: "300px" }}>
                                    <DropzoneUpload
                                        tamanhoMaximoMB={10}
                                        onDrop={files => onDrop(files, ReferenciaImagemMarketplace.capa)}
                                        limiteArquivos={1}
                                        mimetypes={[
                                            MIMETypes["image/jpeg"],
                                            MIMETypes["image/jpg"],
                                            MIMETypes["image/png"],
                                        ]}
                                        descricao="Tamanho ideal: 1920 x 600"
                                    />
                                </Box>
                            ) : (
                                <Paper variant="outlined" sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column" }}>
                                    <Paper sx={{ width: "400px", height: "150px", borderRadius: "35px" }}>
                                        <img style={{ width: "400px", height: "150px", objectFit: "cover", borderRadius: "35px" }} src={
                                            imagemCapa instanceof File
                                                ? URL.createObjectURL(imagemCapa) :
                                                imagemCapa.url
                                        } />
                                    </Paper>
                                    <Box sx={{ p: 2, ml: 2, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                        <Typography>{imagemCapa instanceof File ? imagemCapa.name : marketplace?.nome}</Typography>
                                        <Button onClick={() => setImagemCapa(undefined)} size="small" startIcon={<Delete />} color="error">REMOVER</Button>
                                    </Box>
                                </Paper>
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={true}
                        sx={{
                            "&:before": {
                                display: "none",
                            },
                            "&.MuiAccordion-root::before": {
                                display: "none",
                            },
                            borderRadius: "16px",
                        }}
                    >
                        <AccordionSummary>
                            <Typography variant="h6">
                                Cor primária
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex" }}>
                            <HexColorPicker color={corPrimaria} onChange={(cor) => setCorPrimaria(cor)} />
                            <Box sx={{ p: "1rem", ml: "0.5rem" }}>
                                <CorInput
                                    label="Cor primária"
                                    cor={corPrimaria}
                                    mensagemCopiar="Cor primária copiada para a área de transferência"
                                    onChange={(cor) => setCorPrimaria(cor)}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={true}
                        sx={{
                            "&.Mui-expanded": {
                                margin: 0,
                            },
                            "&:before": {
                                display: "none",
                            },
                            "&.MuiAccordion-root::before": {
                                display: "none",
                            },
                            borderRadius: "16px",
                        }}
                    >
                        <AccordionSummary>
                            <Typography variant="h6">
                                Cor secundária
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex" }}>
                            <HexColorPicker color={corSecundaria} onChange={(cor) => setCorSecundaria(cor)} />
                            <Box sx={{ p: "1rem", ml: "0.5rem" }}>
                                <CorInput
                                    label="Cor secundária"
                                    cor={corSecundaria}
                                    mensagemCopiar="Cor secundároa copiada para a área de transferência"
                                    onChange={(cor) => setCorSecundaria(cor)}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </FormContainer>
                <ResumoPaper>
                    <Typography variant="h6">Pré-visualização</Typography>
                    <Alert severity="info" sx={{ mt: "1rem" }}>
                        A pré-visualização apresentada é apenas ilustrativa
                    </Alert>
                    <Paper variant="outlined" sx={{ mt: "1rem", display: "flex", flexDirection: "column" }}>
                        <HeaderBar>
                            <Menu />
                            {marketplace?.configuracao?.nomePublico}
                            {
                                imagemLogo ? (
                                    <img style={{ width: "65px", height: "65px", objectFit: "contain", borderRadius: "25px" }} src={
                                        imagemLogo instanceof File
                                            ? URL.createObjectURL(imagemLogo) :
                                            imagemLogo.url
                                    } />
                                ) : (
                                    <Avatar>{marketplace?.nome && marketplace?.nome.charAt(0).toUpperCase()}</Avatar>
                                )
                            }
                        </HeaderBar>
                        <StatusHeader>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <StatusLabel variant="body1">
                                    <Dot />
                                    Loja aberta
                                </StatusLabel>
                            </Box>
                            <MinOrderContainer>
                                <Typography variant="body1">$ Pedido mín.</Typography>
                                <MinOrderText variant="body1" fontWeight={"bold"}>R$ 20,00</MinOrderText>
                            </MinOrderContainer>
                        </StatusHeader>
                        <BuscaContainer>
                            <TextField fullWidth placeholder="Buscar no cardápio" label="Buscar no cardápio" />
                        </BuscaContainer>
                        <CategoriasContainer>
                            <Tabs
                                value={0}
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                sx={{ mb: 2 }}
                                TabIndicatorProps={{
                                    style: {
                                        borderBottom: `2px solid ${corPrimaria}`
                                    }
                                }}
                            >
                                <Tab label="CATEGORIA 1"
                                    sx={{
                                        color: corPrimaria,
                                        '&.Mui-selected': {
                                            color: corPrimaria
                                        }
                                    }}
                                />
                                <Tab label="CATEGORIA 2" />
                                <Tab label="CATEGORIA 3" />
                            </Tabs>
                        </CategoriasContainer>
                        <CategoriaContainer container>
                            <Card variant="outlined" sx={{ width: "48%" }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={`${process.env.PUBLIC_URL}/images/produtos/generico.png`}
                                    title="Pizza exemplo"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Produto 1
                                    </Typography>
                                    <Typography fontWeight={"bold"} variant="body2" color={corSecundaria}>
                                        R$ 30,00
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" sx={{ width: "48%" }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={`${process.env.PUBLIC_URL}/images/produtos/generico.png`}
                                    title="Pizza exemplo"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Produto 2
                                    </Typography>
                                    <Typography fontWeight={"bold"} variant="body2" color={corSecundaria}>
                                        R$ 40,00
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" sx={{ width: "48%", mt: 2 }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={`${process.env.PUBLIC_URL}/images/produtos/generico.png`}
                                    title="Pizza exemplo"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Produto 3
                                    </Typography>
                                    <Typography fontWeight={"bold"} variant="body2" color={corSecundaria}>
                                        R$ 15,00
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" sx={{ width: "48%", mt: 2 }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={`${process.env.PUBLIC_URL}/images/produtos/generico.png`}
                                    title="Pizza exemplo"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Produto 4
                                    </Typography>
                                    <Typography fontWeight={"bold"} variant="body2" color={corSecundaria}>
                                        R$ 60,00
                                    </Typography>
                                </CardContent>
                            </Card>
                        </CategoriaContainer>
                        <ButtonCarrinhoContainer>
                            <Button variant="contained" sx={{
                                borderRadius: "10px",
                                display: "flex",
                                padding: "1rem",
                                justifyContent: "space-between",
                                width: "90%",
                                background: corPrimaria
                            }}>
                                <Typography fontWeight={"bold"}>
                                    Ver carrinho
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                    R$ 45,00
                                </Typography>
                            </Button>
                        </ButtonCarrinhoContainer>
                    </Paper>
                    <ButtonsContainer>
                        <Button variant="outlined" onClick={handleOpenSair}>
                            SAIR
                        </Button>
                        {loadingSalvar ? (
                            <CircularProgress size={30} />
                        ) : (
                            <Button
                                variant={"contained"}
                                startIcon={<Save />}
                                onClick={handleSalvarPersonalizacao}
                            >
                                SALVAR
                            </Button>
                        )}
                    </ButtonsContainer>
                </ResumoPaper>
            </ContentContainer>
            <Dialog
                open={openSair}
                onClose={handleCloseSair}
            >
                <DialogTitle>
                    Deseja sair?
                </DialogTitle>
                <DialogContent>
                    <Alert severity="warning">
                        As alterações que não foram salvas serão pedidas
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSair}>Cancelar</Button>
                    <Button onClick={() => navigate("/marketplace")} autoFocus variant="contained">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer>
    );
}