import { Box, Chip, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { IHistoricoPedido } from '../../interface/IHistoricoPedido';
import { StatusPedido } from '../../enum/StatusPedido';
import { useDataHora } from '../../../core/hooks/useDataHora';

interface HistoricoPedidoTimelineProps {
    historico: IHistoricoPedido[];
    dataHoraEmissao: string;
}

const HistoricoPedidoTimeline = ({ historico, dataHoraEmissao }: HistoricoPedidoTimelineProps) => {
    const { dataHoraBr } = useDataHora();
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={-1} alternativeLabel>
                <Step key={dataHoraEmissao}>
                    <StepLabel>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography variant="caption">{dataHoraBr(dataHoraEmissao)}</Typography>
                            Pedido emitido
                        </Box>
                    </StepLabel>
                </Step>
                {historico.map((item, index) => (
                    <Step key={index}>
                        <StepLabel>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography variant="caption">{dataHoraBr(item.dataHora)}</Typography>
                                {StatusPedido[item.status]}
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default HistoricoPedidoTimeline;
