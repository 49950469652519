export enum ModeloPrecificacaoPizza {
    "Maior preço" = "maiorPreco",
    "Preço dividido" = "precoDividido",
    "Preço misto" = "precoMisto" 
}

export enum ModeloPrecificacaoPizzaDescricao {
    "maiorPreco" = "Maior preço",
    "precoDividido" = "Preço dividido",
    "precoMisto" = "Preço misto"
}