import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const CardContainer = styled(Paper)`
    padding: 1.5rem;
    width: 100%;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .conteudo {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .icone {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
    }
`