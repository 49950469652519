import { ContentCopy, Download } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { useDownload } from "../../../../../core/hooks/useDownload";
import { useCopiar } from "../../../../../core/hooks/useCopiar";
import { useRef } from "react";
import * as htmlToImage from 'html-to-image';
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import QRCode from "qrcode.react";
import { useSnackbar } from "../../../../../core/contexts/SnackbarContext";

interface QRCodeMarketplacePaperProps {
    marketplace: IMarketplace;
}

export const QRCodeMarketplacePaper = ({ marketplace }: QRCodeMarketplacePaperProps) => {
    const { downloadPorUrl } = useDownload();
    const copiar = useCopiar(
        marketplace?.configuracao?.urlExterna ?? "Link não mapeado",
        "Link copiado para a área de transferência!"
    );

    const qrCodeRef = useRef(null);
    const snackbar = useSnackbar();

    const handleDownloadQRCode = () => {
        if (qrCodeRef.current && marketplace?.configuracao?.slug) {
            htmlToImage.toPng(qrCodeRef.current)
                .then((dataUrl) => {
                    downloadPorUrl(dataUrl, `${marketplace?.configuracao?.slug}.png`);
                })
                .catch((error) => {
                    snackbar('Ocorreu um erro ao fazer o download do QR Code', { severity: "error" });
                });
        }
    }

    return (
        <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography variant="h6">Link público e QR Code</Typography>
                <Button onClick={handleDownloadQRCode} startIcon={<Download />} variant="outlined">Download</Button>
            </Box>
            {marketplace?.configuracao?.urlExterna && (
                <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                    <div ref={qrCodeRef}>
                        <QRCode value={marketplace?.configuracao?.urlExterna} />
                    </div>
                </Box>
            )}
            <TextField
                label="Link público"
                disabled
                value={marketplace.configuracao?.urlExterna ?? "Link não mapeado"}
                fullWidth
                sx={{ mt: 4 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={copiar}>
                                <ContentCopy />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </Paper>
    );
}