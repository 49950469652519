import { Box, CardActionArea, CardContent, Typography } from "@mui/material";
import { TipoProdutoCard, TipoProdutoContainer as TipoProdutoContainerDiv } from "./styles";
import { TipoProduto } from "../../enum/TipoProduto";

interface TipoProdutoContainerProps {
    handleTipoProduto: (tipoProduto: TipoProduto) => void;
}

export const TipoProdutoContainer = ({ handleTipoProduto }: TipoProdutoContainerProps) => {
    return (
        <TipoProdutoContainerDiv>
            <TipoProdutoCard
                onClick={() => handleTipoProduto(TipoProduto.industrializado)}
            >
                <CardActionArea>
                    <CardContent>
                        <Typography component="div" variant="subtitle1">
                            Industrializado
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: "2rem" }}>
                            <img src={`${process.env.PUBLIC_URL}/images/produtos/industrializado.png`} alt="Industrializado" />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </TipoProdutoCard>
            <TipoProdutoCard
                onClick={() => handleTipoProduto(TipoProduto.preparado)}
            >
                <CardActionArea>
                    <CardContent>
                        <Typography component="div" variant="subtitle1">
                            Preparado
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: "2rem" }}>
                            <img src={`${process.env.PUBLIC_URL}/images/produtos/preparado.png`} alt="Preparado" />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </TipoProdutoCard>
            <TipoProdutoCard
                onClick={() => handleTipoProduto(TipoProduto.pizza)}
            >
                <CardActionArea>
                    <CardContent>
                        <Typography component="div" variant="subtitle1">
                            Pizza
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: "2rem" }}>
                            <img src={`${process.env.PUBLIC_URL}/images/produtos/pizza.png`} alt="Pizza" />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </TipoProdutoCard>
        </TipoProdutoContainerDiv>
    );
}